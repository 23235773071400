import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import IndexContratos from "./contratoEmpresa/index";
import zIndex from "@material-ui/core/styles/zIndex";
import "./style.css"

const initialState = {
  cargar: false,

  custom: { EMPRESA: null },
  data: { LOTE: [], MANZANA: [], EMPRESAS: [], TIPO_DE_CONTRATO: [] },
  crear: false,
  modificar: false,
  tabla: true,
};
class index extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  async componentDidMount() {
    const EMPRESAS = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/empresa/All`
    );
    await this.setState({
      data: { ...this.state.data, EMPRESAS: EMPRESAS.data },
    });

    const EMPRESA = await this.state.data.EMPRESAS.find(
      (x) => x.ID_EMPRESA == this.props.auth[0].ID_EMPRESA
    );
    await this.updateStateEMPRESA(EMPRESA);
  }

  updateStateEMPRESA = async (EMPRESA) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        EMPRESA: EMPRESA,
        ID_EMPRESA: EMPRESA.ID_EMPRESA,
      },
    });
    const TIPOS_CONTRATO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/TIPO_CONTRATO_EMPRESA/AllActivo/${EMPRESA.ID_EMPRESA}`
    );
    await this.setState({
      data: { ...this.state.data, TIPOS_CONTRATO: TIPOS_CONTRATO.data },
    });
  };
  updateStateTipo = async (TIPO_DE_CONTRATO) => {
    await this.setState({
      cargar: false,
      custom: {
        ...this.state.custom,
        TIPO_DE_CONTRATO: TIPO_DE_CONTRATO,
      },
    });
    await this.setState({ cargar: true });
  };
  contrato = (e) => {
    if (
      this.state &&
      this.state.custom &&
      this.state.custom.EMPRESA &&
      this.state.custom.TIPO_DE_CONTRATO
    ) {
      return (
        <IndexContratos
          auth={this.props.auth}
          EMPRESA={this.state.custom.EMPRESA}
          TIPO_DE_CONTRATO={this.state.custom.TIPO_DE_CONTRATO}
          Access={this.props.Access}
        />
      );
    } else {
      return "";
    }
  };
  render() {
    return (
      <Fragment>
        <div class="container-fluid row">
          <label class="col-form-label">SELECCIONE EMPRESA</label>
          <div class="col-sm-7 row">
            <Select
            id="SelectTipoContrato1"
              classNamePrefix="select-solo-con"
              className="select-solo"
              onChange={this.updateStateEMPRESA}
              options={this.state.data.EMPRESAS}
              isMulti={false}
              components={makeAnimated()}
              placeholder={"SELECCIONE EMPRESA"}
              getOptionLabel={(options) => options.NOMBRE_EMPRESA}
              getOptionValue={(options) => options.ID_EMPRESA}
              value={this.state.custom.EMPRESA}
            />
          </div>
        </div>
        <div class="container-fluid row" id="SelectTipoContrato2" >
          <label class="col-form-label">TIPO DE CONTRATO</label>
          <div class="col-sm-7 row">
            <Select
            id="SelectTipoContrato2"
              classNamePrefix="select-solo-con"
              className="select-solo"
              onChange={this.updateStateTipo}
              options={this.state.data.TIPOS_CONTRATO}
              isMulti={false}
              components={makeAnimated()}
              placeholder={"SELECCIONE EL TIPO DE CONTRATO"}
              getOptionLabel={(options) => options.NOMBRE_TIPO_CONTRATO}
              getOptionValue={(options) => options.ID_TIPO_CONTRATO_EMPRESA}
              value={this.state.custom.TIPO_DE_CONTRATO}
            />
          </div>
        </div>

        {this.state.cargar && this.contrato()}
        <br />
      </Fragment>
    );
  }
}

export default index;
