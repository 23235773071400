import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet, fetchPostFormData} from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  file: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
};
class CONFIGURACION extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
    const SUCURSAL = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/SUCURSAL/all`
    );
    this.setState({ data: { ...this.state.data, SUCURSAL: SUCURSAL.data } });
  }
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
    
    
    const ID_EMPRESA = await this.props.auth[0].ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    const ID_SUCURSAL = await this.props.auth[0].ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });
  };
  UpdateStateCreate =async (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            file: { ...this.state.file, [name]: e.target.result },
          });
        };
      await  this.setState({ custom: { ...this.state.custom, [name]: e.target.files[0] } });
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/configuracion_Empresa_Lotificadora/createFile`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  UpdateStateCreateFile = (e) => {
    const { name, value } = e.target;
    ;
    this.setState({ custom: { ...this.state.custom, [name]: e.target.files } });
  };

  CreateFile = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();

await this.setState({
  custom:{
    ...this.state.custom,contrato:this.state.custom.contrato[0],
   contrato2:this.state.custom.contrato[0],
  }
})

    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/configuracion_Empresa_Lotificadora/createFile`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/configuracion_Empresa_Lotificadora/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR LA EMPRESA ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/configuracion_Empresa_Lotificadora/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {},file:{}, tabla: false });
      this.setState({ custom: {},file:{}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {},file:{} });
    await this.setState({
      custom: {
        ...this.state.custom,
        ACTIVO_CARGO: ACTIVO_GENERICO[0],
        FECHA_CREACION: new Date(),
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const ACTIVO_CARGO = await ACTIVO_GENERICO.find(
      (x) => x.NOMBRE_ACTIVO_GENERICO == e.ACTIVO_CARGO
    );
    await this.setState({
      custom: { ...this.state.custom, ACTIVO_CARGO: ACTIVO_CARGO },
    });
    const EMPRESA = await this.state.data.EMPRESA.find(
      (x) => x.ID_EMPRESA == e.ID_EMPRESA
    );
    await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    const SUCURSAL = await this.state.data.SUCURSAL.find(
      (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    );
    await this.setState({
      custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    });
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_CARGO") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {/* <form onSubmit={(e) => this.CreateFile(e)} >
        <input type="file" name="contrato"  onChange={this.UpdateStateCreateFile}/>
        <input type="submit" value="Subir"/>
</form> */}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">Crear Empresa pagina de inicio</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                type: "I",
                name: "LOGO_EMPRESA",
                value: this.state.file.LOGO_EMPRESA,
                label: "SUBIR LOGO_EMPRESA",
              },
            
              {
                type: "I",
                name: "CAROUSEL_PRINCIPAL_1",
                value: this.state.file.CAROUSEL_PRINCIPAL_1,
                label: "CAROUSEL_PRINCIPAL_1",
              },
              {
                type: "I",
                name: "CAROUSEL_PRINCIPAL_3",
                value: this.state.file.CAROUSEL_PRINCIPAL_3,
                label: "CAROUSEL_PRINCIPAL_3",
              },
              {
                type: "I",
                name: "CAROUSEL_PRINCIPAL_4",
                value: this.state.file.CAROUSEL_PRINCIPAL_4,
                label: "CAROUSEL_PRINCIPAL_4",
              },
              {
                type: "I",
                name: "CAROUSEL_SECUNDARIO_1",
                value: this.state.file.CAROUSEL_SECUNDARIO_1,
                label: "CAROUSEL_SECUNDARIO_1",
              },
              {
                type: "I",
                name: "CAROUSEL_SECUNDARIO_2",
                value: this.state.file.CAROUSEL_SECUNDARIO_2,
                label: "CAROUSEL_SECUNDARIO_2",
              },
              {
                type: "I",
                name: "CAROUSEL_SECUNDARIO_3",
                value: this.state.file.CAROUSEL_SECUNDARIO_3,
                label: "CAROUSEL_SECUNDARIO_3",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_EMPRESA",
                value: this.state.file.NOMBRE_EMPRESA,
                label: "NOMBRE_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "QUIENES_SOMOS",
                value: this.state.file.QUIENES_SOMOS,
                label: "QUIENES_SOMOS",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_1",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_1,
                label: "SERVICIOS_IMAGENES_GRID_1",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_2",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_2,
                label: "SERVICIOS_IMAGENES_GRID_2",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_3",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_3,
                label: "SERVICIOS_IMAGENES_GRID_3",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_4",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_4,
                label: "SERVICIOS_IMAGENES_GRID_4",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_5",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_5,
                label: "SERVICIOS_IMAGENES_GRID_5",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_6",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_6,
                label: "SERVICIOS_IMAGENES_GRID_6",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_7",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_7,
                label: "SERVICIOS_IMAGENES_GRID_7",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_8",
                value: this.state.file.SERVICIOS_IMAGENES_GRID_8,
                label: "SERVICIOS_IMAGENES_GRID_8",
              },
              {
                config: Text,
                type: "G",
                name: "UBICACION_EMPRESA",
                value: this.state.file.UBICACION_EMPRESA,
                label: "UBICACION_EMPRESA",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION",
                value: this.state.custom.FECHA_CREACION,
                label: "FECHA_CREACION",
              },
              
            ]}
          ></FormsG>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR EMPRESA</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              
                {
                config: Number,
                name: "ID_CONFGURACION_EMPRESA_PAGINA_INICIO",
                value: this.state.custom.ID_CONFGURACION_EMPRESA_PAGINA_INICIO,
                label: "ID_CONFGURACION_EMPRESA_PAGINA_INICIO",
              },
              
              {
                type: "I",
                name: "LOGO_EMPRESA",
                value: this.state.custom.LOGO_EMPRESA,
                label: "SUBIR LOGO_EMPRESA",
              },
            
              {
                type: "I",
                name: "CAROUSEL_PRINCIPAL_1",
                value: this.state.custom.CAROUSEL_PRINCIPAL_1,
                label: "CAROUSEL_PRINCIPAL_1",
              },
              {
                type: "I",
                name: "CAROUSEL_PRINCIPAL_3",
                value: this.state.custom.CAROUSEL_PRINCIPAL_3,
                label: "CAROUSEL_PRINCIPAL_3",
              },
              {
                type: "I",
                name: "CAROUSEL_PRINCIPAL_4",
                value: this.state.custom.CAROUSEL_PRINCIPAL_4,
                label: "CAROUSEL_PRINCIPAL_4",
              },
              {
                type: "I",
                name: "CAROUSEL_SECUNDARIO_1",
                value: this.state.custom.CAROUSEL_SECUNDARIO_1,
                label: "CAROUSEL_SECUNDARIO_1",
              },
              {
                type: "I",
                name: "CAROUSEL_SECUNDARIO_2",
                value: this.state.custom.CAROUSEL_SECUNDARIO_2,
                label: "CAROUSEL_SECUNDARIO_2",
              },
              {
                type: "I",
                name: "CAROUSEL_SECUNDARIO_3",
                value: this.state.custom.CAROUSEL_SECUNDARIO_3,
                label: "CAROUSEL_SECUNDARIO_3",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_EMPRESA",
                value: this.state.custom.NOMBRE_EMPRESA,
                label: "NOMBRE_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "QUIENES_SOMOS",
                value: this.state.custom.QUIENES_SOMOS,
                label: "QUIENES_SOMOS",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_1",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_1,
                label: "SERVICIOS_IMAGENES_GRID_1",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_2",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_2,
                label: "SERVICIOS_IMAGENES_GRID_2",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_3",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_3,
                label: "SERVICIOS_IMAGENES_GRID_3",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_4",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_4,
                label: "SERVICIOS_IMAGENES_GRID_4",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_5",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_5,
                label: "SERVICIOS_IMAGENES_GRID_5",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_6",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_6,
                label: "SERVICIOS_IMAGENES_GRID_6",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_7",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_7,
                label: "SERVICIOS_IMAGENES_GRID_7",
              },
              {
                type: "I",
                name: "SERVICIOS_IMAGENES_GRID_8",
                value: this.state.custom.SERVICIOS_IMAGENES_GRID_8,
                label: "SERVICIOS_IMAGENES_GRID_8",
              },
              {
                config: Text,
                type: "G",
                name: "UBICACION_EMPRESA",
                value: this.state.custom.UBICACION_EMPRESA,
                label: "UBICACION_EMPRESA",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION",
                value: this.state.custom.FECHA_CREACION,
                label: "FECHA_CREACION",
              },
              
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_CARGO") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="EMPRESA"
            url="configuracion_Empresa_Lotificadora"
            responsive="vertical"
            allcolumns={[
              {
                name: "LOGO_EMPRESA",
                label: "LOGO_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACION",
                label: "FECHA_CREACION",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
            },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_EMPRESA_INICIO") ? true : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_EMPRESA_INICIO") ? true : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(CONFIGURACION);
