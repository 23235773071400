import LogoNaranjo from "../../../../assets/img/Naranjo/logo_naranjo.jpg";
import LogoJocotillo from "../../../../assets/img/Jocotillo/logo_jocotillo.jpeg";
import LogoXela from "../../../../assets/img/REBIERA_INMOBILIARIA.jpg";
// import LogoTikal from "../../../../assets/img/tikal/tikal_logo.png";
import LogoHaciendaNaranjo from "../../../../assets/img/Naranjo/logo_naranjo.jpg";
import LogoJocotillo2 from "../../../../assets/img/Jocotillo/logo_jocotillo.jpeg";
import LogoBrisasdelMar from "../../../../assets/img/brisas/logo brisas.png";
import LogoVillasDelRosario from "../../../../assets/img/villas del rosario/logo_villas.jpeg";
import LogoPruebas from "../../../../assets/img/newContrato.jpg";
import LogoSanMartin from "../../../../assets/img/REBIERA_INMOBILIARIA.jpg";
import LogoValle from "../../../../assets/img/EL VALLE/LOGO.jpeg";
import LogoPortalesEstancia from "../../../../assets/img/PORTALES LA ESTANCIA/LOGO.jpeg";
import LogoLomasTeculutan from "../../../../assets/img/teculutan/logo teculutan.png";
import ArbolitosLogo from "../../../../assets/img/Arbolitos/LogoArbolitos.jpg";
import PacayalLogo from "../../../../assets/img/SantaAna/logoSantaAna.jpg";
import ElMartilloLogo from "../../../../assets/img/ElMartillo/LogoElMartillo.jpg";
import PradosLogo from "../../../../assets/img/Prados de Chiquimulilla/logo.jpg";
import PasacoLogo from "../../../../assets/img/Pasaco/PasacoLogo.png";
import LaDichaLogo from "../../../../assets/img/LaDicha/LaDichaLogo.png";
import LasFloresLogo from "../../../../assets/img/LasFlores/LogoLasFlores.png";
import LogoTikal from "../../../../../../App/src/assets/img/tikal/2x/Mesa de trabajo 10.jpg"
import LogoLomaReal from "../../../../../../App/src/assets/img/LOMAREAL/LomaReal2024.png"
import LogoSanJacinto from "../../../../assets/img/HaciendaSanJacinto/Logoajcinto.png"
import SantaElena from "../../../../assets/img/15SANTAELENA_NUEVA/FincaSantaElena.png"
import ElEden from "../../../../assets/img/JardinDelEden/ElEden.png"






export default {
  empresas: [
    { ID_EMPRESA: 1, logoEmpresa: LogoNaranjo },
    { ID_EMPRESA: 2, logoEmpresa: LogoJocotillo },
    { ID_EMPRESA: 3, logoEmpresa: LogoXela },
    { ID_EMPRESA: 4, logoEmpresa: LogoTikal },
    { ID_EMPRESA: 5, logoEmpresa: LogoHaciendaNaranjo },
    { ID_EMPRESA: 6, logoEmpresa: LogoJocotillo2 },
    { ID_EMPRESA: 8, logoEmpresa: LogoBrisasdelMar },
    { ID_EMPRESA: 9, logoEmpresa: LogoVillasDelRosario },
    { ID_EMPRESA: 12, logoEmpresa: LogoPruebas },
    { ID_EMPRESA: 13, logoEmpresa: LogoSanMartin },
    { ID_EMPRESA: 14, logoEmpresa: LogoValle },
    { ID_EMPRESA: 15, logoEmpresa: LogoPortalesEstancia },
    { ID_EMPRESA: 16, logoEmpresa: LogoLomasTeculutan },
    { ID_EMPRESA: 17, logoEmpresa: PacayalLogo },
    { ID_EMPRESA: 18, logoEmpresa: ArbolitosLogo },
    { ID_EMPRESA: 19, logoEmpresa: ElMartilloLogo },
    { ID_EMPRESA: 20, logoEmpresa: PradosLogo },
    { ID_EMPRESA: 22, logoEmpresa: LaDichaLogo },
    { ID_EMPRESA: 23, logoEmpresa: PasacoLogo },
    { ID_EMPRESA: 28, logoEmpresa: LasFloresLogo },
    { ID_EMPRESA: 31, logoEmpresa: LogoLomaReal },
    { ID_EMPRESA: 33, logoEmpresa: LogoSanJacinto },
    { ID_EMPRESA: 34, logoEmpresa: SantaElena },
  ],
};



