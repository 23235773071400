import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet, fetchPostFormData, fetchPostFile } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
  AsingacionIcon,BackupIcon, CloudDownloadIcon, DesktopWindowsIcon
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {}, file: {},
  data: {SUCURSAL:[],
    EMPRESA:[]},
  crear: false,
  subir: false,
  modificar: false,
  tabla: true,
};
class CLIENTE extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({
         custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
    //      const SUCURSAL = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/SUCURSAL/all`
    // );
    // this.setState({ data: { ...this.state.data, SUCURSAL: SUCURSAL.data } });
    // const EMPRESA = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    // );
    // this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
  }
   calcularEdad=(fecha)=> {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
}
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
    const ID_SUCURSAL = await this.props.auth[0].ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });
    const ID_EMPRESA = await this.props.auth[0].ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
  };
  SubirCrear = async (e) => {
    e.preventDefault();
    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/contrato/ruta_contrato`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      subir: false,
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  UpdateStateCreate =async (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            file: { ...this.state.file, [name]: e.target.result },
          });
        };
      await  this.setState({ custom: { ...this.state.custom, [name]: e.target.files[0] } });
      } else {
        const { name, value } = e.target;
        await  this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      await  this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      await  this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
      if(datos && datos[0]=="FECHA_DE_NACIMIENTO_CLIENTE")
      {
        if(datos[1])
          {
            await      this.setState({
                custom:{
                  ...this.state.custom,
                  EDAD_CLIENTE:this.calcularEdad(new Date(datos[1]))
                }
              })
          }
      }

    }
  };


  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CLIENTE/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CLIENTE/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      subir: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL CLIENTE ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/CLIENTE/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false,  subir: false, });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        FECHA_DE_NACIMIENTO_CLIENTE: new Date(),
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false,  subir: false, });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true,  subir: false, });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    // const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    // this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });

    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });

    this.setState({ crear: false, modificar: true, tabla: false,  subir: false, });
  };

  ShowSubirContrato = async (e) => {
    this.setState({ custom: e });
    // const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    // this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });

    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });

    this.setState({ crear: false, modificar: false, tabla: false,  subir: true, });
  };

  DescargarContrato = async (e) => {
    
    if(e.RUTA_CONTRATO_REAL!=null)
    {

    await this.setState({ custom: e });

    const data = await fetchPostFile(
    `${process.env.REACT_APP_SERVER}/api/cliente/descargar_contrato`,this.state.custom
    );
    await this.setState({
  fileNuevo: data,
})

var archivo=await this.state.fileNuevo.blob();


const href = window.URL.createObjectURL(archivo);
const link = document.createElement('a');
link.href = href;
link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.${archivo.type.split('/')[1]}`); //or any other extension
// link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();

await this.setState({ custom: {} });

}
else{
  alert('NO A SUBIDO DOCUMENTO')
}
  };
 VerContrato = async (e) => {

    if(e.RUTA_CONTRATO_REAL!=null)
    {

    await this.setState({ custom: e });

    const data = await fetchPostFile(
    `${process.env.REACT_APP_SERVER}/api/cliente/descargar_contrato`,this.state.custom
    );
    await this.setState({
  fileNuevo: data,
})
var archivo=await this.state.fileNuevo.blob();


const href = window.URL.createObjectURL(archivo);
const link = document.createElement('a');
link.href = href;
// link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.pdf`); //or any other extension
link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();

await this.setState({ custom: {} });

}
else{
  alert('NO A SUBIDO DOCUMENTO')
}
  };
  ShowAsingacion = (e) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/CLIENTE/Estado_Cuenta_cliente/${e.ID_CLIENTE}`
    );
  };
  render() {
    const redireccion = this.props.Access("READ_CLIENTE") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.subir && (
 <Fragment>
    <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">SUBIR ARCHIVO CONTRATO</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={BackupIcon}
                  className="m-3"
                  onClick={this.SubirCrear}
                />
              </div>
            }
            allcolumns={[
              {
                type: "I",
                name: "RUTA_CONTRATO",
                value: this.state.file.RUTA_CONTRATO,
                label: "SUBIR CONTRATO",
              },
            ]}
          ></FormsG>
 </Fragment>
        )}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR CLIENTE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRES_CLIENTE",
                value: this.state.custom.NOMBRES_CLIENTE,
                label: "NOMBRES_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "APELLIDOS_CLIENTE",
                value: this.state.custom.APELLIDOS_CLIENTE,
                label: "APELLIDOS_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NIT_CLIENTE",
                value: this.state.custom.NIT_CLIENTE,
                label: "NIT_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "ESTADO_CIVIL_CLIENTE",
                value: this.state.custom.ESTADO_CIVIL_CLIENTE,
                label: "ESTADO_CIVIL_CLIENTE",
              },
              {
                type: "D",
                subType: "D",
                name: "FECHA_DE_NACIMIENTO_CLIENTE",
                value: this.state.custom.FECHA_DE_NACIMIENTO_CLIENTE,
                label: "FECHA_DE_NACIMIENTO_CLIENTE",
              },
              {
                config: Number,
                type: "G",
                name: "EDAD_CLIENTE",
                value: this.state.custom.EDAD_CLIENTE,
                label: "EDAD_CLIENTE",
                shrink:true,
                readOnly:true
              },
              {
                config: Text,
                type: "G",
                name: "GENERO_CLIENTE",
                value: this.state.custom.GENERO_CLIENTE,
                label: "GENERO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DPI_CLIENTE",
                value: this.state.custom.DPI_CLIENTE,
                label: "DPI_CLIENTE",
              },
             {
                config: Text,
                type: "G",
                name: "TELEFONO_CLIENTE",
                value: this.state.custom.TELEFONO_CLIENTE,
                label: "TELEFONO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NACIONALIDAD_CLIENTE",
                value: this.state.custom.NACIONALIDAD_CLIENTE,
                label: "NACIONALIDAD_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "EMAIL_CLIENTE",
                value: this.state.custom.EMAIL_CLIENTE,
                label: "EMAIL_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_CLIENTE",
                value: this.state.custom.DIRECCION_CLIENTE,
                label: "DIRECCION_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "PROFECION_CLIENTE",
                value: this.state.custom.PROFECION_CLIENTE,
                label: "PROFECION_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_CONYUGE_CLIENTE",
                value: this.state.custom.NOMBRE_CONYUGE_CLIENTE,
                label: "NOMBRE_CONYUGE_CLIENTE",
              },
              {
                config: Number,
                type: "G",
                name: "PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE",
                value: this.state.custom
                  .PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE,
                label: "PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_INSTITUCION_TRABAJO_CLIENTE",
                value: this.state.custom.NOMBRE_INSTITUCION_TRABAJO_CLIENTE,
                label: "NOMBRE_INSTITUCION_TRABAJO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_DEL_TRABAJO_CLIENTE",
                value: this.state.custom.DIRECCION_DEL_TRABAJO_CLIENTE,
                label: "DIRECCION_DEL_TRABAJO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "TELEFONO_TRABAJO_CLIENTE",
                value: this.state.custom.TELEFONO_TRABAJO_CLIENTE,
                label: "TELEFONO_TRABAJO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE",
                value: this.state.custom
                  .PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE,
                label: "PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE",
              },
              {
                config: Number,
                type: "G",
                name: "SUELDO_MENSUAL_CLIENTE",
                value: this.state.custom.SUELDO_MENSUAL_CLIENTE,
                label: "SUELDO_MENSUAL_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE",
                value: this.state.custom
                  .REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE,
                label: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE",
                value: this.state.custom
                  .REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE,
                label: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE",
                value: this.state.custom.REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE,
                label: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRES_CO_DEUDOR_CLIENTE",
                value: this.state.custom.NOMBRES_CO_DEUDOR_CLIENTE,
                label: "NOMBRES_CO_DEUDOR_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "APELLIDOS_CO_DEUDOR_CLIENTE",
                value: this.state.custom.APELLIDOS_CO_DEUDOR_CLIENTE,
                label: "APELLIDOS_CO_DEUDOR_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "TELEFONO_CO_DEUDOR_CLIENTE",
                value: this.state.custom.TELEFONO_CO_DEUDOR_CLIENTE,
                label: "TELEFONO_CO_DEUDOR_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_CO_DEUDOR_CLIENTE",
                value: this.state.custom.DIRECCION_CO_DEUDOR_CLIENTE,
                label: "DIRECCION_CO_DEUDOR_CLIENTE",
              },
            ]}
          ></FormsG>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR CLIENTE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRES_CLIENTE",
                value: this.state.custom.NOMBRES_CLIENTE,
                label: "NOMBRES_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "APELLIDOS_CLIENTE",
                value: this.state.custom.APELLIDOS_CLIENTE,
                label: "APELLIDOS_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NIT_CLIENTE",
                value: this.state.custom.NIT_CLIENTE,
                label: "NIT_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "ESTADO_CIVIL_CLIENTE",
                value: this.state.custom.ESTADO_CIVIL_CLIENTE,
                label: "ESTADO_CIVIL_CLIENTE",
              },
              {
                type: "D",
                subType: "D",
                name: "FECHA_DE_NACIMIENTO_CLIENTE",
                value: this.state.custom.FECHA_DE_NACIMIENTO_CLIENTE,
                label: "FECHA_DE_NACIMIENTO_CLIENTE",
              },
              {
                config: Number,
                type: "G",
                name: "EDAD_CLIENTE",
                value: this.state.custom.EDAD_CLIENTE,
                label: "EDAD_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "GENERO_CLIENTE",
                value: this.state.custom.GENERO_CLIENTE,
                label: "GENERO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DPI_CLIENTE",
                value: this.state.custom.DPI_CLIENTE,
                label: "DPI_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "TELEFONO_CLIENTE",
                value: this.state.custom.TELEFONO_CLIENTE,
                label: "TELEFONO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NACIONALIDAD_CLIENTE",
                value: this.state.custom.NACIONALIDAD_CLIENTE,
                label: "NACIONALIDAD_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "EMAIL_CLIENTE",
                value: this.state.custom.EMAIL_CLIENTE,
                label: "EMAIL_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_CLIENTE",
                value: this.state.custom.DIRECCION_CLIENTE,
                label: "DIRECCION_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "PROFECION_CLIENTE",
                value: this.state.custom.PROFECION_CLIENTE,
                label: "PROFECION_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_CONYUGE_CLIENTE",
                value: this.state.custom.NOMBRE_CONYUGE_CLIENTE,
                label: "NOMBRE_CONYUGE_CLIENTE",
              },
              {
                config: Number,
                type: "G",
                name: "PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE",
                value: this.state.custom
                  .PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE,
                label: "PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_INSTITUCION_TRABAJO_CLIENTE",
                value: this.state.custom.NOMBRE_INSTITUCION_TRABAJO_CLIENTE,
                label: "NOMBRE_INSTITUCION_TRABAJO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_DEL_TRABAJO_CLIENTE",
                value: this.state.custom.DIRECCION_DEL_TRABAJO_CLIENTE,
                label: "DIRECCION_DEL_TRABAJO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "TELEFONO_TRABAJO_CLIENTE",
                value: this.state.custom.TELEFONO_TRABAJO_CLIENTE,
                label: "TELEFONO_TRABAJO_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE",
                value: this.state.custom
                  .PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE,
                label: "PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE",
              },
              {
                config: Number,
                type: "G",
                name: "SUELDO_MENSUAL_CLIENTE",
                value: this.state.custom.SUELDO_MENSUAL_CLIENTE,
                label: "SUELDO_MENSUAL_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE",
                value: this.state.custom
                  .REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE,
                label: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE",
                value: this.state.custom
                  .REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE,
                label: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE",
              },
              {
                config: Text,
                type: "G",
                name: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE",
                value: this.state.custom.REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE,
                label: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE",
              },
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_CLIENTE") && this.props.auth[0].ID_USUARIO!=40 && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="CLIENTE"
            url="CLIENTE"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_COMPLETO",
                label: "NOMBRE COMPLETO",
                display: true,
                type: "text",
              },
              {
                name: "LOTES",
                label: "LOTES",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "ESTADO DE CUENTA",
                type: "button",
                display: this.props.Access("READ_ESTADO_CUENTA_CLIENTE") && this.props.auth[0].ID_USUARIO!=40
                  ? true
                  : "excluded",
                buttonConfig: AsingacionIcon,
                OnClickButton: this.ShowAsingacion,
              },
              {
                name: "SUBIDO",
                label: "SUBIDO",
                display: true,
                type: "text",
              },
              {
                name: "SUBIR CONTRATO PDF",
                type: "button",
                display: this.props.Access("READ_SUBIR_CONTRATO_CLIENTE")
                  ? true
                  : "excluded",
                buttonConfig: BackupIcon,
                OnClickButton: this.ShowSubirContrato,
              },
              {
                name: "DESCARGAR",
                type: "button",
                display: this.props.Access("READ_DOWLOADER_CONTRATO_CLIENTE")
                  ? true
                  : "excluded",
                buttonConfig: CloudDownloadIcon,
                OnClickButton: this.DescargarContrato,
              },
              {
                name: "VER",
                type: "button",
                display: this.props.Access("READ_VER_CONTRATO_CLIENTE")
                  ? true
                  : "excluded",
                buttonConfig: DesktopWindowsIcon,
                OnClickButton: this.VerContrato,
              },

              {
                name: "NOMBRES_CLIENTE",
                label: "NOMBRES_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "APELLIDOS_CLIENTE",
                label: "APELLIDOS_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "NIT_CLIENTE",
                label: "NIT_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "DPI_CLIENTE",
                label: "DPI_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "TELEFONO_CLIENTE",
                label: "TELEFONO_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "DIRECCION_CLIENTE",
                label: "DIRECCION_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_CONYUGE_CLIENTE",
                label: "NOMBRE_CONYUGE_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE",
                label: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE",
                label: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE",
                label: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE",
                display: false,
                type: "text",
              },

              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_CLIENTE") && this.props.auth[0].ID_USUARIO!=40
                  ? true
                  : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_CLIENTE") && this.props.auth[0].ID_USUARIO!=40
                  ? true
                  : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },

            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(CLIENTE);
