import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchPost,
  fetchGet,
  fetchPostFormData,
  fetchPostFile,
} from "../../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JsonStyleManual from "./style";
import ButtonG from "../../../Material-ui/Input/Button/Button";
import EMPRESAS from "./imagenContrato";
import {
  CrearIcon,
  DesktopWindowsIcon,
  ModificarIcon,
} from "../../../Material-ui/Input/Button/ConfigButton";
import TableG from "../../../Material-ui/Generador/TableG";
import Contrato from "./ContratosPorTipo";
import "./hojaDeControl.css";

const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];

const initialState = {
  JsonStyleManual: JsonStyleManual,
  custom: {},
  data: {
    LOTE: [],
    MANZANA: [],
    MANZANA_AGRUPADO: [],
    MANZANA2: [],
    MANZANA_AGRUPADO2: [],
    MANZANA3: [],
    MANZANA_AGRUPADO3: [],
    MANZANA4: [],
    MANZANA_AGRUPADO4: [],
    MANZANA5: [],
    MANZANA_AGRUPADO5: [],
    USUARIO_PERSONAL: [],
    PORCENTAJEANIOSCONTRATO: [],
    GENERO: [],
    DEPARTAMENTO: [],
    TIPO_MUNICIPIO: [],
    TIPO_MUNICIPIO: [],
    BANCO: [],
    TIPO_CUENTA: [],
  },
  Lotes: { lote2: false, lote3: false, lote4: false, lote5: false },
  crear: false,
  modificar: false,
  ver: false,
  tabla: true,
  empresas: EMPRESAS.empresas[0],
};

// const Genero_cliente_contrato = [
//   { ID_GENERO: 1,  NOMBRE_GENERO: "masculino", ESTADO_GENERO:1 },
//   { ID_GENERO: 2,  NOMBRE_GENERO: "femenino", ESTADO_GENERO:0 }
// ];
class CONTRATO extends Component {
  state = {
    ...initialState,
  };

  ClearState = () => {
    this.setState({ ...initialState });
  };
  //   CambioInicio=(item)=>async(e)=>{
  //     e.preventDefault();
  //   await  this.setState({img:imagenes.img[item.index]})
  // }
  // CambioLogo=async(e)=>{
  //   e.preventDefault();
  //   await this.setState({imgSelect:imagenes.imdel munig[this.props.EMPRESA.ID_EMPRESA]})
  // }

  async componentDidMount() {
    let empresa = EMPRESAS.empresas.find(
      (x) => x.ID_EMPRESA == this.props.EMPRESA.ID_EMPRESA
    );

    await this.setState({ empresas: empresa });
    const PORCENTAJEANIOSCONTRATO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/porcentaje_Anios_Contrato/contrato/${this.props.EMPRESA.ID_EMPRESA}`
    );
    await this.setState({
      data: {
        ...this.state.data,
        PORCENTAJEANIOSCONTRATO: PORCENTAJEANIOSCONTRATO.data,
      },
    });

    const MANZANA_AGRUPADO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO_AGRUPADO/${this.props.EMPRESA.ID_EMPRESA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA_AGRUPADO: MANZANA_AGRUPADO.data },
    });

    const USUARIO_PERSONAL = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/USUARIO/USUARIO_PERSONAL_CONTRATO`
    );
    this.setState({
      data: { ...this.state.data, USUARIO_PERSONAL: USUARIO_PERSONAL.data },
    });


    const USUARIO_PERSONAL_COORDINADOR = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/USUARIO/USUARIO_PERSONAL_CONTRATO_COORDINADOR`
    );
    this.setState({
      data: { ...this.state.data, USUARIO_PERSONAL_COORDINADOR: USUARIO_PERSONAL_COORDINADOR.data },
    });






    
    const GENERO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/genero/all`
    );
    this.setState({
      data: { ...this.state.data, GENERO: GENERO.data },
    });
    const DEPARTAMENTO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/DEPARTAMENTO/all`
    );
    this.setState({
      data: { ...this.state.data, DEPARTAMENTO: DEPARTAMENTO.data },
    });
    const MUNICIPIO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MUNICIPIO/all`
    );
    this.setState({ data: { ...this.state.data, MUNICIPIO: MUNICIPIO.data } });

    const BANCO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/BANCO/all`
    );
    this.setState({ data: { ...this.state.data, BANCO: BANCO.data } });
    const CUENTA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/CUENTA/all`
    );
    this.setState({ data: { ...this.state.data, CUENTA: CUENTA.data } });
  }
  updateStateGENERO = async (GENERO) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        GENERO_CLIENTE_CONTRATO: GENERO?.NOMBRE_GENERO,
        GENERO_CLIENTE: GENERO,
        ID_GENERO_CLIENTE: GENERO?.ID_GENERO,
      },
    });
  };

  updateStateGENERO_TESTIGO = async (GENERO) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        GENERO_TESTIGO_CONTRATO: GENERO?.NOMBRE_GENERO,
        GENERO_TESTIGO: GENERO,
        ID_GENERO_TESTIGO: GENERO?.ID_GENERO,
      },
    });
  };
  updateStateBANCO = async (BANCO) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        BANCO_CONTRATO: BANCO.NOMBRE_BANCO,
        NOMBRE_BANCO_COMPLETO: BANCO.NOMBRE_BANCO_COMPLETO,
        BANCO: BANCO,
        ID_BANCO: BANCO.ID_CUENTA,
      },
    });

    const TIPOS_CUENTA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/CUENTA/FindByBanco/${BANCO.ID_BANCO}`
    );
    await this.setState({
      data: { ...this.state.data, TIPOS_CUENTA: TIPOS_CUENTA.data },
    });
  };
  updateStateCUENTA = async (TIPO_CUENTA) => {
    await this.setState({
      cargar: false,
      custom: {
        ...this.state.custom,
        CUENTA_CONTRATO: TIPO_CUENTA.NO_CUENTA,
        // NO_CUENTA: NO_CUENTA.NO_CUENTA
        NOMBRE_CUENTA_CONTRATO: TIPO_CUENTA.NOMBRE_CUENTA,
        TIPO_CUENTA: TIPO_CUENTA,
      },
    });

    await this.setState({ cargar: true });
  };
  updateStateDEPARTAMENTO = async (DEPARTAMENTO) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        DEPARTAMENTO_CLIENTE_CONTRATO: DEPARTAMENTO.NOMBRE_DEPARTAMENTO,
        DEPARTAMENTO: DEPARTAMENTO,
        ID_DEPARTAMENTO: DEPARTAMENTO.ID_DEPARTAMENTO,
      },
    });
    const TIPOS_MUNICIPIO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MUNICIPIO/AllActivo/${DEPARTAMENTO.ID_DEPARTAMENTO}`
    );
    await this.setState({
      data: { ...this.state.data, TIPOS_MUNICIPIO: TIPOS_MUNICIPIO.data },
    });
  };
  updateStateMunicipio = async (TIPO_MUNICIPIO) => {
    await this.setState({
      cargar: false,
      custom: {
        ...this.state.custom,
        MUNICIPIO_CLIENTE_CONTRATO: TIPO_MUNICIPIO.NOMBRE_MUNICIPIO,
        TIPO_MUNICIPIO: TIPO_MUNICIPIO,
      },
    });
    await this.setState({ cargar: true });
  };
  ModificarLotes = (name, value) => async (e) => {
    e.preventDefault();
    await this.setState({
      Lotes: { ...this.state.Lotes, [name]: value },
    });
    await this.CalcularDatos();
  };
  ModificarLotesDirecto = async (name, value) => {
    await this.setState({
      Lotes: { ...this.state.Lotes, [name]: value },
    });
    await this.CalcularDatos();
  };
  UpdatesIds = async () => {
    const ID_BANCO = await this.state.custom.BANCO.ID_BANCO;
    await this.setState({
      custom: { ...this.state.custom, ID_BANCO: ID_BANCO },
    });
    const ID_CUENTA = await this.state.custom.TIPO_CUENTA.ID_CUENTA;
    await this.setState({
      custom: { ...this.state.custom, ID_CUENTA: ID_CUENTA },
    });
    // FINALIZACION DE CUENTA

    const ID_DEPARTAMENTO = await this.state.custom.DEPARTAMENTO
      .ID_DEPARTAMENTO;
    await this.setState({
      custom: { ...this.state.custom, ID_DEPARTAMENTO: ID_DEPARTAMENTO },
    });
    const ID_MUNICIPIO = await this.state.custom.TIPO_MUNICIPIO.ID_MUNICIPIO;
    await this.setState({
      custom: { ...this.state.custom, ID_MUNICIPIO: ID_MUNICIPIO },
    });
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: {
        ...this.state.custom,
        ID_USUARIO: ID_USUARIO,
      },
    });

    // if (this.props.auth[0].ID_USUARIO != 40) {
    //   await this.setState({
    //     custom: {
    //       ...this.state.custom,
    //       ID_USUARIO_ADMIN_OFICINA_CONTRATO: ID_USUARIO,
    //     },
    //   });
    // }
    await this.setState({
      custom: { ...this.state.custom },
    });
    await this.setState({
      custom: {
        ...this.state.custom,
        ID_CLIENTE: null,
        ID_EMPRESA: this.props.EMPRESA.ID_EMPRESA,
        ID_SUCURSAL: this.props.auth[0].ID_SUCURSAL,
        ID_TIPO_CONTRATO: this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO,
        ESTADO: 1,
        CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO:
          this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            .MESES_PORCENTAJE_ANIOS_CONTRATO,
      },
    });
  };
  UpdateStateCreate = async (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        await this.setState({
          custom: { ...this.state.custom, [name]: value },
        });
        if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO:
                    value -
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
                },
              });
            }
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO: (
                  value * 0.14
                ).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO: (
                  value * 0.1
                ).toFixed(2),
              },
            });
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
              },
            });

            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO: (0).toFixed(
                  2
                ),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO: (0).toFixed(2),
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO:
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO -
                    value,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
              },
            });
          }
        }
        if (
          name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
        ) {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO:
                    (
                      this.state.custom
                        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO /
                      value
                    ).toFixed(2),
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0,
              },
            });
          }
        }
        if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2:
                    value -
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
                },
              });
            }
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO2: (
                  value * 0.14
                ).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO2: (
                  value * 0.1
                ).toFixed(2),
              },
            });
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
              },
            });

            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO2:
                  (0).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO2: (0).toFixed(
                  2
                ),
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2:
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2 -
                    value,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
              },
            });
          }
        }
        if (
          name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
        ) {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO2:
                    (
                      this.state.custom
                        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2 /
                      value
                    ).toFixed(2),
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO2: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO2: 0,
              },
            });
          }
        }
        if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3:
                    value -
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
                },
              });
            }
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO3: (
                  value * 0.14
                ).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO3: (
                  value * 0.1
                ).toFixed(2),
              },
            });
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
              },
            });

            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO3:
                  (0).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO3: (0).toFixed(
                  2
                ),
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3:
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3 -
                    value,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
              },
            });
          }
        }
        if (
          name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
        ) {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO3:
                    (
                      this.state.custom
                        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3 /
                      value
                    ).toFixed(3),
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO3: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO3: 0,
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4:
                    value -
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
                },
              });
            }
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO4: (
                  value * 0.14
                ).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO4: (
                  value * 0.1
                ).toFixed(2),
              },
            });
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
              },
            });

            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO4:
                  (0).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO4: (0).toFixed(
                  2
                ),
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4:
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4 -
                    value,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
              },
            });
          }
        }
        if (
          name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
        ) {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO4:
                    (
                      this.state.custom
                        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4 /
                      value
                    ).toFixed(4),
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO4: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO4: 0,
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5:
                    value -
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
                },
              });
            }
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO5: (
                  value * 0.15
                ).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO5: (
                  value * 0.1
                ).toFixed(2),
              },
            });
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
              },
            });

            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO5:
                  (0).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO5: (0).toFixed(
                  2
                ),
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5:
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5 -
                    value,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
              },
            });
          }
        }
        if (
          name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
        ) {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO5:
                    (
                      this.state.custom
                        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5 /
                      value
                    ).toFixed(5),
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO5: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO5: 0,
              },
            });
          }
        }
      }

      await this.CalcularDatos();
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  handleInputChange = async (event) => {
    const target = event.target;
    console.log(target);
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    //console.log(target.checked,"target.checked")//es este
    //heckboxChange.PAGADO=value;

    await this.setState({
      custom: {
        ...this.state.custom,
        [name]: value,
      },
    });
    await this.CalcularDatos();
  };
  UpdateStateCreatedos = async (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        await this.setState({
          custom: { ...this.state.custom, [name]: value },
        });
        if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO:
                    value -
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
                },
              });
            }
            await await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO: (
                  value * 0.14
                ).toFixed(2),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO: (
                  value * 0.1
                ).toFixed(2),
              },
            });
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
              },
            });

            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO: (0).toFixed(
                  2
                ),
                CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO: (0).toFixed(2),
              },
            });
          }
        }

        if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO") {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO:
                    this.state.custom
                      .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO -
                    value,
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0,
              },
            });
          }
        }
        if (
          name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
        ) {
          if (value) {
            if (
              this.state.custom &&
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO
            ) {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO:
                    (
                      this.state.custom
                        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO /
                      value
                    ).toFixed(2),
                },
              });
            } else {
              await this.setState({
                custom: {
                  ...this.state.custom,
                  CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0,
                },
              });
            }
          } else {
            await this.setState({
              custom: {
                ...this.state.custom,
                CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0,
              },
            });
          }
        }
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  UpdateStateCreateFile = (e) => {
    const { name, value } = e.target;
    this.setState({ custom: { ...this.state.custom, [name]: e.target.files } });
  };
  Create = async (e) => {
    e.preventDefault();

    if (
      this.state.custom &&
      this.state.custom
        .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION &&
      this.state.custom
        .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
        .MESES_PORCENTAJE_ANIOS_CONTRATO
    ) {
      if (
        this.state.custom &&
        this.state.custom
          .CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO
      ) {
        await this.UpdatesIds();
        const data = await fetchPost(
          `${process.env.REACT_APP_SERVER}/api/CONTRATO/create`,
          this.state.custom
        );
        this.setState({ mensaje: data.data });
        alert(data.message);
        if (data.recargar) {
          this.setState({
            file: {},
            custom: {},
            crear: false,
            modificar: false,
            ver: false,
            tabla: true,
          });
        }
      } else {
        alert("VERIFICAR LA FECHA DE LA PRIMERA CUOTA");
      }
    } else {
      alert("VERIFICAR EL SALDO A PAGAR EN CUANTOS AÑOS");
    }
  };
  CreateFile = async (e) => {
    e.preventDefault();
    //  await this.UpdatesIds();
    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/CONTRATO/create`,
      this.state.custom.contrato
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      ver: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CONTRATO/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      ver: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL CONTRATO ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/CONTRATO/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        FECHA_CONTRATO: new Date(),
        CONTADO: false,
      },
    });
    await this.setState({
      crear: true,
      modificar: false,
      ver: false,
      tabla: false,
    });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, ver: false, tabla: true });
  };
  updateStateAsesor = (ASESOR_CONTRATO) => {
    this.setState({
      custom: {
        ...this.state.custom,
        ASESOR_CONTRATO: ASESOR_CONTRATO,
        ID_USUARIO_ASESOR_CONTRATO: ASESOR_CONTRATO.ID_USUARIO,
      },
    });
  };
  updateStateCordinador = (COORDINADOR_CONTRATO) => {
    this.setState({
      custom: {
        ...this.state.custom,
        COORDINADOR_CONTRATO: COORDINADOR_CONTRATO,
        ID_USUARIO_ADMIN_OFICINA_CONTRATO: COORDINADOR_CONTRATO.ID_USUARIO,
      },
    });
  };
  updateStateManzana = async (MANZANA) => {
    const LOTE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, LOTE: LOTE.data },
    });

    await this.setState({
      custom: {
        ...this.state.custom,
        MANZANA: MANZANA,
        CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO: MANZANA.NOMBRE_MANZANA,
      },
    });
  };
  updateStateManzana_AGRUPADO = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO: MANZANA_AGRUPADO },
    });
  };
  updateStateManzana_AGRUPADO2 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA2: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO2: MANZANA_AGRUPADO },
    });
  };
  updateStateManzana_AGRUPADO3 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA3: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO3: MANZANA_AGRUPADO },
    });
  };
  updateStateManzana_AGRUPADO4 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA4: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO4: MANZANA_AGRUPADO },
    });
  };
  updateStateManzana_AGRUPADO5 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA5: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO5: MANZANA_AGRUPADO },
    });
  };
  updateStateLote = async (LOTE) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        LOTE: LOTE,
        ID_LOTE: LOTE.ID_LOTE,
        CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO: LOTE.NO_LOTE,
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO: LOTE.TAMANIO_LOTE,
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO:
          LOTE.PRECIO_LOTE_MAS_INSTERES,
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO: LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO:
          LOTE.PRECIO_LOTE_MAS_INSTERES - LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO: LOTE.NO_FINCA_LOTE,
        CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO: LOTE.NO_FOLIO_LOTE,
        CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO: LOTE.NO_LIBRO_LOTE,
      },
    });
    await this.CalcularDatos();
  };
  updateStateManzana2 = async (MANZANA) => {
    const LOTE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, LOTE2: LOTE.data },
    });

    await this.setState({
      custom: {
        ...this.state.custom,
        MANZANA2: MANZANA,
        CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO2: MANZANA.NOMBRE_MANZANA,
      },
    });
  };
  updateStateLote2 = async (LOTE) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        LOTE2: LOTE,
        ID_LOTE2: LOTE.ID_LOTE,
        CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO2: LOTE.NO_LOTE,
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2: LOTE.TAMANIO_LOTE,
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2:
          LOTE.PRECIO_LOTE_MAS_INSTERES,
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2: LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2:
          LOTE.PRECIO_LOTE_MAS_INSTERES - LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2: LOTE.NO_FINCA_LOTE,
        CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2: LOTE.NO_FOLIO_LOTE,
        CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2: LOTE.NO_LIBRO_LOTE,
      },
    });
    await this.CalcularDatos();
  };
  updateStateManzana3 = async (MANZANA) => {
    const LOTE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, LOTE3: LOTE.data },
    });

    await this.setState({
      custom: {
        ...this.state.custom,
        MANZANA3: MANZANA,
        CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO3: MANZANA.NOMBRE_MANZANA,
      },
    });
  };
  updateStateLote3 = async (LOTE) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        LOTE3: LOTE,
        ID_LOTE3: LOTE.ID_LOTE,
        CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO3: LOTE.NO_LOTE,
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3: LOTE.TAMANIO_LOTE,
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3:
          LOTE.PRECIO_LOTE_MAS_INSTERES,
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3: LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3:
          LOTE.PRECIO_LOTE_MAS_INSTERES - LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3: LOTE.NO_FINCA_LOTE,
        CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3: LOTE.NO_FOLIO_LOTE,
        CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3: LOTE.NO_LIBRO_LOTE,
      },
    });
    await this.CalcularDatos();
  };

  updateStateManzana4 = async (MANZANA) => {
    const LOTE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, LOTE4: LOTE.data },
    });

    await this.setState({
      custom: {
        ...this.state.custom,
        MANZANA4: MANZANA,
        CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO4: MANZANA.NOMBRE_MANZANA,
      },
    });
  };
  updateStateLote4 = async (LOTE) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        LOTE4: LOTE,
        ID_LOTE4: LOTE.ID_LOTE,
        CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO4: LOTE.NO_LOTE,
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4: LOTE.TAMANIO_LOTE,
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4:
          LOTE.PRECIO_LOTE_MAS_INSTERES,
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4: LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4:
          LOTE.PRECIO_LOTE_MAS_INSTERES - LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4: LOTE.NO_FINCA_LOTE,
        CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4: LOTE.NO_FOLIO_LOTE,
        CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4: LOTE.NO_LIBRO_LOTE,
      },
    });
    await this.CalcularDatos();
  };

  updateStateManzana5 = async (MANZANA) => {
    const LOTE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, LOTE5: LOTE.data },
    });

    await this.setState({
      custom: {
        ...this.state.custom,
        MANZANA5: MANZANA,
        CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO5: MANZANA.NOMBRE_MANZANA,
      },
    });
  };
  updateStateLote5 = async (LOTE) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        LOTE5: LOTE,
        ID_LOTE5: LOTE.ID_LOTE,
        CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO5: LOTE.NO_LOTE,
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5: LOTE.TAMANIO_LOTE,
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5:
          LOTE.PRECIO_LOTE_MAS_INSTERES,
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5: LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5:
          LOTE.PRECIO_LOTE_MAS_INSTERES - LOTE.ENGANCHE_LOTE,
        CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5: LOTE.NO_FINCA_LOTE,
        CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5: LOTE.NO_FOLIO_LOTE,
        CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5: LOTE.NO_LIBRO_LOTE,
      },
    });
    await this.CalcularDatos();
  };
  updateStateCuotas = async (
    CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
  ) => {
    await this.setState({
      custom: {
        ...this.state.custom,
        CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION:
          CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION,
      },
    });
    await this.CalcularDatos();
  };

  CalcularDatos = async () => {
    let CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL = 0; //precio
    let CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL = 0; //enganceh
    let CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL = 0; //saldo
    let CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL = 0; //metros

    if (
      this.state.custom &&
      this.state.custom
        .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION &&
      this.state.custom
        .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
        .MESES_PORCENTAJE_ANIOS_CONTRATO
    ) {
      let Meses = await this.state.custom
        .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
        .MESES_PORCENTAJE_ANIOS_CONTRATO;
      let INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO = !this.state.custom
        .CONTADO
        ? await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            .INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
        : 0;
      let BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO = !this.state.custom.CONTADO
        ? await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            .BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
        : 1;
      let IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO = !this.state.custom.CONTADO
        ? await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            .IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
        : 0;
      let InteresAnual = INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO / 12;
      //lote 1
      let Precio = await this.state.custom
        .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO;
      let Enganche = await this.state.custom
        .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO;
      let Saldo = await this.state.custom
        .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO;
      let Metraje = await this.state.custom
        .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO;

      let Base = 0;
      let Iva = 0;
      let anios = 0;
      if (!this.state.custom.CONTADO) {
        Base = parseFloat(
          (Precio / BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
        );
        Iva = parseFloat(
          (Base * IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
        );
        anios =
          (Saldo * InteresAnual * (1 + InteresAnual) ** Meses) /
            ((1 + InteresAnual) ** Meses - 1) +
          Iva / Meses;
        anios = parseFloat(anios.toFixed(2));
      } else {
        Base = 0;
        Iva = 0;
        anios = Saldo / Meses;
        anios = parseFloat(anios.toFixed(2));
      }

      CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
        parseFloat(
          CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
        ) + parseFloat(Precio);
      CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
        parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
        parseFloat(Enganche);
      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
        parseFloat(
          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
        ) + parseFloat(Saldo);
      CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL =
        parseFloat(CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL) +
        parseFloat(Metraje);

      if (this.state && this.state.Lotes && this.state.Lotes.lote2) {
        let Precio2 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2;
        let Enganche2 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2;
        let Saldo2 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2;
        let Metraje2 = await this.state.custom
          .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2;
        let Base2 = 0;
        let Iva2 = 0;
        let anios2 = 0;
        if (!this.state.custom.CONTADO) {
          Base2 = parseFloat(
            (Precio2 / BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          Iva2 = parseFloat(
            (Base2 * IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          anios2 =
            (Saldo2 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva2 / Meses;
          anios2 = parseFloat(anios2.toFixed(2));
        } else {
          Base2 = 0;
          Iva2 = 0;
          anios2 = Saldo2 / Meses;
          anios2 = parseFloat(anios2.toFixed(2));
        }
        Precio = Precio + Precio2;
        Enganche = Enganche + Enganche2;
        Saldo = Saldo + Saldo2;
        Metraje = Metraje + Metraje2;
        Base = Base + Base2;
        Iva = Iva + Iva2;
        anios = anios + anios2;

        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Precio2);
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Enganche2);
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Saldo2);
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Metraje2);
      }

      if (this.state && this.state.Lotes && this.state.Lotes.lote3) {
        let Precio3 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3;
        let Enganche3 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3;
        let Saldo3 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3;
        let Metraje3 = await this.state.custom
          .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3;
        let Base3 = 0;
        let Iva3 = 0;
        let anios3 = 0;
        if (!this.state.custom.CONTADO) {
          Base3 = parseFloat(
            (Precio3 / BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          Iva3 = parseFloat(
            (Base3 * IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          anios3 =
            (Saldo3 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva3 / Meses;
          anios3 = parseFloat(anios3.toFixed(2));
        } else {
          Base3 = 0;
          Iva3 = 0;
          anios3 = Saldo3 / Meses;
          anios3 = parseFloat(anios3.toFixed(2));
        }
        Precio = Precio + Precio3;
        Enganche = Enganche + Enganche3;
        Saldo = Saldo + Saldo3;
        Metraje = Metraje + Metraje3;
        Base = Base + Base3;
        Iva = Iva + Iva3;
        anios = anios + anios3;

        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Precio3);
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Enganche3);
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Saldo3);
      }

      if (this.state && this.state.Lotes && this.state.Lotes.lote4) {
        let Precio4 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4;
        let Enganche4 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4;
        let Saldo4 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4;
        let Metraje4 = await this.state.custom
          .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4;
        let Base4 = 0;
        let Iva4 = 0;
        let anios4 = 0;
        if (!this.state.custom.CONTADO) {
          Base4 = parseFloat(
            (Precio4 / BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          Iva4 = parseFloat(
            (Base4 * IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          anios4 =
            (Saldo4 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva4 / Meses;
          anios4 = parseFloat(anios4.toFixed(2));
        } else {
          Base4 = 0;
          Iva4 = 0;
          anios4 = Saldo4 / Meses;
          anios4 = parseFloat(anios4.toFixed(2));
        }
        Precio = Precio + Precio4;
        Enganche = Enganche + Enganche4;
        Saldo = Saldo + Saldo4;
        Metraje = Metraje + Metraje4;
        Base = Base + Base4;
        Iva = Iva + Iva4;
        anios = anios + anios4;
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Precio4);
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Enganche4);
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Saldo4);
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Metraje4);
      }
      if (this.state && this.state.Lotes && this.state.Lotes.lote5) {
        let Precio5 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5;
        let Enganche5 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5;
        let Saldo5 = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5;
        let Metraje5 = await this.state.custom
          .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5;
        let Base5 = 0;
        let Iva5 = 0;
        let anios5 = 0;
        if (!this.state.custom.CONTADO) {
          Base5 = parseFloat(
            (Precio5 / BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          Iva5 = parseFloat(
            (Base5 * IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO).toFixed(2)
          );
          anios5 =
            (Saldo5 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva5 / Meses;
          anios5 = parseFloat(anios5.toFixed(2));
        } else {
          Base5 = 0;
          Iva5 = 0;
          anios5 = Saldo5 / Meses;
          anios5 = parseFloat(anios5.toFixed(2));
        }
        Precio = Precio + Precio5;
        Enganche = Enganche + Enganche5;
        Saldo = Saldo + Saldo5;
        Metraje = Metraje + Metraje5;
        Base = Base + Base5;
        Iva = Iva + Iva5;
        anios = anios + anios5;
        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Precio5);
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Enganche5);
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Saldo5);
        CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Metraje5);
      }
      await this.setState({
        custom: {
          ...this.state.custom,
          CALCULO_INTERES_PORCENTAJE_CONTRATO:
            // this.state.custom
            //   .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            //.
            INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO,
          CALCULO_IVA_PORCENTAJE_CONTRATO:
            // this.state.custom
            //  .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            //.
            IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO,
          CALCULO_BASE_PORCENTAJE_CONTRATO:
            //   this.state.custom
            ////   .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
            // .
            BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO,
          CALCULO_MESES_CONTRATO:
            this.state.custom
              .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
              .MESES_PORCENTAJE_ANIOS_CONTRATO,
          CALCULO_BASE_CONTRATO: Base,
          CALCULO_INTERESANUAL: InteresAnual,
          CALCULO_IVA_CONTRATO: Iva,
          CALCULO_ANIOS_CONTRATO: anios,
          ID_PORCENTAJE_ANIOS_CONTRATO:
            this.state.custom
              .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
              .ID_PORCENTAJE_ANIOS_CONTRATO,
          CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL:
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL.toFixed(
              2
            ),
          CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL:
            CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL.toFixed(2),
          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL:
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL.toFixed(
              2
            ),
          CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL:
            CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL.toFixed(2),
        },
      });

      await this.setState({
        custom: {
          ...this.state.custom,
          CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO: (
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL * 0.14
          ).toFixed(2),
          CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO: (
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL * 0.1
          ).toFixed(2),
        },
      });

      await this.setState({
        calculoManual: {
          Meses,
          Precio,
          Enganche,
          Saldo,
          Base,
          InteresAnual,
          Iva,
          anios,
        },
        custom: {
          ...this.state.custom,
          CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO:
            anios.toFixed(2),
        },
      });
    }
  };

  ShowReadindividual = async (e) => {
    const BANCO = await this.state.data.BANCO.find(
      (x) => x.NOMBRE_BANCO == e.BANCO_CONTRATO
    );

    const CUENTA = await this.state.data.TIPO_CUENTA.find(
      (x) => x.NOMBRE_CUENTA == e.CUENTA_CONTRATO
    );

    await this.setState({
      custom: { ...this.state.custom, CUENTA: CUENTA },
    });
    const DEPARTAMENTO = await this.state.data.DEPARTAMENTO.find(
      (x) => x.NOMBRE_DEPARTAMENTO == e.DEPARTAMENTO_CLIENTE_CONTRATO
    );
    await this.setState({
      custom: { ...this.state.custom, DEPARTAMENTO: DEPARTAMENTO },
    });
    const MUNICIPIO = await this.state.data.TIPO_MUNICIPIO.find(
      (x) => x.NOMBRE_MUNICIPIO == e.MUNICIPIO_CLIENTE_CONTRATO
    );

    await this.setState({
      custom: { ...this.state.custom, MUNICIPIO: MUNICIPIO },
    });

    const FindLotes = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/contrato/FindLotes/${e.ID_CONTRATO}`
    );

    e.LOTES_CONTRATO_VISTA = FindLotes.data;
    await this.setState({ custom: e });

    // const ESTUDIANTE = await this.state.data.ESTUDIANTE.find(
    //   (x) => x.ID_ESTUDIANTE == e.ID_ESTUDIANTE
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, ESTUDIANTE: ESTUDIANTE },
    // });
    // const ENCARGADO = await this.state.data.ENCARGADO.find(
    //   (x) => x.ID_ENCARGADO == e.ID_ENCARGADO
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, ENCARGADO: ENCARGADO },
    // });
    // const ASIGNACION_CICLO_ESCOLAR = await this.state.data.ASIGNACION_CICLO_ESCOLAR.find(
    //   (x) => x.ID_ASIGNACION_CICLO_ESCOLAR == e.ID_ASIGNACION_CICLO_ESCOLAR
    // );
    // await this.setState({
    //   custom: {
    //     ...this.state.custom,
    //     ASIGNACION_CICLO_ESCOLAR: ASIGNACION_CICLO_ESCOLAR,
    //   },
    // });
    this.setState({ crear: false, ver: true, modificar: false, tabla: false });
  };

  ConvertFecha = (fecha) => {
    const nuevaFecha = new Date(fecha).toLocaleString();
    //"2021-12-03"
    const separado = nuevaFecha.split(" ");
    const separadoFecha = separado[0].split("/");

    return `${separadoFecha[2]}-${separadoFecha[1].length == 1 ? "0" : ""}${
      separadoFecha[1]
    }-${separadoFecha[0].length == 1 ? "0" : ""}${separadoFecha[0]}`;
  };

  ShowUpdate = async (e) => {
    try {
      await this.setState({ custom: null });

      const FindLotes = await fetchGet(
        `${process.env.REACT_APP_SERVER}/api/contrato/FindLotes/${e.ID_CONTRATO}`
      );

      e.LOTES_CONTRATO_VISTA = FindLotes.data;

      //banco cuenta Bam	00001231232
      const BANCO = await this.state.data.BANCO.find(
        (x) => x.NOMBRE_BANCO == e.BANCO_CONTRATO
      );
      if (BANCO) {
        await this.updateStateBANCO(BANCO).then(() => {
          const CUENTA = this.state.data.TIPOS_CUENTA.find(
            (x) => x.NO_CUENTA == e.CUENTA_CONTRATO
          );
          this.updateStateCUENTA(CUENTA);
        });
      }

      //genero
      const GENERO = await this.state.data.GENERO.find(
        (x) => x.NOMBRE_GENERO == e.GENERO_CLIENTE_CONTRATO
      );
      await this.updateStateGENERO(GENERO);
      //genero testigo
      const GENERO_TESTIGO = await this.state.data.GENERO.find(
        (x) => x.NOMBRE_GENERO == e.GENERO_TESTIGO_CONTRATO
      );
      await this.updateStateGENERO_TESTIGO(GENERO_TESTIGO);
      //departamento y municipio

      const DEPARTAMENTO = await this.state.data.DEPARTAMENTO.find(
        (x) => x.NOMBRE_DEPARTAMENTO == e.DEPARTAMENTO_CLIENTE_CONTRATO
      );

      if (DEPARTAMENTO) {
        await this.updateStateDEPARTAMENTO(DEPARTAMENTO).then(() => {
          const MUNICIPIO = this.state.data.TIPOS_MUNICIPIO.find(
            (x) => x.NOMBRE_MUNICIPIO == e.MUNICIPIO_CLIENTE_CONTRATO
          );
          this.updateStateMunicipio(MUNICIPIO);
        });
      }

      const CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION =
        await this.state.data.PORCENTAJEANIOSCONTRATO.find(
          (x) =>
            x.MESES_PORCENTAJE_ANIOS_CONTRATO ==
            e.CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO
        );

      //id lote 1,
      const lote1 = FindLotes.data.find((x) => x.ID_LOTE == e.ID_LOTE);
      if (lote1) {
        await this.updateStateManzana_AGRUPADO({
          UBICACION_MANZANA: lote1?.UBICACION_MANZANA,
        });
        await this.updateStateManzana(lote1);
        await this.setState({
          custom: {
            ...this.state.custom,
            LOTE: lote1,
          },
        });
      }

      const lote2 = FindLotes.data.find((x) => x.ID_LOTE == e.ID_LOTE2);
      if (lote2) {
        await this.ModificarLotesDirecto("lote2", true);
        await this.updateStateManzana_AGRUPADO2({
          UBICACION_MANZANA: lote2?.UBICACION_MANZANA,
        });
        await this.updateStateManzana2(lote2);
        await this.setState({
          custom: {
            ...this.state.custom,
            LOTE2: lote2,
          },
        });
      }

      const lote3 = FindLotes.data.find((x) => x.ID_LOTE == e.ID_LOTE3);
      if (lote3) {
        await this.ModificarLotesDirecto("lote3", true);
        await this.updateStateManzana_AGRUPADO3({
          UBICACION_MANZANA: lote3?.UBICACION_MANZANA,
        });
        await this.updateStateManzana3(lote3);
        await this.setState({
          custom: {
            ...this.state.custom,
            LOTE3: lote3,
          },
        });
      }
      const lote4 = FindLotes.data.find((x) => x.ID_LOTE == e.ID_LOTE4);
      if (lote4) {
        await this.ModificarLotesDirecto("lote4", true);

        await this.updateStateManzana_AGRUPADO4({
          UBICACION_MANZANA: lote4?.UBICACION_MANZANA,
        });
        await this.updateStateManzana4(lote4);
        await this.setState({
          custom: {
            ...this.state.custom,
            LOTE4: lote4,
          },
        });
      }
      const lote5 = FindLotes.data.find((x) => x.ID_LOTE == e.ID_LOTE5);
      if (lote5) {
        await this.ModificarLotesDirecto("lote5", true);

        await this.updateStateManzana_AGRUPADO5({
          UBICACION_MANZANA: lote5?.UBICACION_MANZANA,
        });
        await this.updateStateManzana5(lote5);
        await this.setState({
          custom: {
            ...this.state.custom,
            LOTE5: lote5,
          },
        });
      }

      debugger;
      await this.setState({
        custom: {
          ...this.state.custom,
          ...e,
          //NOMBRE DE ASESOR DE VENTAS
          ASESOR_CONTRATO: this.state.data.USUARIO_PERSONAL.find(
            (x) => x.ID_USUARIO == e.ID_USUARIO_ASESOR_CONTRATO
          ),
          ID_USUARIO_ASESOR_CONTRATO: e.ID_USUARIO_ASESOR_CONTRATO,
          //contado
          CONTADO: e.CONTADO,
          //CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO
          CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION:
            CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION,
          //fechas
          CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO:
            this.ConvertFecha(
              e.CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO
            ),
          TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO: this.ConvertFecha(
            e.TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO
          ),
          DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO:
            this.ConvertFecha(
              e.DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO
            ),
          FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO: this.ConvertFecha(
            e.FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO
          ),
          CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO:
            this.ConvertFecha(
              e.CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO
            ),
          FECHA_FIRMA_CONTRATO: this.ConvertFecha(e.FECHA_FIRMA_CONTRATO),
        },
      });

      //fechas
      //municipio y departamento
      //sector, manzana lote

      // const ESTUDIANTE = await this.state.data.ESTUDIANTE.find(
      //   (x) => x.ID_ESTUDIANTE == e.ID_ESTUDIANTE
      // );
      // await this.setState({
      //   custom: { ...this.state.custom, ESTUDIANTE: ESTUDIANTE },
      // });
      // const ENCARGADO = await this.state.data.ENCARGADO.find(
      //   (x) => x.ID_ENCARGADO == e.ID_ENCARGADO
      // );
      // await this.setState({
      //   custom: { ...this.state.custom, ENCARGADO: ENCARGADO },
      // });
      // const ASIGNACION_CICLO_ESCOLAR = await this.state.data.ASIGNACION_CICLO_ESCOLAR.find(
      //   (x) => x.ID_ASIGNACION_CICLO_ESCOLAR == e.ID_ASIGNACION_CICLO_ESCOLAR
      // );
      // await this.setState({
      //   custom: {
      //     ...this.state.custom,
      //     ASIGNACION_CICLO_ESCOLAR: ASIGNACION_CICLO_ESCOLAR,
      //   },
      // });
      this.setState({
        crear: false,
        modificar: true,
        ver: false,
        tabla: false,
      });
    } catch (error) {
      alert("verificar con soporte este contrato");
      console.error(error);
    }
  };
  descargarheader = async () => {
    // var element = document.createElement('a');
    // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    // element.setAttribute('download', filename);

    // element.style.display = 'none';
    // document.body.appendChild(element);

    // element.click();

    // document.body.removeChild(element);

    const data = await fetchPostFile(
      `${process.env.REACT_APP_SERVER}/api/contrato/header`,
      this.state.custom
    );
    await this.setState({
      fileNuevo: data,
    });

    var archivo = await this.state.fileNuevo.blob();
    const href = window.URL.createObjectURL(archivo);
    const link = document.createElement("a");
    link.href = href;
    //link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.pdf`); //or any other extension
    link.setAttribute("download", `header.htm`); //or any other extension

    //link.setAttribute('target', '_blank'); //or any other extension

    document.body.appendChild(link);
    link.click();
  };
  imprimirComponente2 = (e) => {
    e.preventDefault();
    var mywindow = window.open("", "PRINT");
    // <title>` + document.title  + `</title> <style type="text/css">

    // line-height: 2.9 !important;

    const datos = this.state.JsonStyleManual[0].text;
    const style = ` <style>${datos}
  .footerB{
    display: flex;
    justify-content: space-around;
  }
  #contratoOriginal{
    text-align: justify;
line-height: 2.7 !important;
font-size: 17px !important;
    padding-top: 6cm !important;
    padding-left:  5.8cm !important;
    padding-bottom: 3.8cm !important;
    padding-right:  1cm !important;
  }
#imprimible{
  font-size 12px !important;
  line-height:0;
}
  #contenidosContrato3{
    text-align: right;
    font-size: 14px;
line-height: 2.9 !important;
padding-top: 235px;
    padding-left: 30px;
    padding-bottom: 140px;
    padding-right: 195px;
  }

  #h5-contrato {
    text-align: center;
    padding: 5px 0px 5px;
    text-decoration: underline rgb(24, 13, 13);
  }

  #img-contrato {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    border-radius: 70px;
  }
  #cabecera {
    height: 200px;
    margin-top: 20px;
    margin-left: 70px;
    margin-right: 70px;
    margin-bottom: 20px;
    font-size: 13px;
    text-align: center;
    color: #0c0707;
    background-color: white;
  }
  #container-contrato {
    border: 1px solid rgb(24, 48, 33);

  }
  #bodyAll {
    padding-left: 20px;
    padding-right: 20px;
  }
  </style>`;
    mywindow.document.write(`<html><head>
<title>Lotificadora</title>
${style}
     `);
    mywindow.document.write("</head><body>");
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    //
    // var el = document.getElementById('contratoOriginal2');
    // var el2 = document.getElementById('contratoOriginal2');
    // var divHeight2 = el2.offsetHeight
    // var lineHeight2 = parseInt(el2.style.lineHeight);
    // var lines2 = divHeight2 / lineHeight2;
    // alert("Lines: " + lines2);
    var documentoImpresion = document.getElementById("imprimible").innerHTML;
    //

    //     // var doc = document.implementation.createHTMLDocument();
    //     //  doc.write(`<div id="contratoOriginal"   style="line-height: 2.7 !important;">`+
    //     //             documentoImpresion.slice(53,2533)
    //     //   +`</div>`
    //     //         );
    //             documentoImpresion=`<div id="contratoOriginal"   style="line-height: 2.7 !important;">`+
    //             documentoImpresion.slice(53,2533)
    //   +`</div>`;
    //

    //  var documentoImpresion=""

    //   if (htmlUnDiv.length > 0) {
    //     var res1 = htmlUnDiv.slice(0, 2162)
    //     documentoImpresion=`
    //     <div align="center">
    //     <img
    //       src='https://rebierainmobiliaria.com${this.state.empresas.logoEmpresa}'
    //       alt="logo de la empresa"
    //       width="200" height="100"
    //     />
    //   </div>
    //   <div id='contenidosContrato'>
    //     `+res1+`</div>`;
    // }

    mywindow.document.write(documentoImpresion);
    mywindow.document.write("</body></html>");
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print("", "");
      setTimeout(function () {
        mywindow.close();
      }, 6000);
    }, 200);

    // otra forma
  };

  Export2Doc = async (filename) => {
    // this.descargarheader("header.htm",this.state.header);
    await this.descargarheader();

    const datos = this.state.JsonStyleManual[0].text;
    // #contenidosContrato{
    //   text-align: justify;

    //   line-height: 232%;
    //   font-size: 13;

    //   border: 2px solid white;
    // }
    //margin ://superior, derecho,inferior,izquierdo
    var salto = `
#SaltoPagina{
  ms-special-character:line-break;
  page-break-before:always
}

    #contratoOriginalDis
    {
      font-family: Arial, Helvetica, sans-serif !important;
      text-align: justify !important;
     line-height: 24pt !important;
      font-size: 13px !important;
    }
    @page
    { mso-mirror-margins:yes;
    mso-footnote-separator:url("header.htm") fs;
    mso-footnote-continuation-separator:url("header.htm") fcs;
    mso-endnote-separator:url("header.htm") es;
    mso-endnote-continuation-separator:url("header.htm") ecs;
    mso-facing-pages:yes;}
    @page Section1
    {size:612.0pt 936.0pt;
    margin:5.0cm 47.05pt 110.55pt 5.0cm;
    mso-header-margin:35.4pt;
    mso-footer-margin:35.4pt;
    mso-even-header:url("header.htm") eh1;
    mso-header:url("header.htm") h1;
    mso-paper-source:0;
     font-family: Arial, Helvetica, sans-serif !important;
      text-align: justify !important;
     line-height: 24pt !important;
      font-size: 13px !important;
    }

      div.Section1
      {
          page:Section1;
      }

#img-contrato {
  display: inline-block !important;
  margin-top: 10px  !important;
  margin-bottom: 10px !important;
  width: 40px !important;
  border-radius: 70px !important;
}
    `;

    var preHtml = `<html xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset='utf-8'><title>Export HTML To Doc</title>
    <style type='text/css'>
  ${salto}
    </style>
    </head><body>`;
    var postHtml = "</body></html>";
    var html = document.getElementById("contratoOriginal").innerHTML;

    var datosss = `<span lang=ES-GT style='font-size:10.0pt;mso-fareast-font-family:"Arial";mso-ansi-language:ES-GT;mso-fareast-language:EN-US;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'></span>`;

    let htmlnew = "";

    //   if (html.length > 0) {

    //     var res1 = html.slice(0, 2162)
    htmlnew =
      `
     <div class='Section1'>
     <p class=MsoNormal style='text-align:justify;line-height:24.0pt'><!--[if gte vml 1]><v:shapetype
     id="_x0000_t75" coordsize="21600,21600" o:spt="75" o:preferrelative="t"
     path="m@4@5l@4@11@9@11@9@5xe" filled="f" stroked="f">
     <v:stroke joinstyle="miter"/>
     <v:formulas>
      <v:f eqn="if lineDrawn pixelLineWidth 0"/>
      <v:f eqn="sum @0 1 0"/>
      <v:f eqn="sum 0 0 @1"/>
      <v:f eqn="prod @2 1 2"/>
      <v:f eqn="prod @3 21600 pixelWidth"/>
      <v:f eqn="prod @3 21600 pixelHeight"/>
      <v:f eqn="sum @0 0 1"/>
      <v:f eqn="prod @6 1 2"/>
      <v:f eqn="prod @7 21600 pixelWidth"/>
      <v:f eqn="sum @8 21600 0"/>
      <v:f eqn="prod @7 21600 pixelHeight"/>
      <v:f eqn="sum @10 21600 0"/>
     </v:formulas>
     <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
     <o:lock v:ext="edit" aspectratio="t"/>
    </v:shapetype><v:shape id="Imagen_x0020_1" o:spid="_x0000_s1026" type="#_x0000_t75"
     alt="logo de la empresa" style='position:absolute;left:0;text-align:left;
     margin-left:136.2pt;margin-top:-87.35pt;width:150pt;height:75pt;z-index:-251658240;
     visibility:visible;mso-wrap-style:square;mso-wrap-distance-left:9pt;
     mso-wrap-distance-top:0;mso-wrap-distance-right:9pt;
     mso-wrap-distance-bottom:0;mso-position-horizontal:absolute;
     mso-position-horizontal-relative:text;mso-position-vertical:absolute;
     mso-position-vertical-relative:text'>
     <v:imagedata src="https://rebierainmobiliaria.com${this.state.empresas.logoEmpresa}"/>
    </v:shape><![endif]--><![if !vml]><span style='mso-ignore:vglayout;position:
    relative;z-index:-1895825408'><span style='left:0px;position:absolute;
    left:182px;top:-116px;width:200px;height:100px'><img width=200 height=100
    src="https://rebierainmobiliaria.com${this.state.empresas.logoEmpresa}"
    alt="logo de la empresa" v:shapes="Imagen_x0020_1"></span></span><![endif]>

       ` +
      html +
      `</div>`;

    // }

    // htmlnew+=datosss;

    //   if (html.length > 2162) {
    //     var res2 = html.slice(2163, 4328);
    //     htmlnew+=`<div class='Section2'>`+res2+`</div>`;

    //   }

    //   if (html.length > 4463) {
    //   htmlnew+=datosss;

    //   var res = html.slice(4464, 6695);
    //   htmlnew+=`<div class='Section3'>`+res+`</div>`;
    // }

    // if (html.length > 6695) {
    //     htmlnew+=datosss;

    //     var res = html.slice(6696, 8927);
    //     htmlnew+=`<div class='Section4'>`+res+`</div>`;
    //   }

    //   if (html.length > 8928) {
    //     htmlnew+=datosss;

    //   var res = html.slice(8928, 11159);
    //   htmlnew+=`<div class='Section5'>`+res+`</div>`;
    // }

    // if (html.length > 11159) {

    //   htmlnew+=datosss;
    //   var res = html.slice(11160, 13391);
    //   htmlnew+=`<div class='Section6'>`+res+`</div>`;
    // }

    // if (html.length > 13391) {
    //     htmlnew+=datosss;

    //   var res = html.slice(13392, 15623);
    //   htmlnew+=`<div class='Section7'>`+res+`</div>`;
    // }

    // if (html.length > 15623) {

    //   htmlnew+=datosss;
    //   var res = html.slice(15624, 17855);
    //   htmlnew+=`<div class='Section8'>`+res+`</div>`;

    // }

    // if (html.length > 17855) {

    //   htmlnew+=datosss;
    //   var res = html.slice(17856, 20087);

    //   htmlnew+=`<div class='Section9'>`+res+`</div>`;

    //   }

    htmlnew = preHtml + htmlnew + postHtml;
    //htmlnew=preHtml+`<div id='contratoOriginalDis'>`+html+`</div>`+postHtml;

    //htmlnew=htmlnew.replace('&lt;','<').replace('&gt;','>').replace('&lt;','<').replace('&gt;','>').replace('&lt;/span&gt;','</span>')

    setTimeout(function () {
      var blob = new Blob(["\ufeff", htmlnew], {
        type: "application/msword",
      });
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(htmlnew);
      filename = "contrato" ? "contrato" + ".doc" : "document.doc";

      var downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }, 500);
  };

  render() {
    const { options, value } = this.state;
    const redireccion = this.props.Access("READ_CONTRATO") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <Fragment>
            <div class="col">
              <div id="cabecera" class="row">
                <div id="imgCabecera" class="col">
                  <img
                    id="img-contrato"
                    src={this.state.empresas.logoEmpresa}
                    alt="HaciendaElNaranjo"
                  />
                </div>
              </div>
            </div>
            <div name="botones"></div>
            <div id="bodyAll">
              <form onSubmit={(e) => this.Create(e)}>
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      NOMBRE DE ASESOR DE VENTAS:
                    </label>
                    <div class="col-sm-9">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateAsesor}
                        options={this.state.data.USUARIO_PERSONAL}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE EL ASESOR DE VENTAS"}
                        getOptionLabel={(options) => options.NOMBRE_COMPLETO}
                        getOptionValue={(options) => options.ID_USUARIO}
                        value={this.state.custom.ASESOR_CONTRATO}
                        required
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      COORDINADOR DE VENTA:
                    </label>
                    <div class="col-sm-9">
                      {this.props.auth[0].ID_USUARIO != 40 && (
                        <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateCordinador}
                          options={this.state.data.USUARIO_PERSONAL_COORDINADOR}
                          isMulti={false}
                          name="USUARIO_ADMIN_OFICINA_CONTRATO"
                          components={makeAnimated()}
                          placeholder={"SELECCIONE EL COORDINADOR DE VENTA"}
                          getOptionLabel={(options) => options.NOMBRE_COMPLETO}
                          getOptionValue={(options) => options.ID_USUARIO}
                          value={this.state.custom.COORDINADOR_CONTRATO}
                          required
                        />
                      )}
                      {this.props.auth[0].ID_USUARIO == 40 && (
                        <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateCordinador}
                          options={this.state.data.USUARIO_PERSONAL_COORDINADOR}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE EL COORDINADOR DE VENTAS"}
                          getOptionLabel={(options) => options.NOMBRE_COMPLETO}
                          getOptionValue={(options) => options.ID_USUARIO}
                          value={this.state.custom.COORDINADOR_CONTRATO}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <h5 id="h5-contrato">
                    DATOS GENERALES DEL OPTANTE/SOLICITANTE COMPRADOR
                  </h5>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">NOMBRES:</label>
                    <div class="col-sm-11">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NOMBRES_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.NOMBRES_CLIENTE_CONTRATO
                        }
                        placeholder="Nombres"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">APELLIDOS:</label>
                    <div class="col-sm-11">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="APELLIDOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.APELLIDOS_CLIENTE_CONTRATO
                        }
                        placeholder="Apellidos"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">NIT:</label>
                    <div class="col-sm-2">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NIT_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.NIT_CLIENTE_CONTRATO}
                        placeholder="nit"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">ESTADO CIVIL:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="ESTADO_CIVIL_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.ESTADO_CIVIL_CLIENTE_CONTRATO
                        }
                        placeholder="Estado Civil"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      FECHA DE NACIMIENTO:{" "}
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="date"
                        class="form-control"
                        name="FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO
                        }
                        placeholder="fechaNacimientoComprador"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">EDAD:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="EDAD_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.EDAD_CLIENTE_CONTRATO}
                        placeholder="EDAD"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">DPI:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="DPI_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.DPI_CLIENTE_CONTRATO}
                        placeholder="DPI"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">TELÉFONO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="TELEFONO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.TELEFONO_CLIENTE_CONTRATO
                        }
                        placeholder="telefono"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">GÉNERO:</label>
                    <div class="col-sm-2">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateGENERO}
                        options={this.state.data.GENERO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE GENERO"}
                        getOptionLabel={(options) => options.NOMBRE_GENERO}
                        getOptionValue={(options) => options.ID_GENERO}
                        value={this.state.custom.GENERO}
                        required
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">NACIONALIDAD:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NACIONALIDAD_CLIENTE_CONTARTO"
                        defaultValue={
                          this.state.custom.NACIONALIDAD_CLIENTE_CONTARTO
                        }
                        placeholder="nacionalidad"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">EMAIL:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="email"
                        class="form-control"
                        name="EMAIL_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.EMAIL_CLIENTE_CONTRATO}
                        placeholder="correo electronico"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    {" "}
                    <label class="col-sm-1 col-form-label">DIRECCIÓN:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="DIRECCION_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.DIRECCION_CLIENTE_CONTRATO
                        }
                        placeholder="direccion"
                      />
                    </div>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateDEPARTAMENTO}
                        options={this.state.data.DEPARTAMENTO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE DEPARTAMENTO"}
                        getOptionLabel={(options) =>
                          options.NOMBRE_DEPARTAMENTO
                        }
                        getOptionValue={(options) => options.ID_DEPARTAMENTO}
                        value={this.state.custom.DEPARTAMENTO}
                      />
                    </div>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateMunicipio}
                        options={this.state.data.TIPOS_MUNICIPIO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE EL MUNICIPIO"}
                        getOptionLabel={(options) => options.NOMBRE_MUNICIPIO}
                        getOptionValue={(options) => options.ID_MUNICPIO}
                        value={this.state.custom.TIPO_MUNICIPIO}
                      />
                    </div>
                  </div>

                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">
                      PROFESIÓN U OFICIO:
                    </label>
                    <div class="col-sm-10">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="PROFECION_CLIENTE_CONTARTO"
                        defaultValue={
                          this.state.custom.PROFECION_CLIENTE_CONTARTO
                        }
                        placeholder="profesion"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      NOMBRE DEL CONYUGE:
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NOMBRE_CONYUGE_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.NOMBRE_CONYUGE_CLIENTE_CONTRATO
                        }
                        placeholder="nombre del conyuge"
                      />
                    </div>
                    <label class="col-sm-4 col-form-label">
                      PERSONAS QUE DEPENDEN DEL SOLICITANTE:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO
                        }
                        placeholder="dependientes"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-4 col-form-label">
                      INSTITUCIÓN PARA LA QUE TRABAJA:{" "}
                    </label>
                    <div class="col-sm-8">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="institucion para la que trabaja"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">DIRECCIÓN:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="DIRECCION"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">TELÉFONO:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="TELEFONO_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.TELEFONO_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      {" "}
                      PUESTO QUE DESEMPEÑA:{" "}
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="Puesto que desempeña"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      AÑOS LABORADOS:
                    </label>
                    <div class="col-sm-1">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="ANIOS_LABORADOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.ANIOS_LABORADOS_CLIENTE_CONTRATO
                        }
                        placeholder="años"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      MESES LABORADOS:
                    </label>
                    <div class="col-sm-1">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="MESES_LABORADOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.MESES_LABORADOS_CLIENTE_CONTRATO
                        }
                        placeholder="meses"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">
                      SUELDO MENSUAL:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="SUELDO_MENSUAL_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.SUELDO_MENSUAL_CLIENTE_CONTRATO
                        }
                        placeholder="Salario"
                      />
                    </div>
                    <label class="col-sm-3 col-form-label">
                      INGRESOS EXTRAS:
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="INGRESOS_EXTRAS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.INGRESOS_EXTRAS_CLIENTE_CONTRATO
                        }
                        placeholder="Ingresos extras"
                      />
                    </div>
                  </div>
                </div>
                <br />
                {/* <!--REferencia en caso de emergencia--> */}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h5 className="h5-contrato">
                        REFERENCIA EN CASO DE EMERGENCIA
                      </h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          NOMBRE Y APELLIDO:
                        </label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO
                            }
                            placeholder="nombre contacto de emergencia"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCIÓN:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO
                            }
                            placeholder="Direccion"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">TELÉFONO:</label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO
                            }
                            placeholder="Telefono"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {/* <!--Datos Generales del co-propietario--> */}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h5 className="h5-contrato">
                        DATOS GENERALES DEL OPTANTE CO-PROPIETARIO
                      </h5>
                      <div class="mb-2 row">
                        <label class="col-sm col-form-label">
                          NOMBRE Y APELLIDO:
                        </label>
                        <div class="col-sm-10">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO
                            }
                            placeholder="nombre de co-propietario"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">NIT:</label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO
                            }
                            placeholder="Nit"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          {" "}
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO
                            }
                            placeholder="Estado Civil"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          {" "}
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="date"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO
                            }
                            placeholder="Fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-3">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO
                            }
                            placeholder="Edad"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-4">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO
                            }
                            placeholder="Dpi"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">TELEFONO:</label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO
                            }
                            placeholder="Telefono"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCIÓN:
                        </label>
                        <div class="col-sm-4">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO
                            }
                            placeholder="direccion"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-4">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO
                            }
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 2 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h3>TESTIGO A RUEGO:</h3>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">NOMBRE:</label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO"
                            placeholder="NOMBRE DE TESTIGO DE ARRUEGO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">GENERO:</label>
                        <div class="col-sm-2">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateGENERO_TESTIGO}
                            options={this.state.data.GENERO}
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE GENERO"}
                            getOptionLabel={(options) => options.NOMBRE_GENERO}
                            getOptionValue={(options) => options.ID_GENERO}
                            value={this.state.custom.GENERO}
                          />
                        </div>

                        <label class="col-sm-2 col-form-label">
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO"
                            placeholder="ESTADO CIVIL:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="date"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO"
                            placeholder="fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCIÓN:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DIRECCION_CONTRATO"
                            placeholder="DIRECCION:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">TELÉFONO:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_TELEFONO_CONTRATO"
                            placeholder="TELEFONO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_EDAD_CONTRATO"
                            placeholder="EDAD"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DPI_CONTRATO"
                            placeholder="DPI"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO"
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 9 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h3>TESTIGO A RUEGO:</h3>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">NOMBRE:</label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO"
                            placeholder="NOMBRE DE TESTIGO DE ARRUEGO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">GÉNERO:</label>
                        <div class="col-sm-2">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateGENERO_TESTIGO}
                            options={this.state.data.GENERO}
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE GENERO"}
                            getOptionLabel={(options) => options.NOMBRE_GENERO}
                            getOptionValue={(options) => options.ID_GENERO}
                            value={this.state.custom.GENERO}
                          />
                        </div>

                        <label class="col-sm-2 col-form-label">
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO"
                            placeholder="ESTADO CIVIL:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="date"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO"
                            placeholder="fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCIÓN:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DIRECCION_CONTRATO"
                            placeholder="DIRECCION:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">TELÉFONO:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_TELEFONO_CONTRATO"
                            placeholder="TELEFONO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_EDAD_CONTRATO"
                            placeholder="EDAD"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DPI_CONTRATO"
                            placeholder="DPI"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO"
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                <div id="container-contrato" class="container">
                  <h5 className="h5-contrato">REFERENCIAS PERSONALES</h5>
                  <b>1.</b>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      NOMBRE Y APELLIDO:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Nombre y apellido "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">PARENTESCO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Parentesco "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">DIRECCIÓN:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Direccion "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">TELÉFONO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono "
                      />
                    </div>
                  </div>
                  <b>2.</b>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      NOMBRE Y APELLIDO:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Nombre y apellido "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">PARENTESCO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Parentesco "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">DIRECCIÓN:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Direccion "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">TELÉFONO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono "
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <h5 className="col-sm col-form-label ">
                      CONDICIONES DE COMPRA
                    </h5>
                    <label class="col-sm-1 col-form-label ">SECTOR:</label>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateManzana_AGRUPADO}
                        options={
                          this.state.data
                            ? this.state.data.MANZANA_AGRUPADO
                            : null
                        }
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE SECTOR"}
                        getOptionLabel={(options) => options.UBICACION_MANZANA}
                        getOptionValue={(options) => options.UBICACION_MANZANA}
                        value={this.state.custom.MANZANA_AGRUPADO}
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">MANZANA:</label>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateManzana}
                        options={
                          this.state.data ? this.state.data.MANZANA : null
                        }
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LA MANZANA"}
                        getOptionLabel={(options) => options.NOMBRE_MANZANA}
                        getOptionValue={(options) => options.ID_MANZANA}
                        value={this.state.custom.MANZANA}
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateLote}
                        options={this.state.data ? this.state.data.LOTE : null}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LOTE"}
                        getOptionLabel={(options) => options.NO_LOTE}
                        getOptionValue={(options) => options.ID_LOTE}
                        value={this.state.custom.LOTE}
                      />
                    </div>

                    <label class="col-sm-1 col-form-label ">
                      METROS<sup>2</sup>
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly={false}
                        type="text"
                        class=" form-control "
                        name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO
                        }
                        placeholder="metros "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO
                        }
                        placeholder="finca numero "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO
                        }
                        placeholder="Folio Numero "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO
                        }
                        placeholder="Libro numero "
                      />
                    </div>
                  </div>
                  <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      PRECIO VENTA:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO"
                        readOnly={false}
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO
                        }
                        placeholder="Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO
                        }
                        placeholder="Enganche del terreno "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label ">
                      SALDO DEL PRECIO DE VENTA:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO"
                        readOnly={false}
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO
                        }
                        placeholder="Saldo Precio venta "
                      />
                    </div>
                  </div>
                </div>
                <div className="col text-center" name="botones">
                  {this.state.Lotes.lote2 == false && (
                    <button
                      onClick={this.ModificarLotes("lote2", true)}
                      class="btn btn-primary"
                    >
                      Agregar
                    </button>
                  )}
                </div>
                <br />
                {this.state.Lotes.lote2 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO2}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO2}
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana2}
                            options={
                              this.state.data ? this.state.data.MANZANA2 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA2}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote2}
                            options={
                              this.state.data ? this.state.data.LOTE2 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE2}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCIÓN</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      {this.state.Lotes.lote3 == false && (
                        <Fragment>
                          <button
                            onClick={this.ModificarLotes("lote3", true)}
                            class="btn btn-primary"
                          >
                            Agregar
                          </button>
                          <button
                            onClick={this.ModificarLotes("lote2", false)}
                            class="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </Fragment>
                      )}
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.state.Lotes.lote3 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO3}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO3}
                          />
                        </div>
                      </div>

                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana3}
                            options={
                              this.state.data ? this.state.data.MANZANA3 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA3}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote3}
                            options={
                              this.state.data ? this.state.data.LOTE3 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE3}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCIÓN</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      {this.state.Lotes.lote4 == false && (
                        <Fragment>
                          {/* <button
                            onClick={this.ModificarLotes("lote4", true)}
                            class="btn btn-primary"
                          >
                            Agregar
                          </button> */}
                          <button
                            onClick={this.ModificarLotes("lote3", false)}
                            class="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </Fragment>
                      )}
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.state.Lotes.lote4 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO4}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO4}
                          />
                        </div>
                      </div>

                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana4}
                            options={
                              this.state.data ? this.state.data.MANZANA4 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA4}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote4}
                            options={
                              this.state.data ? this.state.data.LOTE4 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE4}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCIÓN</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      {this.state.Lotes.lote5 == false && (
                        <Fragment>
                          <button
                            onClick={this.ModificarLotes("lote5", true)}
                            class="btn btn-primary"
                          >
                            Agregar
                          </button>
                          <button
                            onClick={this.ModificarLotes("lote4", false)}
                            class="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </Fragment>
                      )}
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.state.Lotes.lote5 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO5}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO5}
                          />
                        </div>
                      </div>

                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana5}
                            options={
                              this.state.data ? this.state.data.MANZANA5 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA5}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote5}
                            options={
                              this.state.data ? this.state.data.LOTE5 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE5}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCIÓN</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      <Fragment>
                        <button
                          onClick={this.ModificarLotes("lote5", false)}
                          class="btn btn-danger"
                        >
                          Eliminar
                        </button>
                      </Fragment>
                    </div>
                    <br />
                  </Fragment>
                )}
                {/* servicios calculados */}
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">BANCO:</label>
                    <div class="col-sm-4">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateBANCO}
                        options={this.state.data.BANCO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE EL BANCO"}
                        getOptionLabel={(options) => options.NOMBRE_BANCO}
                        getOptionValue={(options) => options.ID_BANCO}
                        value={this.state.custom.BANCO}
                      />
                    </div>
        
        {/* CUENTITA */}
                    <label class="col-sm-2 col-form-label ">CUENTA:</label>
                    <div class="col-sm-4">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateCUENTA}
                        options={this.state.data.TIPOS_CUENTA}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LA CUENTA"}
                        getOptionLabel={(options) => options.NOMBRE_CUENTA}
                        getOptionValue={(options) => options.ID_CUENTA}
                        value={this.state.custom.TIPO_CUENTA}
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">Número De Cuenta:</label>
                    <div class="col-sm-4" >
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                    
                        onChange={this.updateStateCUENTA}
                        options={this.state.data.TIPOS_CUENTA}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LA CUENTA"}
                        getOptionLabel={(options) => options.NO_CUENTA}
                        getOptionValue={(options) => options.ID_CUENTA}
                        value={this.state.custom.TIPO_CUENTA}
                      />
                    </div>
                  </div>
                </div>{" "}
                <br />
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">CONTADO:</label>
                    <div class="col-sm-1">
                      <input
                        onChange={this.handleInputChange}
                        type="checkbox"
                        class=""
                        name="CONTADO"
                        value={this.state.custom.CONTADO}
                        placeholder="CONTADO"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ARRAS 10%:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO
                        }
                        placeholder="Arras "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">
                      SALDO A PAGAR EN:
                    </label>
                    <div class="col-sm-4">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateCuotas}
                        options={this.state.data.PORCENTAJEANIOSCONTRATO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LOS MESES"}
                        getOptionLabel={(options) =>
                          options.MESES_PORCENTAJE_ANIOS_CONTRATO
                        }
                        getOptionValue={(options) =>
                          options.ID_PORCENTAJE_ANIOS_CONTRATO
                        }
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div class="mb-2 row">
                    <label class="col-sm-5 col-form-label ">
                      MENSUALMENTE LA PRIMER CUOTA DEBERA PAGARSE EL:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="date"
                        class="form-control"
                        name="CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO
                        }
                        placeholder="fecha de pago "
                      />
                    </div>
                  </div>
                  <br />

                  <div class="mb-2 row">
                    <label class="col-sm-4 col-form-label ">
                      PLAZO MEDIANTE CUOTAS NIVELADAS DE:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        step="0.01"
                        class="form-control"
                        readOnly={false}
                        name="CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO
                        }
                        placeholder="Cuota "
                      />
                    </div>
                    <label class="col-sm-4 col-form-label ">
                      CON UNA COMISION Y SERVICIOS QUE SUMADOS SON:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control"
                        name="CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO
                        }
                        placeholder="Cuota "
                      />
                    </div>
                  </div>

                  {/*totales generales  */}
                  <h5 className="h5-contrato">TOTAL</h5>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      PRECIO VENTA:
                    </label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Enganche del terreno "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label ">
                      SALDO DEL PRECIO DE VENTA:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Saldo Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">APARTADO:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="APARTADO_CONTRATO"
                        defaultValue={0}
                        value={this.state.custom.APARTADO_CONTRATO}
                        placeholder="Apartado "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-5 col-form-label ">
                      FECHA FIRMA CONTRATO:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="date"
                        class="form-control"
                        name="FECHA_FIRMA_CONTRATO"
                        defaultValue={this.state.custom.FECHA_FIRMA_CONTRATO}
                        placeholder="fecha de contrato "
                      />
                    </div>
                    <div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label">
                          OBSERVACIONES:
                        </label>
                        <div class="col-sm-9">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="OBSERVACIONES_CONTRATO"
                            defaultValue={
                              this.state.custom.OBSERVACIONES_CONTRATO
                            }
                            placeholder="Observaciones"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="col text-center" name="botones">
                  <button type="submit" class="btn btn-primary">
                    Ingresar
                  </button>
                </div>
              </form>

              <br />
              {/* <form onSubmit={(e) => this.CreateFile(e)} >
<input   type="file" name="contrato"  onChange={this.UpdateStateCreateFile}/>
<input   type="submit" value="Subir"/>
</form>
<form action={`${process.env.REACT_APP_SERVER}/api/CONTRATO/create`} method="POST" enctype="multipart/form-data">
<input   type="file" name="contratos" multiple />
<input   type="submit" value="Subir"/>
</form> */}
            </div>
          </Fragment>
          /* <ContratoEmpresa
      auth={this.props.auth}
      Access={this.props.Access}

          />*/
        )}
        {this.state.modificar && (
          <Fragment>
            <div class="col">
              <div id="cabecera" class="row">
                <div id="imgCabecera" class="col">
                  <img
                    id="img-contrato"
                    src={this.state.empresas.logoEmpresa}
                    alt="HaciendaElNaranjo"
                  />
                </div>
              </div>
            </div>
            <div name="botones"></div>
            <div id="bodyAll">
              <form onSubmit={(e) => this.Create(e)}>
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      NOMBRE DE ASESOR DE VENTAS:
                    </label>
                    <div class="col-sm-9">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateAsesor}
                        options={this.state.data.USUARIO_PERSONAL}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE EL ASESOR DE VENTAS"}
                        getOptionLabel={(options) => options.NOMBRE_COMPLETO}
                        getOptionValue={(options) => options.ID_USUARIO}
                        value={this.state.custom.ASESOR_CONTRATO}
                        required
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      ADMINISTRADOR DE OFICINA:
                    </label>
                    <div class="col-sm-9">
                      {this.props.auth[0].ID_USUARIO != 40 && (
                        <input
                          onChange={this.UpdateStateCreate}
                          readOnly="False"
                          class="form-control"
                          name="USUARIO_ADMIN_OFICINA_CONTRATO"
                          defaultValue={this.props.auth[0].NOMBRE_COMPLETO}
                          placeholder="Administracion de oficina"
                        />
                      )}
                      {this.props.auth[0].ID_USUARIO == 40 && (
                        <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateCordinador}
                          options={this.state.data.USUARIO_PERSONAL}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE EL COORDINADOR DE VENTAS"}
                          getOptionLabel={(options) => options.NOMBRE_COMPLETO}
                          getOptionValue={(options) => options.ID_USUARIO}
                          value={this.state.custom.COORDINADOR_CONTRATO}
                        />
                      )}
                    </div>
                  </div>

                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      OBSERVACIONES:
                    </label>
                    <div class="col-sm-9">
                      <input
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="OBSERVACIONES_CONTRATO"
                        defaultValue={this.state.custom.OBSERVACIONES_CONTRATO}
                        placeholder="Observaciones"
                      />{" "}
                    </div>
                  </div>
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <h5 id="h5-contrato">
                    DATOS GENERALES DEL OPTANTE/SOLICITANTE COMPRADOR
                  </h5>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">NOMBRES:</label>
                    <div class="col-sm-11">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NOMBRES_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.NOMBRES_CLIENTE_CONTRATO
                        }
                        placeholder="Nombres"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">APELLIDOS:</label>
                    <div class="col-sm-11">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="APELLIDOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.APELLIDOS_CLIENTE_CONTRATO
                        }
                        placeholder="Apellidos"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">NIT:</label>
                    <div class="col-sm-2">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NIT_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.NIT_CLIENTE_CONTRATO}
                        placeholder="nit"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">ESTADO CIVIL:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="ESTADO_CIVIL_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.ESTADO_CIVIL_CLIENTE_CONTRATO
                        }
                        placeholder="Estado Civil"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      FECHA DE NACIMIENTO:{" "}
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="date"
                        class="form-control"
                        name="FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO
                        }
                        placeholder="fechaNacimientoComprador"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">EDAD:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="EDAD_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.EDAD_CLIENTE_CONTRATO}
                        placeholder="EDAD"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">DPI:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="DPI_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.DPI_CLIENTE_CONTRATO}
                        placeholder="DPI"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">TELEFONO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="TELEFONO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.TELEFONO_CLIENTE_CONTRATO
                        }
                        placeholder="telefono"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">GENERO:</label>
                    <div class="col-sm-2">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateGENERO}
                        options={this.state.data.GENERO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE GENERO"}
                        getOptionLabel={(options) => options.NOMBRE_GENERO}
                        getOptionValue={(options) => options.ID_GENERO}
                        value={this.state.custom.GENERO}
                        required
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">NACIONALIDAD:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NACIONALIDAD_CLIENTE_CONTARTO"
                        defaultValue={
                          this.state.custom.NACIONALIDAD_CLIENTE_CONTARTO
                        }
                        placeholder="nacionalidad"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">EMAIL:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="email"
                        class="form-control"
                        name="EMAIL_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.EMAIL_CLIENTE_CONTRATO}
                        placeholder="correo electronico"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    {" "}
                    <label class="col-sm-1 col-form-label">DIRECCION:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="DIRECCION_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.DIRECCION_CLIENTE_CONTRATO
                        }
                        placeholder="direccion"
                      />
                    </div>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateDEPARTAMENTO}
                        options={this.state.data.DEPARTAMENTO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE DEPARTAMENTO"}
                        getOptionLabel={(options) =>
                          options.NOMBRE_DEPARTAMENTO
                        }
                        getOptionValue={(options) => options.ID_DEPARTAMENTO}
                        value={this.state.custom.DEPARTAMENTO}
                      />
                    </div>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateMunicipio}
                        options={this.state.data.TIPOS_MUNICIPIO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE EL MUNICIPIO"}
                        getOptionLabel={(options) => options.NOMBRE_MUNICIPIO}
                        getOptionValue={(options) => options.ID_MUNICPIO}
                        value={this.state.custom.TIPO_MUNICIPIO}
                      />
                    </div>
                  </div>

                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">
                      PROFESION U OFICIO:
                    </label>
                    <div class="col-sm-10">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="PROFECION_CLIENTE_CONTARTO"
                        defaultValue={
                          this.state.custom.PROFECION_CLIENTE_CONTARTO
                        }
                        placeholder="profesion"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      NOMBRE DEL CONYUGE:
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NOMBRE_CONYUGE_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.NOMBRE_CONYUGE_CLIENTE_CONTRATO
                        }
                        placeholder="nombre del conyuge"
                      />
                    </div>
                    <label class="col-sm-4 col-form-label">
                      PERSONAS QUE DEPENDEN DEL SOLICITANTE:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO
                        }
                        placeholder="dependientes"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-4 col-form-label">
                      INSTITUCION PARA LA QUE TRABAJA:{" "}
                    </label>
                    <div class="col-sm-8">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="institucion para la que trabaja"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">DIRECCION:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="DIRECCION"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">TELEFONO:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="TELEFONO_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.TELEFONO_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      {" "}
                      PUESTO QUE DESEMPEÑA:{" "}
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control"
                        name="PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="Puesto que desempeña"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      AÑOS LABORADOS:
                    </label>
                    <div class="col-sm-1">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="ANIOS_LABORADOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.ANIOS_LABORADOS_CLIENTE_CONTRATO
                        }
                        placeholder="años"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      MESES LABORADOS:
                    </label>
                    <div class="col-sm-1">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="MESES_LABORADOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.MESES_LABORADOS_CLIENTE_CONTRATO
                        }
                        placeholder="meses"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">
                      SUELDO MENSUAL:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="SUELDO_MENSUAL_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.SUELDO_MENSUAL_CLIENTE_CONTRATO
                        }
                        placeholder="Salario"
                      />
                    </div>
                    <label class="col-sm-3 col-form-label">
                      INGRESOS EXTRAS:
                    </label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control"
                        name="INGRESOS_EXTRAS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.INGRESOS_EXTRAS_CLIENTE_CONTRATO
                        }
                        placeholder="Ingresos extras"
                      />
                    </div>
                  </div>
                </div>
                <br />
                {/* <!--REferencia en caso de emergencia--> */}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h5 className="h5-contrato">
                        REFERENCIA EN CASO DE EMERGENCIA
                      </h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          NOMBRE Y APELLIDO:
                        </label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO
                            }
                            placeholder="nombre contacto de emergencia"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO
                            }
                            placeholder="Direccion"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">TELEFONO:</label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO
                            }
                            placeholder="Telefono"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {/* <!--Datos Generales del co-propietario--> */}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h5 className="h5-contrato">
                        DATOS GENERALES DEL OPTANTE CO-PROPIETARIO
                      </h5>
                      <div class="mb-2 row">
                        <label class="col-sm col-form-label">
                          NOMBRE Y APELLIDO:
                        </label>
                        <div class="col-sm-10">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO
                            }
                            placeholder="nombre de co-propietario"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">NIT:</label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO
                            }
                            placeholder="Nit"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          {" "}
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO
                            }
                            placeholder="Estado Civil"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          {" "}
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="date"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO
                            }
                            placeholder="Fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-3">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO
                            }
                            placeholder="Edad"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-4">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO
                            }
                            placeholder="Dpi"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">TELEFONO:</label>
                        <div class="col-sm-2">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO
                            }
                            placeholder="Telefono"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-4">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO
                            }
                            placeholder="direccion"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-4">
                          <input
                            required
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO
                            }
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 2 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h3>TESTIGO A RUEGO:</h3>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">NOMBRE:</label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO"
                            placeholder="NOMBRE DE TESTIGO DE ARRUEGO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">GENERO:</label>
                        <div class="col-sm-2">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateGENERO_TESTIGO}
                            options={this.state.data.GENERO}
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE GENERO"}
                            getOptionLabel={(options) => options.NOMBRE_GENERO}
                            getOptionValue={(options) => options.ID_GENERO}
                            value={this.state.custom.GENERO}
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO"
                            placeholder="ESTADO CIVIL:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="date"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO"
                            placeholder="fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DIRECCION_CONTRATO"
                            placeholder="DIRECCION:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">TELEFONO:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_TELEFONO_CONTRATO"
                            placeholder="TELEFONO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_EDAD_CONTRATO"
                            placeholder="EDAD"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DPI_CONTRATO"
                            placeholder="DPI"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO"
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                <div id="container-contrato" class="container">
                  <h5 className="h5-contrato">REFERENCIAS PERSONALES</h5>
                  <b>1.</b>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      NOMBRE Y APELLIDO:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Nombre y apellido "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">PARENTESCO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Parentesco "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">DIRECCION:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Direccion "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">TELEFONO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono "
                      />
                    </div>
                  </div>
                  <b>2.</b>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      NOMBRE Y APELLIDO:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Nombre y apellido "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">PARENTESCO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Parentesco "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">DIRECCION:</label>
                    <div class="col-sm-5">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Direccion "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">TELEFONO:</label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono "
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <h5 className="col-sm col-form-label ">
                      CONDICIONES DE COMPRA
                    </h5>
                    <label class="col-sm-1 col-form-label ">SECTOR:</label>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateManzana_AGRUPADO}
                        options={
                          this.state.data
                            ? this.state.data.MANZANA_AGRUPADO
                            : null
                        }
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE SECTOR"}
                        getOptionLabel={(options) => options.UBICACION_MANZANA}
                        getOptionValue={(options) => options.UBICACION_MANZANA}
                        value={this.state.custom.MANZANA_AGRUPADO}
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">MANZANA:</label>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateManzana}
                        options={
                          this.state.data ? this.state.data.MANZANA : null
                        }
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LA MANZANA"}
                        getOptionLabel={(options) => options.NOMBRE_MANZANA}
                        getOptionValue={(options) => options.ID_MANZANA}
                        value={this.state.custom.MANZANA}
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                    <div class="col-sm-3">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateLote}
                        options={this.state.data ? this.state.data.LOTE : null}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LOTE"}
                        getOptionLabel={(options) => options.NO_LOTE}
                        getOptionValue={(options) => options.ID_LOTE}
                        value={this.state.custom.LOTE}
                      />
                    </div>

                    <label class="col-sm-1 col-form-label ">
                      METROS<sup>2</sup>
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly={false}
                        type="text"
                        class=" form-control "
                        name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO
                        }
                        placeholder="metros "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO
                        }
                        placeholder="finca numero "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO
                        }
                        placeholder="Folio Numero "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                    <div class="col-sm-3">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO
                        }
                        placeholder="Libro numero "
                      />
                    </div>
                  </div>
                  <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      PRECIO VENTA:
                    </label>
                    <div class="col-sm-4">
                      <input
                        required
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO"
                        readOnly={false}
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO
                        }
                        placeholder="Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO
                        }
                        placeholder="Enganche del terreno "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label ">
                      SALDO DEL PRECIO DE VENTA:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO"
                        readOnly={false}
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO
                        }
                        placeholder="Saldo Precio venta "
                      />
                    </div>
                  </div>
                </div>
                <div className="col text-center" name="botones">
                  {this.state.Lotes.lote2 == false && (
                    <button
                      onClick={this.ModificarLotes("lote2", true)}
                      class="btn btn-primary"
                    >
                      Agregar
                    </button>
                  )}
                </div>
                <br />
                {this.state.Lotes.lote2 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO2}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO2}
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana2}
                            options={
                              this.state.data ? this.state.data.MANZANA2 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA2}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote2}
                            options={
                              this.state.data ? this.state.data.LOTE2 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE2}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      {this.state.Lotes.lote3 == false && (
                        <Fragment>
                          <button
                            onClick={this.ModificarLotes("lote3", true)}
                            class="btn btn-primary"
                          >
                            Agregar
                          </button>
                          <button
                            onClick={this.ModificarLotes("lote2", false)}
                            class="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </Fragment>
                      )}
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.state.Lotes.lote3 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO3}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO3}
                          />
                        </div>
                      </div>

                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana3}
                            options={
                              this.state.data ? this.state.data.MANZANA3 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA3}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote3}
                            options={
                              this.state.data ? this.state.data.LOTE3 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE3}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      {this.state.Lotes.lote4 == false && (
                        <Fragment>
                          {/* <button
                            onClick={this.ModificarLotes("lote4", true)}
                            class="btn btn-primary"
                          >
                            Agregar
                          </button> */}
                          <button
                            onClick={this.ModificarLotes("lote3", false)}
                            class="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </Fragment>
                      )}
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.state.Lotes.lote4 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO4}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO4}
                          />
                        </div>
                      </div>

                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana4}
                            options={
                              this.state.data ? this.state.data.MANZANA4 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA4}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote4}
                            options={
                              this.state.data ? this.state.data.LOTE4 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE4}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      {this.state.Lotes.lote5 == false && (
                        <Fragment>
                          <button
                            onClick={this.ModificarLotes("lote5", true)}
                            class="btn btn-primary"
                          >
                            Agregar
                          </button>
                          <button
                            onClick={this.ModificarLotes("lote4", false)}
                            class="btn btn-danger"
                          >
                            Eliminar
                          </button>
                        </Fragment>
                      )}
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.state.Lotes.lote5 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <div class="mb-2 row">
                        <h5 className="col-sm-6 col-form-label ">
                          CONDICIONES DE COMPRA
                        </h5>
                        <label class="col-sm-1 col-form-label ">SECTOR:</label>
                        <div class="col-sm-5">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana_AGRUPADO5}
                            options={
                              this.state.data
                                ? this.state.data.MANZANA_AGRUPADO
                                : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE SECTOR"}
                            getOptionLabel={(options) =>
                              options.UBICACION_MANZANA
                            }
                            getOptionValue={(options) =>
                              options.UBICACION_MANZANA
                            }
                            value={this.state.custom.MANZANA_AGRUPADO5}
                          />
                        </div>
                      </div>

                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">MANZANA:</label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateManzana5}
                            options={
                              this.state.data ? this.state.data.MANZANA5 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LA MANZANA"}
                            getOptionLabel={(options) => options.NOMBRE_MANZANA}
                            getOptionValue={(options) => options.ID_MANZANA}
                            value={this.state.custom.MANZANA5}
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          NO. DE LOTE:
                        </label>
                        <div class="col-sm-3">
                          <Select
                            classNamePrefix="select-solo-con"
                            className="select-solo"
                            onChange={this.updateStateLote5}
                            options={
                              this.state.data ? this.state.data.LOTE5 : null
                            }
                            isMulti={false}
                            components={makeAnimated()}
                            placeholder={"SELECCIONE LOTE"}
                            getOptionLabel={(options) => options.NO_LOTE}
                            getOptionValue={(options) => options.ID_LOTE}
                            value={this.state.custom.LOTE5}
                          />
                        </div>

                        <label class="col-sm-1 col-form-label ">
                          METROS<sup>2</sup>
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly={false}
                            type="text"
                            class=" form-control "
                            name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5
                            }
                            placeholder="metros "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label ">
                          FINCA NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5
                            }
                            placeholder="finca numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          FOLIO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5
                            }
                            placeholder="Folio Numero "
                          />
                        </div>
                        <label class="col-sm-1 col-form-label ">
                          LIBRO NO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control "
                            name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5"
                            defaultValue={
                              this.state.custom
                                .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5
                            }
                            placeholder="Libro numero "
                          />
                        </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label ">
                          PRECIO VENTA:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5
                            }
                            placeholder="Precio venta "
                          />
                        </div>
                        <label class="col-sm-2 col-form-label ">
                          ENGANCHE:
                        </label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5"
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5
                            }
                            placeholder="Enganche del terreno "
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label ">
                          SALDO DEL PRECIO DE VENTA:
                        </label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            type="number"
                            class="form-control "
                            name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5"
                            readOnly={false}
                            value={
                              this.state.custom
                                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5
                            }
                            placeholder="Saldo Precio venta "
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col text-center" name="botones">
                      <Fragment>
                        <button
                          onClick={this.ModificarLotes("lote5", false)}
                          class="btn btn-danger"
                        >
                          Eliminar
                        </button>
                      </Fragment>
                    </div>
                    <br />
                  </Fragment>
                )}
                {/* servicios calculados */}
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">BANCO:</label>
                    <div class="col-sm-4">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateBANCO}
                        options={this.state.data.BANCO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE EL BANCO"}
                        getOptionLabel={(options) => options.NOMBRE_BANCO}
                        getOptionValue={(options) => options.ID_BANCO}
                        value={this.state.custom.BANCO}
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">CUENTA:</label>
                    <div class="col-sm-4">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateCUENTA}
                        options={this.state.data.TIPOS_CUENTA}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LA CUENTA"}
                        getOptionLabel={(options) => options.NOMBRE_CUENTA}
                        getOptionValue={(options) => options.ID_CUENTA}
                        value={this.state.custom.TIPO_CUENTA}
                      />
                    </div>
                  </div>
                </div>{" "}
                <br />
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">CONTADO:</label>
                    <div class="col-sm-1">
                      <input
                        onChange={this.handleInputChange}
                        type="checkbox"
                        class=""
                        name="CONTADO"
                        value={this.state.custom.CONTADO}
                        placeholder="CONTADO"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ARRAS 10%:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO
                        }
                        placeholder="Arras "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">
                      SALDO A PAGAR EN:
                    </label>
                    <div class="col-sm-4">
                      <Select
                        classNamePrefix="select-solo-con"
                        className="select-solo"
                        onChange={this.updateStateCuotas}
                        options={this.state.data.PORCENTAJEANIOSCONTRATO}
                        isMulti={false}
                        components={makeAnimated()}
                        placeholder={"SELECCIONE LOS MESES"}
                        getOptionLabel={(options) =>
                          options.MESES_PORCENTAJE_ANIOS_CONTRATO
                        }
                        getOptionValue={(options) =>
                          options.ID_PORCENTAJE_ANIOS_CONTRATO
                        }
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div class="mb-2 row">
                    <label class="col-sm-5 col-form-label ">
                      MENSUALMENTE LA PRIMER CUOTA DEBERA PAGARSE EL:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="date"
                        class="form-control"
                        name="CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO
                        }
                        placeholder="fecha de pago "
                      />
                    </div>
                  </div>
                  <br />

                  <div class="mb-2 row">
                    <label class="col-sm-4 col-form-label ">
                      PLAZO MEDIANTE CUOTAS NIVELADAS DE:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        step="0.01"
                        class="form-control"
                        readOnly={false}
                        name="CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO
                        }
                        placeholder="Cuota "
                      />
                    </div>
                    <label class="col-sm-4 col-form-label ">
                      CON UNA COMISION Y SERVICIOS QUE SUMADOS SON:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control"
                        name="CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO
                        }
                        placeholder="Cuota "
                      />
                    </div>
                  </div>

                  {/*totales generales  */}
                  <h5 className="h5-contrato">TOTAL</h5>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      PRECIO VENTA:
                    </label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Enganche del terreno "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label ">
                      SALDO DEL PRECIO DE VENTA:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL"
                        value={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Saldo Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">APARTADO:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreatedos}
                        type="number"
                        class="form-control "
                        name="APARTADO_CONTRATO"
                        defaultValue={0}
                        value={this.state.custom.APARTADO_CONTRATO}
                        placeholder="Apartado "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-5 col-form-label ">
                      FECHA FIRMA CONTRATO:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        type="date"
                        class="form-control"
                        name="FECHA_FIRMA_CONTRATO"
                        defaultValue={this.state.custom.FECHA_FIRMA_CONTRATO}
                        placeholder="fecha de contrato "
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="col text-center" name="botones">
                  {/* <button type="submit" class="btn btn-primary">
                    Ingresar
                  </button> */}
                </div>
              </form>

              <br />
              {/* <form onSubmit={(e) => this.CreateFile(e)} >
<input   type="file" name="contrato"  onChange={this.UpdateStateCreateFile}/>
<input   type="submit" value="Subir"/>
</form>
<form action={`${process.env.REACT_APP_SERVER}/api/CONTRATO/create`} method="POST" enctype="multipart/form-data">
<input   type="file" name="contratos" multiple />
<input   type="submit" value="Subir"/>
</form> */}
            </div>
          </Fragment>
          /* <ContratoEmpresa
      auth={this.props.auth}
      Access={this.props.Access}

          />*/
        )}
        {this.state.ver && (
          <Fragment>
            <div class="container">
              <div class="row justify-content-md-center">
                <div class="col col-lg-3">
                  <button
                    id="getPDF"
                    onClick={this.imprimirComponente2}
                    class="btn btn-success"
                  >
                    IMPRIMIR HOJA DE CONTROL
                  </button>
                </div>
                {/* <div class="col col-lg-3" style={{display: "none"}}>
                  <button
                    id="getPDF"
                    onClick={this.imprimirComponente3}
                    class="btn btn-success"
                  >
                    IMPRIMIR CONTRATO
                  </button>
                </div> */}
                <div class="col col-lg-3">
                  <button
                    id="getPDF"
                    onClick={this.Export2Doc}
                    class="btn btn-success"
                  >
                    exportar doc
                  </button>
                </div>
              </div>
            </div>

            <div id="imprimible">
              <div class="col">
                <div id="cabecera" class="row">
                  <div id="imgCabecera" class="col">
                    <img
                      id="img-contrato"
                      src={this.state.empresas.logoEmpresa}
                      alt="HaciendaElNaranjo"
                    />
                  </div>
                </div>
              </div>
              <div name="botones"></div>

              <form>
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      NOMBRE DE ASESOR DE VENTAS:
                    </label>
                    <div class="col-sm-9">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="USUARIO_ADMIN_OFICINA_CONTRATO"
                        defaultValue={this.state.custom.USUARIO_ASESOR_CONTRATO}
                        placeholder="Nombre del Asesor."
                      />
                    </div>
                  </div>
                  <div style={{ display: "none" }} class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      ADMINISTRADOR DE OFICINA:
                    </label>
                    <div class="col-sm-9">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="USUARIO_ADMIN_OFICINA_CONTRATO"
                        defaultValue={
                          this.state.custom.USUARIO_ADMIN_OFICINA_CONTRATO
                        }
                        placeholder="Administracion de oficina"
                      />
                    </div>
                  </div>
                  {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                    <div style={{ display: "none" }} class="mb-2 row">
                      <label class="col-sm-3 col-form-label">
                        OBSERVACIONES:
                      </label>
                      <div class="col-sm-9">
                        <input
                          onChange={this.UpdateStateCreate}
                          readOnly="False"
                          class="form-control"
                          name="OBSERVACIONES_CONTRATO"
                          defaultValue={
                            this.state.custom.OBSERVACIONES_CONTRATO
                          }
                          placeholder="Observaciones"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <h5 id="h5-contrato">
                    DATOS GENERALES DEL OPTANTE/SOLICITANTE COMPRADOR
                  </h5>

                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">NOMBRES:</label>
                    <div class="col-sm-11">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="NOMBRES_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.NOMBRES_CLIENTE_CONTRATO
                        }
                        placeholder="Nombres"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">APELLIDOS:</label>
                    <div class="col-sm-11">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="APELLIDOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.APELLIDOS_CLIENTE_CONTRATO
                        }
                        placeholder="Apellidos"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">NIT:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="NIT_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.NIT_CLIENTE_CONTRATO}
                        placeholder="nit"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">ESTADO CIVIL:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="ESTADO_CIVIL_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.ESTADO_CIVIL_CLIENTE_CONTRATO
                        }
                        placeholder="Estado Civil"
                      />
                    </div>
                    <label class="col-sm-3 col-form-label">
                      FECHA DE NACIMIENTO:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class="form-control"
                        name="FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO"
                        defaultValue={
                          new Date(
                            this.state.custom.FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO
                          )
                            .toLocaleString()
                            .split(" ")[0]
                        }
                        placeholder="fechaNacimientoComprador"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">EDAD:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="EDAD_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.EDAD_CLIENTE_CONTRATO}
                        placeholder="EDAD"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">DPI:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="DPI_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.DPI_CLIENTE_CONTRATO}
                        placeholder="DPI"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">TELEFONO:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="TELEFONO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.TELEFONO_CLIENTE_CONTRATO
                        }
                        placeholder="telefono"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">NACIONALIDAD:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="NACIONALIDAD_CLIENTE_CONTARTO"
                        defaultValue={
                          this.state.custom.NACIONALIDAD_CLIENTE_CONTARTO
                        }
                        placeholder="nacionalidad"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">EMAIL:</label>
                    <div class="col-sm-5">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="email"
                        class="form-control"
                        name="EMAIL_CLIENTE_CONTRATO"
                        defaultValue={this.state.custom.EMAIL_CLIENTE_CONTRATO}
                        placeholder="correo electronico"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">DIRECCION:</label>
                    <div class="col-sm-10">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="DIRECCION_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.DIRECCION_CLIENTE_CONTRATO
                        }
                        placeholder="direccion"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label-sm">
                      DEPARTAMENTO:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="DEPARTAMENTO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.DEPARTAMENTO_CLIENTE_CONTRATO
                        }
                        placeholder="departamento "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">MUNICIPIO:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="MUNICIPIO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.MUNICIPIO_CLIENTE_CONTRATO
                        }
                        placeholder="municipio "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      PROFESION U OFICIO:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="PROFECION_CLIENTE_CONTARTO"
                        defaultValue={
                          this.state.custom.PROFECION_CLIENTE_CONTARTO
                        }
                        placeholder="profesion"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      NOMBRE DEL CONYUGE:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="NOMBRE_CONYUGE_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.NOMBRE_CONYUGE_CLIENTE_CONTRATO
                        }
                        placeholder="nombre del conyuge"
                      />
                    </div>
                    <label class="col-sm-4 col-form-label">
                      PERSONAS QUE DEPENDEN DEL SOLICITANTE:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO
                        }
                        placeholder="dependientes"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-4 col-form-label">
                      INSTITUCION PARA LA QUE TRABAJA:
                    </label>
                    <div class="col-sm-8">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="institucion para la que trabaja"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label">DIRECCION:</label>
                    <div class="col-sm-8">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="DIRECCION"
                      />
                    </div>
                    <label class="col-sm-1 col-form-label">TELEFONO:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="TELEFONO_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.TELEFONO_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label">
                      PUESTO QUE DESEMPEÑA:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO
                        }
                        placeholder="Puesto que desempeña"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      AÑOS LABORADOS:
                    </label>
                    <div class="col-sm-1">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="ANIOS_LABORADOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.ANIOS_LABORADOS_CLIENTE_CONTRATO
                        }
                        placeholder="años"
                      />
                    </div>
                    <label class="col-sm-2 col-form-label">
                      MESES LABORADOS:
                    </label>
                    <div class="col-sm-1">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="MESES_LABORADOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.MESES_LABORADOS_CLIENTE_CONTRATO
                        }
                        placeholder="meses"
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label">
                      SUELDO MENSUAL:
                    </label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="SUELDO_MENSUAL_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.SUELDO_MENSUAL_CLIENTE_CONTRATO
                        }
                        placeholder="Salario"
                      />
                    </div>
                    <label class="col-sm-3 col-form-label">
                      INGRESOS EXTRAS:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="INGRESOS_EXTRAS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom.INGRESOS_EXTRAS_CLIENTE_CONTRATO
                        }
                        placeholder="Ingresos extras"
                      />
                    </div>
                  </div>
                </div>
                <br />
                {/* <!--REferencia en caso de emergencia--> */}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h5 className="h5-contrato">
                        REFERENCIA EN CASO DE EMERGENCIA
                      </h5>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          NOMBRE Y APELLIDO:
                        </label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO
                            }
                            placeholder="nombre contacto de emergencia"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-6">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO
                            }
                            placeholder="Direccion"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">TELEFONO:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO
                            }
                            placeholder="Telefono"
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                  </Fragment>
                )}
                {/* <!--Datos Generales del co-propietario--> */}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      <h5 className="h5-contrato">
                        DATOS GENERALES DEL OPTANTE CO-PROPIETARIO
                      </h5>
                      <div class="mb-2 row">
                        <label class="col-sm col-form-label">
                          NOMBRE Y APELLIDO:
                        </label>
                        <div class="col-sm-10">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO
                            }
                            placeholder="nombre de co-propietario"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">NIT:</label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO
                            }
                            placeholder="Nit"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          {" "}
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO
                            }
                            placeholder="Estado Civil"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          {" "}
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO"
                            defaultValue={
                              new Date(
                                this.state.custom.DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO
                              )
                                .toLocaleString()
                                .split(" ")[0]
                            }
                            placeholder="Fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-3">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO
                            }
                            placeholder="Edad"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-4">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO
                            }
                            placeholder="Dpi"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">TELEFONO:</label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO
                            }
                            placeholder="Telefono"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-7">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO
                            }
                            placeholder="direccion"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-2">
                          <input
                            onChange={this.UpdateStateCreate}
                            readOnly="False"
                            class="form-control"
                            name="DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO
                            }
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 2 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      {/* hasta aqui quede */}
                      <h3>TESTIGO A RUEGO:</h3>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">NOMBRE:</label>
                        <div class="col-sm-10">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO
                            }
                            placeholder="NOMBRE DE TESTIGO DE ARRUEGO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-4">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO
                            }
                            placeholder="ESTADO CIVIL:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO"
                            defaultValue={
                              new Date(
                                this.state.custom.TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO
                              )
                                .toLocaleString()
                                .split(" ")[0]
                            }
                            placeholder="fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-4">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DIRECCION_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_DIRECCION_CONTRATO
                            }
                            placeholder="DIRECCION:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">TELEFONO:</label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_TELEFONO_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_TELEFONO_CONTRATO
                            }
                            placeholder="TELEFONO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-2">
                          <input
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_EDAD_CONTRATO"
                            defaultValue={
                              this.state.custom.TESTIGO_ARRUEGO_EDAD_CONTRATO
                            }
                            placeholder="EDAD"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DPI_CONTRATO"
                            defaultValue={
                              this.state.custom.TESTIGO_ARRUEGO_DPI_CONTRATO
                            }
                            placeholder="DPI"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO
                            }
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 9 && (
                  <Fragment>
                    <div id="container-contrato" class="container">
                      {/* hasta aqui quede */}
                      <h3>TESTIGO A RUEGO:</h3>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">NOMBRE:</label>
                        <div class="col-sm-10">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO
                            }
                            placeholder="NOMBRE DE TESTIGO DE ARRUEGO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          ESTADO CIVIL:
                        </label>
                        <div class="col-sm-4">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_ESTADO_CIVIL_CONTRATO
                            }
                            placeholder="ESTADO CIVIL:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">
                          FECHA DE NACIMIENTO:
                        </label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO"
                            defaultValue={
                              new Date(
                                this.state.custom.TESTIGO_ARRUEGO_FECHA_NACIMIENTO_CONTRATO
                              )
                                .toLocaleString()
                                .split(" ")[0]
                            }
                            placeholder="fecha de nacimiento"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-2 col-form-label">
                          DIRECCION:
                        </label>
                        <div class="col-sm-4">
                          <input
                            readOnly="False"
                            type="text"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DIRECCION_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_DIRECCION_CONTRATO
                            }
                            placeholder="DIRECCION:"
                          />
                        </div>
                        <label class="col-sm-3 col-form-label">TELEFONO:</label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_TELEFONO_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_TELEFONO_CONTRATO
                            }
                            placeholder="TELEFONO"
                          />
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-1 col-form-label">EDAD:</label>
                        <div class="col-sm-2">
                          <input
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_EDAD_CONTRATO"
                            defaultValue={
                              this.state.custom.TESTIGO_ARRUEGO_EDAD_CONTRATO
                            }
                            placeholder="EDAD"
                          />
                        </div>
                        <label class="col-sm-1 col-form-label">DPI:</label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            type="number"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_DPI_CONTRATO"
                            defaultValue={
                              this.state.custom.TESTIGO_ARRUEGO_DPI_CONTRATO
                            }
                            placeholder="DPI"
                          />
                        </div>
                        <label class="col-sm-2 col-form-label">
                          NACIONALIDAD:
                        </label>
                        <div class="col-sm-3">
                          <input
                            readOnly="False"
                            class="form-control"
                            name="TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO"
                            defaultValue={
                              this.state.custom
                                .TESTIGO_ARRUEGO_NACIONALIDAD_CONTRATO
                            }
                            placeholder="nacionalidad"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                  </Fragment>
                )}
                <div id="container-contrato" class="container">
                  <h5 className="h5-contrato">REFERENCIAS PERSONALES</h5>
                  <b>1.</b>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      NOMBRE Y APELLIDO:
                    </label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Nombre y apellido "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">PARENTESCO:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Parentesco "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">DIRECCION:</label>
                    <div class="col-sm-8">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Direccion "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">TELEFONO:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono "
                      />
                    </div>
                  </div>
                  <b>2.</b>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      NOMBRE Y APELLIDO:
                    </label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Nombre y apellido "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">PARENTESCO:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Parentesco "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">DIRECCION:</label>
                    <div class="col-sm-8">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Direccion "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">TELEFONO:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control "
                        name="REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO
                        }
                        placeholder="Telefono "
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">BANCO:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="BANCO_CONTRATO"
                        defaultValue={this.state.custom.BANCO_CONTRATO}
                        placeholder="BANCO "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">CUENTA:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="NOMBRE_CUENTA_CONTRATO"
                        defaultValue={this.state.custom.NOMBRE_CUENTA_CONTRATO}
                        placeholder="CUENTA "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">NO. CUENTA:</label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="CUENTA_CONTRATO"
                        defaultValue={this.state.custom.CUENTA_CONTRATO}
                        placeholder="CUENTA "
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                    <h5 className="h5-contrato">CONDICIONES DE COMPRA</h5>
                    <label class="col-sm-1 col-form-label ">MANZANA:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO
                        }
                        placeholder="Manzana "
                      />
                    </div>

                    <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control"
                        name="NO_LOTE"
                        // defaultValue=  {(this.state.data && this.state.data.LotesContrato)?this.state.data.LotesContrato.map((item) =>{return item.NO_LOTE}).join(' , '):" NO POSEE CONTRATO" }
                        defaultValue={this.state.custom.NO_LOTE}
                        placeholder="No. de lote "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">
                      METROS<sup>2</sup>
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class=" form-control "
                        name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="metros "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO
                        }
                        placeholder="finca numero "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO
                        }
                        placeholder="Folio Numero "
                      />
                    </div>
                    <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control "
                        name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO
                        }
                        placeholder="Libro numero "
                      />
                    </div>
                  </div>
                  <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                  <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label ">
                      PRECIO VENTA:
                    </label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO"
                        defaultValue={
                          "Q " +
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO"
                        defaultValue={
                          "Q " +
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Enganche del terreno "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label ">
                      SALDO DEL PRECIO DE VENTA:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO"
                        defaultValue={
                          "Q " +
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
                        }
                        placeholder="Saldo Precio venta "
                      />
                    </div>
                    <label class="col-sm-2 col-form-label ">ARRAS 10%:</label>
                    <div class="col-sm-4">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO"
                        defaultValue={
                          "Q " +
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO
                        }
                        placeholder="Arras "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-3 col-form-label ">
                      SALDO A PAGAR EN:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="number"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
                        defaultValue={
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO
                        }
                        placeholder="tiempo "
                      />
                    </div>
                    <label class="col-sm-4 col-form-label ">
                      PLAZO MEDIANTE CUOTAS NIVELADAS DE:
                    </label>
                    <div class="col-sm-3">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        step="0.01"
                        class="form-control "
                        name="CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO"
                        defaultValue={
                          "Q " +
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO
                        }
                        placeholder="Cuota "
                      />
                    </div>
                  </div>
                  <div class="mb-2 row">
                    <label class="col-sm-4 col-form-label ">
                      CON UNA COMISION Y SERVICIOS QUE SUMADOS SON:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        type="text"
                        class="form-control"
                        name="CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO"
                        defaultValue={
                          "Q " +
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO
                        }
                        placeholder="Cuota "
                      />
                    </div>
                    <label class="col-sm-4 col-form-label ">
                      MENSUALMENTE LA PRIMER CUOTA DEBERA PAGARSE EL:
                    </label>
                    <div class="col-sm-2">
                      <input
                        onChange={this.UpdateStateCreate}
                        readOnly="False"
                        class="form-control"
                        name="CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO"
                        defaultValue={
                          new Date(
                            this.state.custom.CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO
                          )
                            .toLocaleString()
                            .split(" ")[0]
                        }
                        type="text"
                        placeholder="fecha de pago"
                      />
                    </div>
                  </div>
                  <div>
                    {this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO == 1 && (
                      <div class="mb-2 row">
                        <label class="col-sm-3 col-form-label">
                          OBSERVACIONES:
                        </label>
                        <div class="col-sm-9">
                          <input
                            onChange={this.UpdateStateCreate}
                            class="form-control"
                            readOnly="False"
                            name="OBSERVACIONES_CONTRATO"
                            defaultValue={
                              this.state.custom.OBSERVACIONES_CONTRATO
                            }
                            placeholder=""
                          />
                        </div>
                      </div>
                    )}
                    
                    <br />
                    <div className="row footerB">
                      <div class="form-group col-md-4 row">
                        <p>F________________________________________</p>
                      </div>
                      <div className="d-flex flex-end">
                        <h4>NO SE DEVOLVERÁN RESERVAS.</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div>
                <br />
              </div>
            </div>
            <Contrato
              EMPRESA={this.props.EMPRESA}
              TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              custom={this.state.custom}
              logoEmpresa={this.state.empresas.logoEmpresa}
            />
            <br />
            {/* <form onSubmit={(e) => this.CreateFile(e)} >
        <input   type="file" name="contrato"  onChange={this.UpdateStateCreateFile}/>
        <input   type="submit" value="Subir"/>
</form>
<form action={`${process.env.REACT_APP_SERVER}/api/CONTRATO/create`} method="POST" enctype="multipart/form-data">
        <input   type="file" name="contratos" multiple />
        <input   type="submit" value="Subir"/>
</form> */}
          </Fragment>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_CONTRATO") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="CONTRATO"
            url="CONTRATO"
            urlSecundary={`ByEmpresaByTipo/${this.props.EMPRESA.ID_EMPRESA}/${this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO}`}
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_USUARIO_ASESOR_CONTRATO",
                label: "NOMBRE_USUARIO_ASESOR_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_USUARIO_ADMIN_OFICINA_CONTRATO",
                label: "NOMBRE_USUARIO_ADMIN_OFICINA_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "OBSERVACIONES_CONTRATO",
                label: "OBSERVACIONES_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_CLIENTE",
                label: "NOMBRE_CLIENTE",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_COMPLETO_CLIENTE",
                label: "NOMBRES CLIENTE CONTRATO",
                display: true,
                type: "text",
              },
              {
                name: "APELLIDOS_CLIENTE_CONTRATO",
                label: "APELLIDOS CLIENTE CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "NIT_CLIENTE_CONTRATO",
                label: "NIT_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "ESTADO_CIVIL_CLIENTE_CONTRATO",
                label: "ESTADO_CIVIL_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO",
                label: "FECHA_DE_NACIMIENTO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "EDAD_CLIENTE_CONTRATO",
                label: "EDAD_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "DPI_CLIENTE_CONTRATO",
                label: "DPI_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "TELEFONO_CLIENTE_CONTRATO",
                label: "TELEFONO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "NACIONALIDAD_CLIENTE_CONTARTO",
                label: "NACIONALIDAD_CLIENTE_CONTARTO",
                display: false,
                type: "text",
              },
              {
                name: "EMAIL_CLIENTE_CONTRATO",
                label: "EMAIL_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DIRECCION_CLIENTE_CONTRATO",
                label: "DIRECCION_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "PROFECION_CLIENTE_CONTARTO",
                label: "PROFECION_CLIENTE_CONTARTO",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_CONYUGE_CLIENTE_CONTRATO",
                label: "NOMBRE_CONYUGE_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO",
                label: "PERSONAS_QUE_DEPENDEN_DEL_SOLICITANTE_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO",
                label: "NOMBRE_INSTITUCION_TRABAJO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO",
                label: "DIRECCION_DEL_TRABAJO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "TELEFONO_TRABAJO_CLIENTE_CONTRATO",
                label: "TELEFONO_TRABAJO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO",
                label: "PUESTO_QUE_DESEMPENIA_EMPRESA_TRABAJO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "ANIOS_LABORADOS_CLIENTE_CONTRATO",
                label: "ANIOS_LABORADOS_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "MESES_LABORADOS_CLIENTE_CONTRATO",
                label: "MESES_LABORADOS_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "SUELDO_MENSUAL_CLIENTE_CONTRATO",
                label: "SUELDO_MENSUAL_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "INGRESOS_EXTRAS_CLIENTE_CONTRATO",
                label: "INGRESOS_EXTRAS_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO",
                label:
                  "REFERENCIA_EMERGENCIA_NOMBRES_APELLIDOS_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO",
                label: "REFERENCIA_EMERGENCIA_DIRECCION_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO",
                label: "REFERENCIA_EMERGENCIA_TELEFONO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO",
                label:
                  "DATOS_GENERALES_CO_PROPIETARIO_NOMBRE_Y_APELLIDO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO",
                label: "DATOS_GENERALES_CO_PROPIETARIO_NIT_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO",
                label:
                  "DATOS_GENERALES_CO_PROPIETARIO_ESTADO_CIVIL_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO",
                label:
                  "DATOS_GENERALES_CO_PROPIETARIO_FECHA_NACIMIENTO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO",
                label: "DATOS_GENERALES_CO_PROPIETARIO_EDAD_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO",
                label: "DATOS_GENERALES_CO_PROPIETARIO_DPI_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO",
                label:
                  "DATOS_GENERALES_CO_PROPIETARIO_TELEFONO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO",
                label:
                  "DATOS_GENERALES_CO_PROPIETARIO_DIRECCION_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO",
                label:
                  "DATOS_GENERALES_CO_PROPIETARIO_NACIONALIDAD_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO",
                label:
                  "REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_UNO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO",
                label: "REFERENCIAS_PERSONALES_DIRECCION_UNO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO",
                label: "REFERENCIAS_PERSONALES_TELEFONO_UNO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO",
                label:
                  "REFERENCIAS_PERSONALES_NOMBRE_APELLIDO_DOS_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO",
                label: "REFERENCIAS_PERSONALES_DIRECCION_DOS_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO",
                label: "REFERENCIAS_PERSONALES_TELEFONO_DOS_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO",
                label: "REFERENCIAS_PERSONALES_PARENTEZCO_UNO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO",
                label: "REFERENCIAS_PERSONALES_PARENTEZCO_DOS_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "NO_LOTE",
                label: "NO_LOTE",
                display: true,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO",
                label: "MANZANA",
                display: true,
                type: "text",
              },
              {
                name: "UBICACION_MANZANA",
                label: "SECTOR",
                display: false,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO",
                label:
                  "CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO",
                label:
                  "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO",
                label:
                  "CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO",
                label: "CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO",
                display: false,
                type: "number",
              },
              {
                name: "CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO",
                label:
                  "CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: false,
                type: "text",
              },
              // {
              //   name: "ELIMINAR",
              //   type: "button",
              //   display: this.props.Access("DELETE_CONTRATO")
              //     ? true
              //     : "excluded",
              //   buttonConfig: EliminarIcon,
              //   OnClickButton: this.Delete,
              // },
              {
                name: "VER",
                type: "button",
                display: this.props.Access("READ_CONTRATO_INDIVIDUAL")
                  ? true
                  : "excluded",
                buttonConfig: DesktopWindowsIcon,
                OnClickButton: this.ShowReadindividual,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_CONTRATO")
                  ? true
                  : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(CONTRATO);
