import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ExplicitIcon from '@material-ui/icons/Explicit';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import BackupIcon from '@material-ui/icons/Backup';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
function icons(icon){

switch(icon){
  case "SaveIcon":
    return <SaveIcon/>
    case "DeleteIcon":
    return <DeleteIcon/>
      case "UpdateIcon":
        return <UpdateIcon/>
          case "SearchRoundedIcon":
        return <SearchRoundedIcon/>
          case "AddCircleIcon":
            return <AddCircleIcon/>
              case "ArrowBackIcon":
                return <ArrowBackIcon/>
                case "AssignmentIcon":
                  return <AssignmentIcon/>
                  case "PictureAsPdfIcon":
                    return <PictureAsPdfIcon/>
                    case "ExplicitIcon":
                      return <ExplicitIcon/>
                    case "OpenInBrowserIcon":
                      return <OpenInBrowserIcon/>
                    case "LockOpenIcon":
                      return <LockOpenIcon/>
                    case "CloseIcon":
                      return <CloseIcon/>
                    case "CancelIcon":
                      return <CancelIcon/>
                    case "HighlightOffIcon":
                      return <HighlightOffIcon/>
                    case "CancelPresentationIcon":
                      return <CancelPresentationIcon/>
                    case "AddShoppingCartIcon":
                      return <AddShoppingCartIcon/>
                    case "AddPhotoAlternateIcon":
                      return <AddPhotoAlternateIcon/>
                    case "BackupIcon":
                      return <BackupIcon/>
                    case "GetAppIcon":
                      return <GetAppIcon/>
                    case "CloudDownloadIcon":
                      return <CloudDownloadIcon/>
                    case "DesktopWindowsIcon":
                      return <DesktopWindowsIcon/>
                      case "AttachMoneyIcon":
                      return <AttachMoneyIcon/>

}

}

export default function ButtonG({onClick,disabled=false,config,className=""}) {
  const {variant,color,text,icon}=config;
    return (
    <Button  onClick={onClick} className={className} variant={variant} color="primary" style={{backgroundColor:color}} disabled={disabled} startIcon={icons(icon)} >{text}</Button>
    )
}
