//pagina de inicio
import slider1 from "./img/prueba1.jpg";
import slider2 from "./img/slider2.jpg";
import slider3 from "./img/slider3.jpg";
import sliderP from "./img/Rebiera_Inmobiliaria.jpg";
import landpage1 from "./img/sliderPage1.jpg";
import landpage2 from "./img/sliderPage2.jpg";
import landpage3 from "./img/sliderPage3.jpg";
import cardt1 from "./img/cardT.jpg";
import modern1 from "./img/modern1.jpg";
import modern2 from "./img/modern2.jpg";
import modern3 from "./img/modern3.jpg";
import rustic1 from "./img/rustico1.jpg";
import rustic2 from "./img/rustico2.jpg";
import rustic3 from "./img/rustico3.jpg";
import logonavBar from "./img/logo.png";

// PAGINA DE inicio logotipos
import jocotillo from "../../assets/img/Jocotillo/Jocotillo2024.png";
import naranjo from "../../assets/img/Naranjo/Naranjo2024.png";
import pradosdechiquimulilla from "../../assets/img/Finca Gudiela/PradosChiquimulilla2024.png";
import arbolitos from "../../assets/img/Los Arbolitos/Arbolitos.png";
import brisas from "../../assets/img/Brisas del Mar/Brisas2024.png";
import martillo from "../../assets/img/El Martillo/Martillo2024.png";
import fincagluidiela from "../../assets/img/Finca Gudiela/PradosChiquimulilla2024.png";
import estadia from "../../assets/img/La Estancia/2x/Mesa de trabajo 10.jpg";
import santaana from "../../assets/img/Santa Ana/ParcelamientoSantaAna2024.png";
import tecultan from "../../assets/img/teculutan/2x/Mesa de trabajo 10.jpg";
import tikal from "../../assets/img/tikal/Tikal2024.png";
import villasrosario from "../../assets/img/villas del rosario/VillasdelRosario2024.png";
import ladicha from "../../assets/img/LaDicha/LaDichaLogo.png"
import lasflores from "../../assets/img/LasFlores/LogoLasFlores.png";

import SantaElena from "../../assets/img/15SANTAELENA_NUEVA/FincaSantaElena.png";
import LogoSanJacinto from "../../assets/img/HaciendaSanJacinto/SanJacinto2024.png";
import Pasaco from "../../assets/img/Pasaco/PasacoLogo.png"
import LomaReal from "../../assets/img/LOMAREAL/LomaReal2024.png"
import ElEden from "../../assets/img/JardinDelEden/ElEden.png"









//pagina de LOTIFICADORA EL JOCOTILLO
import sliderJOCOTILLO1 from "../../assets/img/Jocotillo/slider1.jpg";
import sliderJOCOTILLO2 from "../../assets/img/Jocotillo/slider2.jpg";
import sliderJOCOTILLO3 from "../../assets/img/Jocotillo/slider3.jpg";
import sliderJOCOTILLOP from "../../assets/img/Jocotillo/sliderP.jpg";
import landpageJOCOTILLO1 from "../../assets/img/Jocotillo/jocotillo1.jpg";
import landpageJOCOTILLO2 from "../../assets/img/Jocotillo/jocotillo2.jpg";
import landpageJOCOTILLO3 from "../../assets/img/Jocotillo/jocotillo3.jpg";
import cardtJOCOTILLO1 from "../../assets/img/Jocotillo/jocotillo4.jpg";
import gridJOCOTILLO1 from "../../assets/img/Jocotillo/jocotillo9.jpg";
import gridJOCOTILLO2 from "../../assets/img/Jocotillo/jocotillo11.jpg";
import gridJOCOTILLO3 from "../../assets/img/Jocotillo/jocotillo12.jpg";
import gridJOCOTILLO4 from "../../assets/img/Jocotillo/jocotillo1.jpg";
import gridJOCOTILLO5 from "../../assets/img/Jocotillo/jocotillo2.jpg";
import gridJOCOTILLO6 from "../../assets/img/Jocotillo/jocotillo3.jpg";
import gridJOCOTILLO7 from "../../assets/img/Jocotillo/jocotillo4.jpg";
import gridJOCOTILLO8 from "../../assets/img/Jocotillo/jocotillo9.jpg";
import logonavBarJOCOTILLO from "../../assets/img/Jocotillo/logo_jocotillo.jpeg";
import logoMenuJocotillo from "../../assets/img/Jocotillo/logo_jocotillo.jpeg";

// PAGINA DE TECULUTAN
import sliderTECULUTAN1 from "../../assets/img/teculutan/1.jfif";
import sliderTECULUTAN2 from "../../assets/img/teculutan/2.jfif";
import sliderTECULUTAN3 from "../../assets/img/teculutan/3.jfif";
import sliderTECULUTANP from "../../assets/img/teculutan/4.jfif";
import landpageTECULUTAN1 from "../../assets/img/teculutan/5.jfif";
import landpageTECULUTAN2 from "../../assets/img/teculutan/6.jfif";
import landpageTECULUTAN3 from "../../assets/img/teculutan/7.jfif";
import cardtTECULUTAN1 from "../../assets/img/teculutan/8.jfif";
import gridTECULUTAN1 from "../../assets/img/teculutan/9.jfif";
import gridTECULUTAN2 from "../../assets/img/teculutan/10.jfif";
import gridTECULUTAN3 from "../../assets/img/teculutan/11.jfif";
import gridTECULUTAN4 from "../../assets/img/teculutan/12.jfif";
import gridTECULUTAN5 from "../../assets/img/teculutan/13.jfif";
import gridTECULUTAN6 from "../../assets/img/teculutan/14.jfif";
import gridTECULUTAN7 from "../../assets/img/teculutan/15.jfif";
import gridTECULUTAN8 from "../../assets/img/teculutan/16.jfif";
import logonavBarTECULUTAN from "../../assets/img/teculutan/logo teculutan baner.png";
import logoMenuTECULUTAN from "../../assets/img/teculutan/logo teculutan.png";

// PAGINA DE PROYECTO TIKAL
import sliderTIKAL1 from "../../assets/img/tikal/tikal.jpeg";
import sliderTIKAL2 from "../../assets/img/tikal/tikal2.jpeg";
import sliderTIKAL3 from "../../assets/img/tikal/tikal3.jpeg";
import sliderTIKALP from "../../assets/img/tikal/tikal4.jpeg";
import landpageTIKAL1 from "../../assets/img/tikal/tikal5.jpeg";
import landpageTIKAL2 from "../../assets/img/tikal/tikal6.jpeg";
import landpageTIKAL3 from "../../assets/img/tikal/tikal7.jpeg";
import cardtTIKAL1 from "../../assets/img/tikal/tikal.jpeg";
import gridTIKAL1 from "./img/modern1.jpg";
import gridTIKAL2 from "../../assets/img/tikal/tikal2.jpeg";
import gridTIKAL3 from "../../assets/img/tikal/tikal3.jpeg";
import gridTIKAL4 from "../../assets/img/tikal/tikal4.jpeg";
import gridTIKAL5 from "../../assets/img/tikal/tikal5.jpeg";
import gridTIKAL6 from "../../assets/img/tikal/tikal6.jpeg";
import gridTIKAL7 from "../../assets/img/tikal/tikal7.jpeg";
import gridTIKAL8 from "../../assets/img/tikal/tikal7.jpeg";
import logonavBarTIKAL from "../../assets/img/tikal/baner.png";
import logoMenuTIKAL from "../../assets/img/tikal/tikalMenu.png";

//pagina de LOTIFICADORA EL NARANJO
import sliderNARANJO1 from "../../assets/img/Naranjo/naranjo6.jpg";
import sliderNARANJO2 from "../../assets/img/Naranjo/naranjo5.jpg";
import sliderNARANJO3 from "../../assets/img/Naranjo/naranjo4.jpg";
import sliderNARANJOP from "../../assets/img/Naranjo/logo_naranjo.jpg";
import landpageNARANJO1 from "../../assets/img/Naranjo/naranjo10.jpg";
import landpageNARANJO2 from "../../assets/img/Naranjo/naranjo9.jpg";
import landpageNARANJO3 from "../../assets/img/Naranjo/naranjo8.jpg";
import cardtNARANJO1 from "../../assets/img/Naranjo/naranjo1.jpg";
import gridNARANJO1 from "../../assets/img/Naranjo/naranjo2.jpg";
import gridNARANJO2 from "../../assets/img/Naranjo/naranjo3.jpg";
import gridNARANJO3 from "../../assets/img/Naranjo/naranjo4.jpg";
import gridNARANJO4 from "../../assets/img/Naranjo/naranjo5.jpg";
import gridNARANJO5 from "../../assets/img/Naranjo/naranjo5.jpg";
import gridNARANJO6 from "../../assets/img/Naranjo/naranjo6.jpg";
import gridNARANJO7 from "../../assets/img/Naranjo/naranjo8.jpg";
import gridNARANJO8 from "../../assets/img/Naranjo/naranjo9.jpg";
import logonavBarNARANJO from "../../assets/img/Naranjo/logo_naranjo.jpg";
import logoMenuNARANJO from "../../assets/img/Naranjo/logo_naranjo.jpg";

// PAGINA DE BRISAS DEL MAR
import sliderBRISAS1 from "./img/slider1.jpg";
import sliderBRISAS2 from "./img/slider2.jpg";
import sliderBRISAS3 from "./img/slider3.jpg";
import sliderBRISASP from "./img/Rebiera_Inmobiliaria.jpg";
import landpageBRISAS1 from "./img/sliderPage1.jpg";
import landpageBRISAS2 from "./img/sliderPage2.jpg";
import landpageBRISAS3 from "./img/sliderPage3.jpg";
import cardtBRISAS1 from "./img/cardT.jpg";
import gridBRISAS1 from "./img/modern1.jpg";
import gridBRISAS2 from "./img/modern2.jpg";
import gridBRISAS3 from "./img/modern3.jpg";
import gridBRISAS4 from "./img/rustico1.jpg";
import gridBRISAS5 from "./img/rustico2.jpg";
import gridBRISAS6 from "./img/rustico3.jpg";
import gridBRISAS7 from "./img/rustico2.jpg";
import gridBRISAS8 from "./img/rustico3.jpg";
import logonavBarBRISAS from "../../assets/img/brisas/logo brisas baner.png";
import logoMenuBRISAS from "../../assets/img/brisas/logo brisas baner.png";

// PAGINA DE VILLAS DEL ROSARIO
import sliderVILLAS1 from "../../assets/img/villas del rosario/rosario2.jpg";
import sliderVILLAS2 from "../../assets/img/villas del rosario/rosario3.jpg";
import sliderVILLAS3 from "../../assets/img/villas del rosario/rosario4.jpg";
import sliderVILLASP from "../../assets/img/villas del rosario/logo_villas.jpeg";
import landpageVILLAS1 from "../../assets/img/villas del rosario/rosario5.jpg";
import landpageVILLAS2 from "../../assets/img/villas del rosario/rosario6.jpg";
import landpageVILLAS3 from "../../assets/img/villas del rosario/rosario7.jpg";
import cardtVILLAS1 from "../../assets/img/villas del rosario/logo_villas.jpeg";
import gridVILLAS1 from "../../assets/img/villas del rosario/rosario2.jpg";
import gridVILLAS2 from "../../assets/img/villas del rosario/rosario3.jpg";
import gridVILLAS3 from "../../assets/img/villas del rosario/rosario4.jpg";
import gridVILLAS4 from "../../assets/img/villas del rosario/rosario5.jpg";
import gridVILLAS5 from "../../assets/img/villas del rosario/rosario6.jpg";
import gridVILLAS6 from "../../assets/img/villas del rosario/rosario7.jpg";
import gridVILLAS7 from "../../assets/img/villas del rosario/rosario8.jpg";
import gridVILLAS8 from "../../assets/img/villas del rosario/rosario9.jpg";
import logonavBarVILLAS from "../../assets/img/villas del rosario/logo_villas.jpeg";
import logoMenuVILLAS from "../../assets/img/villas del rosario/logo_villas.jpeg";

// PAGINA DE LA ATLANTIDA PERDIDA
import sliderATLANTIDA1 from "./img/slider1.jpg";
import sliderATLANTIDA2 from "./img/slider2.jpg";
import sliderATLANTIDA3 from "./img/slider3.jpg";
import sliderATLANTIDAP from "./img/Rebiera_Inmobiliaria.jpg";
import landpageATLANTIDA1 from "./img/sliderPage1.jpg";
import landpageATLANTIDA2 from "./img/sliderPage2.jpg";
import landpageATLANTIDA3 from "./img/sliderPage3.jpg";
import cardtATLANTIDA1 from "./img/cardT.jpg";
import gridATLANTIDA1 from "./img/modern1.jpg";
import gridATLANTIDA2 from "./img/modern2.jpg";
import gridATLANTIDA3 from "./img/modern3.jpg";
import gridATLANTIDA4 from "./img/rustico1.jpg";
import gridATLANTIDA5 from "./img/rustico2.jpg";
import gridATLANTIDA6 from "./img/rustico3.jpg";
import gridATLANTIDA7 from "./img/rustico2.jpg";
import gridATLANTIDA8 from "./img/rustico3.jpg";
import logonavBarATLANTIDA from "./img/logo.png";
import logoMenuATLANTIDA from "./img/tikal.png";

// PAGINA DE SAN MARTIN
import sliderSANMARTIN1 from "./img/slider1.jpg";
import sliderSANMARTIN2 from "./img/slider2.jpg";
import sliderSANMARTIN3 from "./img/slider3.jpg";
import sliderSANMARTINP from "./img/Rebiera_Inmobiliaria.jpg";
import landpageSANMARTIN1 from "./img/sliderPage1.jpg";
import landpageSANMARTIN2 from "./img/sliderPage2.jpg";
import landpageSANMARTIN3 from "./img/sliderPage3.jpg";
import cardtSANMARTIN1 from "./img/cardT.jpg";
import gridSANMARTIN1 from "./img/modern1.jpg";
import gridSANMARTIN2 from "./img/modern2.jpg";
import gridSANMARTIN3 from "./img/modern3.jpg";
import gridSANMARTIN4 from "./img/rustico1.jpg";
import gridSANMARTIN5 from "./img/rustico2.jpg";
import gridSANMARTIN6 from "./img/rustico3.jpg";
import gridSANMARTIN7 from "./img/rustico2.jpg";
import gridSANMARTIN8 from "./img/rustico3.jpg";
import logonavBarSANMARTIN from "./img/logo.png";
import logoMenuSANMARTIN from "./img/tikal.png";

import sliderELVALLE1 from "../../assets/img/EL VALLE/7.jfif";
import sliderELVALLE2 from "../../assets/img/EL VALLE/1.jfif";
import sliderELVALLE3 from "../../assets/img/EL VALLE/2.jfif";
import sliderELVALLEP from "../../assets/img/EL VALLE/3.jfif";
import landpageELVALLE1 from "../../assets/img/EL VALLE/4.jfif";
import landpageELVALLE2 from "../../assets/img/EL VALLE/5.jfif";
import landpageELVALLE3 from "../../assets/img/EL VALLE/6.jfif";
import cardtELVALLE1 from "../../assets/img/EL VALLE/1.jfif";
import gridELVALLE1 from "../../assets/img/EL VALLE/2.jfif";
import gridELVALLE2 from "../../assets/img/EL VALLE/3.jfif";
import gridELVALLE3 from "../../assets/img/EL VALLE/4.jfif";
import gridELVALLE4 from "../../assets/img/EL VALLE/5.jfif";
import gridELVALLE5 from "../../assets/img/EL VALLE/6.jfif";
import gridELVALLE6 from "../../assets/img/EL VALLE/1.jfif";
import gridELVALLE7 from "../../assets/img/EL VALLE/2.jfif";
import gridELVALLE8 from "../../assets/img/EL VALLE/3.jfif";
import logonavBarELVALLE from "../../assets/img/EL VALLE/LOGO.jpeg";
import logoMenuELVALLE from "../../assets/img/EL VALLE/LOGO.jpeg";

//Naranjo nueva
import videoNaranjo from "../../assets/img/El Naranjo/3x/animacion.mp4";
import naranjorecortado from "../../assets/img/El Naranjo/3x/Naranjo Logo Recortado.jpg";
import rotanda from "../../assets/img/El Naranjo/3x/Rotonda Imagen.jpg";
import casas from "../../assets/img/El Naranjo/3x/Casas.jpg";
import ciclovia from "../../assets/img/El Naranjo/3x/Ciclovia.jpg";
import construccion from "../../assets/img/El Naranjo/3x/construccion.jpg";
//import logopagNaranjo from "../../assets/img/El Naranjo/3x/Naranjo Logo Recortado.png"

//Videos
import videoJocotillo from "../../assets/img/El Jocotillo/Jocotillo Logo 2.mp4";
import videoPrados from "../../assets/img/Prados de Chiquimulilla/Logo Prados de Chiquimulilla 2.mp4";
import videoArbolitos from "../../assets/img/Los Arbolitos/Los Arbolitos Animacion Logo.mp4";
import videoTikal from "../../assets/img/tikal/Animacion Logotipo Proyecto Tikal.mp4";
import videoBrisas from "../../assets/img/Brisas del Mar/Las Brisas Animacion logo.mp4";
import videoElMartillo from "../../assets/img/El Martillo/El Martillo Animacion Logo.mp4";
import videoVillas from "../../assets/img/villas del rosario/Villa del Rosario Logo animado.mp4";
import videoSantaAna from "../../assets/img/Santa Ana/Santa Ana Animacion Logo.mp4";

// PAGINA DE PORTALESLAESTANCIA
import sliderPORTALESLAESTANCIA1 from "../../assets/img/PORTALES LA ESTANCIA/1.jfif";
import sliderPORTALESLAESTANCIA2 from "../../assets/img/PORTALES LA ESTANCIA/2.jfif";
import sliderPORTALESLAESTANCIA3 from "../../assets/img/PORTALES LA ESTANCIA/3.jfif";
import sliderPORTALESLAESTANCIAP from "../../assets/img/PORTALES LA ESTANCIA/4.jfif";
import landpagePORTALESLAESTANCIA1 from "../../assets/img/PORTALES LA ESTANCIA/5.jfif";
import landpagePORTALESLAESTANCIA2 from "../../assets/img/PORTALES LA ESTANCIA/6.jfif";
import landpagePORTALESLAESTANCIA3 from "../../assets/img/PORTALES LA ESTANCIA/7.jfif";
import cardtPORTALESLAESTANCIA1 from "../../assets/img/PORTALES LA ESTANCIA/8.jfif";
import gridPORTALESLAESTANCIA1 from "../../assets/img/PORTALES LA ESTANCIA/9.jfif";
import gridPORTALESLAESTANCIA2 from "../../assets/img/PORTALES LA ESTANCIA/10.jfif";
import gridPORTALESLAESTANCIA3 from "../../assets/img/PORTALES LA ESTANCIA/11.jfif";
import gridPORTALESLAESTANCIA4 from "../../assets/img/PORTALES LA ESTANCIA/12.jfif";
import gridPORTALESLAESTANCIA5 from "../../assets/img/PORTALES LA ESTANCIA/1.jfif";
import gridPORTALESLAESTANCIA6 from "../../assets/img/PORTALES LA ESTANCIA/2.jfif";
import gridPORTALESLAESTANCIA7 from "../../assets/img/PORTALES LA ESTANCIA/3.jfif";
import gridPORTALESLAESTANCIA8 from "../../assets/img/PORTALES LA ESTANCIA/4.jfif";
import logonavBarPORTALESLAESTANCIA from "../../assets/img/PORTALES LA ESTANCIA/LOGO.jpeg";
import logoMenuPORTALESLAESTANCIA from "../../assets/img/PORTALES LA ESTANCIA/LOGO.jpeg";

//imagenes nuevas Jocotillo
import img1_paisaje from "../../assets/img/ImagenesJocotilloNuevas/img10_florbonita.jpg"
import img2_paisaje from "../../assets/img/ImagenesJocotilloNuevas/img2_paisaje.jpg"
import img3_lotedividido from "../../assets/img/ImagenesJocotilloNuevas/img3_lotedividido.jpg"
import img4_lotedividido from "../../assets/img/ImagenesJocotilloNuevas/img4_lotedividido.jpg"
import img5_lotedividido from "../../assets/img/ImagenesJocotilloNuevas/img5_lotedividido.jpg"
import img6_arbol from "../../assets/img/ImagenesJocotilloNuevas/img6_arbol.jpg"
import img7_arbol from "../../assets/img/ImagenesJocotilloNuevas/img7_arbol.jpg"
import img8_flor from "../../assets/img/ImagenesJocotilloNuevas/img8_flor.jpg"
import img9_flor from "../../assets/img/ImagenesJocotilloNuevas/img9_flor.jpg"
import img10_florbonita from "../../assets/img/ImagenesJocotilloNuevas/img10_florbonita.jpg"
import img11_flor from "../../assets/img/ImagenesJocotilloNuevas/img11_flor.jpg"
import img12_florcerca from "../../assets/img/ImagenesJocotilloNuevas/img11_flor.jpg"
import img13_caminito from "../../assets/img/ImagenesJocotilloNuevas/img11_flor.jpg"
import img14_paisajebonito from "../../assets/img/ImagenesJocotilloNuevas/img14_paisajebonito.jpg"
import img15_florbonita from "../../assets/img/ImagenesJocotilloNuevas/img15_florbonita.jpg"
import img16_camino from "../../assets/img/ImagenesJocotilloNuevas/img16_camino.jpg"


//NuevasPrados
import img1Prados from "../../assets/img/ImagenesPradosNuevas/img1.jpg"
import img2Prados from "../../assets/img/ImagenesPradosNuevas/img2.jpg"
import img3Prados from "../../assets/img/ImagenesPradosNuevas/img3.jpg"
import img4Prados from "../../assets/img/ImagenesPradosNuevas/img4.jpg"
import img5Prados from "../../assets/img/ImagenesPradosNuevas/img5.jpg"
import img6Prados from "../../assets/img/ImagenesPradosNuevas/img6.jpg"
import img7Prados from "../../assets/img/ImagenesPradosNuevas/img7.jpg"
import img8Prados from "../../assets/img/ImagenesPradosNuevas/img8.jpg"
import img9Prados from "../../assets/img/ImagenesPradosNuevas/img9.jpg"
import img10Prados  from "../../assets/img/ImagenesPradosNuevas/img10.jpg"
import img11Prados  from "../../assets/img/ImagenesPradosNuevas/img11.jpg"
import img12Prados  from "../../assets/img/ImagenesPradosNuevas/img12.jpg"

import img1martillo from "../../assets/img/NuevasImagenesMartillo/img1martillo.jpg"
import img2martillo from "../../assets/img/NuevasImagenesMartillo/img2martillo.jpg"
import img3martillo from "../../assets/img/NuevasImagenesMartillo/img3martillo.jpg"
import img4martillo from "../../assets/img/NuevasImagenesMartillo/img4martillo.jpg"
import img5martillo from "../../assets/img/NuevasImagenesMartillo/img5martillo.jpg"
import img6martillo from "../../assets/img/NuevasImagenesMartillo/img6martillo.jpg"
import img7martillo from "../../assets/img/NuevasImagenesMartillo/img7martillo.jpg"
import img8martillo from "../../assets/img/NuevasImagenesMartillo/img8martillo.jpg"
import img9martillo from "../../assets/img/NuevasImagenesMartillo/img9martillo.jpg"
import img10martillo from "../../assets/img/NuevasImagenesMartillo/img10martillo.jpg"
import img11martillo from "../../assets/img/NuevasImagenesMartillo/img11martillo.jpg"



import videoPasaco from "../../assets/img/12PASACOIMAGEN_NUEVA/El Amate Logo.mp4"
import videoelEden from "../../assets/img/JardinDelEden/EL Eden.mp4"



//NARANJO
import ImagenCasaNaranjo from "../../assets/img/Naranjo/ImagenCasaNaranjo.jpg"
import AdoquinNaranjo from "../../assets/img/Naranjo/AdoquinNaranjo.jpg"
import AtardecerNaranjo from "../../assets/img/Naranjo/AtardecerNaranjo.jpg"
import CajasAgaNaranjo from "../../assets/img/Naranjo/CajasAguaNaranjo.jpg"
import CalleNaranjo from "../../assets/img/Naranjo/CalleNaranjo.jpg"
import Campo2Naranjo from "../../assets/img/Naranjo/Campo2Naranjo.jpg"
import CampoNaranjo from "../../assets/img/Naranjo/CampoNaranjo.jpg"
import MilpaNaranjo from "../../assets/img/Naranjo/MilpaNaranjo.jpg"
import NaranjoAtardecer from "../../assets/img/Naranjo/NaranjoAtardecer.jpg"
import NaranjoAtardecerRotonda from "../../assets/img/Naranjo/NaranjoAtardecerRotonda.jpg"
import SolNaranjo from "../../assets/img/Naranjo/SolNaranjo.jpg"



//IMAGEN PASACO

//IMAGEN VILLAS DEL ROSARIO

//IMAGEN LOMA REAL

//IMAGEN SAN JACINTO

//IMAGEN SANTA ELENA


//Imagen las Flores
import Atardecer1LasFlores from "../../assets/img/LasFlores/AtardecerFlores.jpg";
import Atardecer2LasFlores from "../../assets/img/LasFlores/AtardecerClaroFlores.jpg";
import ArbolLasFores from "../../assets/img/LasFlores/ArbolLasFlores.jpg";
import EntradaLasFlores from "../../assets/img/LasFlores/EntradaFlores.jpg";
import CaminoLasFlores from "../../assets/img/LasFlores/CaminoFlores.jpg";
import TerrenoLimpioFlores from "../../assets/img/LasFlores/TerrenoFlores.jpg";
import TerrenoMontanaLasFlores from "../../assets/img/LasFlores/TerrenoMontanaFlores.jpg";







export default {
  img: [
    //pagina de inicio
    {
      ImagenCasaNaranjo: ImagenCasaNaranjo,
      SolNaranjo: SolNaranjo,
      AdoquinNaranjo: AdoquinNaranjo,
      AtardecerNaranjo: AtardecerNaranjo,
      CajasAgaNaranjo: CajasAgaNaranjo,
      CalleNaranjo: CalleNaranjo,
      Campo2Naranjo: Campo2Naranjo,
      CampoNaranjo: CampoNaranjo,
      MilpaNaranjo: MilpaNaranjo,
      NaranjoAtardecer: NaranjoAtardecer,
      NaranjoAtardecerRotonda: NaranjoAtardecerRotonda,
      SolNaranjo: SolNaranjo,
      ladicha: ladicha,
      lasflores: lasflores,
      img1martillo: img1martillo,
      img2martillo: img2martillo,
      img3martillo: img3martillo,
      img4martillo: img4martillo,
      img5martillo: img5martillo,
      img6martillo: img6martillo,
      img7martillo: img7martillo,
      img8martillo: img8martillo,
      img9martillo: img9martillo,
      img10martillo: img10martillo,
      img11martillo: img11martillo,
      img1Prados: img1Prados,
      img2Prados: img2Prados,
      img3Prados: img3Prados,
      img4Prados: img4Prados,
      img5Prados: img5Prados,
      img6Prados: img6Prados,
      img7Prados: img7Prados,
      img8Prados: img8Prados,
      img9Prados: img9Prados,
      img10Prados: img10Prados,
      img11Prados: img11Prados,
      img12Prados: img12Prados,
      img1_paisaje: img1_paisaje,
      img2_paisaje: img2_paisaje,
      img3_lotedividido: img3_lotedividido,
      img4_lotedividido: img4_lotedividido,
      img5_lotedividido: img5_lotedividido,
      img6_arbol: img6_arbol,
      img7_arbol: img7_arbol,
      img8_flor: img8_flor,
      img9_flor: img9_flor,
      img10_florbonita: img10_florbonita,
      img11_flor: img11_flor,
      img12_florcerca: img12_florcerca,
      img13_caminito: img13_caminito,
      img14_paisajebonito: img14_paisajebonito,
      img15_florbonita: img15_florbonita,
      img16_camino: img16_camino,
      logoEmpresa: logonavBar,
      rotanda: rotanda,
      carP: sliderP,
      jocotillo: jocotillo,
      videoJocotillo: videoJocotillo,
      naranjorecortado: naranjorecortado,
      naranjo: naranjo,
      SantaElena: SantaElena,
      LogoSanJacinto: LogoSanJacinto,
      Pasaco: Pasaco,
      LomaReal: LomaReal,
      ElEden: ElEden,
      videoelEden: videoelEden,
      videoPasaco: videoPasaco,
      videoNaranjo: videoNaranjo,
      pradosdechiquimulilla: pradosdechiquimulilla,
      videoPrados: videoPrados,
      arbolitos: arbolitos,
      videoArbolitos: videoArbolitos,
      brisas: brisas,
      videoBrisas: videoBrisas,
      martillo: martillo,
      videoElMartillo: videoElMartillo,
      fincagluidiela: fincagluidiela,
      estadia: estadia,
      santaana: santaana,
      videoSantaAna: videoSantaAna,
      tecultan: tecultan,
      tikal: tikal,
      videoTikal: videoTikal,
      casas: casas,
      construccion: construccion,
      ciclovia: ciclovia,
      villasrosario: villasrosario,
      videoVillas: videoVillas,
      carP1: slider1,
      carP2: slider2,
      carP3: slider3,
      carS1: landpage1,
      carS2: landpage2,
      carS3: landpage3,
      Profile: cardt1,

      Atardecer1LasFlores: Atardecer1LasFlores,
      Atardecer2LasFlores: Atardecer2LasFlores,
      EntradaLasFlores: EntradaLasFlores,
      ArbolLasFores: ArbolLasFores,
      CaminoLasFlores: CaminoLasFlores,
      TerrenoLimpioFlores: TerrenoLimpioFlores,
      TerrenoMontanaLasFlores: TerrenoMontanaLasFlores,
      



      tituloEmpresa: "REBIERA INMOBILIARIA",
      quienes_somos: `Somos una empresa que busca el desarrollo en el ambito de lotes rusticos, buscando esencialmente
    ofrecer la calidad de vida, ofreciendoles a cada una de las familias para construir la casa que ha soñado, o generando un valor agregado en
    sus activos personales, durante muchos años hemos dado oportunidad de vivienda a muchas personas.`,
      GRID_1: modern1,
      GRID_2: modern2,
      GRID_3: modern3,
      GRID_4: rustic1,
      GRID_5: rustic2,
      GRID_6: rustic3,
      GRID_7: modern1,
      GRID_8: modern2,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      direccion1:
        "https://www.google.com/maps/@14.7152768,-92.0154187,3a,90y,259.85h,70.64t/data=!3m10!1e1!3m8!1sef_CRbyT910oOZvPzMHqBw!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3Def_CRbyT910oOZvPzMHqBw%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D271.81726%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656!9m2!1b1!2i28",
        direccioneljocotillo2:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d509419.84691085824!2d-90.10473245817788!3d14.279784094268424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDEyJzE1LjUiTiA5MMKwMDgnNTAuMyJX!5e0!3m2!1ses!2sgt!4v1684374977688!5m2!1ses!2sgt",
      
      
      
      
      
      direccionbirsas:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3871.6516505648992!2d-90.81588508516695!3d13.979328590200545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDU4JzQ1LjYiTiA5MMKwNDgnNDkuMyJX!5e0!3m2!1ses!2sgt!4v1684382809650!5m2!1ses!2sgt",
      
      
      direccionladicha:
 "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d241.5030647473472!2d-91.36772080542534!3d14.424336836940268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDI1JzI4LjQiTiA5McKwMjInMDIuMiJX!5e0!3m2!1ses!2sgt!4v1684382403001!5m2!1ses!2sgt",


 

 
 direccionarbolitos:
 "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3868.2961918367764!2d-90.09100128516472!3d14.177422290073023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDEwJzM4LjciTiA5MMKwMDUnMTkuNyJX!5e0!3m2!1ses!2sgt!4v1684382478538!5m2!1ses!2sgt",



 proyectolasflores:
 "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3870.054140664819!2d-90.4162951851659!3d14.073980290139561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDA0JzI2LjMiTiA5MMKwMjQnNTAuOCJX!5e0!3m2!1ses!2sgt!4v1684383066233!5m2!1ses!2sgt",


 direcciontikal:
"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3862.7537532186875!2d-91.36298188516096!3d14.498820689867424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDI5JzU1LjgiTiA5McKwMjEnMzguOSJX!5e0!3m2!1ses!2sgt!4v1684383206444!5m2!1ses!2sgt"

      // "logomenu" : logoMenu
    },
    // CONTENIDO DE JOCOTILLO
    {
      logoEmpresa: logonavBarJOCOTILLO,
      carP: sliderJOCOTILLOP,
      carP1: sliderJOCOTILLO1,
      carP2: sliderJOCOTILLO2,
      carP3: sliderJOCOTILLO3,
      carS1: landpageJOCOTILLO1,
      carS2: landpageJOCOTILLO2,
      carS3: landpageJOCOTILLO3,
      Profile: cardtJOCOTILLO1,
      tituloEmpresa: "JOCOTILLO",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL JOCOTILLO`,
      GRID_1: gridJOCOTILLO1,
      GRID_2: gridJOCOTILLO2,
      GRID_3: gridJOCOTILLO3,
      GRID_4: gridJOCOTILLO4,
      GRID_5: gridJOCOTILLO5,
      GRID_6: gridJOCOTILLO6,
      GRID_7: gridJOCOTILLO7,
      GRID_8: gridJOCOTILLO8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logomenu: logoMenuJocotillo,
    },
    // CONTENIDO DE TECULUTAN
    {
      logoEmpresa: logonavBarTECULUTAN,
      carP: sliderTECULUTANP,
      carP1: sliderTECULUTAN1,
      carP2: sliderTECULUTAN2,
      carP3: sliderTECULUTAN3,
      carS1: landpageTECULUTAN1,
      carS2: landpageTECULUTAN2,
      carS3: landpageTECULUTAN3,
      Profile: cardtTECULUTAN1,
      tituloEmpresa: "TECULUTAN",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL TECULUTAN`,
      GRID_1: gridTECULUTAN1,
      GRID_2: gridTECULUTAN2,
      GRID_3: gridTECULUTAN3,
      GRID_4: gridTECULUTAN4,
      GRID_5: gridTECULUTAN5,
      GRID_6: gridTECULUTAN6,
      GRID_7: gridTECULUTAN7,
      GRID_8: gridTECULUTAN8,
      direccion:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7708.014913961781!2d-89.72444164036392!3d14.992291285697124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTTCsDU5JzMyLjMiTiA4OcKwNDMnMTIuMyJX!5e0!3m2!1ses!2sgt!4v1623091415489!5m2!1ses!2sgt",
      // "logoMenu" : logoMenuTECULUTAN
    },
    // CONTENIDO DE PROYECTO TIKAL
    {
      logoEmpresa: logonavBarTIKAL,
      carP: sliderTIKALP,
      carP1: sliderTIKAL1,
      carP2: sliderTIKAL2,
      carP3: sliderTIKAL3,
      carS1: landpageTIKAL1,
      carS2: landpageTIKAL2,
      carS3: landpageTIKAL3,
      Profile: cardtTIKAL1,
      tituloEmpresa: "PROYECTO TIKAL",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL TIKAL`,
      GRID_1: gridTIKAL1,
      GRID_2: gridTIKAL2,
      GRID_3: gridTIKAL3,
      GRID_4: gridTIKAL4,
      GRID_5: gridTIKAL5,
      GRID_6: gridTIKAL6,
      GRID_7: gridTIKAL7,
      GRID_8: gridTIKAL8,
      direccion:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7725.508029102029!2d-91.3609616!3d14.4988057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTTCsDI5JzU1LjgiTiA5McKwMjEnMzguOSJX!5e0!3m2!1ses!2sgt!4v1621307137716!5m2!1ses!2sgt",

      logoMenu: logoMenuTIKAL,
    },
    // CONTENIDO DE LOTIFICADORA EL NARANJO
    {
      logoEmpresa: logonavBarNARANJO,
      carP: sliderNARANJOP,
      carP1: sliderNARANJO1,
      carP2: sliderNARANJO2,
      carP3: sliderNARANJO3,
      carS1: landpageNARANJO1,
      carS2: landpageNARANJO2,
      carS3: landpageNARANJO3,
      Profile: cardtNARANJO1,
      tituloEmpresa: "EL NARANJO",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL NARANJO`,
      GRID_1: gridNARANJO1,
      GRID_2: gridNARANJO2,
      GRID_3: gridNARANJO3,
      GRID_4: gridNARANJO4,
      GRID_5: gridNARANJO5,
      GRID_6: gridNARANJO6,
      GRID_7: gridNARANJO7,
      GRID_8: gridNARANJO8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logoMenu: logoMenuNARANJO,
    },
    // CONTENIDO DE BRISAS DEL MAR
    {
      logoEmpresa: logonavBarBRISAS,
      carP: sliderBRISASP,
      carP1: sliderBRISAS1,
      carP2: sliderBRISAS2,
      carP3: sliderBRISAS3,
      carS1: landpageBRISAS1,
      carS2: landpageBRISAS2,
      carS3: landpageBRISAS3,
      Profile: cardtBRISAS1,
      tituloEmpresa: "BRISAS DEL MAR",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL BRISAS`,
      GRID_1: gridBRISAS1,
      GRID_2: gridBRISAS2,
      GRID_3: gridBRISAS3,
      GRID_4: gridBRISAS4,
      GRID_5: gridBRISAS5,
      GRID_6: gridBRISAS6,
      GRID_7: gridBRISAS7,
      GRID_8: gridBRISAS8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logoMenu: logoMenuBRISAS,
    },
    // CONTENIDO DE VILLAS DEL ROSARIO
    {
      logoEmpresa: logonavBarVILLAS,
      carP: sliderVILLASP,
      carP1: sliderVILLAS1,
      carP2: sliderVILLAS2,
      carP3: sliderVILLAS3,
      carS1: landpageVILLAS1,
      carS2: landpageVILLAS2,
      carS3: landpageVILLAS3,
      Profile: cardtVILLAS1,
      tituloEmpresa: "VILLAS DEL ROSARIO",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL VILLAS`,
      GRID_1: gridVILLAS1,
      GRID_2: gridVILLAS2,
      GRID_3: gridVILLAS3,
      GRID_4: gridVILLAS4,
      GRID_5: gridVILLAS5,
      GRID_6: gridVILLAS6,
      GRID_7: gridVILLAS7,
      GRID_8: gridVILLAS8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logoMenu: logoMenuVILLAS,
    },
    // CONTENIDO DE LA ATLANTIDA PERDIDA
    {
      logoEmpresa: logonavBarATLANTIDA,
      carP: sliderATLANTIDAP,
      carP1: sliderATLANTIDA1,
      carP2: sliderATLANTIDA2,
      carP3: sliderATLANTIDA3,
      carS1: landpageATLANTIDA1,
      carS2: landpageATLANTIDA2,
      carS3: landpageATLANTIDA3,
      Profile: cardtATLANTIDA1,
      tituloEmpresa: "ATLANTIDA PERDIDA",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL ATLANTIDA`,
      GRID_1: gridATLANTIDA1,
      GRID_2: gridATLANTIDA2,
      GRID_3: gridATLANTIDA3,
      GRID_4: gridATLANTIDA4,
      GRID_5: gridATLANTIDA5,
      GRID_6: gridATLANTIDA6,
      GRID_7: gridATLANTIDA7,
      GRID_8: gridATLANTIDA8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logoMenu: logoMenuATLANTIDA,
    },
    // CONTENIDO DE LA SAN MARTIN
    {
      logoEmpresa: logonavBarSANMARTIN,
      carP: sliderSANMARTINP,
      carP1: sliderSANMARTIN1,
      carP2: sliderSANMARTIN2,
      carP3: sliderSANMARTIN3,
      carS1: landpageSANMARTIN1,
      carS2: landpageSANMARTIN2,
      carS3: landpageSANMARTIN3,
      Profile: cardtSANMARTIN1,
      tituloEmpresa: "SAN MARTIN",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL SANMARTIN`,
      GRID_1: gridSANMARTIN1,
      GRID_2: gridSANMARTIN2,
      GRID_3: gridSANMARTIN3,
      GRID_4: gridSANMARTIN4,
      GRID_5: gridSANMARTIN5,
      GRID_6: gridSANMARTIN6,
      GRID_7: gridSANMARTIN7,
      GRID_8: gridSANMARTIN8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logoMenu: logoMenuSANMARTIN,
    }, // CONTENIDO DE EL VALLE
    {
      logoEmpresa: logonavBarELVALLE,
      carP: sliderELVALLEP,
      carP1: sliderELVALLE1,
      carP2: sliderELVALLE2,
      carP3: sliderELVALLE3,
      carS1: landpageELVALLE1,
      carS2: landpageELVALLE2,
      carS3: landpageELVALLE3,
      Profile: cardtELVALLE1,
      tituloEmpresa: "EL VALLE",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL ELVALLE`,
      GRID_1: gridELVALLE1,
      GRID_2: gridELVALLE2,
      GRID_3: gridELVALLE3,
      GRID_4: gridELVALLE4,
      GRID_5: gridELVALLE5,
      GRID_6: gridELVALLE6,
      GRID_7: gridELVALLE7,
      GRID_8: gridELVALLE8,
      direccion:
        "https://www.google.com/maps/embed?pb=!4v1619914761750!6m8!1m7!1stLrx5QSOtSGEYijauQC00A!2m2!1d14.71513455968448!2d-92.01539145575546!3f350.20705199042163!4f0!5f0.7820865974627469",
      logoMenu: logoMenuELVALLE,
    },
    // CONTENIDO DE PORTALESLAESTANCIA
    {
      logoEmpresa: logonavBarPORTALESLAESTANCIA,
      carP: sliderPORTALESLAESTANCIAP,
      carP1: sliderPORTALESLAESTANCIA1,
      carP2: sliderPORTALESLAESTANCIA2,
      carP3: sliderPORTALESLAESTANCIA3,
      carS1: landpagePORTALESLAESTANCIA1,
      carS2: landpagePORTALESLAESTANCIA2,
      carS3: landpagePORTALESLAESTANCIA3,
      Profile: cardtPORTALESLAESTANCIA1,
      tituloEmpresa: "PORTALES LA ESTANCIA",
      quienes_somos: `INFORMACION REFERENRTE A LOTIFICADORA EL PORTALESLAESTANCIA`,
      GRID_1: gridPORTALESLAESTANCIA1,
      GRID_2: gridPORTALESLAESTANCIA2,
      GRID_3: gridPORTALESLAESTANCIA3,
      GRID_4: gridPORTALESLAESTANCIA4,
      GRID_5: gridPORTALESLAESTANCIA5,
      GRID_6: gridPORTALESLAESTANCIA6,
      GRID_7: gridPORTALESLAESTANCIA7,
      GRID_8: gridPORTALESLAESTANCIA8,
      direccion:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7707.754108489126!2d-89.7460111055011!3d14.999528571190929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTTCsDU5JzU4LjMiTiA4OcKwNDQnNDkuNCJX!5e0!3m2!1ses!2sgt!4v1623091278418!5m2!1ses!2sgt",
      logoMenu: logoMenuPORTALESLAESTANCIA,
    },
  ],
  empresas: [
    { nombre: "Inicio", index: 0, logo: logonavBar },
    { nombre: "JOCOTILLO", index: 1, logo: logoMenuJocotillo },
    { nombre: "TECULUTAN", index: 2, logo: logoMenuTECULUTAN },
    { nombre: "PROYECTO TIKAL", index: 3, logo: logoMenuTIKAL },
    { nombre: "EL NARANJO", index: 4, logo: logoMenuNARANJO },
    { nombre: "BRISAS DEL MAR", index: 5, logo: logoMenuBRISAS },
    { nombre: "VILLAS DEL ROSARIO", index: 6, logo: logoMenuVILLAS },
    { nombre: "LA ATLANTIDA PERDIDA", index: 7, logo: logoMenuJocotillo },
    { nombre: "SAN MARTIN", index: 8, logo: logoMenuSANMARTIN },
    { nombre: "EL VALE", index: 9, logo: logoMenuELVALLE },
    {
      nombre: "PORTALES LA ESTANCIA",
      index: 10,
      logo: logoMenuPORTALESLAESTANCIA,
    },
  ],
};
