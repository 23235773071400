import React, { Component, Fragment } from "react";
import { fetchPost, fetchGet } from "../../../../../src/utils/Fetch";
import imagenes from "../.././.././../components/Inicio/imagenes"
import ".././Haciendas el Naranjo/style.css";
import ReactPlayer from 'react-player'

// import animacion from ".././Haciendas el Naranjo/Naranjo Logo Animación.mp4"

// import style from "./../Hacienda Jocotillo2/style.css";

import { withRouter
  ,Redirect, Link
} from "react-router-dom";

import { Button, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"ACTIVO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"INACTIVO"}]
const initialState = {
};
class Jocotillo extends Component {
  state = {
    img:imagenes.img[0],
    empresas:imagenes.empresas,
    EMPRESA: {},
    ServiciosEmpresa: {},
  }

  CambioInicio=(item)=>async(e)=>{
      e.preventDefault();
    await  this.setState({img:imagenes.img[item.index]})
  }

  async componentDidMount() {
   /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
   const EMPRESA = await fetchGet(
     `${process.env.REACT_APP_SERVER}/api/EMPRESA/Find/${35}`
   );
   this.setState({ EMPRESA: EMPRESA.data  });
   const ServiciosEmpresa = await fetchGet(
    `${process.env.REACT_APP_SERVER}/api/EMPRESA/ServiciosFind/${35}`
  );
  this.setState({ ServiciosEmpresa: ServiciosEmpresa.data  });
 }

  render() {
   const StylerCarouselPrimario = {
  width: "100%",
  height: "370px",
        };
        const StylerCarouselSecundario = {
         width: "600px",
         height: "300px",
               };
        const imgTotalCarousel = {
         width: "280px",

               };

    return (
<>
<br></br>
<br></br>
<br></br>
<br></br>


        <div className="row" id="div_primero">

<div className="col-md-4">

</div>




<div className="img-fluid" >
<h1>{this.state.EMPRESA.NOMBRE_EMPRESA}</h1>
<h2 id="letras_contactanos">Contactanos: {this.state.EMPRESA.TELEFONO_EMPRESA }</h2>
<h2 id="letras_contactanos">Dirección: {this.state.EMPRESA.DIRECCION_EMPRESA + ', ' + 
this.state.EMPRESA.DEPARTAMENTO_EMPRESA} 
</h2>
</div>



<div className="col-md-4">

</div>

</div>

{/* ------------------------------------------------------------------------- */}
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<div className="row">
<div className="col-md-1">
  
</div>
<div className="col-md-5" >
<ReactPlayer
          url={this.state.img.videoelEden}
          className='react-player'
          // id='video_naranjo'
          playing
          loop
          width='100%'
          // height='100%'
        />
</div>

<div className="col-md-1">
  
</div>


{this.state.ServiciosEmpresa?

<div className="col-md-5" id="div_nuestros_servivios">
      <h2>Nuestros Servicios</h2>
      
<div id="div_lista_flex">
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS1? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS1: "Lotificación"} </li>
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS2? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS2: "Agua"}</li>
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS3? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS3: "Luz"}</li>
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS4? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS4: "Drenaje"}</li>
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS5? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS5: "Áreas Verdes"}</li>
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS6? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS6: "Área Deportiva"}</li>
 <li  id="nuestros_servicios_lista">{this.state.ServiciosEmpresa.NUESTROS_SERVICIOS7? this.state.ServiciosEmpresa.NUESTROS_SERVICIOS7: "Áreas Forestales"}</li>


</div>
</div>
:
<div className="col-md-5 justify-content-center align-items-center" id="div_nuestros_servivios">
      <h2>Nuestros Servicios</h2>
      
<div id="div_lista_flex">
 <li  id="nuestros_servicios_lista">Lotificación</li>
 <li  id="nuestros_servicios_lista">Agua</li>
 <li  id="nuestros_servicios_lista">Luz</li>
 <li  id="nuestros_servicios_lista">Drenaje</li>
 <li  id="nuestros_servicios_lista">Áreas Verdes</li>
 <li  id="nuestros_servicios_lista">Área Deportiva</li>
 <li  id="nuestros_servicios_lista">Áreas Forestales</li>

</div>
</div>
}

</div>
<br></br>
<br></br>
<br></br>
<br></br>
<div className="row">

<div className="col-md-5" id="mapa_naranjo" >
  <h2>Nuestra Ubicación</h2>
<br></br>
<br></br>
<br></br>

<div id="map-container-google-9" className="z-depth-1-half map-container-5" style={{height: "5%", width:"100%" }}>
               <iframe  name="UBICACION_EMPRESA" src={this.state.img.direccioneljocotillo2} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
            </div>
</div>

<div className="col-md-1">
  
</div>
<div className="col-md-5" id="div_nuestros_proyectos">
      <h2>Nuestros Proyectos</h2>
      
      <Carousel>
      
        
      <Carousel.Item>
        
 
        
        <img
          className="d-block w-100"
          id="primera_imagen_carousel"
          src={this.state.img.img2_paisaje}
          alt="Revisa tu conexion a internet"
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
  

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.state.img.img4_lotedividido}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.state.img.img5_lotedividido}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.state.img.img14_paisajebonito}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>





      <Carousel.Item>
        <img
          className="d-block w-100"
          src={this.state.img.img14_paisajebonito}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>






  
    </Carousel>
</div>

</div>

<br></br>
<br></br>
<br></br>
<br></br>





</>


    );
  }
}

export default withRouter(Jocotillo);
