import React, { Component, Fragment } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { fetchPost } from "./utils/Fetch";

import Login from "./components/Login";
import Navbar from "./components/Navbar/navbar";
import Footer from "./components/Inicio/Nuestros Proyectos/Footer/footer";
import Inicio from "./components/Inicio";
import InicioLogin from "./components/Inicio/inicio";

//seguridad
import Acceso from "./components/Seguridad/Accesos";
import Rol from "./components/Seguridad/Roles";
import Asing from "./components/Seguridad/Asing/Asing";
import Usuario from "./components/Seguridad/Usuario";
import CambioContra from "./components/Seguridad/Usuario/CambioContra";

//Navbar Negro
import NavbarNegro from "./components/Inicio/Nuestros Proyectos/NavbarNegro/index"
import FooterNegro from "./components/Inicio/Nuestros Proyectos/NFooterNegro/index"

//caja
import Caja from "./components/Caja/Caja";
import CajaApertura from "./components/Caja/CajaApertura";
import CajaDetalle from "./components/Caja/CajaDetalle";
import MovimientoCaja from "./components/Caja/MovimientoCaja";
import CajaPagosVarios from "./components/Caja/PagosVarios";

//catalogos
import Banco from "./components/Catalogos/Banco";
import Cuenta from "./components/Catalogos/Banco/Asing";
import Cargo from "./components/Catalogos/Cargo";
import Departamento from "./components/Catalogos/Departamento";
import Empresa from "./components/Catalogos/Empresa";
import Lote from "./components/Catalogos/Lote";
import Manzana from "./components/Catalogos/Manzana";
import Municipio from "./components/Catalogos/Municipio";
import Nacionalidad from "./components/Catalogos/Nacionalidad";
import PersonalAdministrativo from "./components/Catalogos/Personal Administrativo";
import Sucursal from "./components/Catalogos/Sucursal";
import Porcentaje from "./components/Catalogos/Porcentaje";
import Cliente from "./components/Catalogos/Cliente";
import Cliente2 from "./components/Catalogos/Cliente2";
import EstadoCuenta from "./components/Catalogos/Cliente/Asing";
import GenerarCuotasSimples from "./components/Catalogos/Cliente/GenerarCuotasSimples";

import PorcentajeAniosEmpresa from "./components/Catalogos/Porcentaje Anios Empresa";
import Movimiento from "./components/Catalogos/Movimiento";
import SerieDocumento from "./components/Catalogos/SerieDocumento";
import TiposContrato from "./components/Catalogos/TiposContrato";
import Proveedor from "./components/Catalogos/Proveedor";
import Gasto from "./components/Catalogos/Gasto";

//operaciones
import Contrato from "./components/Operaciones/Contrato";
import Recibo from "./components/Navbar/recibo";

//ver contratos
import VerContrato from "./components/Inicio/VerPdf";

// configuraciones
import PaginaInicio from "./components/Configuraciones/PaginaInicio";
import MovimientoEmpresa from "./components/Configuraciones/MovimientoEmpresa";
import TipoContratoEmpresa from "./components/Configuraciones/Tipos de Contrato Empresa";

//paginas nuevas inicio
import EmpresaNav from "./components/Inicio/Empresa/index";
import QuienesSomos from "./components/Inicio/Quienes Somos/index";
import ViviendaInicio from "./components/Inicio/Vivienda/index";
import ContactanosIncio from "./components/Inicio/Contactanos/index";
import SistemaFinancieroIN from "./components/Inicio/Sistema Finan/index";
import Naranjo from "./components/Inicio/Nuestros Proyectos/Haciendas el Naranjo";
import Jocotillo from "./components/Inicio/Nuestros Proyectos/Hacienda Jocotillo2";
import Chiquimulilla from "./components/Inicio/Nuestros Proyectos/Prados de Chiquimulilla/index";
import Arbolitos from "./components/Inicio/Nuestros Proyectos/Residencias los Arbolitos/index";
import Brisas from "./components/Inicio/Nuestros Proyectos/Mar";
import Martillo from "./components/Inicio/Nuestros Proyectos/Residenciales el Martillo/index";
import VillasDelRosario from "./components/Inicio/Nuestros Proyectos/Villas del Rosario/index";
import ProyectoTikal from "./components/Inicio/Nuestros Proyectos/Proyecto Tikal/index";
import ParcelamientosAnaYPacayal from "./components/Inicio/Nuestros Proyectos/Parcelamientos Santa Ana y Pacayal/index";
import LaDicha from "./components/Inicio/Nuestros Proyectos/LaDicha/index"
import Pasaco from "./components/Inicio/Nuestros Proyectos/Pasaco"
import LasFlores from "./components/Inicio/Nuestros Proyectos/LasFlores/index"
import LomaReal from "./components/Inicio/Nuestros Proyectos/LomaReal/index"
import SantaElenaPosasVerdes from "./components/Inicio/Nuestros Proyectos/SantaElenaPosasVerdes/index"
import SanJacinto from  "./components/Inicio/Nuestros Proyectos/SanJacinto/index"
import JardinDelEden from  "./components/Inicio/Nuestros Proyectos/JardínDelEdén/index"

//ver reportes
import ReportesBanco from "./components/Reportes/Banco";
import ReportesCliente from "./components/Reportes/Cliente";
import ReportesLote from "./components/Reportes/Lote";
import ReportesManzana from "./components/Reportes/Manzana";
import ReportesPersonaAdministrativo from "./components/Reportes/PersonaAdministrativo";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: false };
  }

  async componentDidMount() {
    await this.authenticateToken();
  }

  authenticateToken = async () => {
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/authenticateToken`,
      this.state
    );

    if (data) {
      await this.auth(data.data);
    } else {
      await this.auth(false);
    }
  };

  Access = (acceso) => {
    // const { auth  } = this.state;
    // // console.log('auth',auth)
    // let resultadoBusqueda;

    // if(auth){
    //   auth.map((data, index) => {
    //     // console.log(data.NOMBRE_ACCESO,data.NOMBRE_ACCESO === acceso)
    //     // return data.accesses.map((acc, index_p) => {
    //       if (data.NOMBRE_ACCESO === acceso) {

    //         resultadoBusqueda = true;
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     // });
    //   });
    // }
    // else{
    //   return false;
    // }
    // return !!resultadoBusqueda;
    return true;
  };

  auth = async (auth) => {
    await this.setState({
      auth: auth,
    });
  };

  retornar = () => {
    if (
      window.location.pathname == "/login" ||
      window.location.pathname == "/inicio"
    ) {
      return <Redirect to={window.location.pathname} />;
    } else {
      return <Redirect to="/" />;
    }
  };

  cerrarsesion = async () => {
    await localStorage.removeItem("token", "");
    await this.authenticateToken();
  };

  render() {
    const mensaje = this.state.auth ? (
      <Fragment>
        <br/>        
        <p>{`Bienvenido: ${this.state.auth[0].USUARIO_USUARIO} ${
          this.state.auth[0].NOMBRE_COMPLETO
            ? `( ${this.state.auth[0].NOMBRE_COMPLETO} )`
            : ""
        }`}</p>
        <button className="btn btn-light" onClick={this.cerrarsesion}>
          Cerrar Sesion
        </button>
      </Fragment>
    ) : (
      // `Bienvenido: Prueba de login`
      // ()=>{
      // let location = useLocation();
      // console.log(location.pathname);

      // return(
      this.retornar()
      //null
      // )
      // }
    );

    return (
      <Router basename={`${process.env.PUBLIC_URL}/`}>
        {/* {this.authenticateToken()} */}

        {this.state.auth && (
          <Navbar
            auth={this.state.auth}
            authenticateToken={this.authenticateToken}
            Access={this.Access}
          />
        )}
        <Fragment height="100hv">
          <p className="text-right">{mensaje}</p>

          <Route
            exact
            path={`/`}
            render={() => (
              <Inicio auth={this.state.auth} Access={this.Access} />
            )}
          />


          <Route
            path={`/NuestrosProyectos/`}
            render={() => (
              <NavbarNegro auth={this.state.auth} Access={this.Access} />
            )}
          />



          
          <Route
            exact
            path={`/QuienesSomos`}
            render={() => (
              <QuienesSomos auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/EmpresaIncio`}
            render={() => (
              <EmpresaNav auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/ViviendaInicio`}
            render={() => (
              <ViviendaInicio auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/ContactanosInicio`}
            render={() => (
              <ContactanosIncio auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/SistemaFinancieroIN`}
            render={() => (
              <SistemaFinancieroIN
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
          <Route
            exact
            path={`/Inicio`}
            render={() => (
              <InicioLogin auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/Naranjo`}
            render={() => (
              <Naranjo auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/Jocotillo`}
            render={() => (
              <Jocotillo auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/Pradosdechiquimulilla`}
            render={() => (
              <Chiquimulilla auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/ResidencialeslosArbolitos`}
            render={() => (
              <Arbolitos auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/BrisasdelMar`}
            render={() => (
              <Brisas auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/ResidencialesElMartillo`}
            render={() => (
              <Martillo auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/VillasDelRosario`}
            render={() => (
              <VillasDelRosario auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/ProyectoTikal`}
            render={() => (
              <ProyectoTikal auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/ParcelamientosAnaYPacayal`}
            render={() => (
              <ParcelamientosAnaYPacayal
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/LasFlores`}
            render={() => (
              <LasFlores auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/LaDicha`}
            render={() => (
              <LaDicha auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/Pasaco`}
            render={() => (
              <Pasaco
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
                    <Route
            exact
            path={`/NuestrosProyectos/LomaReal`}
            render={() => (
              <LomaReal
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
                    <Route
            exact
            path={`/NuestrosProyectos/SantaElenaPosasVerdes`}
            render={() => (
              <SantaElenaPosasVerdes
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
          <Route
            exact
            path={`/NuestrosProyectos/SanJacinto`}
            render={() => (
              <SanJacinto
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
                    <Route
            exact
            path={`/NuestrosProyectos/JardinDelEden`}
            render={() => (
              <JardinDelEden
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />



<Route
            
            path={`/NuestrosProyectos/`}
            render={() => (
              <FooterNegro
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />


          <Route
            exact
            path={`/login`}
            render={() => <Login auth={this.auth} Access={this.Access} />}
          />
          {/* seguridad */}
          <Route
            exact
            path={`/acceso`}
            render={() => (
              <Acceso auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/roles`}
            render={() => <Rol auth={this.state.auth} Access={this.Access} />}
          />
          <Route
            exact
            path={`/Asing/:id`}
            render={() => <Asing auth={this.state.auth} Access={this.Access} />}
          />
          <Route
            exact
            path={`/usuario`}
            render={() => (
              <Usuario auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/usuario/CambioContra`}
            render={() => (
              <CambioContra
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />

          {/* caja */}
          <Route
            exact
            path={`/Caja`}
            render={() => (
              <Caja
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Caja_Apertura`}
            render={() => (
              <CajaApertura
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Caja_Detalle`}
            render={() => (
              <CajaDetalle
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Movimiento_Caja`}
            render={() => (
              <MovimientoCaja
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Caja/Pagos_Varios`}
            render={() => (
              <CajaPagosVarios
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          {/* catalogos */}
          <Route
            exact
            path={`/Banco`}
            render={() => (
              <Banco
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/BANCO/CUENTA/:id`}
            render={() => (
              <Cuenta
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Cargo`}
            render={() => (
              <Cargo
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Departamento`}
            render={() => (
              <Departamento
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Empresa`}
            render={() => (
              <Empresa
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Lote`}
            render={() => (
              <Lote
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Manzana`}
            render={() => (
              <Manzana
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Municipio`}
            render={() => (
              <Municipio
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Nacionalidad`}
            render={() => (
              <Nacionalidad
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Personal_Administrativo`}
            render={() => (
              <PersonalAdministrativo
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Sucursal`}
            render={() => (
              <Sucursal
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Porcentaje`}
            render={() => (
              <Porcentaje
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Cliente`}
            render={() => (
              <Cliente
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/ClienteNuevaSeccion`}
            render={() => (
              <Cliente2
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Cliente/Estado_Cuenta_cliente/:id`}
            render={() => (
              <EstadoCuenta
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Generar_Cuotas_Simples/:id`}
            render={() => (
              <GenerarCuotasSimples
                EMPRESA={this.state.auth[0]}
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Porcentaje_Anios_Empresa`}
            render={() => (
              <PorcentajeAniosEmpresa
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Movimiento`}
            render={() => (
              <Movimiento
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Serie_Documento`}
            render={() => (
              <SerieDocumento
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/Tipo_Contrato`}
            render={() => (
              <TiposContrato
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/PROVEEDOR`}
            render={() => (
              <Proveedor
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />
          <Route
            exact
            path={`/GASTO`}
            render={() => (
              <Gasto
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />

          {/* operaciones */}
          <Route
            exact
            path={`/Contrato`}
            render={() => (
              <Contrato auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/Recibo`}
            render={() => (
              <Recibo auth={this.state.auth} Access={this.Access} />
            )}
          />

          {/* ver contrato */}
          <Route
            exact
            path={`/Inicio/VerPdf`}
            render={() => (
              <VerContrato auth={this.state.auth} Access={this.Access} />
            )}
          />

          {/* Configuraciones*/}
          <Route
            exact
            path={`/Pagina_Inicio`}
            render={() => (
              <PaginaInicio auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/Movimiento_Empresa`}
            render={() => (
              <MovimientoEmpresa auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/TIPO_CONTRATO_EMPRESA`}
            render={() => (
              <TipoContratoEmpresa
                auth={this.state.auth}
                modificar={true}
                Access={this.Access}
                auth={this.state.auth}
              />
            )}
          />

          {/* ver contrato */}
          <Route
            exact
            path={`/Reportes/Banco`}
            render={() => (
              <ReportesBanco auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/Reportes/Cliente`}
            render={() => (
              <ReportesCliente auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/Reportes/Lote`}
            render={() => (
              <ReportesLote auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/Reportes/Manzana`}
            render={() => (
              <ReportesManzana auth={this.state.auth} Access={this.Access} />
            )}
          />
          <Route
            exact
            path={`/Reportes/Persona_Administrativo`}
            render={() => (
              <ReportesPersonaAdministrativo
                auth={this.state.auth}
                Access={this.Access}
              />
            )}
          />
        </Fragment>
        {/* <Footer  auth={this.state.auth}   Access={this.Access}/> */}
      </Router>
    );
  }
}

export default App;
