import React, { Component, Fragment } from "react";
import { fetchPost, fetchGet } from "../../../../../src/utils/Fetch";
import imagenes from "../.././.././../components/Inicio/imagenes"
import ".././Haciendas el Naranjo/style.css";
import ReactPlayer from 'react-player'

// import animacion from ".././Haciendas el Naranjo/Naranjo Logo Animación.mp4"

// import style from "./../Hacienda Jocotillo2/style.css";

import { withRouter
  ,Redirect, Link
} from "react-router-dom";

import { Button, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"ACTIVO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"INACTIVO"}]
const initialState = {
};
class Footer extends Component {
  state = {
    img:imagenes.img[0],
    empresas:imagenes.empresas
  }

  CambioInicio=(item)=>async(e)=>{
      e.preventDefault();
    await  this.setState({img:imagenes.img[item.index]})
  }

  async componentDidMount() {
   /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
   const EMPRESA = await fetchGet(
     `${process.env.REACT_APP_SERVER}/api/EMPRESA/Inicio`
   );
   this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
 }

  render() {
   const StylerCarouselPrimario = {
  width: "100%",
  height: "370px",
        };
        const StylerCarouselSecundario = {
         width: "600px",
         height: "300px",
               };
        const imgTotalCarousel = {
         width: "280px",

               };

    return (
<>
<footer>
<div>
    Hola soy el footer
    <div>
        colors
    </div>
</div>

</footer>



</>


    );
  }
}

export default withRouter(Footer);
