export const Password={
     id:"outlined-password-input"
    ,type:"password"
    ,autoComplete:"current-password"
    ,variant:"outlined"  
    ,multiline:false
}
export const Number={
    id:"standard-number"
    ,type:"number"
    ,variant:"outlined"  
    ,multiline:false
}
export const Text={
    id:"outlined-basic"
    ,type:"text"
    ,variant:"outlined"  
    ,multiline:false
}
export const Multiline={
     id:"outlined-multiline-flexible"
    ,type:"text"
    ,variant:"outlined"  
    ,multiline:true
}

