import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import index from '../../index';

const initialState = {
  datosss: `<span lang=ES-GT style='font-size:10.0pt;mso-fareast-font-family:"Arial";mso-ansi-language:ES-GT;mso-fareast-language:EN-US;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'></span>`
};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (


      <div id="contratoOriginal">
        En {this.props.EMPRESA.DIRECCION_EMPRESA} municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA},
        con fecha {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}. Comparece por una parte:  <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, quien manifiesta
        ser de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS} años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL}, {this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD}, {" "}
        {this.props.EMPRESA.REPRESENTANTE_PROFESION}, con domicilio en el departamento de {this.props.EMPRESA.REPRESENTANTE_DOMICILIO_DEPARTAMENTO} y de paso por
        este departamento, se identifica con Documento Personal de Identificación con Código Único de Identificación{" "}
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el Registro Nacional de las Personas
        de la República de Guatemala, quien en lo sucesivo se denominará <b>"El promitente vendedora"</b>; y, por la otra parte comparece {" "}
        <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, de {this.props.custom.EDAD_LETRAS} {" "} años, {this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO.toLowerCase()}, {" "}
        {this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO.toLowerCase()}, {this.props.custom.PROFECION_CLIENTE_CONTARTO.toLowerCase()}, con domicilio y
        residencia en {this.props.custom.DIRECCION_CLIENTE_CONTRATO} el municipio de {this.props.custom.MUNICIPIO_CLIENTE_CONTRATO}, departamento de {this.props.custom.DEPARTAMENTO_CLIENTE_CONTRATO},{" "}
        se identifica con Documento Personal de Identificación con Código Único de Identificación{" "}
        {this.props.custom.DPI_LETRAS} ({this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el Registro Nacional de las Personas de la República de Guatemala,
        en lo sucesivo se denominará <b>"el promitente comprador"</b>.  Los comparecientes me aseguran hallarse en el libre ejercicio de sus derechos civiles, en español idioma que
        hablan y entienden me manifiestan que por el presente acto otorgan <b> PROMESA DE COMPRAVENTA DE LOTE RÚSTICO UBICADO EN {this.props.EMPRESA.NOMBRE_EMPRESA.toUpperCase()} CON
          FACILIDADES DE PAGO,</b> de conformidad con las siguientes cláusulas: <b><u>PRIMERA:</u></b> Manifiesta <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, que es legítima propietaria de la
        finca rústica inscrita en el Registro General de la Propiedad, identificada con el número de{" "}
        <b>
          finca: {this.props.custom.FINCA_LETRAS}{" "}({this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO}),{" "}
          folio: {this.props.custom.FOLIO_LETRAS} {" "}({this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO}){" "}
          del libro: {this.props.custom.LIBRO_LETRAS}{" "} ({this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO}) de{" "}
          {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}</b>, {" "}
        consistente en terreno rústico, mismo que se ubica en {this.props.EMPRESA.DIRECCION_EMPRESA} municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA},{" "}
        con las medidas y colindancias que constan en su respectiva inscripción registral.{" "}
        SEGUNDA: Continúa manifestando el
        <b>"el promitente vendedor"</b>; que promete en venta a favor del  <b>"promitente
          comprador"</b>{" "}
        {this.props.custom.LOTES_CONTRATO_VISTA.map((item, index) => {
          return (
            <Fragment>
              un lote de <b>{item.LOTE_LETRAS_METRAJE} ({item.TAMANIO_LOTE}),
                IDENTIFICADO CON EL NÚMERO{" "}
                {" "}
                {item.LOTE_LETRAS} ({item.NO_LOTE}) MANZANA “
                {item.NOMBRE_MANZANA}”{" "}
              </b>
              <b>SECTOR {item.UBICACION_MANZANA}.</b>{" "}
            </Fragment>
          );
        })}
        Puede variar la numeración en cuanto al lote, no así la ubicación.
        La presente promesa de venta se regirá mediante las
        siguientes cláusulas:{" "}
        <b><u>
          A) PRECIO DE VENTA ES DE:{" "}
          {this.props.custom.PRECIO_TOTAL_LETRAS.toUpperCase()} (Q.{" "}
          {this.props.custom.PRECIO_VENTA_CLIENTE_TOTOTAL_MONEDA}). B) CON UN
          ENGANCHE DE {this.props.custom.ENGANCHE_TOTAL_LETRAS.toUpperCase()} (
          {this.props.custom.ENGANCHE_CLIENTE_TOTOTAL_MONEDA}). C) EL SALDO DE{" "}
          {this.props.custom.SALDO_PRECIO_TOTAL_LETRAS.toUpperCase()} (Q.
          {this.props.custom.PRECIO_SALDO_VENTA_CLIENTE_TOTOTAL_MONEDA}) SE
          CANCELARÁ EN {this.props.custom.ANIOS_PAGAR_LETRAS} AÑOS ES DECIR{" "}
          {this.props.custom.CUANTAS_CUOTAS_LETRAS} CUOTAS DE:{" "}
          {this.props.custom.CUOTAS_CLIENTE_LETRAS} (Q.{" "}
          {this.props.custom.CUOTAS_CLIENTE_TOTOTAL_MONEDA}) LAS CUALES DARÁN
          INICIO EN FECHA {this.props.custom.PRIMERCUOTA_LETRAS}
        </u> </b><u>{" "}
          <b>Y SERÁN DEPOSITADOS; AL {this.props.custom.NOMBRE_BANCO_COMPLETO} (
            {this.props.custom.BANCO_CONTRATO}), AL NÚMERO DE CUENTA{" "}
            {this.props.custom.CUENTA_CONTRATO}</b> A NOMBRE DE{" "}
          <b>“{this.props.custom.NOMBRE_CUENTA_CONTRATO}”</b></u>, el número de
        cuenta puede variar según políticas de la Superintendencia De Bancos,
        se notificara por escrito si esto acontece.{" "}
        <u><b>TERCERA:</b></u>{" "}
        otras condiciones de la negociación: El precio del lote no incluye
        gastos de escrituración, ni pago del Impuesto al Valor Agregado (IVA), ni tasas o
        contribuciones municipales, en caso que el promitente comprador{' '}
        quiera dar por terminada la opción de compra del inmueble, perderá a favor de{" "}
        <b>"El promitente vendedor"</b>, el diez por ciento (10%) del valor total
        de la propiedad por concepto de arras, más los gastos administrativos
        equivalentes al catorce por ciento (14%), los cuales se harán efectivos
        de lo abonado a la fracción prometido en venta en el presente contrato,
        la devolución únicamente del <u>capital y no de intereses</u>, dicha devolución
        se realizará conforme las cuotas canceladas, si existiere un saldo a
        favor del promitente comprador, esperara el tiempo necesario que le tome
        a <b>"El promitente vendedor"</b>, vender la propiedad  para recibir el saldo restante
        que el promitente comprador pueda tener a su favor.{" "}
        <u><b>CUARTA:</b></u> Opción de compraventa: El promitente comprador se obliga
        a comprar el inmueble en el precio, plazo y condiciones pactadas y
        especificadas en este documento privado.{" "}
        <u><b>QUINTA:</b></u> El precio y forma de pago: El precio del
        inmueble es calculado en quetzales o su equivalencia en dólares de los
        Estados Unidos de América para este proyecto, utilizando el tipo de
        cambio que reporte el Banco de Guatemala, si existiere variación
        considerable, al alza del dólar, podría variar la cuota establecida en
        el presente contrato, la cual será notificada al promitente comprador.{" "}
        <u><b>SEXTA:</b></u> Cuota: El promitente comprador se obliga a pagar las
        cuotas a partir de la fecha pactada{" "}
        <b>
          <u>
            mensual y consecutivamente,{" "}
          </u> </b>
        quedando obligado a enviar fotografía de la boleta de pago al número
        de teléfono <b><u>{this.props.EMPRESA.TELEFONO_EMPRESA}</u></b>de lo contrario
        recaerá en mora, la pérdida de la boleta de pago física o electrónica
        es responsabilidad del promitente comprador, obligándose a realizar de
        nuevo el pago de la cuota mensual, el promitente comprador, declara
        que los fondos con los que honre cada una de las cuotas de la presente
        promesa serán de legítima procedencia, por lo que libera de toda
        responsabilidad a <b>"El promitente vendedor"</b>,{" "}
        de no ser cierto lo acá declarado.{" "}
        <u><b>SÉPTIMA:</b></u>{" "}
        Pagos extraordinarios: El promitente comprador podrá hacer pagos{" "}
        extraordinarios abonados a capital sin restricción alguna, dichos pagos{" "}
        reducen el plazo para la compra definitiva,{" "}
        pero no reduce el monto de cuota nivelada.{" "}
        <u><b>OCTAVA:</b></u>{" "}
        Recargos por mora: En caso de que el <b>promitente comprador</b> no
        realice el pago correspondiente el día de su vencimiento, tendrá como
        margen para cancelar su cuota{" "}
        <b>
          <u>
            <i>
              cinco días posteriores a la fecha pactada</i>
          </u></b>, de lo contrario el
        promitente comprador tendrá que cancelar un recargo por mora
        acumulativa del cinco por ciento (5%) sobre el saldo a la fecha.

        {" "}
        <u> Si el promitente comprador incumpliere con{" "}</u>
        <b>
          <u>
            dos o más cuotas atrasadas se da por terminada la presente
            negociación sin necesidad de notificación alguna,{" "}
          </u>
        </b>
        como consecuencia el promitente comprador perderá a favor de{" "}
        <b>"El promitente vendedor"</b>, el diez por ciento (10%) del valor total
        de la propiedad por concepto de arras, más los gastos administrativos
        equivalentes al catorce por ciento (14%), los cuales se harán efectivos
        de lo abonado al lote prometido en venta en el presente contrato,
        la devolución únicamente del <u>capital y no intereses</u>,
        dicha devolución se realizará de igual manera de la recepción de  las cuotas canceladas,
        si existiere un saldo a favor del promitente comprador,
        esperara el tiempo necesario que le tome a <b>"El promitente vendedor"</b>,
        vender la propiedad  para recibir el saldo restante. <u><b>NOVENA:</b></u>
        <i> Lugar de pago: </i>El promitente comprador acepta que todos los
        pagos a que se refiere el presente contrato se harán efectivos en moneda
        de curso legal,{" "}
        sin necesidad de cobro alguno, los recibos se extenderán en la
        oficina ubicada en la casa patronal de la finca en mención.{" "}
        <u><b>DÉCIMA:</b></u>{' '}
        Posesión del terreno:
        {" "}
        a) El promitente comprador podrá tomar posesión del inmueble
        al momento de la negociación, b) el promitente comprador recibe el inmueble
        para su cuidado, limpieza, y resguardo,{" "}
        c) las bases de delimitación de la propiedad se entregan al momento de
        la firma del presente contrato, siendo responsable de el resguardo
        de las misma el promitente comprador, si se dañara,{" "}
        d) <u>el promitente comprador se obliga a cancelar una cuota anual de limpieza 
            y mantenimiento de las calles principales del residencial, siendo el costo de 
            ciento cincuenta quetzales anuales por lote estándar y trescientos quetzales 
            si es un lote esquina, pudiendo ser cancelados en dos pagos el primero 
            en el mes de junio y el segundo en el mes de diciembre de cada año.</u>{" "}
        <u><b>DÉCIMA PRIMERA:</b></u>{" "}El promitente comprador acepta sin
        necesidad de notificación alguna que el promitente vendedor, pueda ceder
        el presente contrato de promesa de venta, en todo caso al momento de suscribir
        la escritura traslativa de dominio el inmueble deberá encontrarse libre
        de gravamen y anotaciones <u><b>DÉCIMA SEGUNDA:</b></u>{" "}
        Escrituración: La escritura registrada en la que se documente la
        compraventa se otorgara, estando cancelado el cien por ciento (100%) del
        terreno objeto de la opción, el promitente vendedor, designará al Bufete
        Jurídico Figueroa & Asociados, para que faccione la escritura de compraventa,
        los honorarios, impuestos fiscales, gastos de escrituración y registro,
        serán por cuenta del promitente comprador, así como cualquier otro gasto
        que se designe por este negocio, los
        cuales tendrán un costo de {this.props.EMPRESA.ESCRITURA_PRECIO_LETRAS}{" "}
        (Q.
        {this.props.EMPRESA.ESCRITURA_PRECIO_MONEDA}) por lote.{" "}
        <u><b>DÉCIMA TERCERA:</b></u> Obligación de compra de servicios: El
        promitente comprador se obliga a comprar con la desarrolladora{" "}
        {this.props.EMPRESA.RAZON_SOCIAL_EMPRESA}, los servicios básicos y
        urbanización, dicha obligación se plasmará en la escritura traslativa de
        dominio para su cumplimiento. <u><b>DÉCIMA CUARTA:</b></u> Limitaciones en el
        ejercicio del derecho: El promitente comprador acepta y se obliga a
        observar lo siguiente: a) Dar aviso a <b>"El promitente vendedor"</b>, o en
        su defecto a la desarrolladora{" "}
        {this.props.EMPRESA.RAZON_SOCIAL_EMPRESA}, antes de construir
        para que este verifique los mojones y medidas; El promitente comprador,
        tendrá que realizar los trámites de licencia de construcción ante la municipalidad del departamento,{" "}
        se obliga a otorgar una copia de la licencia de construcción a "<b>El promitente vendedor"</b>;
        {" "}b) El promitente
        comprador acepta desde ya, que, si no ha pagado la totalidad del valor
        del terreno y construye en él e incumple con sus cuotas mensuales,
        la construcción pertenece a <b>"El promitente vendedor"</b>; sin compensación alguna.{" "}
        <u><b>DÉCIMA QUINTA:</b></u>
        {" "}Incumplimiento del promitente comprador: Si el promitente comprador incumpliera
        las obligaciones que adquiere, acepta que <b>"El promitente vendedor"</b>,
        puede optar por lo siguiente: demandar ejecutivamente el saldo que hubiera,
        incluyendo intereses, multas, costos, daños y perjuicios, pudiendo embargar
        los bienes presentes y futuros del promitente comprador. <u><b>DÉCIMA SEXTA</b></u>: Aceptación: Bien enterados de su
        contenido, objeto, validez y efectos legales, lo aceptan, ratifican y
        firman.
        <pre></pre>
        <br />
        <br />
        <br />
        <br />
        <br />
        <pre>f:__________________                                     f:___________________</pre>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        <pre>
          {/*<br id="SaltoPagina"/>*/}
          En {this.props.EMPRESA.DIRECCION_EMPRESA} municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA},{" "} 
          departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA},{ } con fecha{" "}
          {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}, yo como Notario (a),
          Doy fe, que las firmas que anteceden y calzan{" "}
          <b>
            {" "}
            PROMESA DE COMPRAVENTA DE LOTE RÚSTICO UBICADO EN{" "}
            {this.props.EMPRESA.NOMBRE_EMPRESA.toUpperCase()} CON FACILIDADES DE
            PAGO son AUTENTICAS,
          </b>
          {' '}por haber sido puestas en mi presencia por:{" "}
          <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, quien se identifica con
          Documento Personal de Identificación, con Código Único de Identificación{" "}
          {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
          {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el
          Registro Nacional de las Personas de la República de Guatemala; y,{" "}
          <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, quien
          se identifica con Documento Personal de Identificación, con Código Único
          de Identificación: {this.props.custom.DPI_LETRAS} (
          {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el
          Registro Nacional de las Personas de la República de Guatemala. Previa
          lectura de la presente Acta de Legalización de firmas contenida en tres
          únicas hojas de papel bond tamaño oficio, la cual firmo y sello, los
          comparecientes vuelven a firmar. <pre></pre>
          <br />
          <br />
          <pre>f:____________________ f:____________________</pre>
          <br />
          ANTE MÍ:
        </pre>
      </div>
    );
  }
}
export default withRouter(CONTRATO);
