import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
};
class MOVIMIENTO_EMPRESA extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({       
       custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ const MOVIMIENTO =
      await fetchGet(`${process.env.REACT_APP_SERVER}/api/MOVIMIENTO/all`);
    this.setState({
      data: { ...this.state.data, MOVIMIENTO: MOVIMIENTO.data },
    });
  
  }
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
    const ID_MOVIMIENTO = await this.state.custom.MOVIMIENTO.ID_MOVIMIENTO;
    await this.setState({
      custom: { ...this.state.custom, ID_MOVIMIENTO: ID_MOVIMIENTO },
    });
    const ID_EMPRESA = await this.props.auth[0].ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    const ID_SUCURSAL = await this.props.auth[0].ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });
    
  };
  UpdateStateCreate = (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/MOVIMIENTO_EMPRESA/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/MOVIMIENTO_EMPRESA/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (
      window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL MOVIMIENTO_EMPRESA ?")
    ) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/MOVIMIENTO_EMPRESA/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
        FECHA_CREACION_MOVIMIENTO_EMPRESA: new Date(),
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const MOVIMIENTO = await this.state.data.MOVIMIENTO.find(
      (x) => x.ID_MOVIMIENTO == e.ID_MOVIMIENTO
    );
    await this.setState({
      custom: { ...this.state.custom, MOVIMIENTO: MOVIMIENTO },
    });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const ESTADO = await ACTIVO_GENERICO.find(
    //   (x) => x.NOMBRE_ACTIVO_GENERICO == e.ESTADO
    // );
    // await this.setState({ custom: { ...this.state.custom, ESTADO: ESTADO } });
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_MOVIMIENTO_EMPRESA") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR MOVIMIENTO_EMPRESA</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                type: "A",
                options: this.state.data.MOVIMIENTO,
                OptionLabel: "NOMBRE_MOVIMIENTO",
                placeholder: "SELECCIONE MOVIMIENTO",
                name: "MOVIMIENTO",
                value: this.state.custom.MOVIMIENTO,
                label: "MOVIMIENTO",
              },
            ]}
          ></FormsG>
        )}
        
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_MOVIMIENTO_EMPRESA") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="MOVIMIENTO_EMPRESA"
            url="MOVIMIENTO_EMPRESA"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_MOVIMIENTO",
                label: "NOMBRE_MOVIMIENTO",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACION_MOVIMIENTO_EMPRESA",
                label: "FECHA_CREACION_MOVIMIENTO_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_MOVIMIENTO_EMPRESA")
                  ? true
                  : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(MOVIMIENTO_EMPRESA);
