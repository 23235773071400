import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet,fetchPostFile,fetchPostFormData } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
  BackupIcon,
  DesktopWindowsIcon,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";

const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "CHEQUE" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "EFECTIVO" },
];
const initialState = {
  custom: {
PROVEEDOR:[],GASTO:[],CAJAS_ABIERTAS:[]
  },
  data: {CUENTA:[{NOMBRE_CUENTA:"SELECCIONE UN BANCO"}],
PROVEEDOR:[],GASTO:[],CAJAS_ABIERTAS:[]
},
  crear: false,
  modificar: false,
  tabla: true, file: {},
  subir: false,
};
class PAGO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    const PROVEEDOR = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/proveedor/all`
    );
    this.setState({ data: { ...this.state.data, PROVEEDOR: PROVEEDOR.data } });
   
    const CAJAS_ABIERTAS = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/caja_Apertura/CAJAS_ABIERTAS`
    );
    this.setState({ data: { ...this.state.data, CAJAS_ABIERTAS: CAJAS_ABIERTAS.data } });
  //   const CUENTAS = await fetchGet(
  //     `${process.env.REACT_APP_SERVER}/api/CUENTA/ALL`
  //   );
  //  await this.setState({ data: { ...this.state.data, CUENTAS: CUENTAS.data } });
  //   const BANCO = await fetchGet(
  //     `${process.env.REACT_APP_SERVER}/api/BANCO/all`
  //   );
  //   this.setState({ data: { ...this.state.data, BANCO: BANCO.data } });


  }
  UpdatesIds = async () => {
    const {ID_USUARIO,ID_EMPRESA,ID_SUCURSAL} = this.props.auth[0];
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO,
        ID_EMPRESA,ID_SUCURSAL
      },
    });
    const ID_PROVEEDOR = await this.state.custom.PROVEEDOR.ID_PROVEEDOR;
    await this.setState({
      custom: { ...this.state.custom, ID_PROVEEDOR: ID_PROVEEDOR },
    });
    const ID_CAJA_APERTURA  = await this.state.custom.CAJAS_ABIERTAS.ID_CAJA_APERTURA;
    await this.setState({
      custom: { ...this.state.custom, ID_CAJA_APERTURA: ID_CAJA_APERTURA },
    });
    const ID_GASTO = await this.state.custom.GASTO.ID_GASTO;
    await this.setState({
      custom: { ...this.state.custom, ID_GASTO: ID_GASTO },
    });

    

    await this.setState({
      custom: { ...this.state.custom, 
        ID_MOVIMIENTO:1,
        FECHA_CAJA_DETALLE:this.state.custom.FECHA_CREACION_CAJA_DETALLE,
        ESTADO:1,
        DESCRIPCION_CAJA_DETALLE:'PAGOS VARIOS'
      },
    }); 

    // if(this.state.custom.CHEQUE){
    //   const ID_BANCO = await this.state.custom.BANCO.ID_BANCO;
    //   await this.setState({
    //     custom: { ...this.state.custom, ID_BANCO: ID_BANCO },
    //   });
    //   const CUENTA = await this.state.custom.CUENTA;
    //   await this.setState({
    //     custom: { ...this.state.custom, ID_CUENTA: CUENTA.ID_CUENTA,NO_CUENTA:CUENTA.NO_CUENTA },
    //   });
    // }

  
  };
  UpdateStateCreate = async(e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            file: { ...this.state.file, [name]: e.target.result },
          });
        };
      await  this.setState({ custom: { ...this.state.custom, [name]: e.target.files[0] } });
      } else {
        const { name, value } = e.target;
        await   this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      await this.setState({ custom: { ...this.state.custom, [name]: value } });
      if(name=="PROVEEDOR" && value)
      {
        const GASTO = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/GASTO/Byproveedor/${value.ID_PROVEEDOR}`
        );
       await this.setState({ data: { ...this.state.data, GASTO: GASTO.data } });
      }
    } else {
      const datos = e.split("|");
      await  this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    if(this.state.custom.MONTO_CAJA_DETALLE && this.state.custom.CAJAS_ABIERTAS && this.state.custom.CAJAS_ABIERTAS.MONTO_CIERRE_CAJA_APERTURA){
          if(this.state.custom.MONTO_CAJA_DETALLE<=this.state.custom.CAJAS_ABIERTAS.MONTO_CIERRE_CAJA_APERTURA){
          await this.UpdatesIds();
          const data = await fetchPost(
            `${process.env.REACT_APP_SERVER}/api/caja_Detalle/create`,
            this.state.custom
          );
          this.setState({ mensaje: data.data });
          alert(data.message);
          this.setState({
            file: {},
            custom: {},
            crear: false,
            modificar: false,
            tabla: true,subir: false,
          });
        
        }else{
          alert("EL MONTO A PAGAR SUPERA EL TOTAL DE LA CAJA");
        }
      }else{
        alert("SELECCIONE CAJA Y MONTO");
      }
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/PAGO/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,subir: false,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL PAGO ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/PAGO/delete`,this.state.custom
      );
      alert(data.message);
   await   this.setState({ custom: {}, tabla: false ,subir: false,});
   await   this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: { ...this.state.custom, FECHA_CREACION_CAJA_DETALLE: new Date() },
    });
    await this.setState({ crear: true, modificar: false, tabla: false,subir: false, });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true ,subir: false,});
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const PROVEEDOR = await this.state.data.PROVEEDOR.find(
      (x) => x.ID_PROVEEDOR == e.ID_PROVEEDOR
    );
    await this.setState({ custom: { ...this.state.custom, PROVEEDOR: PROVEEDOR } });
    const GASTOS = await this.state.data.GASTOS.find(
      (x) => x.ID_GASTOS == e.ID_GASTOS
    );
    await this.setState({ custom: { ...this.state.custom, GASTOS: GASTOS } });
    const ESTADO = await ACTIVO_GENERICO.find(
      (x) => x.NOMBRE_ACTIVO_GENERICO== e.ESTADO
    );
    await this.setState({ custom: { ...this.state.custom, ESTADO: ESTADO } });

    this.setState({ crear: false, modificar: true, tabla: false,subir: false, });
  };
  SubirCrear = async (e) => {
    e.preventDefault();
    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/caja_detalle/AGREGAR_RUTA_DOCUMENTO_CAJA_DETALLE`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
  await  this.setState({
      file: {},
      custom: {},
      subir: false,
      crear: false,
      modificar: false,
      tabla: true,
    });

  
  };
  ShowSubir = async (e) => {
    this.setState({ custom: e });
    // const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    // this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });
    
    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
await    this.setState({ crear: false, modificar: false, tabla: false,  subir: true, });
  };
  Ver = async (e) => {
 
    await this.setState({ custom: e });
 
    const data = await fetchPostFile(
    `${process.env.REACT_APP_SERVER}/api/caja_detalle/DESCARGAR_RUTA_DOCUMENTO_CAJA_DETALLE`,this.state.custom
    );
    await this.setState({
  fileNuevo: data,
})

const href = window.URL.createObjectURL(await this.state.fileNuevo.blob());
const link = document.createElement('a');
link.href = href;
// link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.pdf`); //or any other extension
link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();

await this.setState({ custom: {} });


  };
  render() {
    const redireccion = this.props.Access("READ_PAGO") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        
        {redireccion}
        {this.state.subir && (
 <Fragment>
    <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">SUBIR FACTURA</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={BackupIcon}
                  className="m-3"
                  onClick={this.SubirCrear}
                />
              </div>
            }
            allcolumns={[
              {
                type: "I",
                name: "RUTA_DOCUMENTO_CAJA_DETALLE",
                value: this.state.file.RUTA_DOCUMENTO_CAJA_DETALLE,
                label: "SUBIR FACTURA",
              },
            ]}
          ></FormsG>
 </Fragment>
        )}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                
                <h1 className="text-center">CREAR PAGO</h1>
              </Fragment>
            }
            customFooterbar={
              <Fragment>
<div className="text-center">
              
              <ButtonG
                config={Regresar}
                className="m-3"
                onClick={this.ShowTable}
              />
              <ButtonG
                config={Agregar}
                className="m-3"
                onClick={this.Create}
              />
            </div>
              </Fragment>
          }
            allcolumns={[
              // {
              //   type: "A",
              //   options: ACTIVO_GENERICO,
              //   OptionLabel: "NOMBRE_ACTIVO_GENERICO",
              //   placeholder: "EL TIPO DE PAGO",
              //   name: "CHEQUE",
              //   value: this.state.custom.CHEQUE,
              //   label: "CHEQUE",
              // },
              
              {
                type: "A",
                options: this.state.data.CAJAS_ABIERTAS,
                OptionLabel: "NOMBRE_CAJA",
                placeholder: "SELECCIONE PROVEEDOR",
                name: "CAJAS_ABIERTAS",
                value: this.state.custom.CAJAS_ABIERTAS,
                label: "CAJAS ABIERTAS",
              },
              {
                type: "A",
                options: this.state.data.PROVEEDOR,
                OptionLabel: "NOMBRE_PROVEEDOR",
                placeholder: "SELECCIONE PROVEEDOR",
                name: "PROVEEDOR",
                value: this.state.custom.PROVEEDOR,
                label: "PROVEEDOR",
              },
              {
                type: "A",
                options: this.state.data.GASTO,
                OptionLabel: "NOMBRE_GASTO",
                placeholder: "SELECCIONE GASTO",
                name: "GASTO",
                value: this.state.custom.GASTO,
                label: "GASTO",
              },
              {
                config: Text,
                type: "G",
                name: "NO_DOCUMENTO_CAJA_DETALLE",
                value: this.state.custom.NO_DOCUMENTO_CAJA_DETALLE,
                label: "FACTURA",
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_CAJA_DETALLE",
                value: this.state.custom.MONTO_CAJA_DETALLE,
                label: "MONTO",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_CAJA_DETALLE",
                value: this.state.custom.FECHA_CREACION_CAJA_DETALLE,
                label: "FECHA",
              },
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                
                {this.props.Access("CREATE_PAGO") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}
              </Fragment>
            }
            title="PAGO"
            url="caja_Detalle"
            urlSecundary='pagos_Varios'
            responsive="vertical"
            allcolumns={[
              {
                name: "SUBIR FACTURA",
                type: "button",
                display: this.props.Access("AGREGAR_RUTA_DOCUMENTO_CAJA_DETALLE")
                  ? true
                  : "excluded",
                buttonConfig: BackupIcon,
                OnClickButton: this.ShowSubir,
              },
              {
                name: "VER FACTURA",
                type: "button",
                display: this.props.Access("VER_RUTA_DOCUMENTO_CAJA_DETALLE")
                  ? true
                  : "excluded",
                buttonConfig: DesktopWindowsIcon,
                OnClickButton: this.Ver,
              },
              {
                name: "SUBIDO",
                label: "SUBIDO",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_PROVEEDOR",
                label: "PROVEEDOR",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_GASTO",
                label: "GASTO",
                display: true,
                type: "text",
              },
              {
                name: "NO_DOCUMENTO_CAJA_DETALLE",
                label: "FACTURA",
                display: true,
                type: "text",
              },
              {
                name: "MONTO_CAJA_DETALLE",
                label: "TOTAL",
                display: true,
                type: "number",
              },
              {
                name: "FECHA_CREACION_CAJA_DETALLE",
                label: "FECHA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "SUCURSAL",
                display: true,
                type: "text",
              },
              // {
              //   name: "ELIMINAR",
              //   type: "button",
              //   display: this.props.Access("DELETE_PAGO") ? true : "excluded",
              //   buttonConfig: EliminarIcon,
              //   OnClickButton: this.Delete,
              // },
              // {
              //   name: "MODIFICAR",
              //   type: "button",
              //   display: this.props.Access("UPDATE_PAGO") ? true : "excluded",
              //   buttonConfig: ModificarIcon,
              //   OnClickButton: this.ShowUpdate,
              // },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(PAGO);
