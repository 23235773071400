import { green } from "@material-ui/core/colors";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
 import logo from "../../assets/img/LOGO.png"
 import "./navbar.css"

class navbar extends Component {
  state = {};
  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  cerrarsesion = async () => {
    await localStorage.removeItem("token", "");
    this.props.authenticateToken();
    this.props.history.push("/");
  };
  render() {

    return (
      <nav id="navbarFix" className="navbar navbar-dark navbar-expand-xl bg-Color-Extra sticky-top">
        <Link to={`/`} className="navbar-brand">
        <img id="logoNa" src={logo} width="120" height="60" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
       {/*  {this.props.auth && (
           <li className="nav-item ">
              <Link
                    className="nav-link"
                  >
                    COLEGIO
              </Link>
                </li>)}
                <li className="nav-item ">
                  <Link
                    to={`/login`}
                    className="nav-link"
                  >
                    LOGIN
                  </Link>
                </li>*/}
          {this.props.auth && (
            <Fragment>
            <Fragment>
{/* {this.props.auth[0].ID_USUARIO!=40 && ( */}
  <Fragment>
              <li className="nav-item dropdown">
                <div className="dropdown">
                  {/* <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Lotificadora
                  </button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                    to={`/login`}
                    className="dropdown-item"
                  >
                    LOGIN
                  </Link>
                    {this.props.Access("READ_ACCESO") && ( <Link className="dropdown-item" >Quienes somos</Link>)}
                    {this.props.Access("READ_ROLES") && ( <Link  className="dropdown-item" >Contactenos</Link>)}  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <div className="dropdown">
                  {/* <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    SEGURIDAD
                  </button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {this.props.Access("READ_ACCESO") && ( <Link to={`/ACCESO`} className="dropdown-item" >Acceso</Link>)}
                    {this.props.Access("READ_ROLES") && ( <Link to={`/ROLES`} className="dropdown-item" >Roles</Link>)}
                    {this.props.Access("READ_USUARIO") && ( <Link to={`/USUARIO`} className="dropdown-item" >Usuario</Link>)}
                    {this.props.Access("UPDATE_CAMBIO_CONTRASENIA") && ( <Link to={`Usuario/CambioContra`} className="dropdown-item" >Cambiar contraseña</Link>)}
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <div className="dropdown">
                  {/* <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButtonCaja"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                  CAJA
                  </button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {this.props.Access("READ_CAJA") && (<Link to={`/CAJA`} className="dropdown-item" >Caja</Link>)}
                    {this.props.Access("READ_CAJA_APERTURA") && (<Link to={`/CAJA_APERTURA`} className="dropdown-item" >Caja apertura</Link>)}
                    {this.props.Access("READ_CAJA_PAGOS_VARIOS") && (<Link to={`/Caja/Pagos_Varios`} className="dropdown-item" >pagos varios</Link>)}
                    {/* {this.props.Access("READ_CAJA_DETALLE") && (<Link to={`/CAJA_DETALLE`} className="dropdown-item" >CAJA_DETALLE</Link>)}
                    {this.props.Access("READ_MOVIMIENTO_CAJA") && (<Link to={`/MOVIMIENTO_CAJA`} className="dropdown-item" >MOVIMIENTO_CAJA</Link>)} */}

                  </div>
                </div>
              </li>
              </Fragment>
{/* )} */}
              <li className="nav-item dropdown">
                <div className="dropdown">
                  <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    CONTRATO
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {this.props.Access("READ_CONTRATO") && ( <Link to={`/CONTRATO`} className="dropdown-item" >Contrato</Link>)}
                    {/* {this.props.Access("READ_CONTRATO") && ( <Link to={`/Recibo`} className="dropdown-item" >Recibo</Link>)} */}
                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <div className="dropdown">
                   {/* <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                 CATALOGOS
                  </button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* {this.props.auth[0].ID_USUARIO!=40 && ( 
                     <Fragment>*/}
                  {this.props.Access("READ_EMPRESA")  && ( <Link to={`/Empresa`} className="dropdown-item" >Empresa</Link>)}
                  {this.props.Access("READ_SUCURSAL") && ( <Link to={`/Sucursal`} className="dropdown-item" >Sucursal</Link>)}
                  {this.props.Access("READ_BANCO") && ( <Link to={`/Banco`} className="dropdown-item" >Banco</Link>)}
                  {this.props.Access("READ_DEPARTAMENTO") && ( <Link to={`/Departamento`} className="dropdown-item" >Departamento</Link>)}
                  {this.props.Access("READ_MUNICIPIO") && ( <Link to={`/Municipio`} className="dropdown-item" >Municipio</Link>)}
                  {this.props.Access("READ_NACIONALIDAD") && ( <Link to={`/Nacionalidad`} className="dropdown-item" >Nacionalidad</Link>)}
                  {this.props.Access("READ_CARGO") && ( <Link to={`/Cargo`} className="dropdown-item" >Cargo</Link>)}
                  {this.props.Access("READ_PERSONALADMINISTRATIVO") && ( <Link to={`/Personal_Administrativo`} className="dropdown-item" >Personal Administrativo</Link>)}
                  {this.props.Access("READ_PORCENTAJE_ANIOS_EMPRESA") && ( <Link to={`/Porcentaje_Anios_Empresa`} className="dropdown-item" >Porcentaje de años por empresa</Link>)}
                  {this.props.Access("READ_MANZANA") && ( <Link to={`/Manzana`} className="dropdown-item" >Manzana</Link>)}
                  {this.props.Access("READ_LOTE") && ( <Link to={`/Lote`} className="dropdown-item" >Lote</Link>)}
                  {this.props.Access("READ_TIPO_CONTRATO") && ( <Link to={`/TIPO_CONTRATO`} className="dropdown-item" >Tipo contrato</Link>)}
                  {/* </Fragment>
                  )} */}
                  {this.props.Access("READ_CLIENTE") && ( <Link to={`/CLIENTE`} className="dropdown-item" >Cliente</Link>)}
                  {/* {this.props.auth[0].ID_USUARIO!=40 && ( */}
                     {/* <Fragment> */}
                  {this.props.Access("READ_PROVEEDOR") && ( <Link to={`/PROVEEDOR`} className="dropdown-item" >Proveedor</Link>)}
                  {this.props.Access("READ_GASTO") && ( <Link to={`/GASTO`} className="dropdown-item" >Gasto</Link>)}
                    {/* {this.props.Access("READ_SERIE_DOCUMENTO") && ( <Link to={`/SERIE_DOCUMENTO`} className="dropdown-item" >SERIE_DOCUMENTO</Link>)} */}
                    {/* {this.props.Access("READ_MOVIMIENTO") && ( <Link to={`/MOVIMIENTO`} className="dropdown-item" >MOVIMIENTO</Link>)} */}
                  {/*   </Fragment>
                  )} */}
                  </div>
                </div>
              </li>


            



              {/* {this.props.auth[0].ID_USUARIO!=40 && ( */}
  <Fragment>
              <li className="nav-item dropdown">
                <div className="dropdown">
                  {/* <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButtonCaja"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    CONFIGURACION
                  </button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {this.props.Access("READ_CONFGURACION_EMPRESA_PAGINA_INICIO_LOTIFICADORA") && (<Link to={`/Pagina_Inicio`} className="dropdown-item" >Pagina inicio</Link>)}
                    {/* {this.props.Access("READ_MOVIMIENTO_EMPRESA") && (<Link to={`/MOVIMIENTO_EMPRESA`} className="dropdown-item" >MOVIMIENTO_EMPRESA</Link>)} */}
                    {this.props.Access("READ_TIPO_CONTRATO_EMPRESA") && ( <Link to={`/TIPO_CONTRATO_EMPRESA`} className="dropdown-item" >Tipo contrato empresa</Link>)}

                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <div className="dropdown">
                   <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButtonReportes"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                  REPORTES
                  </button> 
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {/* {this.props.Access("REPORT_BANCO") && (<Link to={`/reportes/Banco`} className="dropdown-item" >Banco</Link>)} */}
                    {/* {this.props.Access("REPORT_CLIENTE") && (<Link to={`/reportes/Cliente`} className="dropdown-item" >Cliente</Link>)} */}
                    {this.props.Access("REPORT_LOTE") && (<Link to={`/reportes/Lote`} className="dropdown-item" >Lote</Link>)}
                    {/* {this.props.Access("REPORT_MANZANA") && (<Link to={`/reportes/Manzana`} className="dropdown-item" >Manzana</Link>)} */}
                    {/* {this.props.Access("REPORT_PERSONA_ADMINISTRATIVO") && (<Link to={`/reportes/Persona_Administrativo`} className="dropdown-item" >Personal Administrativo</Link>)} */}

                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <div className="dropdown">
                   <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                 CLIENTES
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* {this.props.auth[0].ID_USUARIO!=40 && ( 
                     <Fragment>*/}
                  
                  {/* </Fragment>
                  )} */}
                  {this.props.Access("READ_CLIENTE") && ( <Link to={`/ClienteNuevaSeccion`} className="dropdown-item" >Cliente</Link>)}
                  </div>
                </div>
              </li>

              </Fragment>
{/* )} */}
            </Fragment>
            {/*  <li className="nav-item dropdown">
                <div className="dropdown">
                  <button
                    class="btn nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    ASIGNACIONES
                  </button>
               <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                 {this.props.Access("READ_ASIGNACION_CICLO_ESCOLAR") && ( <Link to={`/ASIGNACION_CICLO_ESCOLAR`} className="dropdown-item" >Asignación ciclo escolar</Link>)}
                     {this.props.Access("READ_ASIGNACION_CURSO") && ( <Link to={`/ASIGNACION_CURSO`} className="dropdown-item" >ASIGNACION_CURSO</Link>)}
              //comentar esto despues {this.props.Access("READ_ASIGNACION_PAGOS_ASIGNACION_CICLO_ESCOLAR") && ( <Link to={`/ASIGNACION_PAGOS_ASIGNACION_CICLO_ESCOLAR`} className="dropdown-item" >ASIGNACION_PAGOS_ASIGNACION_CICLO_ESCOLAR</Link>)}
                   </div>
                </div>
                  </li>*/}


</Fragment>
          )}
</ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(navbar);
