import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { fetchPost,fetchGet, fetchPostFile } from "../../utils/Fetch";
import Select from "react-select";
 import logo from "../../assets/img/REBIERA_INMOBILIARIA.jpg"
 import makeAnimated from "react-select/animated";
// import Error from '../Alertas/Error';
import doc from '../../../src/assets/img/Naranjo/naranjo1.jpg'

const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"ACTIVO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"INACTIVO"}]
const initialState = {
  user: "",
  password: "",
  data: null,
};

class Login extends Component {
  state = {
    ...initialState,
  };
    actualizarState = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };
  async componentDidMount() {
   
  }

  limpiarState = () => {
    this.setState({ ...initialState });
  };
  descarga=async(e)=>{
    e.preventDefault();

    const data = await fetchPostFile(
      `${process.env.REACT_APP_SERVER}/api/contrato/DowloaderDocumento`,this.state
    );
await this.setState({
  fileNuevo: data,
})

const href = window.URL.createObjectURL(await this.state.fileNuevo.blob());
const link = document.createElement('a');
link.href = href;
// link.setAttribute('download', 'documento.pdf'); //or any other extension
link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();


  }
  iniciarSesion = async (e) => {
    e.preventDefault();
  

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/auth`,
      this.state
    );
    if (data.data) {
      localStorage.setItem("token", data.token);
      this.props.auth(data.data);
      
       alert(`Datos de Acceso Correctos, Bienvenido ${data.data[0].USUARIO_USUARIO}`);
      setTimeout(() => {
        this.props.history.push("/inicio");
      }, 500);
    } else {
      alert("Datos de Acceso Incorrectos");
    }
  };

 
  validarForm = () => {
    const { user, password } = this.state;

    const noValido = !user || !password;

    return noValido;
  };
  render() {
    const { user, password } = this.state;

    return (
      <Fragment>

        <h1 className="text-center">Iniciar Sesión</h1>
        <div className="d-flex justify-content-center">
<img src={logo} className="logo-inicio" />
</div>
        <div className="row  justify-content-center">
          <form onSubmit={(e) => this.iniciarSesion(e)} className="col-md-8">
            <div className="form-group">
              <label>Usuario</label>
              <input
                onChange={this.actualizarState}
                value={user}
                type="text"
                name="user"
                className="form-control"
                placeholder="Nombre Usuario"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                onChange={this.actualizarState}
                value={password}
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
              />
            </div>
     
            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Iniciar Sesión
            </button>
          </form>
          {/* <button onClick={this.descarga}>descarga</button>
          <a href={`http://localhost:4000/api/contrato/DowloaderDocumento`}>des</a>

          <object data={doc} width="400" height="300"></object>*/}
        </div> 
      </Fragment>
    );
  }
}

export default withRouter(Login);
