import React, { Component, Fragment } from "react";
import { withRouter} from "react-router-dom";


const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"ACTIVO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"INACTIVO"}]
const initialState = {
};

class Inicio extends Component {
  state = {
    ...initialState
  }

  render() {
    return (

       <>


      <div></div>


      </>
    );
  }
}

export default withRouter(Inicio);
