import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
  CloseIcon,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "CERRADA" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "ABIERTA" },
];
const initialState = {
  custom: {},
  data: {CAJA:[],BANCO:[],CUENTA:[]},
  crear: false,
  modificar: false,
  tabla: true,
};
class CAJA_APERTURA extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ 
    const CAJA =
      await fetchGet(`${process.env.REACT_APP_SERVER}/api/CAJA/Apertura`);
    this.setState({ data: { ...this.state.data, CAJA: CAJA.data } });

    const BANCO =
    await fetchGet(`${process.env.REACT_APP_SERVER}/api/banco/all`);
  this.setState({ data: { ...this.state.data, BANCO: BANCO.data } });

  }
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
    const ID_CAJA = await this.state.custom.CAJA.ID_CAJA;
    await this.setState({ custom: { ...this.state.custom, ID_CAJA: ID_CAJA } });

    const ID_BANCO = await this.state.custom.BANCO.ID_BANCO;
    await this.setState({ custom: { ...this.state.custom, ID_BANCO: ID_BANCO } });
   
    const ID_CUENTA = await this.state.custom.CUENTA.ID_CUENTA;
    await this.setState({ custom: { ...this.state.custom, ID_CUENTA: ID_CUENTA } });
   
    const ID_EMPRESA = await this.props.auth[0].ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    const ID_SUCURSAL = await this.props.auth[0].ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });
  };
  UpdateStateCreate = async(e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        await   this.setState({ custom: { ...this.state.custom, [name]: value } });
        if(name=="MONTO_RETIRO_CAJA_APERTURA")
        {
          if(value)
          {
            await this.setState({
              custom:{
                ...this.state.custom,MONTO_APERTURA_CAJA_APERTURA:parseFloat(parseFloat(value)+parseFloat(this.state.custom.MONTO_ANTERIOR_CAJA_APERTURA)).toFixed(2)
              }
            })
          }
          else{
            await this.setState({
              custom:{
                ...this.state.custom,MONTO_APERTURA_CAJA_APERTURA:this.state.custom.MONTO_ANTERIOR_CAJA_APERTURA
              }
            })
          }
        }

      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      await this.setState({ custom: { ...this.state.custom, [name]: value } });
      if(name=="BANCO" && value)
      {
            if(value){
              const CUENTA = await fetchGet(
                `${process.env.REACT_APP_SERVER}/api/CUENTA/FindByBanco/${value.ID_BANCO}`
              );
             await this.setState({ data: { ...this.state.data, CUENTA: CUENTA.data } });
            }
            else{
              await this.setState({ custom: { ...this.state.custom, BANCO: [],CUENTA:[] },
                data: { ...this.state.data, CUENTA:[] }
               });
            }
      }
      if(name=="CAJA")
      {
      if(value){
       await this.setState({ custom: { ...this.state.custom, MONTO_ANTERIOR_CAJA_APERTURA: value.MONTO_ANTERIOR_CAJA_APERTURA } });
      }
      else{
       await this.setState({ custom: { ...this.state.custom, MONTO_ANTERIOR_CAJA_APERTURA: 0 } });
      }
      }
    } else {
      const datos = e.split("|");
      await this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    await this.setState({
      custom: {
        ...this.state.custom,
        MONTO_CIERRE_CAJA_APERTURA:this.state.custom.MONTO_APERTURA_CAJA_APERTURA
      },
    });

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CAJA_APERTURA/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });

    const CAJA =
    await fetchGet(`${process.env.REACT_APP_SERVER}/api/CAJA/Apertura`);
  this.setState({ data: { ...this.state.data, CAJA: CAJA.data } });
  
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CAJA_APERTURA/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Cerrar = async (e) => {
    if(e.CERRADA_CAJA_APERTURA!=="CERRADA"){
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA CERRAR LA CAJA?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/CAJA_APERTURA/CERRAR`,
        this.state.custom
      );
      alert(data.message);
     
      const CAJA =
      await fetchGet(`${process.env.REACT_APP_SERVER}/api/CAJA/Apertura`);
      await this.setState({ data: { ...this.state.data, CAJA: CAJA.data } });

      await  this.setState({ custom: {}, tabla: false });
      await  this.setState({ custom: {}, tabla: true });
    }
  }else{
    alert("LA CAJA YA A SIDO CERRARA VERIFICAR ESTADO")
  }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
        FECHA_CREACION_CAJA_APERTURA: new Date(),
        FECHA_CIERRE_CAJA_APERTURA:null,
        CERRADA_CAJA_APERTURA: ACTIVO_GENERICO[1].ID_ACTIVO_GENERICO,
        MONTO_CIERRE_CAJA_APERTURA:0
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const CAJA = await this.state.data.CAJA.find((x) => x.ID_CAJA == e.ID_CAJA);
    await this.setState({ custom: { ...this.state.custom, CAJA: CAJA } });
    // const ESTADO = await ACTIVO_GENERICO.find(
    //   (x) => x.NOMBRE_ACTIVO_GENERICO == e.ESTADO
    // );
    // await this.setState({ custom: { ...this.state.custom, ESTADO: ESTADO } });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const CERRADA_CAJA_APERTURA = await ACTIVO_GENERICO.find(
    //   (x) => x.NOMBRE_ACTIVO_GENERICO == e.CERRADA_CAJA_APERTURA
    // );
    // await this.setState({
    //   custom: {
    //     ...this.state.custom,
    //     CERRADA_CAJA_APERTURA: CERRADA_CAJA_APERTURA,
    //   },
    // });
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_CAJA_APERTURA") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR CAJA_APERTURA</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                type: "A",
                options: this.state.data.CAJA,
                OptionLabel: "NOMBRE_CAJA",
                placeholder: "SELECCIONE CAJA",
                name: "CAJA",
                value: this.state.custom.CAJA,
                label: "CAJA",
              },
              {
                type: "A",
                options: this.state.data.BANCO,
                OptionLabel: "NOMBRE_BANCO",
                placeholder: "SELECCIONE BANCO",
                name: "BANCO",
                value: this.state.custom.BANCO,
                label: "BANCO",
              },
              {
                type: "A",
                options: this.state.data.CUENTA,
                OptionLabel: "NOMBRE_CUENTA",
                placeholder: "SELECCIONE CUENTA",
                name: "CUENTA",
                value: this.state.custom.CUENTA,
                label: "CUENTA",
              },
              {
                config: Text,
                type: "G",
                name: "NO_BOLETA_CAJA_APERTURA",
                value: this.state.custom.NO_BOLETA_CAJA_APERTURA,
                label: "NO_BOLETA",
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_ANTERIOR_CAJA_APERTURA",
                value: this.state.custom.MONTO_ANTERIOR_CAJA_APERTURA,
                label: "MONTO_ANTERIOR_CAJA_APERTURA",
                readOnly:true,
                shrink:true
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_RETIRO_CAJA_APERTURA",
                value: this.state.custom.MONTO_RETIRO_CAJA_APERTURA,
                label: "MONTO_RETIRO_CAJA_APERTURA",
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_APERTURA_CAJA_APERTURA",
                value: this.state.custom.MONTO_APERTURA_CAJA_APERTURA,
                label: "MONTO_APERTURA_CAJA_APERTURA",
                readOnly:true,
                shrink:true
              },
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
          configBit={ACTIVO_GENERICO}
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_CAJA_APERTURA") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="CAJA_APERTURA"
            url="CAJA_APERTURA"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_CAJA",
                label: "NOMBRE_CAJA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACION_CAJA_APERTURA",
                label: "FECHA_CREACION_CAJA_APERTURA",
                display: true,
                type: "text",
              },
              {
                name: "CERRADA_CAJA_APERTURA",
                label: "CERRADA_CAJA_APERTURA",
                display: true,
                type: "bit",
              },
              {
                name: "MONTO_ANTERIOR_CAJA_APERTURA",
                label: "MONTO_ANTERIOR_CAJA_APERTURA",
                display: true,
                type: "number",
              },
              {
                name: "MONTO_RETIRO_CAJA_APERTURA",
                label: "MONTO_RETIRO_CAJA_APERTURA",
                display: true,
                type: "number",
              },
              {
                name: "MONTO_APERTURA_CAJA_APERTURA",
                label: "MONTO_APERTURA_CAJA_APERTURA",
                display: true,
                type: "number",
              },
              {
                name: "MONTO_CIERRE_CAJA_APERTURA",
                label: "MONTO_CIERRE_CAJA_APERTURA",
                display: true,
                type: "number",
              },
              {
                name: "CERRAR",
                type: "button",
                display: this.props.Access("CERRAR_CAJA_APERTURA") 
                  ? true
                  : "excluded",
                buttonConfig: CloseIcon,
                OnClickButton: this.Cerrar,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(CAJA_APERTURA);
