import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import  TextFieldG from '../Input/TextField/TextField';
import DateG from '../Input/Date/Date';
import AutocompleteG from '../Input/Autocomplete/Autocomplete';
import ImgG from '../Input/File/Img';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

     {/* outlined-disabled */}
      {/* id="outlined-read-only-input" */}
      {/* <TextField id="standard-search" label="Search field" type="search" /> */}


export default function FormsG({allcolumns,updateState,autoComplete="off",customFooterbar,customToolbar}) {
  const classes = useStyles();

  return (
    <form  className={classes.root+" m-2  justify-content-center"} 
     noValidate autoComplete={autoComplete} >
{customToolbar}
    <div className="d-flex flex-wrap justify-content-center">

    {
      
      allcolumns &&  allcolumns.map((item)=>{
const {config,name,value,label,helperText="",readOnly=false,disabled=false,required=true,type="G",subType="D",options=[{}],OptionLabel,multiple=false,placeholder="",shrink=false,show=false}=item;

 switch(type) {
  case "G":
   return( <TextFieldG  config={config} onChange={updateState} name={name} value={value} label={label} helperText={helperText}  readOnly={readOnly} disabled={disabled} required={required}shrink={shrink} />)
    break;
    case "I":
   return( <ImgG      onChange={updateState} name={name} value={value}  label={label} helperText={helperText}  readOnly={readOnly} disabled={disabled} required={required} />)
    break;
  case "A":
    return(
      <AutocompleteG                   onChange={updateState}  name={name}  value={value}  label={label}                                            disabled={disabled} 
                                       options={options} OptionLabel={OptionLabel}   multiple={multiple}  placeholder={placeholder} ></AutocompleteG>
    )
    break;
    case "D":
      return( <DateG                    onChange={updateState}  name={name} value={value} label={label} helperText={helperText} readOnly={readOnly} 
                      type={subType}  show={show}  ></DateG>)
    break;
  default:
    return( <TextFieldG  config={config} onChange={updateState} name={name} value={value} label={label} helperText={helperText} readOnly={readOnly} disabled={disabled} required={required} />)
}

        return("")
      })
    }
    
</div>
{customFooterbar}

    </form>


  );
}
