import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import index from "../../index";

const initialState = {
  datosss: `<span lang=ES-GT style='font-size:10.0pt;mso-fareast-font-family:"Arial";mso-ansi-language:ES-GT;mso-fareast-language:EN-US;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'></span>`,
};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (
      <div id="contratoOriginal">
        En el kilómetro noventa y tres carretera al Salvador, 
        el amatillo zona cero ruta a Jalpatagua municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, Departamento
        de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha{" "}
        {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}. Comparece por una parte:{" "}
        <b>
          {" "} {this.props.EMPRESA.REPRESENTANTE_LEGAL}
        </b>
        , de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS}{" "}
        años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL},{" "}
        {this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD},{" "}
        {this.props.EMPRESA.REPRESENTANTE_PROFESION}, con domicilio en el
        departamento de{" "}
        {this.props.EMPRESA.REPRESENTANTE_DOMICILIO_DEPARTAMENTO} y de paso por
        este departamento, se identifica con Documento Personal de
        Identificación con Código Único de Identificación{" "}
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
        {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala, {/* quien
        actúa en calidad de Gerente Administrativo y Representante Legal de la
        entidad denominada <b>SKH, SOCIEDAD ANÓNIMA,</b> calidad que acredita con el
        acta notarial en la que consta su nombramiento como tal, autorizada en
        la ciudad de Guatemala, el ocho de junio del año dos mil veintidós, por
        el Notario, Eduardo Vides Abularach, documento inscrito en el Registro
        Mercantil al número de registro seiscientos sesenta y tres mil
        cuatrocientos treinta (663,430), folio: trescientos cuarenta y tres
        (343) del libro: ochocientos tres (803) de Auxiliares de Comercio. A */}
        quien 
        en lo sucesivo se denominará <b>"El Obligado"</b>; y, por la otra parte comparece:{" "}
        <b>
          {this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}
        </b>
        , de {this.props.custom.EDAD_LETRAS} años,{" "}
        {this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO.toLowerCase()},{" "}
        {this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO.toLowerCase()},{" "}
        {this.props.custom.PROFECION_CLIENTE_CONTARTO.toLowerCase()}, con
        domicilio y residencia en {this.props.custom.DIRECCION_CLIENTE_CONTRATO}{" "}
        municipio de {this.props.custom.MUNICIPIO_CLIENTE_CONTRATO},
        departamento de {this.props.custom.DEPARTAMENTO_CLIENTE_CONTRATO}, se
        identifica con Documento Personal de Identificación, con Código Único de
        Identificación {this.props.custom.DPI_LETRAS} (
        {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala, en lo
        sucesivo se denominará <b>"El Obligante"</b>. Los
        comparecientes me aseguran hallarse en el libre ejercicio de sus
        derechos civiles, en español idioma que hablan y entienden 
        manifiestan que por el presente acto otorgan{" "}
        <b><u>CONTRATO PRIVADO DE OBLIGACIÓN DE ESCRITURAR FRACCIÓN DE BIEN INMBUELE RUSTICO,</u>,</b> de conformidad con
        las siguientes cláusulas: <b><u>PRIMERA:</u></b> Declara: Manifiesta <b>"El Obligado"</b>, 
        es ligítimo propietario de la finca denomidad "PROYECTO TIKAL" inscrita en el Segundo Registro 
        de la Propiedad identifica con el número 
        de finca: <b>{this.props.custom.FINCA_LETRAS}{" "} ({this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO}),
            folio: {this.props.custom.FOLIO_LETRAS} {" "} ({this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO})
            del libro: {this.props.custom.LIBRO_LETRAS}{" "}
            ({this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO}) del
            departamento de  {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}</b>,
          consistente en terreno rústico, mismo que se ubica en {this.props.EMPRESA.DIRECCION_EMPRESA},
          municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, departamento de{" "}
          {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con las medidas y colindancias que constan en su respectiva inscripción registral.
          {" "}
         <b><u>SEGUNDA:</u> OBJETO DEL CONTRATO:</b> Continúa manifestando{" "}
        <b>"El Obligado"</b>, que por este acto, <b>a)</b>{" "}
        {this.props.custom.LOTES_CONTRATO_VISTA.map((item, index) => {
          return (
            <Fragment>
            vende un lote identificado como número{" "}
              <b>
                {" "}
                {item.LOTE_LETRAS.toUpperCase()} ({item.NO_LOTE}) MANZANA “
                {item.NOMBRE_MANZANA}”{" "}
             
              b) POR EL PRECIO DE VENTA DE:{" "}
              {this.props.custom.PRECIO_TOTAL_LETRAS.toUpperCase()} (Q.{" "}
              {this.props.custom.PRECIO_VENTA_CLIENTE_TOTOTAL_MONEDA}),
            c)
              </b>
            </Fragment>
          );
        })}
        y se obliga a escriturar a favor de {" "}
        <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, el lote
        identificado anteriormente. <b>d)</b> Manifiesta el obligado, que <b>ACEPTA</b> {" "}
        el pago que tiene recibidos al contado y a su entera satisfacción en este momento.{" "}
        <b><u>TERCERA:</u> OBLIGACIÓN DE ESCRITURAR:</b> Manifiesta el <b>"El Obligado"</b>,
        que se compromete a escriturar el lote de terreno antes mencionado en el{" "}
        <b>PLAZO DE UN AÑO</b> a partir del presente documento privado.
        a) otras condiciones de la negociación: El precio del lote no incluye gastos de escrituración,
        ni pago del Impuesto al Valor Agregado (IVA), ni tasas o contribuciones municipales,
        en caso que <b>" El Obligante"</b> quiera dar por terminada la opción de compra del inmueble,
        perderá a favor de <b>"El Obligado"</b>; el diez por ciento (10%) del valor total de la
         propiedad por concepto de arras, más los gastos administrativos equivalentes al 
         catorce por ciento (14%), los cuales se harán efectivos de lo abonado al lote prometido
          en venta en el presente contrato, la devolución únicamente del <u>capital 
          y no  intereses</u>, dicha devolución se realizará de igual manera de la recepción de 
          las cuotas canceladas, si existiere un saldo a favor del Obligante, 
          esperara el tiempo necesario que le tome a <b>"El Obligado"</b>; vender la propiedad  
          para recibir el saldo restante,  b) la escritura en la que se documente la 
          compraventa se entregará debidamente registrada y/o inscrita en el Segundo Registro 
          De La Propiedad de la República de Guatemala, <b>"el obligado"</b>, designa a 
          Bufete Jurídico Figueroa & Asociados exclusivamente, para que faccione la escritura 
          de compraventa; los honorarios, impuestos, gastos de escrituración y registro, 
          serán por cuenta del obligante, así como cualquier otro gasto que se designe por 
          este negocio, 
        los cuales tendrán un costo de {this.props.EMPRESA.ESCRITURA_PRECIO_LETRAS} (Q.{this.props.EMPRESA.ESCRITURA_PRECIO_MONEDA}) por lote. {" "} 
        <b><u>CUARTA:</u></b> Posesión del terreno: 
        a) El Obligante podrá tomar posesión del inmueble al momento de la negociación, 
        b) El Obligante recibe el inmueble para su cuidado, limpieza, y resguardo, 
         tiene la obligación de limpiar su propiedad en caso contrario se cobrar ochenta quetzales trimestrales,  
        c) las bases de delimitación de la propiedad 
        se entregan al momento de la firma del presente contrato, siendo responsable 
        de el resguardo de las misma, si se dañara, d) El Obligante se obliga a cancelar 
        una cuota anual de limpieza y mantenimiento de las calles principales del 
         residencial, siendo el costo de  {/* ciento cincuenta quetzales anuales por lote 
        estándar y trescientos quetzales si es un lote esquina,*/} trescientos quetzales anuales por lote, 
        pudiendo ser cancelados en dos pagos el primero en el mes de junio y el segundo en el mes de diciembre de cada año.{" "}          
        <b><u>QUINTA:</u></b>{" "}<u>OBLIGACIÓN:</u> El Obligante, 
        se obliga a comprar con la desarrolladora REBIERA INMOBILIARIA, los servicios 
        básicos y urbanización, dicha obligación se plasmará en la escritura traslativa de dominio para su cumplimiento. 
        <b><u>SEXTA:</u></b> Los comparecientes manifiestan su responsabilidad en cuanto a 
        las obligaciones adquiridas y renunciamos al fuero de nuestros domicilios 
        pudiendo ser demandados ante cualquier tribunal de la República de Guatemala 
        en caso de incumplimiento. {" "}
        <b><u>SEPTIMA:</u></b>{" "}
        Los comparecientes manifiestan su plena conformidad y <b>ACEPTAN</b> el presente 
        contrato privado, quienes bien enterados de su contenido, objeto, validez y 
        efectos legales, lo aceptan, ratifican y firman, no así:{" "} 
        {this.props.NOMBRE_COMPLETO_CLIENTE}, por no saber firmar únicamente imprime su huella 
        dactilar del pulgar de la mano derecha, 
        haciéndolo a su ruego como testigo capaz civilmente e idóneo:{" "} 
        {this.props.custom.TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO.toUpperCase()}, quien se identifica con Documento Personal de
        Identificación -DPI- con Código Único de Identificación -CUI- {" "}
        {this.props.custom.DPI_TESTIGO_LETRAS} ({this.props.custom.DPI_SEPARADO_TESTIGO_CONTRATO}) extendido por el Registro Nacional de las
        Personas de la República de Guatemala.
        <pre></pre>
        <br />
        <br />
        <br />
        <pre>f:__________________ f:___________________</pre>
        <br />
        <br />
        {/*<br id="SaltoPagina"/>*/}
        <pre> 
        En el kilómetro noventa y tres, carretera al Salvador El Amatillo zona 
        cero ruta a Jalpatagua municipio de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, 
        con fecha{" "}
        {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}, yo{" "} 
        como Notario (a), DOY FE, que las firmas que anteceden y 
        <b>CONTRATO PRIVADO DE OBLIGACIÓN DE ESCRITURAR FRACCIÓN DE BIEN INMUEBLE RUSTICO, 
        son AUTENTICAS,</b> por haber sido puestas en mi presencia 
        por: <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, quien se identifica con 
        Documento Personal de Identificación, con Código Único de Identificación: 
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
        {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}){" "}
        extendido por el Registro Nacional de las Personas de la República de Guatemala; 
        y, <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, quien se identifica con Documento Personal de Identificación, 
        con Código Único de Identificación: {this.props.custom.DPI_LETRAS} (
        {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}){" "}
        extendido por el Registro Nacional de las Personas de la República de Guatemala. 
        Previa lectura de la presente Acta de Legalización de firmas contenida en dos únicas 
        hojas de papel bond tamaño oficio, la cual firmo y sello, los comparecientes vuelven a firmar, no así:{" "}
        {this.props.custom.NOMBRE_COMPLETO_CLIENTE}, por no saber firmar únicamente imprime su huella dactilar del pulgar de la mano derecha, 
        haciéndolo a su ruego como testigo idóneo, capaz civilmente: 
        {this.props.custom.TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO}, quien se identifica con Documento Personal de Identificación -DPI- con Código Único de Identificación -CUI- 
        {this.props.custom.DPI_TESTIGO_LETRAS} ({this.props.custom.DPI_SEPARADO_TESTIGO_CONTRATO}){" "} 
        extendido por el Registro Nacional de las Personas de la República de Guatemala.
        <pre></pre></pre>
        <br />
        <br />
        <pre>f:____________________ f:____________________</pre>
        <br />
        <pre><i>ANTE MÍ:</i></pre>
        
      </div>
    );
  }
}
export default withRouter(CONTRATO);