import React from "react";
import 'date-fns';
import {MuiPickersUtilsProvider,DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker,KeyboardDateTimePicker  } from "@material-ui/pickers";
function types(name,label,value,onChange,type,readOnly,helperText,show=false,placeholder){
  if(show){
    switch (type) {
      case "D":
        return( <DatePicker
        margin="normal"
            id="date-picker-dialog"
           label={label}
           value={value}
           onChange={(e)=>onChange(name+"|"+e.toString())}
          autoOk
           inputVariant="outlined"
          onError={console.log}
          format="dd/MM/yyyy"
          helperText={helperText}
          disabled={readOnly}
          readOnly={readOnly}
        />)
        break;
    case "DT":
    return(<DateTimePicker
         required={false}
           label={label}
           value={value}
           onChange={(e)=>onChange(name+"|"+e.toString())}
           disabled={!readOnly}
           readOnly={!readOnly}
          autoOk
           inputVariant="outlined"
          onError={console.log}
      format="dd/MM/yyyy HH:mm"
      helperText={helperText}
  
      // value={selectedDate}
      // onChange={handleDateChange}
    />)
    break;
    case "T":
    return(  <TimePicker 
       id="time"
           label={label}
           value={value}
           onChange={(e)=>onChange(name+"|"+e.toString())}
          autoOk
          disabled={readOnly}
          readOnly={readOnly}
           inputVariant="outlined"
          onError={console.log}
          helperText={helperText}
  
     //  value={selectedDate} 
     //  onChange={handleDateChange}
       />)
    break;
      
    }
  }
  else{
    switch (type) {
      case "D":
        return( 
         <KeyboardDatePicker
          margin="normal"
          // clearable
          label={label}
          value={value}
          placeholder={placeholder}
          onChange={(e)=>onChange(name+"|"+e.toString())}
          // minDate={new Date()}
          readOnly={readOnly}
          disabled={readOnly}
           inputVariant="outlined"
          onError={console.log}
          format="dd/MM/yyyy"
          helperText={helperText}
        />
      
        )
        break;
    case "DT":
    return(  
      <KeyboardDateTimePicker
      variant="outlined"
      margin="normal"
      ampm={false}
      label={label}
        value={value}
      disablePast
      onChange={(e)=>onChange(name+"|"+e.toString())}
      // minDate={new Date()}
      readOnly={!readOnly}
      disabled={!readOnly}
       inputVariant="outlined"
      onError={console.log}
      format="dd/MM/yyyy  HH:mm"
      helperText={helperText}
    />
  //   <KeyboardDateTimePicker
  //   variant="outlined"
  //   margin="normal"
  //   ampm={false}
  //   label={label}
  //   value={value}
  //   onError={console.log}
  //   disablePast
  //   format="yyyy/MM/dd HH:mm"
  // />
    )
    break;
    case "T":
    return(  <TimePicker 
       id="time"
           label={label}
           value={value}
           onChange={(e)=>onChange(name+"|"+e.toString())}
          autoOk
          readOnly={readOnly}
           inputVariant="outlined"
          onError={console.log}
          helperText={helperText}
          disabled={readOnly}
          readOnly={readOnly}
  
     //  value={selectedDate} 
     //  onChange={handleDateChange}
       />)
    break;
      
    }
  }
}

export default function DateG({name,label,value,onChange,type="D",readOnly=false,helperText=""}) {
    return (
<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
{types(name,label,value,onChange,type,readOnly,helperText)}
      </MuiPickersUtilsProvider>
    )
}
