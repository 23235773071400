import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
/*CONTRATOS NORMALES */
import ContratoConsolidadoNormal from "./contratoConsolidado/contratoNormal";
import ContratoBrisasDelMarNormal from "./010-BrisasdelMar/contrato";
import ContratoJocotilloNormal from "./008-Jocotillo/contrato";
import ContratoVillasDelRosarioNormal from "./012-VillasDelRosario/contrato";
import ContratoPruebas from "./ContratoPruebas/contrato";

/*CONTRATOS TESTIGOS */
import ContratoConsolidadoTestigo from "./contratoConsolidado/ContratoTestigo";
import ContratoBrisasDelMartestigo from "./010-BrisasdelMar/ContratoTestigo";
import ContratoJocotilloTestigo from "./008-Jocotillo/ContratoTestigo";
import ContratoVillasDelRosarioTestigo from "./012-VillasDelRosario/ContratoTestigo";
import ObligacionTestigoVillasDelRosario from "./012-VillasDelRosario/ObligacionConTestigo"

/*CONTRATOS ESCRITURACION */
import ContratoConsolidadoEscritura from "./contratoConsolidado/Escrituracion";
import ContratoBrisasDelMarEscritura from "./010-BrisasdelMar/Escrituracion";
import ContratoJocotilloEscritura from "./008-Jocotillo/Escrituracion";
import ObligacionTestigoBrisas from "./010-BrisasdelMar/ObligacionConTestigo"
import ContratoVillasDelRosarioEscritura from "./012-VillasDelRosario/Escrituracion";

/*CONTRATOS ESCRITURACION El Martillo*/
import ContratoElMartilloNormal from "./011-ElMartillo/contrato";
import ContratoElMartilloTestigo from "./011-ElMartillo/ContratoTestigo";
import ContratoElMartilloEscrituracion from "./011-ElMartillo/Escrituracion";
import ObligacionTestigoMartillo from "./011-ElMartillo/ObligacionConTestigo"

/*CONTRATOS ESCRITURACION */
import ContratosArbolitosNormal from "./006-Arbolitos/contrato";
import ContratosArbolitosTestigo from "./006-Arbolitos/ContratoTestigo";
import ContratosArbolitosEscrituracion from "./006-Arbolitos/Escrituracion";
import ArbolitosObligacionTestigo from "./006-Arbolitos/contratoObligacionAEscriturarConTestigoARuego"


/*CONTRATOS ESCRITURACION */
import ContratospacayalNormal from "./002-SantaAnaYPacayal/contrato";
import ContratospacayalTestigo from "./002-SantaAnaYPacayal/ContratoTestigo";
import ContratospacayalEscrituracion from "./002-SantaAnaYPacayal/Escrituracion";


/*CONTRATOS PASACO */
import ContratosPasacoNormal from "./001-Pasaco/contratonNormal";
import ContratoNormalConTestigoARuego from "./001-Pasaco/ContratoNormalConTestigoARuego";
import ContatroObligacionAEscriturarConTestigoARuego from "./001-Pasaco/contratoObligacionAEscriturarConTestigoARuego";
import ContratoObligacionEscriturar from "./001-Pasaco/contratoObligacionEscriturar";

/*CONTRATOS lAS FLORES */
import ContratosLaFloresNormal from "./003-LasFlores/contratoNormal";
import ContratoNormalConTestigoARuegoFlores from "./003-LasFlores/ContratoNormalConTestigoARuego";
import ContatoObligacionConTestigo from "./003-LasFlores/ContratoObligacionTestigo";
import ContratoObligacionAEscrituraLasFlroes from "./003-LasFlores/contratoObligacionEscriturar"
// Contrato de prueba testigo


//Tikal 
import TikalContratoNormal from "./005-Tikal/contratonNormal"
import TikalContratoNormalConTestigo from './005-Tikal/ContratoNormalConTestigoARuego'
import TikalContratoObligacion from './005-Tikal/contratoObligacionEscriturar'
import TikalObligacionConTestigo from './005-Tikal/contratoObligacionAEscriturarConTestigoARuego'

/*CONTRATOS lA DICHA */
import ContratosLaDichaNormal from "./004-LaDicha/contratoNormal";
import ContratoNormalConTestigoARuegoLaDicha from "./004-LaDicha/ContratoNormalConTestigoARuego";
import ContatroObligacionAEscriturarConTestigoARuegoLaDicha from "./004-LaDicha/contratoObligacionAEscriturarConTestigoARuego";
import ContratoObligacionEscriturarLaDicha from "./004-LaDicha/contratoObligacionEscriturar";



// Contratos Obligacion Con Testigo a Ruego
import ContratoObligacionTestigoJocotilo from './008-Jocotillo/ObligacionConTestigo'



// Contratos Loma Real
import ContratosLomaRealNormal from "./013-LomaReal/contratonNormal";
import ContratoNormalConTestigoARuegoLomaReal from "./013-LomaReal/ContratoNormalConTestigoARuego";
import ContatroObligacionAEscriturarConTestigoARuegoLomaReal from "./013-LomaReal/contratoObligacionAEscriturarConTestigoARuego";
import ContratoObligacionEscriturarLomaReal from "./013-LomaReal/contratoObligacionEscriturar";



// Contratos HACIENDASSANJACINTO
import ContratosHaciendaSanJacintoNormal from "./014-HaciendasSanJacinto/contratonNormal";
import ContratoNormalConTestigoARuegoHaciendaSanJacinto from "./014-HaciendasSanJacinto/ContratoNormalConTestigoARuego";
import ContatroObligacionAEscriturarConTestigoARuegoHaciendaSanJacinto from "./014-HaciendasSanJacinto/contratoObligacionAEscriturarConTestigoARuego";
import ContratoObligacionEscriturarHaciendaSanJacinto from "./014-HaciendasSanJacinto/contratoObligacionEscriturar";




// Contratos FINCASSANTAELENA 
import ContratoNormalHaciendaFINCASSANTAELENA  from "./015-SantaElena/contratonNormal";
import ContratoNormalConTestigoARuegoFINCASSANTAELENA  from "./015-SantaElena/ContratoNormalConTestigoARuego";
import ContatroObligacionAEscriturarConTestigoARuegoFINCASSANTAELENA  from "./015-SantaElena/contratoObligacionAEscriturarConTestigoARuego";
import ContratoObligacionEscriturarFINCASSANTAELENA from "./015-SantaElena/contratoObligacionEscriturar";



/*CONTRATOS PRADOS */
import ContratosPradosNormal from "./007-Prados/contrato";
import ContratosPradosTestigo from "./007-Prados/ContratoTestigo";
import ContratosPradosEscrituracion from "./007-Prados/ContratoObligacionRueego2";
import ContratosPradosObligacionEscriturar from "./007-Prados/contratoObligacionEscriturar";
// import ContratosPradosPromesaManzana from "./007-Prados/contratoPromesaManzana";
// import ContratosPradosPromesaParcelas from "./007-Prados/contratoPromesaParcelas";
// import ContratosPradosLoteUrbanizado from "./007-Prados/contratoLoteUrbanizado";
/** custom={this.state.custom} logoEmpresa={this.state.empresas.logoEmpresa}
 *  TIPO_DE_CONTRATO
 *                  1 NORMAL
 *                  2 TESTIGO
 *                  3 ESCRITURACION
 *
 * ID_EMPRESA:
 *  EMPRESA
 *                  1 lotificadora el naranjo
 *                  2 jocotillo * <-- ContratoJocotillo --
 *                  3 xela
 *                  4 Proyecto tikal * <--ContratoJocotillo
 *                  5 haciendas el naranjo * <-- ContratoVillasDelRosario
 *                  6 hacienda el jocotillo 2 * <-- ContratoJocotillo
 *                  7  no existe
 *                  8 brisas del mar * <-- brisas
 *                  9 villas del rosario * <-- ContratoVillasDelRosario
 *                  10 santa polonia
 *                  11 nueva atlantida
 *                  12 pruebas
 *                  13 san martin
 *                  14 el valle
 *                  15 portales la estancia
 *                  16 lomas de teculutan
 *  *               17 Santa Ana
 *                  18 arbolitos
 *                  19 El Martillo
 */
//
const initialState = {};
class ContratosPorTipo extends Component {
  state = {
    ...initialState,
    CONTENIDO: "<h1>CARGANDO CLAUSULAS</h1>",
    Contratos: [
      {
        ID_EMPRESA: 1,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 2,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 3,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 4,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <TikalContratoNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <TikalContratoNormalConTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3249023523",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <TikalContratoObligacion
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <TikalObligacionConTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 5,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 6,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                  SECTOR: "SECTOR Cedros.",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3753006479",
                  TELEFONO: "3791-4760",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                  SECTOR: "SECTOR Cedros.",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3537004180",
                  TELEFONO: "3791-4760",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                  SECTOR: "SECTOR Cedros.",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3537004180",
                  TELEFONO: "3791-4760",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContratoObligacionTestigoJocotilo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                  SECTOR: "SECTOR Cedros.",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3537004180",
                  TELEFONO: "3791-4760",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 8,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoBrisasDelMarNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoBrisasDelMartestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoBrisasDelMarEscritura
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ObligacionTestigoBrisas
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                  SECTOR: "SECTOR Cedros.",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3537004180",
                  TELEFONO: "3791-4760",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 9,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoVillasDelRosarioNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoVillasDelRosarioTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoVillasDelRosarioEscritura
                //  PROYECTO={{DEPARTAMENTOALTERNO:"", ESCRITURACION:"tres mil quetzales (Q3,000.00)"}}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ObligacionTestigoVillasDelRosario
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                  SECTOR: "SECTOR Cedros.",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3537004180",
                  TELEFONO: "3791-4760",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },    
        ],
      },
      {
        ID_EMPRESA: 10,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 11,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 12,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  DEPARTAMENTOALTERNO: "",
                  ESCRITURACION: "tres mil quetzales (Q3,000.00)",
                }}
                BANCO={{
                  NOMBRE: "BANRURAL",
                  NOMBRECOMPLETO: "Desarrollo Rural, Sociedad Anónima",
                  CUENTA: "3068044150",
                  TELEFONO: "5534-8109",
                  BUFFETE: "Figueroa & Asociados",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 13,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 14,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 15,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },

      {
        ID_EMPRESA: 16,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoJocotilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoJocotilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoJocotilloEscritura
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 17,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratospacayalNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratospacayalTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratospacayalEscrituracion
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 31,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosLomaRealNormal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoNormalConTestigoARuegoLomaReal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoObligacionEscriturarLomaReal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContatroObligacionAEscriturarConTestigoARuegoLomaReal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
        ],
      },
      {
        ID_EMPRESA: 18,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosArbolitosNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratosArbolitosTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratosArbolitosEscrituracion
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ArbolitosObligacionTestigo
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          }
        ],
      },

      {
        ID_EMPRESA: 19,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoElMartilloNormal
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-4026067-0 ",
                  TELEFONO: "5415-2123",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoElMartilloTestigo
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoElMartilloEscrituracion
                PROYECTO={{
                  NOMBRE: "BRISAS DEL MAR",
                  DIRECCION:
                    "UBICADO EN ALDEA OBERO, MUNICIPIO DE MASAGUA  DEPARTAMENTO DE ESCUINTLA",
                }}
                BANCO={{ NOMBRE: "MUNHDO", CUENTA: "0050050266 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
           {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ObligacionTestigoMartillo
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          }
        ],
      },
      {
        ID_EMPRESA: 20,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosPradosNormal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratosPradosTestigo
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratosPradosObligacionEscriturar
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
           {
             ID_TIPO_CONTRATO: 9,
             CONTENIDO: (
               <ContratosPradosEscrituracion
                 PROYECTO={{
                   NOMBRE: "PRADOS DE CHIQUIMULILLA",
                   DIRECCION:
                     "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                 }}
                 BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                 custom={this.props.custom}
                 logoEmpresa={this.props.logoEmpresa}
                 EMPRESA={this.props.EMPRESA}
                 TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
               />
             ),
           }
          // {
          //   ID_TIPO_CONTRATO: 7,
          //   CONTENIDO: (
          //     <ContratosPradosPromesaParcelas
          //       PROYECTO={{
          //         NOMBRE: "PRADOS DE CHIQUIMULILLA",
          //         DIRECCION:
          //           "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
          //       }}
          //       BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
          //       custom={this.props.custom}
          //       logoEmpresa={this.props.logoEmpresa}
          //       EMPRESA={this.props.EMPRESA}
          //       TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
          //     />
          //   ),
          // },

          // {
          //   ID_TIPO_CONTRATO: 8,
          //   CONTENIDO: (
          //     <ContratosPradosLoteUrbanizado
          //       PROYECTO={{
          //         NOMBRE: "PRADOS DE CHIQUIMULILLA",
          //         DIRECCION:
          //           "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
          //       }}
          //       BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
          //       custom={this.props.custom}
          //       logoEmpresa={this.props.logoEmpresa}
          //       EMPRESA={this.props.EMPRESA}
          //       TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
          //     />
          //   ),
          // },
        ],
      },





      {
        ID_EMPRESA: 23,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosPasacoNormal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoNormalConTestigoARuego
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoObligacionEscriturar
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContatroObligacionAEscriturarConTestigoARuego
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
         

         
        ],
      },









      {
        ID_EMPRESA: 33,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosHaciendaSanJacintoNormal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoNormalConTestigoARuegoHaciendaSanJacinto
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoObligacionEscriturarHaciendaSanJacinto
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContatroObligacionAEscriturarConTestigoARuegoHaciendaSanJacinto
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
         

         
        ],
      },








      {
        ID_EMPRESA: 34,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratoNormalHaciendaFINCASSANTAELENA
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoNormalConTestigoARuegoFINCASSANTAELENA
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoObligacionEscriturarFINCASSANTAELENA
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContatroObligacionAEscriturarConTestigoARuegoFINCASSANTAELENA
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
         

         
        ],
      },






      {
        ID_EMPRESA: 28,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosLaFloresNormal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoNormalConTestigoARuegoFlores
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoObligacionAEscrituraLasFlroes
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContatoObligacionConTestigo
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },         
        ],
      },


      {
        ID_EMPRESA: 22,
        TIPO_DE_CONTRATO: [
          {
            ID_TIPO_CONTRATO: 1,
            CONTENIDO: (
              <ContratosLaDichaNormal
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{
                  NOMBRE: "BAM",
                  NOMBRECOMPLETO:
                    "Banco Agromercantil de Guatemala, Sociedad Anónima",
                  CUENTA: "30-2015046-7 ",
                  TELEFONO: "3295-7998",
                }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 2,
            CONTENIDO: (
              <ContratoNormalConTestigoARuegoLaDicha
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 3,
            CONTENIDO: (
              <ContratoObligacionEscriturarLaDicha        
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
          {
            ID_TIPO_CONTRATO: 9,
            CONTENIDO: (
              <ContatroObligacionAEscriturarConTestigoARuegoLaDicha
                PROYECTO={{
                  NOMBRE: "PRADOS DE CHIQUIMULILLA",
                  DIRECCION:
                    "UBICADO EN MUNICIPIO DE CHIQUIMULILLA  DEPARTAMENTO DE SANTA ROSA",
                }}
                BANCO={{ NOMBRE: "BAM", CUENTA: "30-2015046-7 " }}
                custom={this.props.custom}
                logoEmpresa={this.props.logoEmpresa}
                EMPRESA={this.props.EMPRESA}
                TIPO_DE_CONTRATO={this.props.TIPO_DE_CONTRATO}
              />
            ),
          },
         
        ],
      },


    ],
  };

  async componentDidMount() {
    await this.CargarContrato();
  }
  CargarContrato = async () => {
    try {
      let empresaSeleccion = await this.state.Contratos.find(
        (x) => x.ID_EMPRESA == this.props.EMPRESA.ID_EMPRESA
      );
      if (empresaSeleccion && empresaSeleccion != null) {
        let contratoSeleccion = await empresaSeleccion.TIPO_DE_CONTRATO.find(
          (x) =>
            x.ID_TIPO_CONTRATO == this.props.TIPO_DE_CONTRATO.ID_TIPO_CONTRATO
        );
        if (contratoSeleccion && contratoSeleccion != null) {
          this.setState({ CONTENIDO: contratoSeleccion.CONTENIDO });
        } else {
          this.setState({
            CONTENIDO:
              "<h1>NO EXISTEN LA CONFIGURACIÒN PARA ESTA TIPO DE CONTRATO</h1>",
          });
        }
      } else {
        this.setState({
          CONTENIDO: "<h1>NO EXISTEN LA CONFIGURACIÒN PARA ESTA EMPRESA</h1>",
        });
      }
    } catch (error) {
      await this.setState({ error });

      this.setState({
        CONTENIDO:
          "<h1>NO EXISTEN LAS CLAUSULAS GUARDADAS PARA ESTE TIPO DE CONTRATO</h1>",
      });
    }
  };

  render() {
    return <Fragment>{this.state.CONTENIDO}</Fragment>;
  }
}
export default withRouter(ContratosPorTipo);



