import React from 'react'

const SistemaFinancieroIN = () => {
  return (
    <div>
        <h1>Sistema Financiaro</h1>
    </div>
  )
}

export default SistemaFinancieroIN