import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
import { ca, el } from "date-fns/locale";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "VENDIDO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "NO VENDIDO" },
];
const initialState = {
  custom: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
};
class LOTE extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/all`
    );
    this.setState({ data: { ...this.state.data, MANZANA: MANZANA.data } });
    const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
    const SUCURSAL = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/SUCURSAL/all`
    );
    this.setState({ data: { ...this.state.data, SUCURSAL: SUCURSAL.data } });
    /*const PORCENTAJE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/PORCENTAJE/all`
    );
    this.setState({
      data: { ...this.state.data, PORCENTAJE: PORCENTAJE.data },
    });*/
  }
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
    const ID_MANZANA = await this.state.custom.MANZANA.ID_MANZANA;
    await this.setState({
      custom: { ...this.state.custom, ID_MANZANA: ID_MANZANA },
    });
    const ID_EMPRESA = await this.state.custom.EMPRESA.ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    const VENDIDOS_LOTE = this.state.custom.VENDIDOS_LOTE.ID_ACTIVO_GENERICO;
    await this.setState({
      custom: { ...this.state.custom, VENDIDOS_LOTE: VENDIDOS_LOTE },
    });
    const ID_SUCURSAL = await this.state.custom.SUCURSAL.ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });/*
      const ID_PORCENTAJE = await this.state.custom.PORCENTAJEID.ID_PORCENTAJE;
    await this.setState({
      custom: { ...this.state.custom, ID_PORCENTAJE: ID_PORCENTAJE },
    });*/

  };
  UpdateStateCreate = async(e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        await this.setState({ custom: { ...this.state.custom, [name]: value } });
        if(name=="ENGANCHE_LOTE")
        {


           if(value){
          if(this.state.custom && this.state.custom.PRECIO_LOTE_MAS_INSTERES)
            {
              const engancheLote=this.state.custom.PRECIO_LOTE_MAS_INSTERES*0.10;

              if(value<engancheLote)
                {
                  await this.setState({custom: { ...this.state.custom,
                    ENGANCHE_LOTE:engancheLote.toFixed(2) }
                  });
                }
            }
            else{
              await this.setState({custom: { ...this.state.custom,
                ENGANCHE_LOTE:(0).toFixed(2) }
              });
            }
          }else{
            await this.setState({custom: { ...this.state.custom,
              ENGANCHE_LOTE:(0).toFixed(2) }
            });
          }
          }



        if(name=="PRECIO_LOTE")
      {


         if(value){
        if(this.state.custom && this.state.custom.PORCENTAJE)
          {
            await this.setState({custom: { ...this.state.custom,
              PRECIO_LOTE_MAS_INSTERES:((value*this.state.custom.PORCENTAJE)+parseFloat(value)).toFixed(2) }
            });

                const engancheLote=this.state.custom.PRECIO_LOTE_MAS_INSTERES*0.10;
                await this.setState({custom: { ...this.state.custom,
                  ENGANCHE_LOTE:engancheLote.toFixed(2) }
                });


          }
          else{
            await this.setState({custom: { ...this.state.custom,
              PRECIO_LOTE_MAS_INSTERES:(0).toFixed(2) }
            });
          }
        }else{
          await this.setState({custom: { ...this.state.custom,
            PRECIO_LOTE_MAS_INSTERES:(0).toFixed(2) }
          });
        }
        }


      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      await  this.setState({ custom: { ...this.state.custom, [name]: value } });

      if(name=="PORCENTAJEID" && value)
      {


       await this.setState({custom: { ...this.state.custom, PORCENTAJE:value.VALOR_PORCENTAJE } });
          if(value){
         if(this.state.custom && this.state.custom.PRECIO_LOTE)
           {
             await this.setState({custom: { ...this.state.custom,
               PRECIO_LOTE_MAS_INSTERES:((this.state.custom.PORCENTAJE*this.state.custom.PRECIO_LOTE)+parseFloat(this.state.custom.PRECIO_LOTE)).toFixed(2) }
             });
             const engancheLote=this.state.custom.PRECIO_LOTE_MAS_INSTERES*0.10;
             await this.setState({custom: { ...this.state.custom,
               ENGANCHE_LOTE:engancheLote.toFixed(2) }
             });
            }
           else{
             await this.setState({custom: { ...this.state.custom,
               PRECIO_LOTE_MAS_INSTERES:(0).toFixed(2) }
             });
           }
         }else{
           await this.setState({custom: { ...this.state.custom,
             PRECIO_LOTE_MAS_INSTERES:(0).toFixed(2) }
           });
         }
      }


    } else {
      const datos = e.split("|");
      await  this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };

  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/LOTE/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/LOTE/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL LOTE ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/LOTE/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        VENDIDOS_LOTE: ACTIVO_GENERICO[0],
        FECHA_CREACION_LOTE: new Date(),
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
        PORCENTAJEID:null,
        ID_PORCENTAJE:null,
        PORCENTAJE:null,
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const MANZANA = await this.state.data.MANZANA.find(
      (x) => x.ID_MANZANA == e.ID_MANZANA
    );
    await this.setState({ custom: { ...this.state.custom, MANZANA: MANZANA } });
    const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });

    const EMPRESA = await this.state.data.EMPRESA.find(
      (x) => x.ID_EMPRESA == e.ID_EMPRESA
    );
    await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    const VENDIDOS_LOTE = await ACTIVO_GENERICO.find(
      (x) => x.NOMBRE_ACTIVO_GENERICO == e.VENDIDOS_LOTE
    );
    await this.setState({
      custom: { ...this.state.custom, VENDIDOS_LOTE: VENDIDOS_LOTE },
    });
    const SUCURSAL = await this.state.data.SUCURSAL.find(
      (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    );
    await this.setState({
      custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    });
    /*
    const PORCENTAJE = await this.state.data.PORCENTAJE.find(
      (x) => x.ID_PORCENTAJE == e.ID_PORCENTAJE
    );
    await this.setState({
      custom: { ...this.state.custom, PORCENTAJEID: PORCENTAJE },
    });*/
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_LOTE") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR LOTE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NO_LOTE",
                value: this.state.custom.NO_LOTE,
                label: "NO_LOTE",
              },
              {
                type: "A",
                options: this.state.data.MANZANA,
                OptionLabel: "NOMBRE_MANZANA",
                placeholder: "SELECCIONE MANZANA",
                name: "MANZANA",
                value: this.state.custom.MANZANA,
                label: "MANZANA",
              },
              {
                config: Number,
                type: "G",
                name: "DESCRIPCION_LOTE",
                value: this.state.custom.DESCRIPCION_LOTE,
                label: "DESCRIPCION_LOTE",
              },
              {
                config: Text,
                type: "G",
                name: "ALTITUD_LOTE",
                value: this.state.custom.ALTITUD_LOTE,
                label: "ALTITUD_LOTE",
              },
              {
                config: Text,
                type: "G",
                name: "TAMANIO_LOTE",
                value: this.state.custom.TAMANIO_LOTE,
                label: "TAMANIO_LOTE",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: ACTIVO_GENERICO,
                OptionLabel: "NOMBRE_ACTIVO_GENERICO",
                placeholder: "SELECCIONE @VENDIDOS_LOTE",
                name: "VENDIDOS_LOTE",
                value: this.state.custom.VENDIDOS_LOTE,
                label: "VENDIDO",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_LOTE",
                value: this.state.custom.FECHA_CREACION_LOTE,
                label: "FECHA_CREACION_LOTE",
              },
              {
                config: Number,
                type: "G",
                name: "PRECIO_LOTE",
                value: this.state.custom.PRECIO_LOTE,
                label: "PRECIO_LOTE",
              }, {
                config: Number,
                type: "G",
                name: "NO_FINCA_LOTE",
                value: this.state.custom.NO_FINCA_LOTE,
                label: "NO_FINCA_LOTE",
              },
              {
                config: Number,
                type: "G",
                name: "NO_FOLIO_LOTE",
                value: this.state.custom.NO_FOLIO_LOTE,
                label: "NO_FOLIO_LOTE",
              },/*
              {
                type: "A",
                options: this.state.data.PORCENTAJE,
                OptionLabel: "NOMBRE_PORCENTAJE",
                placeholder: "SELECCIONE PORCENTAJE",
                name: "PORCENTAJEID",
                value: this.state.custom.PORCENTAJEID,
                label: "PORCENTAJE",
              },
              {
                config: Number,
                type: "G",
                name: "PRECIO_LOTE_MAS_INSTERES",
                value: this.state.custom.PRECIO_LOTE,
                label: "PRECIO_LOTE_MAS_INSTERES",
                shrink:true,
                readOnly:true
              },*/
              {
                config: Number,
                type: "G",
                name: "ENGANCHE_LOTE",
                value: this.state.custom.ENGANCHE_LOTE,
                shrink:true,
                label: "ENGANCHE_LOTE",
              },
              {
                config: Number,
                type: "G",
                name: "CUOTA_MINIMA",
                value: this.state.custom.CUOTA_MINIMA,
                label: "CUOTA_MINIMA",
              },
              {
                config: Number,
                type: "G",
                name: "CUOTA_MAXIMA",
                value: this.state.custom.CUOTA_MAXIMA,
                label: "CUOTA_MAXIMA",
              },
            ]}
          ></FormsG>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR LOTE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NO_LOTE",
                value: this.state.custom.NO_LOTE,
                label: "NO_LOTE",
              },
              {
                type: "A",
                options: this.state.data.MANZANA,
                OptionLabel: "NOMBRE_MANZANA",
                placeholder: "SELECCIONE MANZANA",
                name: "MANZANA",
                value: this.state.custom.MANZANA,
                label: "MANZANA",
              },
              {
                config: Text,
                type: "G",
                name: "DESCRIPCION_LOTE",
                value: this.state.custom.DESCRIPCION_LOTE,
                label: "DESCRIPCION_LOTE",
              },
              {
                config: Text,
                type: "G",
                name: "ALTITUD_LOTE",
                value: this.state.custom.ALTITUD_LOTE,
                label: "ALTITUD_LOTE",
              },
              {
                config: Text,
                type: "G",
                name: "TAMANIO_LOTE",
                value: this.state.custom.TAMANIO_LOTE,
                label: "TAMANIO_LOTE",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: ACTIVO_GENERICO,
                OptionLabel: "NOMBRE_ACTIVO_GENERICO",
                placeholder: "SELECCIONE VENDIDOS_LOTE",
                name: "VENDIDOS_LOTE",
                value: this.state.custom.VENDIDOS_LOTE,
                label: "VENDIDO",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_LOTE",
                value: this.state.custom.FECHA_CREACION_LOTE,
                label: "FECHA_CREACION_LOTE",
              },

              {
                config: Number,
                type: "G",
                name: "PRECIO_LOTE",
                value: this.state.custom.PRECIO_LOTE,
                label: "PRECIO_LOTE",
              }, {
                config: Number,
                type: "G",
                name: "NO_FINCA_LOTE",
                value: this.state.custom.NO_FINCA_LOTE,
                label: "NO_FINCA_LOTE",
              },
              {
                config: Number,
                type: "G",
                name: "NO_FOLIO_LOTE",
                value: this.state.custom.NO_FOLIO_LOTE,
                label: "NO_FOLIO_LOTE",
              },/*
              {
                type: "A",
                options: this.state.data.PORCENTAJE,
                OptionLabel: "NOMBRE_PORCENTAJE",
                placeholder: "SELECCIONE PORCENTAJE",
                name: "PORCENTAJEID",
                value: this.state.custom.PORCENTAJEID,
                label: "PORCENTAJE",
              },
              {
                config: Number,
                type: "G",
                name: "PRECIO_LOTE_MAS_INSTERES",
                value: this.state.custom.PRECIO_LOTE_MAS_INSTERES,
                label: "PRECIO_LOTE_MAS_INSTERES",
                shrink:true,
                readOnly:true
              },*/
              {
                config: Number,
                type: "G",
                name: "ENGANCHE_LOTE",
                value: this.state.custom.ENGANCHE_LOTE,
                label: "ENGANCHE_LOTE",
              },
              {
                config: Number,
                type: "G",
                name: "CUOTA_MINIMA",
                value: this.state.custom.CUOTA_MINIMA,
                label: "CUOTA_MINIMA",
              },
              {
                config: Number,
                type: "G",
                name: "CUOTA_MAXIMA",
                value: this.state.custom.CUOTA_MAXIMA,
                label: "CUOTA_MAXIMA",
              },
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
          configBit={ACTIVO_GENERICO}
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_LOTE") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="LOTE"
            url="LOTE"
            responsive="vertical"
            allcolumns={[
              {
                name: "NO_LOTE",
                label: "NO_LOTE",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_MANZANA",
                label: "NOMBRE_MANZANA",
                display: true,
                type: "text",
              },
              {
                name: "DESCRIPCION_LOTE",
                label: "DESCRIPCION_LOTE",
                display: false,
                type: "text",
              },
              {
                name: "ALTITUD_LOTE",
                label: "ALTITUD_LOTE",
                display: false,
                type: "text",
              },
              {
                name: "TAMANIO_LOTE",
                label: "TAMAÑO_LOTE",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: false,
                type: "text",
              },
              {
                name: "VENDIDOS_LOTE",
                label: "VENDIDO",
                display: true,
                type: "bit",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: false,
                type: "text",
              },
              {
                name: "FECHA_CREACION_LOTE",
                label: "FECHA_CREACION_LOTE",
                display: false,
                type: "text",
              },
              {
                name: "PRECIO_LOTE",
                label: "PRECIO_LOTE",
                display: true,
                type: "number",
              },
              {
                name: "NOMBRE_PORCENTAJE",
                label: "NOMBRE_PORCENTAJE",
                display: false,
                type: "text",
              },
              // {
              //   name: "PORCENTAJE",
              //   label: "PORCENTAJE",
              //   display: true,
              //   type: "text",
              // },
              // {
              //   name: "PRECIO_LOTE_MAS_INSTERES",
              //   label: "PRECIO_LOTE_MAS_INSTERES",
              //   display: true,
              //   type: "number",
              // },
              {
                name: "ENGANCHE_LOTE",
                label: "ENGANCHE_LOTE",
                display: true,
                type: "number",
              },
              // {
              //   name: "CUOTA_MINIMA",
              //   label: "CUOTA_MINIMA",
              //   display: true,
              //   type: "number",
              // },
              // {
              //   name: "CUOTA_MAXIMA",
              //   label: "CUOTA_MAXIMA",
              //   display: true,
              //   type: "number",
              // },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_LOTE") ? true : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_LOTE") ? true : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(LOTE);
