import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
};
class PORCENTAJE extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
    const SUCURSAL = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/SUCURSAL/all`
    );
    this.setState({ data: { ...this.state.data, SUCURSAL: SUCURSAL.data } });
  }
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
    const ID_EMPRESA = await this.state.custom.EMPRESA.ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    const ID_SUCURSAL = await this.state.custom.SUCURSAL.ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });
  };
  UpdateStateCreate = (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/PORCENTAJE/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/PORCENTAJE/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL PORCENTAJE ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/PORCENTAJE/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        FECHA_CREACION_PORCENTAJE: new Date(),
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const EMPRESA = await this.state.data.EMPRESA.find(
      (x) => x.ID_EMPRESA == e.ID_EMPRESA
    );
    await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    const SUCURSAL = await this.state.data.SUCURSAL.find(
      (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    );
    await this.setState({
      custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    });
    
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_PORCENTAJE") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR PORCENTAJE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_PORCENTAJE",
                value: this.state.custom.NOMBRE_PORCENTAJE,
                label: "NOMBRE_PORCENTAJE",
              },
              {
                config: Number,
                type: "G",
                name: "VALOR_PORCENTAJE",
                value: this.state.custom.VALOR_PORCENTAJE,
                label: "VALOR_PORCENTAJE",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_PORCENTAJE",
                value: this.state.custom.FECHA_CREACION_PORCENTAJE,
                label: "FECHA_CREACION_PORCENTAJE",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
            ]}
          ></FormsG>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR PORCENTAJE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_PORCENTAJE",
                value: this.state.custom.NOMBRE_PORCENTAJE,
                label: "NOMBRE_PORCENTAJE",
              },
              {
                config: Number,
                type: "G",
                name: "VALOR_PORCENTAJE",
                value: this.state.custom.VALOR_PORCENTAJE,
                label: "VALOR_PORCENTAJE",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_PORCENTAJE",
                value: this.state.custom.FECHA_CREACION_PORCENTAJE,
                label: "FECHA_CREACION_PORCENTAJE",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_PORCENTAJE") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="PORCENTAJE"
            url="PORCENTAJE"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_PORCENTAJE",
                label: "NOMBRE_PORCENTAJE",
                display: true,
                type: "text",
              },
              {
                name: "VALOR_PORCENTAJE",
                label: "VALOR_PORCENTAJE",
                display: true,
                type: "number",
              },
              {
                name: "FECHA_CREACION_PORCENTAJE",
                label: "FECHA_CREACION_PORCENTAJE",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: true,
                type: "text",
              },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_PORCENTAJE")
                  ? true
                  : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_PORCENTAJE")
                  ? true
                  : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(PORCENTAJE);
