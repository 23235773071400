import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
import AutocompleteG from "../../Material-ui/Input/Autocomplete/Autocomplete";
const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"ACTIVO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"INACTIVO"}]

const initialState = {
  custom: { ASIGNADOS: [], ASIGNACION_CICLO_ESCOLAR: [] },
  data: {},
  crear: true,
  modificar: false,
  tabla: true,
};
class ASIG extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    const BANCO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/Banco/Find/${this.props.match.params.id}`
    );
    await this.setState({
      data: { ...this.state.data, ...BANCO.data },
      custom: { ...this.state.custom, ...BANCO.data },
    });
  }
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: {
        ...this.state.custom,
        ID_USUARIO: ID_USUARIO,
        ID_BANCO: this.state.data.ID_BANCO,
      },
    });
   
    const ESTADO=true;
    await this.setState({
      custom: { ...this.state.custom, ESTADO: ESTADO },
    }); 
  };
  UpdateStateCreate = (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();

    const ID_SUCURSAL=this.props.auth[0].ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });

    const ID_EMPRESA=this.props.auth[0].ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    await this.setState({
      custom: {
        ...this.state.custom,
        FECHA_CREACION_CUENTA: new Date(),ESTADO: ACTIVO_GENERICO[0],
      },
    });

    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CUENTA/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);

    await this.setState({
      custom: {},
      tabla: false,
      crear: false,
    });
    await this.setState({
      custom: {},
      tabla: true,
      crear: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CUENTA/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR LA CUENTA?")) {
      e.ESTADO = 0;
      await this.setState({ customDelete: e });

      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/CUENTA/delete`,
        this.state.customDelete
      );
      alert(data.message);
      await this.setState({
        customDelete: {},
        tabla: false,
      });
      await this.setState({
        customDelete: {},
        tabla: true,
      });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    // await this.setState({
    //   custom: { ...this.state.custom, FECHA_CREACION_ASIGNACION_CURSO: new Date() },
    // });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    await this.setState({ custom: e });
    const ACCESO = await this.state.data.ACCESO.find(
      (x) => x.ID_ACCESO == e.ID_ACCESO
    );
    await this.setState({ custom: { ...this.state.custom, ACCESO: ACCESO } });
    const ROL = await this.state.data.ROL.find((x) => x.ID_ROL == e.ID_ROL);
    await this.setState({ custom: { ...this.state.custom, ROL: ROL } });
    const ESTADO = await ACTIVO_GENERICO.find(
      (x) => x.NOMBRE_ACTIVO_GENERICO == e.ESTADO
    );
    await this.setState({ custom: { ...this.state.custom, ESTADO: ESTADO } });

    this.setState({ crear: false, modificar: true, tabla: false });
  };
  RegresarAnterior = (e) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/BANCO`);
  };
  render() {
    const redireccion = this.props.Access("READ_CUENTA") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                
                <h1 className="text-center">CREAR ASIGNACION DE CUENTA</h1>
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                   <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.RegresarAnterior}
                />
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_BANCO",
                value: this.state.data.NOMBRE_BANCO+' ',
                label: "NOMBRE",
                readOnly:true
              },
              {
                config: Text,
                type: "G",
                name: "NOMBRE_CUENTA",
                value: this.state.custom.NOMBRE_CUENTA,
                label: "CUENTA",
              },
              {
                config: Text,
                type: "G",
                name: "NO_CUENTA",
                value: this.state.custom.NO_CUENTA,
                label: "NO CUENTA",
              },{
                config: Number,
                type: "G",
                name: "SALDO",
                value: this.state.custom.SALDO,
                label: "SALDO",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_CUENTA",
                value: this.state.custom.FECHA_CREACION_CUENTA,
                label: "FECHA CREACION",
                readOnly:true,

              },
            ]}
          ></FormsG>
        )}

        {this.state.tabla && (
          <TableG
            customToolbar={<Fragment></Fragment>}
            title="CUENTAS ASIGNADAS"
            url="CUENTA"
            urlSecundary={`FindByBANCO/${this.props.match.params.id}`}
            responsive="vertical"
            allcolumns={[
                {
                  name: "NOMBRE_CUENTA",
                  label: "NOMBRE",
                  display: true,
                  type: "text",
                },
                {
                  name: "NO_CUENTA",
                  label: "NO CUENTA",
                  display: true,
                  type: "text",
                },
                {
                  name: "FECHA_CREACION_CUENTA",
                  label: "FECHA CREACION",
                  display: true,
                  type: "text",
                  
                },  {
                  name: "SALDO",
                  label: "SALDO",
                  display: true,
                  type: "number",
                },
                {
                  name: "ELIMINAR",
                  type: "button",
                  display: this.props.Access("DELETE_CUENTA") ? true : "excluded",
                  buttonConfig: EliminarIcon,
                  OnClickButton: this.Delete,
                },
                {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_CUENTA") ? true : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(ASIG);
