import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (
      <div id="contratoOriginal">
        <div className="container" id="contenidosContrato">
          <div align="center">
            <img
              id="img-contrato"
              src={this.props.logoEmpresa}
              alt="HaciendaElNaranjo"
            />
          </div>
          En la aldea {this.props.PROYECTO.DIRECCION}, el doce de junio del año
          dos mil veintiuno, Comparece por una parte el señor:
          <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL},</b> quien manifiesta ser
          de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS} años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL},
          {this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD}, Ejecutivo, de este domicilio, quien se identifica con el
          Documento Personal de Identificación –DPI- con Código Único de
          Identificación -CUI- {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.REPRESENTANTE_DPI}), emitido por el
          Registro Nacional de las Personas de la República de Guatemala, quien
          actúa en su calidad de ADMINISTRADOR ÚNICO Y REPRESENTANTE LEGAL de la
          entidad mercantil denominada {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, calidad que acredita con el acta notarial en la que
          consta su nombramiento como tal, autorizada en Santa Catarina Pinula,
          departamento de Guatemala, el nueve noviembre del año dos mil
          diecinueve, por la Notaria {this.props.EMPRESA.NOTARIA}, documento
          inscrito en el Registro Mercantil al número de registro {this.props.EMPRESA.REPRESENTANTE_REGISTROMERCANTIL_LETRAS} ({this.props.EMPRESA.NOTARIA_REGISTRO_MERCANTIL}), folio {this.props.EMPRESA.REPRESENTANTE_NOTARIAFOLIO_LETRAS} ({this.props.EMPRESA.NOTARIA_FOLIO}) del
          libro {this.props.EMPRESA.REPRESENTANTE_NOTARIALIBRO_LETRAS} ({this.props.EMPRESA.NOTARIA_LIBRO}) de Auxiliares de Comercio. En lo
          sucesivo se denominará <b> el promitente vendedor, </b> y por otra
          parte comparece: <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE},</b>{" "}
          de {this.props.custom.EDAD_LETRAS} años de edad,{" "}
          {this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO},{" "}
          {this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO},{" "}

          {this.props.custom.PROFECION_CLIENTE_CONTARTO}, con domicilio y
          residencia {this.props.custom.DIRECCION_CLIENTE_CONTRATO} en el
          municipio de Masagua departamento de Escuintla, se identifica con
          Documento Personal de Identificación, Código Único De Identificación
          CUI: {this.props.custom.DPI_LETRAS} (
          {this.props.custom.DPI_CLIENTE_CONTRATO}), extendido por el Registro
          Nacional de las Personas de la República de Guatemala, en lo sucesivo
          se denominará <b>“el promitente comprador”.</b> Los comparecientes me
          aseguran hallarse en el libre ejercicio de sus derechos civiles y que
          por el presente acto otorgan{" "}
          <b>
            PROMESA DE VENTA DE LOTE RUSTICO PROYECTO “BRISAS DEL MAR” UBICADO
            EN ALDEA OBERO, MUNICIPIO DE MASAGUA DEPARTAMENTO DE ESCUINTLA CON
            FACILIDADES DE PAGO,
          </b>{" "}
          de conformidad con las siguientes cláusulas:
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato3">
          <br />
          <b>PRIMERA:</b> Manifiesta el señor:{" "}
          <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL},</b> en la calidad con que actúa, que
          su representada {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL},
          es legítima propietaria de la finca rústica ubicada en Aldea Obero,
          Municipio De Masagua, Departamento De Escuintla, inscrita en el
          Registro General De La Propiedad e identificada con el número de
          finca:{" "}
          <b>
            {this.props.custom.FINCA_LETRAS}(
            {this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO})
            folio: {this.props.custom.FOLIO_LETRAS1} (
            {this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO})
            del libro: {this.props.custom.LIBRO_LETRAS} (
            {this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO})
          </b>{" "}
          del departamento de Escuintla. <b>SEGUNDA:</b> Continúa manifestando{" "}
          <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL},</b> en la calidad con que actúa, que
          su representada, promete en venta a favor del promitente comprador un
          lote de <b>NOVECIENTOS METROS CUADRADOS</b>({" "}
          {this.props.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO}),
          identificado con el
          <b>
            {" "}
            NÚMERO {this.props.custom.LOTE_LETRAS} (
            {this.props.custom.CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO})
            MANZANA “
            {this.props.custom.CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO}”.
          </b>{" "}
          Puede variar la numeración en cuanto al lote o manzana, pero no así su
          ubicación. La presente promesa de venta se regirá mediante las
          siguientes cláusulas:
          <b>
            {" "}
            A) PRECIO DE VENTA ES DE{" "}
            {
              this.props.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            }{" "}
            (
            {
              this.props.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            }
            ). B) CON UN ENGANCHE DE{" "}
            {
              this.props.custom
                .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO
            }{" "}
            POR CIENTO. C) EL SALDO DE QUINCE MIL QUINIENTOS CINCUENTA Y CINCO
            QUETZALES CON CINCUENTAY SEIS CENTAVOS (
            {
              this.props.custom
                .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO
            }
            ). SE CANCELARÁ EN {this.props.custom.ANIOS_PAGAR} AÑOS ES{" "}
            {this.props.custom.CUOTAS_LETRAS} CUOTAS DE TRESCIENTOS SETENTA Y
            SIETE QUETZALES CON NOVENTA Y CUATRO CENTAVOS (
            {
              this.props.custom
                .CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO
            }
            ) LAS CUALES DIERON INICIO EN FECHA {this.props.custom.PRIMERCUOTA_LETRAS}
          </b>{" "}
          y serán depositados: al {this.props.BANCO.NOMBRECOMPLETO} ({this.props.BANCO.NOMBRE}), al número de cuenta: <b>{this.props.BANCO.CUENTA}</b> {" "}a nombre de {" "}
          {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL} <b>TERCERA:</b>{" "}
          Otras condiciones de la negociación: El precio no incluye gastos de
          escrituración, título de agua, drenaje, adoquín, electricidad, pago
          del Impuesto al Valor Agregado (IVA), ni tasas o contribuciones
          municipales; en caso que el promitente comprador quiera dar por
          terminada la opción de compra del inmueble perderá a favor de {" "}
          {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, el diez por
          ciento (10%) del valor total de
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato2">
          la propiedad por concepto de arras, más los gastos administrativos
          equivalentes al catorce por ciento (14%), los cuales se harán
          efectivos de lo abonado al lote prometido en venta en el presente
          contrato, realizando la devolución únicamente de capital y no
          intereses, dicha devolución se realizará conforme las cuotas
          canceladas, si existiere un saldo a favor del promitente comprador,
          esperara el tiempo necesario que le tome a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, vender el terreno para recibir el saldo
          restante que el promitente comprador pueda tener a su favor.{" "}
          <b>CUARTA:</b> Opción de compra venta: El promitente comprador se
          obliga a comprar el inmueble en el precio, plazo y condiciones
          especificadas en este documento. <b>QUINTA</b> El precio y forma de
          pago: El precio del inmueble es calculado en quetzales o su
          equivalencia en Dólares de los Estados Unidos de América para este
          proyecto, utilizando el tipo de cambio que reporte el Banco de
          Guatemala; si existiere una variación considerable, al alza de dólar,
          podría variar la cuota establecida en el presente contrato, la cual
          será notificada al promitente comprador. <b>SEXTA:</b> Cuota: El
          promitente comprador se obliga a pagar las cuotas a partir de la fecha
          indicada mensual y consecutivamente,{" "}
          <b>
            {" "}
            quedando obligado a enviar fotografía de la boleta de pago al número
            de teléfono {this.props.BANCO.TELEFONO} de lo contrario recaerá en mora, la perdida de
            la boleta de pago física o electrónica es responsabilidad del
            comprador, obligándose a realizar de nuevo del pago de la cuota
            mensual, “el promitente comprador”.{" "}
          </b>
          declara que los fondos con los que honre cada una de las cuotas de la
          presente promesa serán de legítima procedencia, por lo que libera de
          toda responsabilidad a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, 
          de no ser cierto lo acá declarado. <b>SÉPTIMA:</b> Pagos
          extraordinarios: El promitente comprador podrá hacer pagos
          extraordinarios abonados a capital sin restricción alguna; dichos
          pagos reducen el plazo para la compra definitiva, pero no reduce el
          monto de cuota. <b>OCTAVA: </b>
          Recargos por Mora. En caso el promitente comprador no realice el pago
          correspondiente el día de su vencimiento, tendrá como margen para
          cancelar su cuota <b> cinco </b>
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato3">
          <br />
          <b> días posteriores a la fecha pactada, </b> de lo contrario el
          promitente comprador tendrá que cancelar un recargo por mora
          acumulativa del cinco por ciento (5%) sobre el saldo a la fecha. Si el
          promitente comprador incumpliere con{" "}
          <b>
            {" "}
            dos o más cuotas atrasadas se dará por terminada la presente
            negociación{" "}
          </b>
          sin necesidad de notificación de la misma; como consecuencia el
          promitente comprador perderá a favor de {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, el diez por ciento (10%) del valor
          total de la propiedad por concepto de arras, más los gastos
          administrativos equivalentes al catorce por ciento (14%).{" "}
          <b>NOVENA:</b> Lugar de pago: El promitente comprador acepta que todos
          los pagos a que se refiere el presente contrato se harán efectivos en
          moneda de curso legal, sin necesidad de cobro alguno, los recibos se
          extenderán en la oficina ubicada en la casa patronal de dicho
          proyecto. <b>DÉCIMA:</b> Posesión del terreno: El promitente comprador
          podrá tomar posesión al momento de enganchar la propiedad.{" "}
          <b>DÉCIMA PRIMERA:</b> El promitente comprador acepta sin necesidad de
          notificación alguna que el promitente vendedor, pueda ceder el
          presente contrato de promesa de venta, en todo caso al momento de
          suscribir la escritura traslativa de dominio el inmueble deberá
          encontrarse libre de gravamen y anotaciones. <b>DÉCIMA SEGUNDA:</b>
          Escrituración: La escritura registrada en la que se documente la
          compraventa se entregará con licencias ambientales, estudio de
          capacidad de uso de la tierra, estudio de impacto ambiental y licencia
          municipal, estando cancelado el cien por ciento (100%) del terreno
          objeto de la opción,{" "}
          <b>{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL},</b> designa a
          Bufete Jurídico Figueroa Y Asociados exclusivamente, para que faccione
          la escritura de compraventa; los honorarios, impuestos, gastos de
          escrituración y registro, serán por cuenta del promitente comprador,
          así como cualquier otro gasto que se designe por este negocio, los
          cuales tendrán un costo de mil ochocientos quetzales (Q1,800.00) por
          lote. <b>DÉCIMA TERCERA:</b> Limitaciones en el ejercicio del derecho:
          El promitente comprador acepta y se obliga a observar lo siguiente: a)
          Dar aviso a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, o en
          su defecto a
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato2">
          desarrolladora REBIERA INMOBILIARIA, antes de construir, para que este
          verifique los mojones y medidas. b) El promitente comprador acepta
          desde ya que, si no ha pagado la totalidad del valor del terreno y
          construye en él, la construcción pertenece a{" "}
          <b>{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL},</b> sin
          compensación alguna. <b>DÉCIMA CUARTA:</b> Incumplimiento del
          Promitente Comprador: Si el promitente comprador incumpliera las
          obligaciones que adquiere, acepta que{" "}
          <b>{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL},</b> puede
          optar por demandar ejecutivamente el saldo que hubiera, incluyendo
          intereses, multas, costos, daños y perjuicios, pudiendo embargar los
          bienes presentes y futuros del promitente comprador.{" "}
          <b>DÉCIMA QUINTA:</b> Aceptación: Bien enterados de su contenido
          objeto, validez y efectos legales, lo aceptan, ratifican y firman,
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col">F______________________________</div>
            <div class="col">F______________________________</div>
          </div>
          <br />
          <br />
          <br />
          En la aldea El Obero, Municipio De Masagua, Departamento De Escuintla,
          el doce de junio del año dos mil veintiuno, yo como{" "}
          <b>Notario Doy Fe,</b> que las firmas que anteceden y calzan{" "}
          <b>
            PROMESA DE VENTA DE LOTE RUSTICO PROYECTO “BRISAS DEL MAR” UBICADO
            EN ALDEA OBERO, MUNICIPIO DE MASAGUA DEPARTAMENTO DE ESCUINTLA CON
            FACILIDADES DE PAGO, son AUTENTICAS
          </b>
          , por haber sido puestas en mi presencia por el señor:
          <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL},</b> quien se identifica con el
          Documento Personal de Identificación –DPI- con Código Único de
          Identificación -CUI-  {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.REPRESENTANTE_DPI}),
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato3">
          <br />
           emitido por el Registro Nacional de las Personas de la
          República de Guatemala; y, {this.props.custom.NOMBRE_COMPLETO_CLIENTE}
          , quien se identifica con Documento Personal de Identificación, Código
          Único De Identificación CUI: {this.props.custom.DPI_LETRAS} (
          {this.props.custom.DPI_CLIENTE_CONTRATO}), extendido por el Registro
          Nacional de las Personas de la República de Guatemala. Previa lectura
          sello y firmo la presente Acta de Legalización contenida en tres
          únicas hojas de papel bond tamaño oficio la cual, firmo y sello.
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col">F______________________________</div>
            <div class="col">F______________________________</div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br /> <br />
          ANTE MÍ:
        </div>
      </div>
    );
  }
}
export default withRouter(CONTRATO);
