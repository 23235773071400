import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {

};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (
      <div id="contratoOriginal">

        En el municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, Departamento
        de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha{" "}
        {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}. Comparece por una parte:{" "}
        <b>
         {this.props.EMPRESA.REPRESENTANTE_LEGAL}{' '}
        </b>
        de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS}{" "}
        años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL},{" "}
        {this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD},{" "}
        {this.props.EMPRESA.REPRESENTANTE_PROFESION}, con domicilio en el
        departamento de{" "}
        {this.props.EMPRESA.REPRESENTANTE_DOMICILIO_DEPARTAMENTO} y de paso por
        este municipio, quien se identifica con Documento Personal de
        Identificación: Código Único de Identificación{" "}
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
        {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala, 
        quien en lo sucesivo se denominará{" "} <b>"El Obligado"</b>;{" "} y por la otra parte comparece:{" "}
        {" "}<b>
          {""}
          {this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}
        </b>
        , de {this.props.custom.EDAD_LETRAS} años de edad,{" "}
        {this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO.toLowerCase()},{" "}
        {this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO.toLowerCase()},{" "}
        {this.props.custom.PROFECION_CLIENTE_CONTARTO.toLowerCase()}, con
        domicilio y residencia en {this.props.custom.DIRECCION_CLIENTE_CONTRATO},{" "}
        del municipio de {this.props.custom.MUNICIPIO_CLIENTE_CONTRATO},
        departamento de {this.props.custom.DEPARTAMENTO_CLIENTE_CONTRATO}, se
        identifica con Documento Personal de Identificación con Código Único de
        Identificación {this.props.custom.DPI_LETRAS} (
        {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala, en lo
        sucesivo se denominará <b>"El Obligante"</b>. Los
        comparecientes me aseguran hallarse en el libre ejercicio de sus
        derechos civiles, en español idioma que hablan y entienden me
        manifiestan que por el presente acto otorgan{" "}
        <b>
          {" "}
          CONTRATO PRIVADO DE OBLIGACIÓN DE ESCRITURAR FRACCIÓN DE BIEN INMUEBLE RUSTICO{" "}
          {/* {this.props.EMPRESA.NOMBRE_EMPRESA.toUpperCase()} CON FACILIDADES DE
          PAGO, */}
        </b>{" "}
        de conformidad con las siguientes cláusulas: <b>PRIMERA:{" "}Manifiesta{" "}
        "El Obligado"</b>; de conformidad a la escritura número ciento setenta y dos 
        de fecha veintiuno de septiembre del año dos mil veintidós, faccionada por el Notario: 
        Vicente Norberto Lemus Silva, de conformidad a la cláusula quinta inciso 
        d) el señor <b>RUBÉN DARIO ESCOBAR RUIZ,</b> autoriza otorgar promesas de compraventa a 
        terceras personas, dicho inmueble se encuentra inscrito en el Registro General de la
        Propiedad identificada con el número de <b>Finca: </b>
        <b>
          {" "}
          {this.props.custom.FINCA_LETRAS} (
          {this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO}),
          <b>Folio:</b> {this.props.custom.FOLIO_LETRAS} (
          {this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO})
          del <b>Libro:</b> {this.props.custom.LIBRO_LETRAS} (
          {this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO})
          de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}
        </b>
        , consistentes en terreno rústicos, se ubican en el municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, Departamento
        de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, 
        con las medidas y colindancias que constan en su respectiva inscripción
        registral. <u>SEGUNDA</u>: <b>OBJETO DEL CONTRATO:</b> Continúa manifestando  
        <b>"El Obligado"</b>, <b>a)</b>{" "}vende un lote identificado con número{" "}
        {this.props.custom.LOTES_CONTRATO_VISTA.map((item, index) => {
          return (
            <Fragment>
              <b>          
                {" "}
                {item.LOTE_LETRAS} ({item.NO_LOTE}) MANZANA “
                {item.NOMBRE_MANZANA}”,{" "}
                con un área superficial de{" "}
                {item.LOTE_LETRAS_METRAJE} ({item.TAMANIO_LOTE}){" "}b) 
                POR EL PRECIO DE VENTA DE: {this.props.custom.PRECIO_TOTAL_LETRAS.toUpperCase()} (Q.{" "}
          {this.props.custom.PRECIO_VENTA_CLIENTE_TOTOTAL_MONEDA}), c)<b/> y se obliga a escriturar a favor de:{" "}
          <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE}</b>,</b> el lote identificado anteriormente.{" "}
          <b>d)</b> Manifiesta <b>el obligado</b>, que <b>ACEPTA</b> el pago que tiene recibidos al contado y a su entera satisfacción en este momento.{" "}
              {/* <b>SECTOR {item.UBICACION_MANZANA}.</b>{" "} */}
            </Fragment>
          );
        })}
        <u><b>TERCERA:</b></u><b>OBLIGACIÓN DE ESCRITURAR:</b>{" "}
        Manifiesta <b>"El Obligado";</b> que se compromete a escriturar el lote de terreno antes mencionado en 
        el <b>PLAZO DE UN AÑO</b> a partir del presente documento privado. a) otras condiciones 
        de la negociación: El precio del lote no incluye gastos de escrituración, ni pago 
        del Impuesto al Valor Agregado (IVA), ni tasas o contribuciones municipales, en caso 
        de que <b>"El Obligante".</b> quiera dar por terminada la opción de compra del inmueble, 
        perderá a favor de <b>"El Obligado";</b> el diez por ciento (10%) del valor total de la 
        propiedad por concepto de arras, más los gastos administrativos equivalentes al 
        catorce por ciento (14%), b) la escritura en la que se documente la compraventa se 
        entregará debidamente registrada y/o inscrita en el Registro General De La Propiedad 
        de la Republica de Guatemala, <b>"el obligado"</b>, designa a Bufete Jurídico 
        Figueroa & Asociados exclusivamente, para que faccione la escritura de compraventa; 
        los honorarios, impuestos, gastos de escrituración y registro, serán por cuenta del 
        obligante, así como cualquier otro gasto que se designe por este negocio, los cuales 
        tendrán un costo de {this.props.EMPRESA.ESCRITURA_PRECIO_LETRAS} (Q.
        {this.props.EMPRESA.ESCRITURA_PRECIO_MONEDA}) por lote.{" "}

        <u><b>CUARTA:</b></u> Posesión del terreno: 
        a) El Obligante podrá tomar posesión del inmueble al momento de la 
        negociación, b) El Obligante recibe el inmueble para su cuidado, limpieza, 
        y resguardo, c) las bases de delimitación de la propiedad se entregan al momento 
        de la firma del presente contrato, siendo responsable de el resguardo de las misma 
        el Obligante, d) El Obligante se obliga a cancelar una cuota anual de limpieza y 
        mantenimiento  de las calles principales del residencial, siendo el costo de 
        ciento cincuenta quetzales anuales por lote.{" "}
        <b><u>QUINTA:</u>{" "}OBLIGACIÓN DE COMPRA DE SERVICIOS:</b>{" "}
        El Obligante se obliga a comprar con la desarrolladora REBIERA INMOBILIARIA, 
        los servicios básicos y urbanización, dicha obligación se plasmará en la 
        escritura traslativa de dominio para su cumplimiento.{" "}
        <b><u>SEXTA:</u></b>{" "}
        Los comparecientes manifiestan su responsabilidad en cuanto a las obligaciones 
        adquiridas y renunciamos al fuero de nuestros domicilios 
        pudiendo ser demandados ante cualquier tribunal de la República de Guatemala 
        en caso de incumplimiento.{" "}
        <b><u>SEPTIMA:</u></b>{" "}
        Los comparecientes manifiestan su plena conformidad y ACEPTAN el presente 
        contrato privado, quienes bien enterados de su contenido, objeto, validez y 
        efectos legales, lo aceptan, ratifican y firman, no así:{" "} 
        {this.props.NOMBRE_COMPLETO_CLIENTE}, por no saber firmar únicamente imprime su huella 
        dactilar del pulgar de la mano derecha, 
        haciéndolo a su ruego como testigo capaz civilmente e idóneo:{" "} 
        {this.props.custom.TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO.toUpperCase()}, quien se identifica con Documento Personal de
        Identificación -DPI- con Código Único de Identificación -CUI- {" "}
        {this.props.custom.DPI_TESTIGO_LETRAS} ({this.props.custom.DPI_SEPARADO_TESTIGO_CONTRATO}) extendido por el Registro Nacional de las
        Personas de la República de Guatemala.
        
        
        <br />
        <br />
        <br /> 
        <br />
        <br />
        f:__________________                                     f:___________________
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        
        En el municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA},{" "}
        Departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha{" "}
        {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}, yo{" "} 
        como Notario (a), DOY FE, que las firmas que anteceden y 
        CONTRATO PRIVADO DE OBLIGACIÓN DE ESCRITURAR FRACCIÓN DE BIEN INMUEBLE RUSTICO, 
        son AUTENTICAS, por haber sido puestas en mi presencia 
        por: <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, quien se identifica con 
        Documento Personal de Identificación, con Código Único de Identificación: 
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
        {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}){" "}
        extendido por el Registro Nacional de las Personas de la República de Guatemala; 
        y, <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, quien se identifica con Documento Personal de Identificación, 
        con Código Único de Identificación: {this.props.custom.DPI_LETRAS} (
        {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}){" "}
        extendido por el Registro Nacional de las Personas de la República de Guatemala. 
        Previa lectura de la presente Acta de Legalización de firmas contenida en dos únicas 
        hojas de papel bond tamaño oficio, la cual firmo y sello, los comparecientes vuelven a firmar, no así:{" "}
        {this.props.custom.NOMBRE_COMPLETO_CLIENTE}, por no saber firmar únicamente imprime su huella dactilar del pulgar de la mano derecha, 
        haciéndolo a su ruego como testigo idóneo, capaz civilmente : 
        {this.props.custom.TESTIGO_ARRUEGO_NOMBRE_COMPLETO_CONTRATO}, quien se identifica con Documento Personal de Identificación -DPI- con Código Único de Identificación -CUI- 
        {this.props.custom.DPI_TESTIGO_LETRAS} ({this.props.custom.DPI_SEPARADO_TESTIGO_CONTRATO}){" "} 
        extendido por el Registro Nacional de las Personas de la República de Guatemala.
        <br />
        <br />
        f:____________________ f:____________________
        <br />
        <i>ANTE MÍ:</i>
         
 
      </div>
    );
  } 
}
export default withRouter(CONTRATO);
