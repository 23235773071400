import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet,fetchPostFormData, fetchPostFile } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import ReciboPrueba from '../../Navbar/recibo'
import '../../Navbar/recibo.css'
import LogoPrincipal from '../../../assets/img/Jocotillo/logo_jocotillo.jpeg'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import EMPRESAS from "../../Operaciones/Contrato/contratoEmpresa/imagenContrato";

import JsonStyleManual from "../../Operaciones/Contrato/contratoEmpresa/style";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar, PDFIcon, ExcelIcon, BackupIcon, CloudDownloadIcon, DesktopWindowsIcon, AttachMoneyIcon, AsingacionIcon
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
import AutocompleteG from "../../Material-ui/Input/Autocomplete/Autocomplete";
import "jspdf-autotable";

import ReactExport from "react-export-excel";
import jsPDF from "jspdf";
import { it } from "date-fns/locale";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"PAGADO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"NO PAGADO"}]

const initialState = {
  JsonStyleManual: JsonStyleManual,
  custom: { ASIGNADOS: [], ASIGNACION_CICLO_ESCOLAR: [] },
  data: {BANCO:[],CUENTA:[]},
  crear: true,
  modificar: false,
  modificarDatos: true,
  tabla: false,
  tablaPagos: false,
  RecargaForzada:true,
  dataPagos:[],
  DatosTicket: { MONTO_PAGO_PAGO :null,
    Faltante: null, MONTO_PAGO_LETRAS: null, CUOTA_LETRAS_RECIBO:null,
    FECHA_PAGO_SEPARADO:null,
    SaldoAnterior: null,
    seleccion: null, faltanteTot: null, montoPagotot: null, TOTAL_ANTERIOR: null, NOMBRE_USUARIO_PAGO:null
  }
,  file: {},
  subir: false,subirBanco: false,
  empresas: EMPRESAS.empresas[0]
};
class ASIG extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

//  INICO DE EXPORTACION TICKET
  imprimirComponente2 = async(e) => {

    var mywindow = window.open("", "PRINT");
    // <title>` + document.title  + `</title> <style type="text/css">
    
    // line-height: 2.9 !important;
    const data = await this.state.dataPagos.find(x => x.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE == e.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE)
    
     data.TOTAL_ANTERIOR= parseFloat(this.state.totalesCuota.totalNoPagado) + parseFloat(data.MONTO_PAGO_PAGO)
    data.Faltante = this.state.totalesCuota.totalNoPagado
    data.montoPago = data.MONTO_PAGO_PAGO.toFixed(2)
    
    data.SaldoAnterior = this.state.totalesCuota.saldoAnterior

    data.SaldoPagado = e.SALDO_PAGADO;
    data.SaldoNoPagado = (e.SALDO_PAGADO - data.MONTO_PAGO_PAGO).toFixed(2);
data.id=e.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE;
    await this.setState({ DatosTicket: data })

    let empresa = EMPRESAS.empresas.find(
      (x) => x.ID_EMPRESA ==e.ID_EMPRESA
    );

    await this.setState({ empresas: empresa });

    const datos = this.state.JsonStyleManual[0].text;
    const style = ` <style>${datos}
    .img_Principal{
      width: 100%;
      background-size: co;
    }
    .Contenedor_imagen{

    }
    .Total{
      border: 1px solid black;
      border-radius: 10px;
    }
    .encabezado_recibo{
      text-align: right;
      padding-right: 20%;
      padding-top: 10px;
      padding-bottom: 10px;
    }


    .itemRecibo{
    border: none;
    border-bottom: 1px solid black;
    }
    .ContenedorRecibo div{
      padding-bottom: 10px;
    }
    .itemRecibo:focus {
      padding-bottom: 50px;
    }
    .Total{
    padding-top: 10px;
    padding-bottom: 10px;
    }
  </style>`
    mywindow.document.write(`<html><head>
<title>Lotificadora</title>
${style}
     `);
    mywindow.document.write(`</head><body>`);

    var documentoImpresion = document.getElementById("imprimible").innerHTML;

    mywindow.document.write(documentoImpresion);
    mywindow.document.write("</body></html>");
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(function () {
      mywindow.print("", "");
    }, 50);
    setTimeout(function () {
      mywindow.close();
    },1000);
  };

//  TERMINA EXPORTACION
  exportTicket = async(id) => {
    const data=await this.state.dataPagos.find(x=>x.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE==id)
    await this.setState ({DatosTicket:data})
    


        const unit = "pt";
        const size = "letter";
        const orientation = "p";
        let marginLeft = 40;
        let margintop = 120;

        const doc = new jsPDF(orientation, unit, size);
        var width   = doc.internal.pageSize.getWidth();
        var height  = doc.internal.pageSize.getHeight();

        doc.line(20,140,width-20,140)
        let cliente="CLIENTE:";
        doc.setFontSize(12);
        doc.text(cliente, marginLeft, margintop);

        marginLeft =parseInt( marginLeft+doc.getTextWidth(cliente)+5);
        doc.setFontSize(12);
        doc.text(data.NOMBRE_CLIENTE, marginLeft, margintop);


        marginLeft = 40;//parseInt(marginLeft+doc.getTextWidth(data.NOMBRE_CLIENTE)+5);
        margintop=margintop+40;
        let FECHA_DE_PAGO="FECHA DE PAGO REALIZADO:";
        doc.text(FECHA_DE_PAGO, marginLeft, margintop);
        marginLeft = parseInt(marginLeft+doc.getTextWidth(FECHA_DE_PAGO)+5);
        doc.setFontSize(12);
        doc.text(data.FECHA_PAGO, marginLeft, margintop);


        marginLeft = 40;//parseInt(marginLeft+doc.getTextWidth(data.NOMBRE_CLIENTE)+5);
        margintop=margintop+20;
        let NO_CUOTA="NO_CUOTA:";
        doc.text(NO_CUOTA, marginLeft, margintop);
        marginLeft = parseInt(marginLeft+doc.getTextWidth(NO_CUOTA)+5);
        doc.setFontSize(12);
        doc.text(data.NO_CUOTA.toString(), marginLeft, margintop);


        margintop=margintop+20;
        marginLeft =40;
        // let MORA="MORA:"
        // let CAPITAL="CAPITAL:"
        // let INTERES="INTERES:"
        let TOTAL_CUOTA="TOTAL CUOTA:"
        // doc.setFontSize(12);
        // doc.text(MORA, marginLeft, margintop);
        // marginLeft = parseInt(marginLeft+doc.getTextWidth(MORA)+5);
        // doc.text(data.MORA.toString() , marginLeft, margintop);

        // margintop=margintop+20;
        // marginLeft =40;
        // doc.text(CAPITAL, marginLeft, margintop);
        // marginLeft = parseInt(marginLeft+doc.getTextWidth(CAPITAL)+5);
        // doc.text(data.CAPITAL.toString() , marginLeft, margintop);


        // margintop=margintop+20;
        // marginLeft =40;
        // doc.text(INTERES, marginLeft, margintop);
        // marginLeft =parseInt( marginLeft+doc.getTextWidth(INTERES)+5);
        // doc.text(data.INTERES.toString() , marginLeft, margintop);

        margintop=margintop+20;
        marginLeft =40;
        doc.text(TOTAL_CUOTA, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(TOTAL_CUOTA)+5);
        doc.text(data.MONTO_PAGO_PAGO.toFixed(2) , marginLeft, margintop);



        let TOTAL_NO_PAGADO="FALTANTE DE PAGO: "
        margintop=margintop+40;
        marginLeft =40;
        doc.text(TOTAL_NO_PAGADO, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(TOTAL_NO_PAGADO)+5);
        
        doc.text(this.state.totalesCuota.totalNoPagado.toFixed(2) , marginLeft, margintop);

        let TOTAL_PAGADO="TOTAL PAGADO:"
        margintop=margintop+20;
        marginLeft =40;
        doc.text(TOTAL_PAGADO, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(TOTAL_PAGADO)+5);
        doc.text(this.state.totalesCuota.totalPagado.toFixed(2) , marginLeft, margintop);

        doc.line(20,margintop+10,width-20,margintop+10)
        doc.line(20,140,20,margintop+10)
        doc.line(width-20,140,width-20,margintop+10)

        marginLeft =40;
        margintop=margintop+40;
        doc.setFontSize(12);
        doc.text(`FECHA DE IMPRESION: ${new Date().toLocaleString()}`, marginLeft, margintop);

        let NOMBRE_USUARIO_PAGO="COBRADOR:"
        margintop=margintop+20;
        marginLeft =40;
        doc.text(NOMBRE_USUARIO_PAGO, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(NOMBRE_USUARIO_PAGO)+5);
        doc.text(data.USUARIO_PAGO_DETALLE , marginLeft, margintop);


        let NOMBRE_EMPRESA="PROYECTO: "
        margintop=margintop+20;
        marginLeft =40;
        doc.text(NOMBRE_EMPRESA, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(NOMBRE_EMPRESA)+5);
        doc.text(data.NOMBRE_EMPRESA , marginLeft, margintop);

        await   doc.save(`TICKED DE PAGO DE ${data.NOMBRE_CLIENTE}.pdf`)
       // let content = { startY: 50, head: headers, body: data };NOMBRE_PERSONAL

      };
  exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const title =(this.state.data)?this.state.data.NOMBRES_CLIENTE+' '+this.state.data.APELLIDOS_CLIENTE:"";
    const headers = [
      [
        "No",
        "NO_CUOTA",
        "MORA",
        "INTERES",
        "CAPITAL",
        "MONTO_PAGO",
        "TOTAL_CUOTA",
        "FECHA DE PAGO",
        "PAGO REALIZADO",
        "CUOTA_PAGADA",
        "USUARIO",
      ],
    ];
    if (this.state.dataReporte) {
      let data = this.state.dataReporte.map((rows, index) => [
        index + 1,
        rows.NO_CUOTA,
        rows.MORA,
        rows.INERES,
        rows.CAPITAL,
        rows.MONTO_PAGO,
        rows.TOTAL_CUOTA,
        rows.FECHA_DE_PAGO_CUOTA,
        rows.FECHA_PAGO,
       rows.CUOTA_PAGADA,
        rows.NOMBRE_USUARIO_PAGO,
      ]);
      let content = { startY: 50, head: headers, body: data };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(
        `REPORTE ESTADO DE CUENTA ${(this.state.data)?this.state.data.NOMBRES_CLIENTE+' '+this.state.data.APELLIDOS_CLIENTE:""} .pdf`
      );
    } else {
      alert("NO EXISTE INFORMACIÓN PARA EXPORTAR EN PDF");
    }
  };
  BuscarPago = async (id) => {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/ByEstadoDeCuenta/${id}`
    );

    
    if (data) {
      if (data.data) {
        data.data.map((item) => {
          item.TOTAL_RESTANTE=(item.TOTAL_CUOTA-item.MONTO_PAGO).toFixed(2);
          item.MONTO_PAGO=(item.TOTAL_CUOTA-item.MONTO_PAGO).toFixed(2);

            item.MORA_RESTANTE=item.MORA_RESTANTE.toFixed(2);
            item.INTERES_RESTANTE=item.INTERES_RESTANTE.toFixed(2);
            item.CAPITAL_RESTANTE=item.CAPITAL_RESTANTE.toFixed(2);

          item.FECHA_PAGO = (item.FECHA_PAGO!=null)?new Date(
            item.FECHA_PAGO
          ).toLocaleString():null
        });

        await this.setState({ dataPagos: data.data });

      } else {
        this.ClearState();
        alert("NO SE ENCONTRO NINGUN PAGO");
      }
    } else {
      this.ClearState();
      alert("NO SE ENCONTRARON DATOS VERIFICAR CON SOPORTE");
    }
  };
  BuscarEstado = async () => {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/ESTADO_CUENTA_CLIENTE/ByCLIENTE/${this.props.match.params.id}`
    );

    if (data) {
      if (data.data) {
        if(data.data.length==0) {await this.setState({RecargaForzada:false}); }else{
          await this.setState({tabla:true});        }
        data.data.map((item) => {
          item.FECHA_ENCUESTA = new Date(item.FECHA_ENCUESTA).toLocaleString();
          item.FECHA_DE_PAGO_CUOTA = new Date(
            item.FECHA_DE_PAGO_CUOTA
          ).toLocaleString();
            item.MORA=item.MORA.toFixed(2);
            item.INTERES=item.INTERES.toFixed(2);
            item.CAPITAL=item.CAPITAL.toFixed(2);
            item.MORA_RESTANTE_REAL=item.MORA_RESTANTE.toFixed(2);
            item.INTERES_RESTANTE_REAL=item.INTERES_RESTANTE.toFixed(2);
            item.CAPITAL_RESTANTE_REAL=item.CAPITAL_RESTANTE.toFixed(2);
            item.MONTO_PAGO_REAL=(item.TOTAL_CUOTA-item.MONTO_PAGO).toFixed(2);
            item.TOTAL_RESTANTE_REAL=(item.TOTAL_CUOTA-item.MONTO_PAGO).toFixed(2);


            item.TOTAL_RESTANTE=(item.TOTAL_CUOTA-item.MONTO_PAGO).toFixed(2);
            item.MONTO_PAGO_RESTANTE=(item.TOTAL_CUOTA-item.MONTO_PAGO).toFixed(2);

              item.MORA_RESTANTE=item.MORA_RESTANTE.toFixed(2);
              item.INTERES_RESTANTE=item.INTERES_RESTANTE.toFixed(2);
              item.CAPITAL_RESTANTE=item.CAPITAL_RESTANTE.toFixed(2);


          item.FECHA_PAGO_REAL = (item.FECHA_PAGO_REAL!=null)?new Date(
            item.FECHA_PAGO_REAL
          ).toLocaleString():null
          item.FECHA_PAGO = (item.FECHA_PAGO!=null)?new Date(
            item.FECHA_PAGO
          ).toLocaleString():null
        });


        let totalPagado=0;
        let totalNoPagado=0;
        let saldos = 0;
       let  MontoP= 0;


        await data.data.map((item)=>{

      switch(item.CUOTA_PAGADA){
           case true:
            item.CUOTA_PAGADA="PAGADO";
             break;
             case false:
            item.CUOTA_PAGADA="NO PAGADO";
              break;
              default:
                item.CUOTA_PAGADA="";
                break;

      }

      totalPagado=totalPagado+item.MONTO_PAGO;
      totalNoPagado=totalNoPagado+item.TOTAL_CUOTA;
          saldos = saldos+item.MONTO_PAGO;




        })
        totalNoPagado=totalNoPagado-totalPagado;
       saldos= totalNoPagado+saldos;







        data.data.push({
          No:"",
          NO_CUOTA:"PAGADO",
          MORA:"",
          INTERES:"",
          CAPITAL:"",
          MONTO_PAGO:"",
          TOTAL_CUOTA:totalPagado.toFixed(2),
          FECHA_DE_PAGO_CUOTA:"",
          FECHA_PAGO:"",
          CUOTA_PAGADA:"",
          NOMBRE_USUARIO_PAGO:"",
        })

        data.data.push({
          No:"",
          NO_CUOTA:"NO PAGADO",
          MORA:"",
          INTERES:"",
          CAPITAL:"",
          MONTO_PAGO:"",
          TOTAL_CUOTA:totalNoPagado.toFixed(2),
          FECHA_DE_PAGO_CUOTA:"",
          FECHA_PAGO:"",
          CUOTA_PAGADA:"",
          NOMBRE_USUARIO_PAGO:"",
        })
await this.setState({totalesCuota:{
  totalPagado, totalNoPagado, saldos
}})


        await this.setState({ dataReporte: data.data });
       if(this.state && this.state.dataReporte){
        let CSV = this.state.dataReporte.map((rows, index) => ({
          No: index + 1,
          NO_CUOTA: rows.NO_CUOTA,
          MORA: rows.MORA,
          INTERES: rows.INTERES,
          CAPITAL: rows.CAPITAL,
          MONTO_PAGO: rows.MONTO_PAGO,
          TOTAL_CUOTA: rows.TOTAL_CUOTA,
          FECHA_DE_PAGO_CUOTA: rows.FECHA_DE_PAGO_CUOTA,
          FECHA_PAGO: rows.FECHA_PAGO,
          CUOTA_PAGADA:   rows.CUOTA_PAGADA,
          NOMBRE_USUARIO_PAGO: rows.NOMBRE_USUARIO_PAGO,
        }));
        this.setState({ CSV: CSV });
       }
      } else {
        this.ClearState();
        alert("NO SE ENCONTRARON DATOS PARA GENERAR EL REPORTE");
      }
    } else {
      this.ClearState();
      alert("NO SE ENCONTRARON DATOS PARA GENERAR EL REPORTE");
    }
  };
  SubirCrear = async (e) => {
    e.preventDefault();
    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/AGREGAR_CUOTA_RUTA`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
  await  this.setState({
      file: {},
      subir: false,
      subirBanco: false,
      crear: false,
      modificar: false,
      tabla: false,
      tablaPagos: true,
    });
    await this.BuscarPago(this.state.custom.ID_ESTADO_CUENTA_CLIENTE)
  };
  SubirCrearBanco = async (e) => {
    e.preventDefault();
    const data = await fetchPostFormData(
      `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/AGREGAR_CUOTA_RUTA_BANCO`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
  await  this.setState({
      file: {},

      subir: false,
      subirBanco: false,
      crear: false,
      modificar: false,
      tabla: false,
      tablaPagos: true,
    });
    await this.BuscarPago(this.state.custom.ID_ESTADO_CUENTA_CLIENTE)
  };

  async componentDidMount() {


    const CLIENTE = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/CLIENTE/Find/${this.props.match.params.id}`
    );
    await this.setState({
      data: { ...this.state.data, ...CLIENTE.data },
      custom: { ...this.state.custom, ...CLIENTE.data },
    });

    const BANCO = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/Banco/ALL`
    );
    await this.setState({
      data: { ...this.state.data, BANCO:BANCO.data },
    });
await this.BuscarEstado();

  }
  ShowSubirContrato = async (e) => {
    this.setState({ custom: e });
    // const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    // this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });

    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });


await    this.setState({ crear: false, modificar: false, tabla: false, tablaPagos: false, subir: true, });

  };

  ShowSubirContratoBanco = async (e) => {
    this.setState({ custom: e });
    // const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    // this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });

    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });


await    this.setState({ crear: false, modificar: false, tabla: false,subir: false,tablaPagos: false,  subirBanco: true, });
  };

validarDatoDecimal=(value)=>{
if(value){
  return   parseFloat(parseFloat(value).toFixed(2))
}else{
  return 0
}



}

 VerContrato = async (e) => {

    if(e.RUTA_BOLETA_REAL!=null)
    {

    await this.setState({ custom: e });

    const data = await fetchPostFile(
    `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/descargar_boleta`,this.state.custom
    );
    await this.setState({
  fileNuevo: data,
})

const href = window.URL.createObjectURL(await this.state.fileNuevo.blob());
const link = document.createElement('a');
link.href = href;
// link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.pdf`); //or any other extension
link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();

await this.setState({ custom: {} });

}
else{
  alert('NO A SUBIDO DOCUMENTO')
}
  };
  VerContratoBanco = async (e) => {

    if(e.RUTA_BOLETA_BANCO_REAL!=null)
    {

    await this.setState({ custom: e });

    const data = await fetchPostFile(
    `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/descargar_boleta_banco`,this.state.custom
    );
    await this.setState({
  fileNuevo: data,
})

const href = window.URL.createObjectURL(await this.state.fileNuevo.blob());
const link = document.createElement('a');
link.href = href;
// link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.pdf`); //or any other extension
link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();

await this.setState({ custom: {} });

}
else{
  alert('NO A SUBIDO DOCUMENTO')
}
  };
 DescargarContrato = async (e) => {

    if(e.RUTA_BOLETA_REAL!=null)
    {

    await this.setState({ custom: e });

    const data = await fetchPostFile(
    `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/descargar_boleta`,this.state.custom
    );
    await this.setState({
  fileNuevo: data,
})


var archivo=await this.state.fileNuevo.blob()
const href = window.URL.createObjectURL(archivo);
const link = document.createElement('a');
link.href = href;
 //link.setAttribute('download', `${e.NOMBRE_COMPLETO} CONTRATO.pdf`); //or any other extension
link.setAttribute('download', `${e.NOMBRE_CLIENTE} BOLETA CUOTA NO ${e.NO_CUOTA}.${archivo.type.split('/')[1]}`); //or any other extension

//link.setAttribute('target', '_blank'); //or any other extension

document.body.appendChild(link);
link.click();

await this.setState({ custom: {} });

}
else{
  alert('NO A SUBIDO DOCUMENTO')
}
  };
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: {
        ...this.state.custom,
        ID_USUARIO: ID_USUARIO,
        ID_CLIENTE: this.state.data.ID_CLIENTE,
      },
    });

    const ID_SUCURSAL=this.props.auth[0].ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });

    const ID_EMPRESA=this.props.auth[0].ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });

    const ID_BANCO = await this.state.custom.BANCO.ID_BANCO;
    await this.setState({
      custom: { ...this.state.custom, ID_BANCO: ID_BANCO },
    });
    const CUENTA = await this.state.custom.CUENTA;
    await this.setState({
      custom: { ...this.state.custom, ID_CUENTA: CUENTA.ID_CUENTA,NO_CUENTA:CUENTA.NO_CUENTA },
    });


  };
  UpdateStateCreate = async(e) => {
    
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            file: { ...this.state.file, [name]: e.target.result },
          });
        };
      await  this.setState({ custom: { ...this.state.custom, [name]: e.target.files[0] } });
      } else {
        const { name, value } = e.target;
        await   this.setState({ custom: { ...this.state.custom, [name]: value } });
        if(name=="MONTO_PAGO_RESTANTE")
        {
          if(value){

let MORA_RESTANTE=this.validarDatoDecimal(this.state.custom.MORA_RESTANTE_REAL);
let INTERES_RESTANTE=this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE_REAL);
let CAPITAL_RESTANTE=this.validarDatoDecimal(this.state.custom.CAPITAL_RESTANTE_REAL);
let MONTO_PAGO_RESTANTE=this.validarDatoDecimal(this.state.custom.MONTO_PAGO_RESTANTE);



MONTO_PAGO_RESTANTE=MONTO_PAGO_RESTANTE-MORA_RESTANTE;

if(MONTO_PAGO_RESTANTE>0){

MONTO_PAGO_RESTANTE=MONTO_PAGO_RESTANTE-INTERES_RESTANTE;
  if(MONTO_PAGO_RESTANTE>0){
MONTO_PAGO_RESTANTE=MONTO_PAGO_RESTANTE-CAPITAL_RESTANTE;

    if(MONTO_PAGO_RESTANTE>0){
    }
    else{

      CAPITAL_RESTANTE=this.validarDatoDecimal(this.state.custom.MONTO_PAGO_RESTANTE)-(this.validarDatoDecimal(this.state.custom.MORA_RESTANTE_REAL)+this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE_REAL));
    }
  }
  else{
    INTERES_RESTANTE=this.validarDatoDecimal(this.state.custom.MONTO_PAGO_RESTANTE)-this.validarDatoDecimal(this.state.custom.MORA_RESTANTE_REAL);
    CAPITAL_RESTANTE=0;
  }
}
else{
  MORA_RESTANTE=this.validarDatoDecimal(this.state.custom.MONTO_PAGO_RESTANTE);
  INTERES_RESTANTE=0;
  CAPITAL_RESTANTE=0;
}









            await this.setState({
              custom:{
                ...this.state.custom,
                MORA_RESTANTE:MORA_RESTANTE.toFixed(2),INTERES_RESTANTE:INTERES_RESTANTE.toFixed(2),CAPITAL_RESTANTE:CAPITAL_RESTANTE.toFixed(2)

              }
            })
          }
        }
        if(name=="CAPITAL_RESTANTE")
        {
          if(value){

            await this.setState({
              custom:{
                ...this.state.custom,MONTO_PAGO_RESTANTE:this.validarDatoDecimal(this.state.custom.CAPITAL_RESTANTE)+this.validarDatoDecimal(this.state.custom.MORA_RESTANTE)+this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE)
              }
            })
          }
          else{
            await this.setState({
              custom:{
                ...this.state.custom,MONTO_PAGO_RESTANTE:this.validarDatoDecimal(this.state.custom.MORA_RESTANTE)+this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE)
              }
            })
          }
        }


        if(name=="MORA_RESTANTE")
        {
          if(value){

            await this.setState({
              custom:{
                ...this.state.custom,MONTO_PAGO_RESTANTE:this.validarDatoDecimal(this.state.custom.CAPITAL_RESTANTE)+this.validarDatoDecimal(this.state.custom.MORA_RESTANTE)+this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE)
              }
            })
          }
          else{
            await this.setState({
              custom:{
                ...this.state.custom,MONTO_PAGO_RESTANTE:this.validarDatoDecimal(this.state.custom.CAPITAL_RESTANTE)+this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE)
              }
            })
          }
        }

        if(name=="INTERES_RESTANTE")
        {
          if(value){

            await this.setState({
              custom:{
                ...this.state.custom,MONTO_PAGO_RESTANTE:this.validarDatoDecimal(this.state.custom.CAPITAL_RESTANTE)+this.validarDatoDecimal(this.state.custom.MORA_RESTANTE)+this.validarDatoDecimal(this.state.custom.INTERES_RESTANTE)
              }
            })
          }
          else{
            await this.setState({
              custom:{
                ...this.state.custom,MONTO_PAGO_RESTANTE:this.validarDatoDecimal(this.state.custom.MORA_RESTANTE)+this.validarDatoDecimal(this.state.custom.CAPITAL_RESTANTE)
              }
            })
          }
        }

      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      await this.setState({ custom: { ...this.state.custom, [name]: value } });
      if(name=="BANCO" )
      {
        if(value){
        const CUENTA = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/CUENTA/FindByBanco/${value.ID_BANCO}`
        );
       await this.setState({ data: { ...this.state.data, CUENTA: CUENTA.data } });
      }else{
       await this.setState({ data: { ...this.state.data, CUENTA:[]} });
      }
    }
    } else {
      const datos = e.split("|");
      await   this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }


    await this.setState({
      custom:{
        ...this.state.custom,
        TOTAL_CUOTA:
        parseFloat(this.state.custom.MORA? this.state.custom.MORA:0)+
        parseFloat(this.state.custom.INTERES? this.state.custom.INTERES:0)+
        parseFloat(this.state.custom.CAPITAL? this.state.custom.CAPITAL:0)
      }
    })
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();

    await this.setState({
      custom:{
        ...this.state.custom,
        MONTO_PAGO:this.state.custom.MONTO_PAGO_RESTANTE,
        MORA:this.state.custom.MORA_RESTANTE,
        INTERES:this.state.custom.INTERES_RESTANTE,
        CAPITAL:this.state.custom.CAPITAL_RESTANTE,

      }
    })
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/CREATE`,
      this.state.custom
    );
    alert(data.message);
    await this.BuscarEstado();


    await this.BuscarPago(this.state.custom.ID_ESTADO_CUENTA_CLIENTE)



    // await this.exportTicket(data.datosResult.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE)
    const datoBusqueda = await this.state.dataPagos.find(x => x.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE == data.datosResult.ID_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE)
    await this.imprimirComponente2(datoBusqueda)



    await this.setState({
      file: {},
      crear: false,
      subir: false,
      modificar: false,
      tabla: true,
      tablaPagos: false,
    });
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });

    await this.setState({ crear: true,
      subir: false,
       modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false,
      subir: false,
      subirBanco: false,
      modificar: false, tabla: true, tablaPagos: false });
  };
  ShowUpdate = async (e) => {

    if(e.CUOTA_PAGADA!=="PAGADO" && e.CUOTA_PAGADA!=="")
    {
      await this.setState({ custom: e });
      await this.setState({
        custom: { ...this.state.custom, FECHA_PAGO: new Date()},
        modificarDatos:true,
      });

      await this.setState({ custom: { ...this.state.custom } });

      this.setState({ crear: false, modificar: true, tabla: false });
    }else{

      alert("LA CUOTA YA A SIDO PAGADA")
    }


  };

  ShowUpdateManual = async (e) => {

    if(e.CUOTA_PAGADA!=="PAGADO" && e.CUOTA_PAGADA!=="")
    {
      await this.setState({ custom: e });
      await this.setState({
        custom: { ...this.state.custom, FECHA_PAGO: new Date()},
        modificarDatos:false
      });

      await this.setState({ custom: { ...this.state.custom } });

      this.setState({ crear: false, modificar: true, tabla: false });
    }else{

      alert("LA CUOTA YA A SIDO PAGADA")
    }

  };
  ShowPagosTable = async (e) => {
await this.setState({custom:e})

  await this.BuscarPago(e.ID_ESTADO_CUENTA_CLIENTE);

    await this.setState({ crear: false, modificar: false, tabla: false,tablaPagos: true });


    if(e.ID_CONTRATO){
      const FindLotes = await fetchGet(
        `${process.env.REACT_APP_SERVER}/api/contrato/FindLotes/${e.ID_CONTRATO}`
        );
        
        await this.setState({ data:{
            ...this.state.data, LotesContrato:FindLotes.data

        }
        });
      }else
      {
      await this.setState({
        data: {
          ...this.state.data, LotesContrato: null
        }
      });
      }


  };
  ShowPagosTableSimple = async (e) => {
    await this.setState({custom:e})
    
        await this.setState({ crear: false, modificar: false, tabla: false,tablaPagos: true,subir:false,subirBanco:false });

      };
  RegresarAnterior = (e) => {
    this.props.history.goBack();
  };
  RegresaTabla = async(e) => {
    await  this.setState({
      file: {},
      custom: {},
      subir: false,
      subirBanco: false,
      crear: false,
      modificar: false,
      tabla: true,
      tablaPagos: false,
      dataPagos: [],
    });
  };
  render() {
    const redireccion = this.props.Access("READ_ESTADO_CUENTA_CLIENTE") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    const RecargaForzada = this.state.RecargaForzada ? (
      ""
    ) : (
      <Redirect to={`/Generar_Cuotas_Simples/${this.props.match.params.id}`} />
    );
    return (
      <Fragment>
        <div id="imprimible" style={{display: 'none'}} >
          <Container >
            <Row className="Total">
              <Col className="Contenedor_imagen"><img className="img_Principal" src={this.state.empresas.logoEmpresa} alt="" /></Col>
              <Col>
                <div className="encabezado_recibo">
                  <h3>recibo de caja</h3>
                  <h6>No.{this.state.DatosTicket.id}</h6>
                </div>

                <div class="form-group row ContenedorRecibo">
                  <label class="col-sm-3 col-form-label">POR Q.</label>
                  <div class="col-sm-9" >
                    <input value={this.state.DatosTicket.MONTO_PAGO_PAGO} class="form-control itemRecibo" />
                  </div>
                  <label class="col-sm-3 col-form-label">LOTE NO.</label>
                  <div class="col-sm-9">
                    <input class="form-control itemRecibo" value={(this.state.data && this.state.data.LotesContrato)?this.state.data.LotesContrato.map((item) =>{return item.NO_LOTE}).join(' , '):" NO POSEE CONTRATO" } />
                  </div>
                  <label class="col-sm-3 col-form-label">MANZANA</label>
                  <div class="col-sm-9">
                    <input class="form-control itemRecibo" value={(this.state.data && this.state.data.LotesContrato) ? this.state.data.LotesContrato.map((item2) => {  return item2.NOMBRE_MANZANA}).join(' , '):"NO POSEE CONTRATO"} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="Total">
              <Col>
                <div class="form-group row ContenedorRecibo">
                  <label class="col-sm-2 col-form-label ">RECIBIMOS DE: </label>
                  <div class="col-sm-10">
                    <input value={this.state.DatosTicket.NOMBRE_CLIENTE} class="form-control itemRecibo" />
                  </div>
                  <label class="col-sm-2 col-form-label">LA CANTIDAD DE: </label>
                  <div class="col-sm-10">
                    <input value={this.state.DatosTicket.MONTO_PAGO_LETRAS} class="form-control itemRecibo" />
                  </div>
                  <label class="col-sm-2 col-form-label">POR CONCEPTO: </label>
                  <div class="col-sm-10">
                    <input value={'Cuota No. '+this.state.DatosTicket.NO_CUOTA} class="form-control itemRecibo" />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="Total">
              <div class="col-6 ">
                <div class="form-group row ">
                  <label class="col-sm-4 col-form-label">SALDO ANTERIOR</label>
                  <div class="col-sm-8">
                  <input value={"-----------"} class="form-control itemRecibo" />
                    {/* <input value={this.state.DatosTicket.SaldoNoPagado} class="form-control itemRecibo" /> */}
                  </div>
                  <label class="col-sm-4 col-form-label">ABONO DE HOY</label>
                    <div  class="col-sm-8">
                      <input value={this.state.DatosTicket.MONTO_PAGO_PAGO} class="form-control itemRecibo" />
                  </div>
                  <label class="col-sm-4 col-form-label">SALDO ACTUAL</label>
                  <div class="col-sm-8">
                  <input value={"-----------"} class="form-control itemRecibo" />
                    {/* <input value={this.state.DatosTicket.SaldoPagado} class="form-control itemRecibo" /> */}
                  </div>
                </div>
                </div>

              <div class="col-6">
                <div class="form-row ">
                  <div class="col-sm-4">
                    <label class="form-label">DEPOSITO NO.</label>
                    <input value={this.state.DatosTicket.NO_BOLETA} class="form-control itemRecibo" />
                  </div>
                  <div class="col-sm-4">
                    <label class="form-label">BANCO</label>
                    <input value={this.state.DatosTicket.NOMBRE_BANCO} class="form-control itemRecibo" />
                  </div>
                  <div class="col-sm-4">
                    <label class="form-label">VALOR</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text itemRecibo" id="basic-addon1">Q.</span>
                      <input value={this.state.DatosTicket.MONTO_PAGO_PAGO} class="form-control itemRecibo" aria-describedby="basic-addon1" />
                    </div>
                  </div>
                </div>
                </div>


              <div className="col-8">
                <div class="form-row">
                  <label class="col-sm-5 col-form-label ">ORATORIO SANTA ROSA</label>
                  <div class="col-sm-7 ">
                    <input value={this.state.DatosTicket.FECHA_PAGO_SEPARADO} class="form-control itemRecibo" />
                  </div>

                </div>
              </div>
              <div className="col-4">
                <div class="col-sm-12">
                  <input class="form-control itemRecibo" value={this.state.DatosTicket.USUARIO_PAGO_DETALLE}/>
                  <label class="form-label">RECIBIDO POR</label>
                </div>
              </div>


            </Row>





          </Container>
        </div>
        {redireccion}
        {RecargaForzada}
        {this.state.subir && (
 <Fragment>
    <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">SUBIR BOLETA RECIBO</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowPagosTableSimple}
                />{" "}
                <ButtonG
                  config={BackupIcon}
                  className="m-3"
                  onClick={this.SubirCrear}
                />

              </div>
            }
            allcolumns={[
              {
                type: "I",
                name: "RUTA_BOLETA",
                value: this.state.file.RUTA_BOLETA,
                label: "SUBIR BOLETA",
              },
            ]}
          ></FormsG>
 </Fragment>
        )}
          {this.state.subirBanco && (
 <Fragment>
    <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">SUBIR BOLETA BANCO</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowPagosTableSimple}
                />{" "}
                <ButtonG
                  config={BackupIcon}
                  className="m-3"
                  onClick={this.SubirCrearBanco}
                />
              </div>
            }
            allcolumns={[
              {
                type: "I",
                name: "RUTA_BOLETA_BANCO",
                value: this.state.file.RUTA_BOLETA_BANCO,
                label: "SUBIR BOLETA",
              },
            ]}
          ></FormsG>
 </Fragment>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>

                <h1 className="text-center">CREAR PAGO </h1>
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                   <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.RegresarAnterior}
                />
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Update}
                />


              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_CLIENTE",
                value: this.state.data.NOMBRES_CLIENTE+' '+this.state.data.APELLIDOS_CLIENTE,
                label: "NOMBRE",
                readOnly:true
              },
              {
                config: Number,
                type: "G",
                name: "NO_CUOTA",
                value: this.state.custom.NO_CUOTA,
                label: "NO_CUOTA",  readOnly:true
              },
              {
                config: Number,
                type: "G",
                name: "MORA_RESTANTE",
                value: this.state.custom.MORA_RESTANTE,
                label: "MORA_RESTANTE",  readOnly:this.state.modificarDatos
              },
              {
                config: Number,
                type: "G",
                name: "INTERES_RESTANTE",
                value: this.state.custom.INTERES_RESTANTE,
                label: "INTERES_RESTANTE",  readOnly:this.state.modificarDatos
              },
              {
                config: Number,
                type: "G",
                name: "CAPITAL_RESTANTE",
                value: this.state.custom.CAPITAL_RESTANTE,
                label: "CAPITAL_RESTANTE",  readOnly:this.state.modificarDatos
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_PAGO_RESTANTE",
                value: this.state.custom.MONTO_PAGO_RESTANTE,
                label: "MONTO_PAGO",  readOnly:false
              },
              {
                config: Number,
                type: "G",
                name: "TOTAL_RESTANTE",
                value: this.state.custom.TOTAL_RESTANTE,
                label: "TOTAL_RESTANTE",  readOnly:true
              },
              {
                config: Number,
                type: "G",
                name: "NO_BOLETA",
                value: this.state.custom.NO_BOLETA,
                label: "NO BOLETA",
              },
              {
                type: "A",
                options: this.state.data.BANCO,
                OptionLabel: "NOMBRE_BANCO",
                placeholder: "SELECCIONE BANCO",
                name: "BANCO",
                value: this.state.custom.BANCO,
                label: "BANCO",
              },
              {
                type: "A",
                options: this.state.data.CUENTA,
                OptionLabel: "NOMBRE_CUENTA",
                placeholder: "SELECCIONE CUENTA",
                name: "CUENTA",
                value: this.state.custom.CUENTA,
                label: "CUENTA",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_PAGO",
                value: this.state.custom.FECHA_PAGO,
                label: "FECHA PAGO",
                readOnly:true,

              },
            ]}
          ></FormsG>
        )}

        {this.state.tabla && (
          <TableG
          configBit={ACTIVO_GENERICO}
          customToolbar={
            <Fragment>

              <Fragment>

                <ExcelFile
                  element={<ButtonG config={ExcelIcon} />}
                  filename={`REPORTE ${(this.state.data)?this.state.data.NOMBRES_CLIENTE+' '+this.state.data.APELLIDOS_CLIENTE:""}`}
                >

                  <ExcelSheet data={this.state.CSV} name={(this.state.data)?this.state.data.NOMBRES_CLIENTE+' '+this.state.data.APELLIDOS_CLIENTE:""}>

                    <ExcelColumn label="No" value="No" />
                    <ExcelColumn label="NO_CUOTA" value="NO_CUOTA" />
                    <ExcelColumn label="MONTO_PAGO" value="MONTO_PAGO" />
                    <ExcelColumn label="TOTAL_CUOTA" value="TOTAL_CUOTA" />
                    <ExcelColumn
                      label="FECHA PAGO"
                      value="FECHA_DE_PAGO_CUOTA"
                    />
                    <ExcelColumn
                      label="PAGO REALIZADO"
                      value="FECHA_PAGO"
                    />
                    <ExcelColumn label="CUOTA_PAGADA" value="CUOTA_PAGADA" />
                    <ExcelColumn
                      label="USUARIO"
                      value="NOMBRE_USUARIO_PAGO"
                    />
                  </ExcelSheet>
                </ExcelFile>
              </Fragment>
              <ButtonG config={PDFIcon} onClick={this.exportPDF} />
            </Fragment>
          }
            title={`ESTADO DE CUENTA ${(this.state.dataReporte && this.state.dataReporte[0])?this.state.dataReporte[0].NOMBRE_CLIENTE.toUpperCase():'' }`}
            data={this.state.dataReporte}
            responsive="vertical"
            allcolumns={[

              {
                name: "PAGAR",
                type: "button",
                display: this.props.Access("INSERT_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE") ? true : "excluded",
                buttonConfig: AttachMoneyIcon,
                OnClickButton: this.ShowUpdate,
              },
              {
                name: "PAGAR MANUAL",
                type: "button",
                display: this.props.Access("INSERT_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE_MANUAL") ? true : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdateManual,
              },
              {
                name: "VER PAGOS",
                type: "button",
                display: this.props.Access("READ_PAGO_ESTADO_CUENTA_CLIENTE_DETALLE_MANUAL")
                  ? true
                  : "excluded",
                buttonConfig: AsingacionIcon,
                OnClickButton: this.ShowPagosTable,
              },
              { name: "NO_CUOTA",
              label: "NO_CUOTA",
              display: true,
              type: "number" },
              {
                name: "MONTO_PAGO",
                label: "MONTO",
                display: true,
                type: "number",
              },
              {
                name: "TOTAL_CUOTA",
                label: "TOTAL_CUOTA",
                display: true,
                type: "number",
              },
              {
                name: "FECHA_DE_PAGO_CUOTA",
                label: "FECHA DE PAGO",
                display: true,
                type: "text",
              },
              {
                name: "CUOTA_PAGADA",
                label: "CUOTA_PAGADA",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_DE_CREACION_CUOTA",
                label: "FECHA DE CREACION",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: false,
                type: "text",
              },
              {
                name: "FECHA_PAGO_REAL",
                label: "PAGO REALIZADO",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_USUARIO_PAGO",
                label: "USUARIO",
                display: false,
                type: "text",
              },

            ]}
          />
        )}
         {this.state.tablaPagos && (
          <TableG
          configBit={ACTIVO_GENERICO}
          customToolbar={
            <Fragment>
              <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.RegresaTabla}
                />
            </Fragment>
          }
            title={`PAGOS ESTADO DE CUENTA ${(this.state.dataPagos && this.state.dataPagos[0])?this.state.dataPagos[0].NOMBRE_CLIENTE.toUpperCase():'' }`}
            data={this.state.dataPagos}
            responsive="vertical"
            allcolumns={[
            {
                name: "SUBIR BOLETA",
                type: "button",
                display: this.props.Access("READ_SUBIR_BOLETA_CUOTA_BANCO")
                  ? true
                  : "excluded",
                buttonConfig: BackupIcon,
                OnClickButton: this.ShowSubirContratoBanco,
              },
              {
                name: "VER BOLETA",
                type: "button",
                display: this.props.Access("READ_VER_BOLETA_CUOTA_BANCO")
                  ? true
                  : "excluded",
                buttonConfig: DesktopWindowsIcon,
                OnClickButton: this.VerContratoBanco,
              },
              {
                name: "SUBIR RECIBO",
                type: "button",
                display: this.props.Access("READ_SUBIR_BOLETA_CUOTA")
                  ? true
                  : "excluded",
                buttonConfig: BackupIcon,
                OnClickButton: this.ShowSubirContrato,
              },
              {
                name: "DESCARGAR RECIBO",
                type: "button",
                display: this.props.Access("READ_DOWLOADER_BOLETA_CUOTA")
                  ? true
                  : "excluded",
                buttonConfig: CloudDownloadIcon,
                OnClickButton: this.DescargarContrato,
              },
              {
                name: "VER RECIBO",
                type: "button",
                display: this.props.Access("READ_VER_BOLETA_CUOTA")
                  ? true
                  : "excluded",
                buttonConfig: DesktopWindowsIcon,
                OnClickButton: this.VerContrato,
              },
               {
                 name: "REIMPRIMIR",
                 type: "button",
                 display: this.props.Access("READ_VER_BOLETA_CUOTA")
                   ? true
                   : "excluded",
                 buttonConfig: DesktopWindowsIcon,
               OnClickButton: this.imprimirComponente2,
               },

              {
                name: "SUBIDO",
                label: "SUBIDO",
                display: true,
                type: "text",
              },

              { name: "NO_CUOTA",
              label: "NO_CUOTA",
              display: true,
              type: "number" },
              {
                name: "MORA_PAGO",
                label: "MORA",
                display: true,
                type: "number",
              },
              {
                name: "INTERES_PAGO",
                label: "INTERES",
                display: true,
                type: "number",
              },
              {
                name: "CAPITAL_PAGO",
                label: "CAPITAL",
                display: true,
                type: "number",
              },
              {
                name: "MONTO_PAGO_PAGO",
                label: "MONTO",
                display: true,
                type: "number",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: false,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: false,
                type: "text",
              },
              {
                name: "FECHA_PAGO",
                label: "PAGO REALIZADO",
                display: true,
                type: "text",
              },
              {
                name: "USUARIO_PAGO_DETALLE",
                label: "USUARIO",
                display: true,
                type: "text",
              },

            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(ASIG);
