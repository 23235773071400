import React, { Component, Fragment } from "react";
import { fetchGet, fetchDelete } from "../../../utils/Fetch";
import { Link, Redirect } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import ButtonG from "../../Material-ui/Input/Button/Button";
import LinkG from "../../Material-ui/Input/Link/Link";
import {
  EliminarIcon,
  ModificarIcon,
  CrearIcon,
} from "../Input/Button/ConfigButton";
import Button from "@material-ui/core/Button";
import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { de } from "date-fns/locale";

const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];

const estadoInicial = { responsive: "vertical", BuscarDatos: "", data: null };

class TableG extends Component {
  constructor(props) {
    super(props);
    this.state = { estadoInicial };
  }

  ColumnButton = (name, display, buttonConfig, OnClickButton) => {
    return {
      name,
      options: {
        display,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ButtonG
              onClick={() => {
                const { data } = this.state;
                const datos = data[dataIndex];
                OnClickButton(datos);
                // data.shift();
                // this.setState({ data });
              }}
              config={buttonConfig}
            />
          );
        },
      },
    };
  };

  // ColumnLink=(name,display,url,Id)=>{
  //   return(
  //     {
  //       name,
  //       options: {
  //         display,
  //         filter: false,
  //         sort: false,
  //         empty: true,
  //         customBodyRenderLite: (dataIndex) => {
  //           return (
  //             <LinkG  name={name} url={()=>url}/>
  //           );
  //         }
  //       }
  //     }
  //   )
  // }

  //#region columns
  ColumnTextFilter = (
    name,
    label,
    display = true,
    filterType = "multiselect",
    filter = true
  ) => {
    return {
      name,
      label,
      options: {
        filter,
        display,
        filterOptions: {
          renderValue: (v) => (v ? v.replace(/^(\w).* (.*)$/, "$1. $2") : ""),
        },
        //display: 'excluded',
        filterType,
        //Choice of filtering view. enum('checkbox', 'dropdown', 'multiselect', 'textField', 'custom')
      },
    };
  };

  ColumnNumberFilter = (name, label, display = true, filter = true) => {
    return {
      name,
      label,
      options: {
        filter,
        display,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && this.state[label + "FilterChecked"]) {
              return [`Min ${label}: ${v[0]}`, `Max ${label}: ${v[1]}`];
            } else if (v[0] && v[1] && !this.state[label + "FilterChecked"]) {
              return `Min ${label}: ${v[0]}, Max ${label}: ${v[1]}`;
            } else if (v[0]) {
              return `Min ${label}: ${v[0]}`;
            } else if (v[1]) {
              return `Max ${label}: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(label, filters) {
            if (filters[0] && filters[1]) {
              return label < filters[0] || label > filters[1];
            } else if (filters[0]) {
              return label < filters[0];
            } else if (filters[1]) {
              return label > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>{label}</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state[label + "FilterChecked"]}
                      onChange={(event) =>
                        this.setState({
                          [label + "FilterChecked"]: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Separar Valores"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    };
  };

  ColumnTextFilterCustom = (
    name,
    label,
    display = true,
    filterType = "multiselect",
    filter = true
  ) => {
    return {
      name,
      label,
      options: {
        filter,
        display,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => v.map((l) => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update");
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = this.state.dataFiltrada;
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item[`${label}`]} value={item[`${label}`]}>
                      <Checkbox
                        color="primary"
                        checked={
                          filterList[index].indexOf(item[`${label}`]) > -1
                        }
                      />
                      <ListItemText primary={item[`${label}`]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    };
  };
  //#endregion columns

  Buscar = async (url, urlSecundary) => {
    const { configBit = ACTIVO_GENERICO } = this.props;

    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/${url}/${urlSecundary}`
    );
    const bits = this.props.allcolumns.filter((x) => x.type === "bit");

    bits.map((item) => {
      data.data.map((dat) => {
        dat[item.name] =
          dat[item.name] === true
            ? configBit[0].NOMBRE_ACTIVO_GENERICO
            : configBit[1].NOMBRE_ACTIVO_GENERICO;
      });
    });

    this.setState({ data: data.data, estado: "Re Activar" });
  };

  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar las props):
    if (this.props.data != prevProps.data) {
      const { configBit = ACTIVO_GENERICO } = this.props;
      const bits = this.props.allcolumns.filter((x) => x.type === "bit");
      let data = this.props.data;
      bits.map((item) => {
        data.map((dat) => {
          dat[item.name] =
            dat[item.name] === true
              ? configBit[0].NOMBRE_ACTIVO_GENERICO
              : configBit[1].NOMBRE_ACTIVO_GENERICO;
        });
      });

      this.setState({ data: data });
    }
  }

  componentDidMount() {
    const {
      url,
      urlSecundary = "ALL",
      data,
      allcolumns,
      responsive,
    } = this.props;

    if (url) {
      this.Buscar(url, urlSecundary);
    }
    if (data) {
      const { configBit = ACTIVO_GENERICO } = this.props;
      const bits = this.props.allcolumns.filter((x) => x.type === "bit");

      bits.map((item) => {
        data.map((dat) => {
          dat[item.name] =
            dat[item.name] === true
              ? configBit[0].NOMBRE_ACTIVO_GENERICO
              : configBit[1].NOMBRE_ACTIVO_GENERICO;
        });
      });

      this.setState({ data: data });
    }
    //#region generar columnas

    // display filterType filter
    this.setState({ responsive });

    if (allcolumns) {
      let columns = allcolumns.map((item) =>
        item.type == "number"
          ? this.ColumnNumberFilter(
              item.name,
              item.label,
              item.display != null ? item.display : true,
              item.filter != null ? item.filter : true
            )
          : item.type == "button"
          ? this.ColumnButton(
              item.name,
              item.display,
              item.buttonConfig,
              item.OnClickButton
            )
          : // (item.type=="link")?
            // this.ColumnLink(item.name,item.display,item.buttonConfig,item.OnClickButton):
            this.ColumnTextFilter(
              item.name,
              item.label,
              item.display != null ? item.display : true,
              item.filterType != null ? item.filterType : "multiselect",
              item.filter != null ? item.filter : true
            )
      );
      this.setState({ columns });
      console.log(columns);
    }

    //#endregion
  }

  Eliminar = (Data) => {
    console.log(Data);
  };

  ActivoReactivo = (e) => {
    e.preventDefault();
    console.log(this.state.estado, "Re Activar");
    if (this.state.estado === "Re Activar") {
      this.Inactivos();
    } else {
      this.Buscar();
    }
  };

  Modificar = (data) => {
    console.log(data);
  };

  Reactivar = async (ID_ACCESO) => {
    const data = await fetchDelete(
      `${process.env.REACT_APP_SERVER}/api/acceso/${ID_ACCESO}/${true}`
    );
    alert(data.message);
    this.Inactivos();
  };

  Inactivos = async () => {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/acceso/inactivo`
    );
    this.setState({ data: data.data, estado: "Activos" });
  };

  cambioEstado = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    //#region opciones
    const options = {
      filterType: "dropdown",
      selectableRows: "none",
      rowsPerPage: 100,
      rowsPerPageOptions: [10, 20, 50, 100, 200],
      customToolbar: () => {
        return this.props.customToolbar;
      },
      responsive: this.state.responsive,
      // "vertical" (default value): In smaller views the table cells will collapse such that the heading is to the left of the cell value.
      // "standard": Table will stay in the standard mode but make small changes to better fit the allocated space.
      // "simple": On very small devices the table rows will collapse into simple display.
      selectableRowsOnClick: false,
      selectableRowsHideCheckboxes: false,
      onRowClick: (rowData, rowState) => {
        // console.log(rowData, rowState,"onRowClick");
      },
      onRowsDelete: (rowsDeleted, newData) => {
        console.log("rowsDeleted");
        console.dir(rowsDeleted);
        console.dir(newData);
        if (
          rowsDeleted &&
          rowsDeleted.data &&
          rowsDeleted.data[0] &&
          rowsDeleted.data[0].dataIndex === 0
        ) {
          window.alert("Can't delete this!");
          return false;
        }
        this.setState({
          data: newData,
          rowsSelected: [],
        });
        console.log(rowsDeleted, "were deleted!");
      },
      textLabels: {
        body: {
          noMatch: "No existen datos",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        pagination: {
          next: "Next Page",
          previous: "Previous Page",
          rowsPerPage: "Rows per page:",
          displayRows: "of",
        },
        toolbar: {
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "View Columns",
          filterTable: "Filter Table",
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Show Columns",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "row(s) Editar",
          delete: "Editar",
          deleteAria: "Editar Selected Rows",
        },
      },
    };
    //#endregion

    return (
      <Fragment>
        <MUIDataTable
          title={this.props.title}
          data={this.state.data}
          columns={this.state.columns}
          options={options}
        />
      </Fragment>
    );
  }
}

export default TableG;
