import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (
      <div id="contratoOriginal">
        <div className="container" id="contenidosContrato">
          <div align="center">
            <img
              id="img-contrato"
              src={this.props.logoEmpresa}
              alt="HaciendaElNaranjo"
            />
          </div>
En aldea el {this.props.PROYECTO.DIRECCION}, municipio de {this.props.PROYECTO.MUNICIPIO}, departamento de {this.props.PROYECTO.DEPARTAMENTO}, 
con fecha veintidós de junio del año dos mil veintiuno. Comparece por una parte el señor: {this.props.EMPRESA.REPRESENTANTE_LEGAL}, 
quien manifiesta ser de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS} años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL},
 {this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD}, {this.props.PROYECTO.REPRESENTANTE_PROFECION}, con domicilio en el departamento de 
 {this.props.PROYECTO.REPRESENTANTE_DEPARTAMENTO_DOMICILIO} y de paso por este departamento, se identifica con Documento Personal de 
 Identificación -DPI- con Código Único de Identificación -CUI- 
 {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.REPRESENTANTE_DPI}) extendido por el Registro Nacional de las 
  Personas de la República de Guatemala, quien actúa en calidad de Gerente de Operaciones y Representante Legal de la entidad 
  denominada {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, calidad que 
acredita con el acta de notarial en la que consta su nombramiento como tal, autorizada en Santa Catarina Pinula, departamento de 
Guatemala, el veintiuno de septiembre de dos mil veinte, por la Notaria, {this.props.EMPRESA.NOTARIA}, documento inscrito en el 
Registro Mercantil al número de registro {this.props.EMPRESA.REPRESENTANTE_REGISTROMERCANTIL_LETRAS} ({this.props.EMPRESA.NOTARIA_REGISTRO_MERCANTIL}), 
folio: {this.props.EMPRESA.REPRESENTANTE_NOTARIAFOLIO_LETRAS} ({this.props.EMPRESA.NOTARIA_FOLIO}) 
del libro: {this.props.EMPRESA.REPRESENTANTE_NOTARIALIBRO_LETRAS} ({this.props.EMPRESA.NOTARIA_LIBRO}) de Auxiliares de Comercio. 
En lo sucesivo se denominará el promitente 
vendedor; y, por la otra parte comparece {this.props.custom.NOMBRE_COMPLETO_CLIENTE}, de {this.props.custom.EDAD_LETRAS}, 
{this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO}, {this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO}, {this.props.custom.PROFECION_CLIENTE_CONTARTO}, 
con domicilio y residencia en el municipio de {this.props.custom.MUNICIPIO_CLIENTE_CONTRATO}, departamento de {this.props.custom.DEPARTAMENTO_CLIENTE_CONTRATO}, 
se identifica con Documento Personal de Identificación -DPI- con Código Único de Identificación -CUI- 
{this.props.custom.DPI_LETRAS} ( {this.props.custom.DPI_CLIENTE_CONTRATO}) extendido por el Registro Nacional de las Personas de la República de 
Guatemala, en lo sucesivo se denominará el promitente comprador. Los comparecientes me aseguran hallarse en el libre ejercicio de 
sus derechos civiles, en español idioma que hablan y entienden me manifiestan que por el presente acto otorgan PROMESA DE COMPRAVENTA 
DE LOTE RÚSTICO PROYECTO {this.props.PROYECTO.NOMBRE} CON FACILIDADES DE PAGO, de conformidad con las siguientes cláusulas: 
PRIMERA: Manifiesta el señor 
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato3">
{this.props.EMPRESA.REPRESENTANTE_LEGAL}, en la calidad con que actúa, que su representada la entidad mercantil denominada 
{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, es legítima propietaria de la finca denominada “{this.props.PROYECTO.NOMBRE}” 
inscrita en el Registro General de la Propiedad identificada con el número: {this.props.custom.FINCA_LETRAS}
({this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO}), folio: {this.props.custom.FOLIO_LETRAS1} 
({this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO}) del libro: {this.props.custom.LIBRO_LETRAS} 
({this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO}) de {this.props.PROYECTO.DEPARTAMENTO}, consistente en 
terreno rústico, mismo que se ubica en Aldea el {this.props.PROYECTO.DIRECCION}, municipio de {this.props.PROYECTO.MUNICIPIO}, 
departamento de  {this.props.PROYECTO.DEPARTAMENTO}, con las medidas y colindancias que constan en su respectiva inscripción registral. 
SEGUNDA: Continúa manifestando el señor {this.props.EMPRESA.REPRESENTANTE_LEGAL}, en la calidad con que actúa, que su representada 
promete en venta a favor del promitente comprador un lote de cuatrocientos ochenta metros cuadrados ({this.props.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO}), 
identificada con el NÚMERO {this.props.custom.LOTE_LETRAS} ({this.props.custom.CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO}) 
MANZANA “{this.props.custom.CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO}” SECTOR {this.props.PROYECTO.SECTOR}. Puede variar la 
numeración en cuanto al lote o manzana, pero no así su ubicación. La presente promesa de venta se regirá mediante las siguientes cláusulas: 
A) PRECIO DE VENTA es de: veinte mil quetzales ({this.props.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL}). 
B) CON UN ENGANCHE DE CINCO MIL QUETZALES ({this.props.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO}). 
C) EL SALDO DE quince mil quetzales ({this.props.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO}) se cancelará en 
{this.props.custom.ANIOS_PAGAR} años es decir {this.props.custom.ANIOS_PAGAR_LETRAS} de seiscientos quetzales con cincuenta y cuatro centavos 
({this.props.custom.CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO}) las cuales darán inicio en fecha 
{this.props.custom.PRIMERCUOTA_LETRAS} y serán depositados; al Banco de {this.props.BANCO.NOMBRECOMPLETO}, ({this.props.BANCO.NOMBRE}) 
al número de cuenta {this.props.BANCO.CUENTA} a nombre de {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, 
la mencionada cuenta puede variar previa notificación por escrito. 
TERCERA: otras condiciones de la negociación: El precio no incluye gastos de escrituración, título de agua, drenaje, 
electricidad, ni pago del Impuesto al Valor Agregado (IVA), ni tasas o contribuciones municipales, en caso que el promitente 
comprador quiera dar por terminada la opción de compra del inmueble, perderá a favor de {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, 
el diez por ciento (10%) del valor total de la propiedad por concepto de arras, más los gastos administrativos equivalentes al catorce por 
ciento (14%), los cuales se 
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato2">
harán efectivos de lo abonado al lote prometido en venta en el presente contrato, la devolución únicamente del capital y no de intereses, 
dicha devolución se realizará conforme las cuotas canceladas, si existiere un saldo a favor del promitente comprador, esperara el tiempo 
necesario que le tome a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, vender el terreno para recibir el saldo restante que el 
promitente comprador pueda tener a su favor. CUARTA: Opción de compraventa: El promitente comprador se obliga a comprar el inmueble en el 
precio, plazo y condiciones especificadas en este documento. QUINTA: El precio y forma de pago: El precio del inmueble es calculado en 
quetzales o su equivalencia en dólares de los Estados Unidos de América para este proyecto, utilizando el tipo de cambio que reporte el 
Banco de Guatemala, si existiere variación considerable, al alza del dólar, podría variar la cuota establecida en el presente contrato, 
la cual será notificada al promitente comprador. SEXTA: Cuota: El promitente comprador se obliga a pagar las cuotas a partir de la fecha 
indicada mensual y consecutivamente, quedando obligado a enviar fotografía de la boleta de pago al número de teléfono {this.props.BANCO.TELEFONO} 
de lo contrario recaerá en mora, la pérdida de la boleta de pago física o electrónica es responsabilidad del promitente comprador, 
obligándose a realizar de nuevo el pago de la cuota mensual, el promitente comprador, declara que los fondos con los que honre cada una de las 
cuotas de la presente promesa serán de legítima procedencia, por lo que libera de toda responsabilidad a 
{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, de no ser cierto lo acá declarado. SÉPTIMA: Pagos extraordinarios: El promitente 
comprador podrá hacer pagos extraordinarios abonados a capital sin restricción alguna, dichos pagos reducen el plazo para la compra 
definitiva, pero no reduce el monto de cuota nivelada, OCTAVA: Recargos por mora: En caso de que el promitente comprador no realice el 
pago correspondiente el día de su vencimiento, tendrá como margen para cancelar su cuota cinco días posteriores a la fecha pactada, 
de lo contrario el promitente comprador tendrá que cancelar un recargo por mora acumulativa del cinco por ciento (5%) sobre el saldo a la fecha. 
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato3">
Si el promitente comprador incumpliere con dos o más cuotas atrasadas se da por terminada la presente negociación sin necesidad de 
notificación de esta, como consecuencia el promitente comprador perderá a favor de {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, 
el diez por ciento (10%) del valor total de la propiedad por concepto de arras, más los gastos administrativos equivalentes al catorce por 
ciento (14%), la devolución se realizará únicamente de capital y no intereses, dicha devolución se realizará conforme las cuotas canceladas, 
si existiere un saldo a favor del promitente comprador. NOVENA: Lugar de pago: El promitente comprador acepta que todos los pagos a que se 
refiere el presente contrato se harán efectivos en moneda de curso legal, sin necesidad de cobro alguno, los recibos se extenderán en la 
oficina ubicada en la casa patronal de dicho proyecto. DÉCIMA: Posesión del terreno: El promitente comprador podrá tomar posesión del 
inmueble, al momento de cancelar la totalidad de la presente promesa de venta. DÉCIMA PRIMERA: El promitente comprador acepta sin necesidad 
de notificación alguna que el promitente vendedor, pueda ceder el presente contrato de promesa de venta, en todo caso al momento de 
suscribir la escritura traslativa de dominio el inmueble deberá encontrarse libre de gravamen y anotaciones. DÉCIMA SEGUNDA: 
Escrituración: La escritura registrada en la que se documente la compraventa se otorgara, estando cancelado el cien por ciento (100%) del 
terreno objeto de la opción, el promitente vendedor, designará al Bufete Jurídico {this.props.BANCO.BUFFETE}, para que faccione la 
escritura de compraventa exclusivamente, los honorarios, impuestos fiscales, gastos de escrituración y registro, serán por cuenta 
del promitente comprador, así como cualquier otro gasto que se designe por este negocio, los cuales tendrán un costo de 
{this.props.PROYECTO.ESCRITURACION} por lote. DÉCIMA TERCERA: Obligación de compra de servicios: El promitente comprador se 
obliga a comprar con la desarrolladora {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, los servicios básicos y urbanización, 
dicha obligación se plasmará en la escritura traslativa de dominio para su cumplimiento. DÉCIMA CUARTA: Limitaciones en el ejercicio 
del derecho: El promitente 
        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato2">
        comprador acepta y se obliga a observar lo siguiente: a) Dar aviso a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, o en su 
        defecto a la desarrolladora {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, antes de construir para que este verifique los 
        mojones y medidas; b) El promitente comprador acepta desde ya, que, si no ha pagado la totalidad del valor del terreno y 
        construye en él, la construcción pertenece a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, sin compensación alguna. 
        DÉCIMA QUINTA: Incumplimiento del Promitente Comprador: Si el promitente comprador incumpliera las obligaciones que adquiere, 
        acepta que {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, puede optar por lo siguiente: demandar ejecutivamente el saldo 
        que hubiera, incluyendo intereses, multas, costos, daños y perjuicios, pudiendo embargar los bienes presentes y futuros del 
        promitente comprador. DÉCIMA SEXTA: Aceptación: Bien enterados de su contenido, objeto, validez y efectos legales, lo aceptan, 
        ratifican y firman.
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col">F______________________________</div>
            <div class="col">F______________________________</div>
          </div>
          <br />
          <br />
          <br />

        </div>{" "}
        <br />
        <div className="container" id="contenidosContrato3">
        En aldea el {this.props.PROYECTO.NOMBRE}, municipio de {this.props.PROYECTO.MUNICIPIO}, departamento de {this.props.PROYECTO.DEPARTAMENTO}, 
        con fecha veintidós de junio del año dos mil veintiuno, yo como Notaria: {this.props.EMPRESA.NOTARIA}, Doy fe, que las firmas que 
        anteceden y calzan PROMESA DE COMPRAVENTA DE LOTE RÚSTICO PROYECTO EL {this.props.PROYECTO.NOMBRE} CON FACILIDADES DE 
        PAGO son AUTENTICAS, por haber sido puestas en mi presencia por el señor {this.props.EMPRESA.REPRESENTANTE_LEGAL}, se identifica con 
        Documento Personal de Identificación -DPI- con Código Único de Identificación -CUI- 
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.REPRESENTANTE_DPI}) extendido por el Registro Nacional de las 
        Personas de la República de Guatemala; y {this.props.custom.NOMBRE_COMPLETO_CLIENTE}, se identifica con Documento 
        Personal de Identificación -DPI- con Código Único de Identificación -CUI- 
        {this.props.custom.DPI_LETRAS} ({this.props.custom.DPI_CLIENTE_CONTRATO}) extendido por el Registro Nacional de las Personas de la 
        República de Guatemala. Previa lectura de la presente Acta de Legalización de firmas contenida en tres únicas hojas de papel bond 
        tamaño oficio, la cual firmo y sello, los comparecientes vuelven a firmar. 
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col">F______________________________</div>
            <div class="col">F______________________________</div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br /> <br />
          ANTE MÍ:
        </div>
      </div>
    );
  }
}
export default withRouter(CONTRATO);
