import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import LogoPrincipal from '../../assets/img/EL VALLE/LOGO.jpeg'
import './recibo.css'

function recibo() {
  return (
    <div>
      <Container >
        <Row className="Total">
          <Col className="Contenedor_imagen"><img className="img_Principal" src={LogoPrincipal} alt="" /></Col>
          <Col>
          <div className="encabezado_recibo">
              <h3>recibo de caja</h3>
              <h6>No. ____________</h6>
          </div>

            <div class="form-group row ContenedorRecibo">
              <label  class="col-sm-3 col-form-label">POR Q.</label>
              <div class="col-sm-9">
                <input class="form-control itemRecibo"  />
              </div>
              <label  class="col-sm-3 col-form-label">LOTE NO.</label>
              <div class="col-sm-9">
                <input class="form-control itemRecibo"  />
              </div>
              <label  class="col-sm-3 col-form-label">MANZANA</label>
              <div class="col-sm-9">
                <input class="form-control itemRecibo"  />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="Total">
          <Col>
            <div class="form-group row ContenedorRecibo">
              <label class="col-sm-2 col-form-label ">RECIBIMOS DE: </label>
              <div class="col-sm-10">
                <input class="form-control itemRecibo" />
              </div>
              <label class="col-sm-2 col-form-label">LA CANTIDAD DE: </label>
              <div class="col-sm-10">
                <input class="form-control itemRecibo" />
              </div>
              <label class="col-sm-2 col-form-label">POR CONCEPTO: </label>
              <div class="col-sm-10">
                <input class="form-control itemRecibo" />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="Total">
          <Col >
            <div class="form-group row ">
              <label class="col-sm-4 col-form-label">SALDO ANTERIOR</label>
              <div class="col-sm-8">
                <input class="form-control itemRecibo" />
              </div>
              <label class="col-sm-4 col-form-label">ABONO DE HOY</label>
              <div class="col-sm-8">
                <input class="form-control itemRecibo" />
              </div>
              <label class="col-sm-4 col-form-label">SALDO ACTUAL</label>
              <div class="col-sm-8">
                <input class="form-control itemRecibo" />
              </div>
            </div>
          </Col>
          <Col>
            <div class="form-row ">
              <div class="col-sm-4">
                <label class="form-label">DEPOSITO NO.</label>
                <input class="form-control itemRecibo" />
              </div>
              <div class="col-sm-4">
                <label class="form-label">BANCO</label>
                <input class="form-control itemRecibo"  />
              </div>
              <div class="col-sm-4">
                <label class="form-label">VALOR</label>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">Q.</span>
                  <input class="form-control"  aria-describedby="basic-addon1"/>
                </div>
              </div>
            </div>
          </Col>
          <Row>
            <div className="col-8" >
              <div class="form-row">
                  <label  class="col-sm-3 col-form-label ">ORATORIO SANTA ROSA</label>
                  <div class="col-sm-9 ">
                    <input class="form-control itemRecibo"/>
                  </div>

              </div>
            </div>
            <div className="col-4">
              <div class="col-sm-12">
                <input class="form-control itemRecibo" />
                <label class="form-label">RECIBIDO POR</label>
              </div>
            </div>

          </Row>
        </Row>



      </Container>
    </div>
  )
}

export default recibo
