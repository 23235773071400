import React, { Component, Fragment } from "react";
import { withRouter 
  ,Redirect
} from "react-router-dom";

import pruebaPdf from "../Inicio/prueba.pdf"

const ACTIVO_GENERICO=[{ID_ACTIVO_GENERICO:true,NOMBRE_ACTIVO_GENERICO:"ACTIVO"},{ID_ACTIVO_GENERICO:false,NOMBRE_ACTIVO_GENERICO:"INACTIVO"}]
const initialState = {

};
class Inicio extends Component {
  state = {
    ...initialState,pdf:pruebaPdf,custom:{}
  }
  UpdateStateCreate = async(e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };

  render() {
    return (
<Fragment>
<div style={{height: 1200}}>
{/** 
<object data={pruebaPdf} type="application/pdf" width="50%" height="50%">
</object>*/}

<object data={this.state.custom.user} type="application/pdf" width="50%" height="50%"/>
<input
                onChange={this.UpdateStateCreate}
                // value={this.state.custom.user}
                type="file"
                name="user"
                className="form-control"
                placeholder="Nombre Usuario"
              />
</div>
</Fragment>
    );
  }
}

export default withRouter(Inicio);
