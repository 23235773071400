import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  data: {SUCURSAL:[],EMPRESA:[]},
  crear: false,
  modificar: false,
  tabla: true,
};
class USUARIO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
  
    const ROL = await fetchGet(`${process.env.REACT_APP_SERVER}/api/ROL/all`);
    this.setState({ data: { ...this.state.data, ROL: ROL.data } });
  }
  UpdatesIds = async () => {
    // const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    // });
    const ID_EMPRESA = await this.state.custom.EMPRESA.ID_EMPRESA;
    await this.setState({
      custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    });
    const ID_SUCURSAL = await this.state.custom.SUCURSAL.ID_SUCURSAL;
    await this.setState({
      custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    });
    const ID_ROL = await this.state.custom.ROL.ID_ROL;
    await this.setState({ custom: { ...this.state.custom, ID_ROL: ID_ROL } });
  };
  UpdateStateCreate = async(e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      await   this.setState({ custom: { ...this.state.custom, [name]: value } });
     if(name=="EMPRESA" && value)
     {
      const SUCURSAL = await fetchGet(
        `${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${value.ID_EMPRESA}`
      );
      await this.setState({ data: { ...this.state.data, SUCURSAL: SUCURSAL.data } });
     }
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/USUARIO/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/USUARIO/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL USUARIO ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/USUARIO/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
        FECHA_CREACIO_USUARIO: new Date(),
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    const SUCURSALES = await fetchGet(`${process.env.REACT_APP_SERVER}/api/SUCURSAL/ByEmpresa/${e.ID_EMPRESA}`);
    this.setState({ data: { ...this.state.data, SUCURSAL:SUCURSALES.data } });
    const EMPRESA = await this.state.data.EMPRESA.find(
      (x) => x.ID_EMPRESA == e.ID_EMPRESA
    );
    await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    const SUCURSAL = await this.state.data.SUCURSAL.find(
      (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    );
    await this.setState({
      custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    });
    const ROL = await this.state.data.ROL.find((x) => x.ID_ROL == e.ID_ROL);
    await this.setState({ custom: { ...this.state.custom, ROL: ROL } });
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_USUARIO") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR USUARIO</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "USUARIO_USUARIO",
                value: this.state.custom.USUARIO_USUARIO,
                label: "USUARIO_USUARIO",
              },
              {
                config: Text,
                type: "G",
                name: "CONTRA_USUARIO",
                value: this.state.custom.CONTRA_USUARIO,
                label: "CONTRA_USUARIO",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACIO_USUARIO",
                value: this.state.custom.FECHA_CREACIO_USUARIO,
                label: "FECHA_CREACIO_USUARIO",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              {
                type: "A",
                options: this.state.data.ROL,
                OptionLabel: "NOMBRE_ROL",
                placeholder: "SELECCIONE ROL",
                name: "ROL",
                value: this.state.custom.ROL,
                label: "ROL",
              },
            ]}
          ></FormsG>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR USUARIO</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "USUARIO_USUARIO",
                value: this.state.custom.USUARIO_USUARIO,
                label: "USUARIO_USUARIO",
              },
              {
                config: Text,
                type: "G",
                name: "CONTRA_USUARIO",
                value: this.state.custom.CONTRA_USUARIO,
                label: "CONTRA_USUARIO",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACIO_USUARIO",
                value: this.state.custom.FECHA_CREACIO_USUARIO,
                label: "FECHA_CREACIO_USUARIO",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              {
                type: "A",
                options: this.state.data.ROL,
                OptionLabel: "NOMBRE_ROL",
                placeholder: "SELECCIONE ROL",
                name: "ROL",
                value: this.state.custom.ROL,
                label: "ROL",
              },
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_USUARIO") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="USUARIO"
            url="USUARIO"
            responsive="vertical"
            allcolumns={[
              {
                name: "USUARIO_USUARIO",
                label: "USUARIO_USUARIO",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACIO_USUARIO",
                label: "FECHA_CREACIO_USUARIO",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_ROL",
                label: "NOMBRE_ROL",
                display: true,
                type: "text",
              },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_USUARIO")
                  ? true
                  : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_USUARIO")
                  ? true
                  : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(USUARIO);
