import React, {Fragment } from "react";
import { Fab } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function ImgG({
  onChange,name,value,label,helperText="",readOnly=false,disabled=false,required=true
  }) {
  const classes = useStyles();

  return (
    <Card className={"MuiFormControl-root MuiTextField-root"}
    >
      <CardActionArea>
      {value &&(
        <CardMedia
          className={classes.media}
          image={value}
        />
      )}

        <CardContent>
        </CardContent>
      </CardActionArea>
      <CardActions style={{display: "contents"}} >
        <Button size="small"  color="primary">
                        <Fragment>
                <label htmlFor={name}>
                  <input
                    style={{ display: 'none' }}
                    id={name}
                    name={name}
                    readOnly={readOnly}
                    type="file"
                    disabled={disabled}
                    onChange={onChange}
                    helperText={helperText}
                    required={required}
                  />
                  <Fab
                    color="secondary"
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    {label}
                  </Fab>
                </label>
                    </Fragment>
        </Button>
      </CardActions>
    </Card>
  );
}
