import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import index from '../../index';

const initialState = {
  datosss:`<span lang=ES-GT style='font-size:12.0pt;mso-fareast-font-family:"Times New Roman";mso-ansi-language:ES-GT;mso-fareast-language:EN-US;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'></span>`
};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (


      <div id="contratoOriginal">
En {this.props.EMPRESA.DIRECCION_EMPRESA} del departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}.{" "} 
Comparece por una parte a) <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, quien manifiesta ser de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS} años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL},{" "} 
{this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD}, {this.props.EMPRESA.REPRESENTANTE_PROFESION}, con domicilio en el departamento de {this.props.EMPRESA.REPRESENTANTE_DOMICILIO_DEPARTAMENTO} {" "} y de 
paso por este departamento, se identifica con Documento Personal de Identificación -DPI- con Código Único de Identificación 
-CUI- {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el Registro Nacional de las Personas de la República de 
Guatemala, quien actúa en calidad de Gerente General y Representante Legal de la entidad denominada <b>{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL.toUpperCase()}</b>, calidad 
que acredita con el acta notarial en la que consta su nombramiento como tal, autorizada en la ciudad de Guatemala, el dos de marzo del año dos mil veinte, por el Notario, 
José Antonio Morales González, documento inscrito en el Registro Mercantil al número de registro quinientos ochenta y seis mil trescientos cincuenta y cuatro (586,354), folio: trescientos cincuenta y nueve (359) del libro: setecientos treinta y siete 
(737) de Auxiliares de Comercio. En lo sucesivo se denominará 
“el promitente vendedor”; y, por la otra parte comparece {this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}, de {this.props.custom.EDAD_LETRAS} años, {this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO.toLowerCase()},{" "} 
{this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO.toLowerCase()}, {this.props.custom.PROFECION_CLIENTE_CONTARTO.toLowerCase()}, con domicilio y residencia en el 
municipio de {this.props.custom.MUNICIPIO_CLIENTE_CONTRATO}, departamento de {this.props.custom.DEPARTAMENTO_CLIENTE_CONTRATO}, se identifica con Documento Personal de 
Identificación -DPI- con Código Único de Identificación -CUI- {this.props.custom.DPI_LETRAS} ({this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el Registro 
Nacional de las Personas de la República de Guatemala, en lo sucesivo se denominará <b>“el promitente comprador”.</b> Los comparecientes me aseguran hallarse en el 
libre ejercicio de sus derechos civiles, en español idioma que hablan y entienden me manifiestan que por el presente acto otorgan {" "}
<b>PROMESA DE COMPRAVENTA DE PARCELA RÚSTICA EN PARCELAMIENTOS SANTA ANA Y PACAYAL CON FACILIDADES DE PAGO,</b> de conformidad con las siguientes cláusulas: 
<b>PRIMERA:</b> Manifiesta el señor <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, en la calidad con que actúa, que su representada la entidad mercantil 
denominada GRUPO KIVU SOCIEDAD ANÓNIMA, es legítima propietaria de la finca denominada “<b>“{this.props.EMPRESA.NOMBRE_EMPRESA.toUpperCase()}”</b>” inscrita en el 
Registro General de la Propiedad identificada con el número de finca: {this.props.custom.FINCA_LETRAS} ({this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO}), 
folio: {this.props.custom.FOLIO_LETRAS1} ({this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO}) del libro: {this.props.custom.LIBRO_LETRAS} {" "}
({this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO}) de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, consistente en terreno rústico, mismo que 
se ubica en {this.props.EMPRESA.DIRECCION_EMPRESA} del departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con las medidas y colindancias que 
constan en su respectiva inscripción registral. <b>SEGUNDA:</b> Continúa manifestando el señor <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL},</b>, en la calidad con que 
actúa, que su representada promete en venta a favor del promitente comprador  {" "}
{this.props.custom.LOTES_CONTRATO_VISTA.map((item,index) => {
return(<Fragment>

  una parcela de  {item.LOTE_LETRAS_METRAJE} {" "}
            ({item.TAMANIO_LOTE}),{" "}identificada 
con el <b>NÚMERO  {" "} {item.LOTE_LETRAS.toUpperCase()} {" "}
            ({item.NO_LOTE}), {" "}</b>
            </Fragment>
)})}
Puede variar la numeración en cuanto a la parcela, no así la ubicación.  La presente promesa de venta se regirá mediante las siguientes cláusulas: <b>
          A) PRECIO DE VENTA ES DE {this.props.custom.PRECIO_TOTAL_LETRAS.toUpperCase()} (Q. {this.props.custom.PRECIO_VENTA_CLIENTE_TOTOTAL_MONEDA}).{" "}
          B) CON UN ENGANCHE DE {this.props.custom.ENGANCHE_TOTAL_LETRAS.toUpperCase()} (Q.{this.props.custom.ENGANCHE_CLIENTE_TOTOTAL_MONEDA}).
C) EL SALDO DE {this.props.custom.SALDO_PRECIO_TOTAL_LETRAS.toUpperCase()}  (Q. {this.props.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL}).
SE CANCELARÁ EN {this.props.custom.ANIOS_PAGAR_LETRAS.toUpperCase()} AÑOS ES DECIR {this.props.custom.CUANTAS_CUOTAS_LETRAS.toUpperCase()}{" "} CUOTAS DE {this.props.custom.CUOTAS_CLIENTE_LETRAS.toUpperCase()}  {" "}
          (Q. {this.props.custom.CUOTAS_CLIENTE_TOTOTAL_MONEDA}) LAS CUALES DARÁN INICIO EN FECHA {" "}
{this.props.custom.PRIMERCUOTA_LETRAS.toUpperCase()}</b> y serán depositados; al {this.props.custom.NOMBRE_BANCO_COMPLETO} ({this.props.custom.BANCO_CONTRATO}),
al número de cuenta <b>{this.props.custom.CUENTA_CONTRATO}</b> a nombre de <b>“{this.props.custom.NOMBRE_CUENTA_CONTRATO}”</b> {" "}
<b>TERCERA:</b>{" "} otras condiciones de la negociación: El precio  del lote no incluye gastos de escrituración, ni pago del Impuesto al Valor Agregado (IVA), ni tasas 
o contribuciones municipales, en caso que el promitente comprador quiera dar por terminada la opción de compra del inmueble, perderá a favor de {" "}
{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, vender el terreno para recibir el saldo restante que el promitente comprador pueda tener a su favor. <b>CUARTA:</b>{" "} 
Opción de compraventa: El promitente comprador se obliga a comprar el inmueble en el precio, plazo y condiciones especificadas en este documento. <b>QUINTA:</b> El precio y 
forma de pago: El precio del inmueble es calculado en quetzales o su equivalencia en dólares de los Estados Unidos de América para este proyecto, utilizando el 
tipo de cambio que reporte el Banco de Guatemala, si existiere variación considerable, al alza del dólar, podría variar la cuota establecida en el presente contrato, 
la cual será notificada al promitente comprador. <b>SEXTA:</b> Cuota: El promitente comprador se obliga a pagar las cuotas a partir de la fecha indicada mensual y consecutivamente, 
quedando obligado a enviar fotografía de la boleta de pago al número de teléfono {this.props.EMPRESA.TELEFONO_EMPRESA} de lo contrario recaerá en mora, la pérdida de la boleta de pago 
física o electrónica es responsabilidad del promitente comprador, obligándose a realizar de nuevo el pago de la cuota mensual, el promitente comprador, declara que los 
fondos con los que honre cada una de las cuotas de la presente promesa serán de legítima procedencia, por lo que libera de toda responsabilidad a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, 
de no ser cierto lo acá declarado. <b>SÉPTIMA:</b> Pagos extraordinarios: El promitente comprador podrá hacer pagos extraordinarios abonados a capital sin restricción alguna, dichos 
pagos reducen el plazo para la compra definitiva, pero no reduce el monto de cuota nivelada, <b>OCTAVA:</b> Recargos por mora: En caso de que el promitente comprador no realice 
el pago correspondiente el día de su vencimiento, tendrá como margen para cancelar su cuota cinco días posteriores a la fecha pactada, de lo contrario el promitente 
comprador tendrá que cancelar un recargo por mora acumulativa del cinco por ciento (5%) sobre el saldo a la fecha. Si el promitente comprador incumpliere con dos o 
más cuotas atrasadas se da por terminada la presente negociación sin necesidad de notificación de esta, como consecuencia el promitente comprador perderá a favor 
de <b>{this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL},</b> {" "} el diez por ciento (10%) del valor total de la propiedad por concepto de arras, 
más los gastos administrativos equivalentes al catorce por ciento (14%), la devolución se realizará únicamente de capital y no intereses, dicha devolución se 
realizará conforme las cuotas canceladas, si existiere un saldo a favor del promitente comprador. <b>NOVENA:</b> Lugar de pago: El promitente comprador acepta que todos los 
pagos a que se refiere el presente contrato se harán efectivos en moneda de curso legal, sin necesidad de cobro alguno, los recibos se extenderán en la oficina 
ubicada en el lugar conocido como “El Manguito” de aldea El Cuje del municipio de Pueblo Nuevo Viñas departamento de Santa Rosa. DÉCIMA: Posesión del terreno: a) El 
promitente comprador podrá tomar posesión del inmueble al momento de la negociación, b) el promitente comprador recibe el inmueble para su cuidado, limpieza, y 
resguardo, c) las bases de delimitación de la propiedad se entregan al momento de la firma del presente contrato, siendo responsable de el resguardo de las misma el 
promitente comprador,  respetando el derecho de paso para senderos de ecoturismo con los que cuenta la finca, dicho derecho quedara implícito en la escritura traslativa de 
dominio, d) el promitente comprador queda obligado a cancelar una cuota anual de limpieza y manteamiento de  las calles principales del parcelamiento, siendo 
costo de quinientos quetzales anuales pudiendo ser cancelados en dos pagos el primero en el mes de  junio y el segundo el mes de diciembre de cada año.   <b>DÉCIMA PRIMERA:</b>{" "} 
El promitente comprador acepta sin necesidad de notificación alguna que el promitente vendedor, pueda ceder el presente contrato de promesa de venta, en 
todo caso al momento de suscribir la escritura traslativa de dominio el inmueble deberá encontrarse libre de gravamen y anotaciones. <b>DÉCIMA SEGUNDA:</b>{" "} 
Escrituración: La escritura registrada en la que se documente la compraventa se otorgara, estando cancelado el cien por ciento (100%) del terreno objeto de la 
opción, el promitente vendedor, designará al Bufete Jurídico Figueroa & Asociados, para que faccione la escritura de compraventa exclusivamente, los honorarios, impuestos fiscales, 
gastos de escrituración y registro, serán por cuenta del promitente comprador, así como cualquier otro gasto que se designe por este negocio, los cuales tendrán un costo 
de {this.props.EMPRESA.ESCRITURA_PRECIO_LETRAS} (Q.{this.props.EMPRESA.ESCRITURA_PRECIO_MONEDA}) por lote. <b>DÉCIMA TERCERA:</b> Obligación de compra de servicios: El promitente comprador se 
obliga a comprar con la desarrolladora {this.props.EMPRESA.RAZON_SOCIAL_EMPRESA}, los servicios básicos y urbanización, dicha obligación se plasmará en la 
escritura traslativa de dominio para su cumplimiento. <b>DÉCIMA CUARTA:</b> Limitaciones en el ejercicio del derecho: El promitente comprador acepta y se obliga a observar lo 
siguiente: a) Dar aviso a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, o en su defecto a la desarrolladora {this.props.EMPRESA.RAZON_SOCIAL_EMPRESA}, antes de construir para 
que este verifique los mojones y medidas; b) El promitente comprador acepta desde ya, que, si no ha pagado la totalidad del valor del terreno y construye en él, la construcción 
pertenece a {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, sin compensación alguna. <b>DÉCIMA QUINTA:</b> Incumplimiento del Promitente Comprador: Si el promitente comprador 
incumpliera las obligaciones que adquiere, acepta que {this.props.EMPRESA.REPRESENTANTE_ENTIDAD_MERCANTIL}, puede optar por lo siguiente: demandar ejecutivamente el saldo que 
hubiera, incluyendo intereses, multas, costos, daños y perjuicios, pudiendo embargar los bienes presentes y futuros del promitente comprador. <b>DÉCIMA SEXTA:</b> Aceptación: 
Bien enterados de su contenido, objeto, validez y efectos legales, lo aceptan, ratifican y firman.
<pre>
</pre>
  <br />
  <br />
  <br />
  <pre>
                        F____________                                       F____________
  </pre>
  <br id="SaltoPagina"/>
  {this.props.EMPRESA.DIRECCION_EMPRESA} del departamento de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS},{" "} 
  yo como Notario (a), Doy fe, que las firmas que anteceden y calzan PROMESA DE <b>COMPRAVENTA DE PARCELA RÚSTICA EN PARCELAMIENTOS {this.props.EMPRESA.NOMBRE_EMPRESA.toUpperCase()} CON 
  FACILIDADES DE PAGO son AUTENTICAS</b>, por haber sido puestas en mi presencia por el señor <b>{this.props.EMPRESA.REPRESENTANTE_LEGAL}</b>, se identifica con Documento Personal 
  de Identificación -DPI- con Código Único de Identificación -CUI- {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} ({this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}),{" "} 
  extendido por el Registro Nacional de las Personas de la República de Guatemala; y <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE}</b>{" "}, se identifica con Documento Personal de 
  Identificación -DPI- con Código Único de Identificación -CUI- {this.props.custom.DPI_LETRAS} ({this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) {" "} extendido 
  por el Registro Nacional de las Personas de la República de Guatemala. Previa lectura de la presente Acta de Legalización de firmas contenida en tres únicas hojas de 
  papel bond tamaño oficio, la cual firmo y sello, los comparecientes vuelven a firmar.<pre>

  </pre>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <pre>
                        F____________                                       F____________
  </pre>
  <br />

  <br />
  <br />
          ANTE MÍ:

      </div>
    );
  }
}
export default withRouter(CONTRATO);
