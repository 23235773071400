export const Agregar={
    color:"#2196f3",
    variant:"contained",
    text:"Guardar",
    icon:"SaveIcon"
}

export const CrearIcon={
    color:"#1565c0",
    variant:"contained",
    icon:"AddCircleIcon"
}
export const Eliminar={
    color:"#ff5252",
    variant:"contained",
    text:"Eliminar",
    icon:"DeleteIcon"
}
export const Modificar={
    color:"#fb8c00",
    variant:"contained",
    text:"Modificar",
    icon:"UpdateIcon"
}
export const Buscar={
    color:"#4caf50",
    variant:"contained",
    text:"Buscar",
    icon:"SearchRoundedIcon"
}


export const AgregarIcon={
    color:"#2196f3",
    variant:"contained",
    icon:"SaveIcon"
}
export const EliminarIcon={
    color:"#ff5252",
    variant:"contained",
    icon:"DeleteIcon"
}

export const ModificarIcon={
    color:"#fb8c00",
    variant:"contained",
    icon:"UpdateIcon"
}
export const BuscarIcon={
    color:"#4caf50",
    variant:"contained",
    icon:"SearchRoundedIcon"
}

export const AgregarCompleto={
    color:"#2196f3",
    variant:"contained",
    text:"Agregar Completo",
    icon:"SaveIcon"
}
export const RegresarIcon={
    color:"#8bc34a",
    variant:"contained",
    icon:"ArrowBackIcon"
}

export const Regresar={
    color:"#8bc34a",
    text:"Regresar",
    variant:"contained",
    icon:"ArrowBackIcon"
}

export const Asingacion={
    color:"#2196f3",
    variant:"contained",
    text:"Asingación",
    icon:"AssignmentIcon"
}
export const AsingacionIcon={
    color:"#1565c0",
    variant:"contained",
    icon:"AssignmentIcon"
}
export const PDFIcon={
    color:"#e91e63",
    variant:"contained",
    icon:"PictureAsPdfIcon"
}
export const ExcelIcon={
    color:"#8bc34a",
    variant:"contained",
    icon:"ExplicitIcon"
}



export const OpenInBrowser={
    color:"#1BDF72",
    variant:"contained",
    text:"Abrir",
    icon:"OpenInBrowserIcon"
}
export const LockOpen={
    color:"#FFC300",
    variant:"contained",
    text:"Desbloquear",
    icon:"LockOpenIcon"
}
export const Close={
    color:"#E05723",
    variant:"contained",
    text:"Cerrar",
    icon:"CloseIcon"
}
export const Cancel={
    color:"#EA1515",
    variant:"contained",
    text:"Cancelar",
    icon:"CancelIcon"
}
export const HighlightOff={
    color:"EA1515",
    variant:"contained",
    text:"Cancelar",
    icon:"HighlightOffIcon"
}
export const CancelPresentation={
    color:"EA1515",
    variant:"contained",
    text:"Cancelar",
    icon:"CancelPresentationIcon"
}
export const AddShoppingCart={
    color:"#0E94E7",
    variant:"contained",
    text:"Agregar",
    icon:"AddShoppingCartIcon"
}
export const AddPhotoAlternate={
    color:"#1FDEF9",
    variant:"contained",
    text:"Agregar",
    icon:"AddPhotoAlternateIcon"
}
export const Backup={
    color:"#42CF03",
    variant:"contained",
    text:"Subir",
    icon:"BackupIcon"
}

export const CloudDownload={
    color:"#03C0CF",
    variant:"contained",
    text:"Descargar",
    icon:"CloudDownload"
}
export const DesktopWindows={
    color:"#CFCC03",
    variant:"contained",
    text:"Ver",
    icon:"DesktopWindows"
}
export const GetApp={
    color:"#03C0CF",
    variant:"contained",
    text:"Descargar",
    icon:"GetAppIcon"
}
export const AttachMoney={
    color:"#4EC20B",
    variant:"contained",
    icon:"PAGAR",
    icon:"AttachMoneyIcon"
}
export const OpenInBrowserIcon={
    color:"#1BDF72",
    variant:"contained",
    icon:"OpenInBrowserIcon"
}
export const LockOpenIcon={
    color:"#FFC300",
    variant:"contained",
    icon:"LockOpenIcon"
}
export const CloseIcon={
    color:"#E05723",
    variant:"contained",
    icon:"CloseIcon"
}
export const CancelIcon={
    color:"#EA1515",
    variant:"contained",
    icon:"CancelIcon"
}
export const HighlightOffIcon={
    color:"EA1515",
    variant:"contained",
    icon:"HighlightOffIcon"
}
export const CancelPresentationIcon={
    color:"EA1515",
    variant:"contained",
    icon:"CancelPresentationIcon"
}
export const AddShoppingCartIcon={
    color:"#0E94E7",
    variant:"contained",
    icon:"AddShoppingCartIcon"
}
export const AddPhotoAlternateIcon={
    color:"#1FDEF9",
    variant:"contained",
    icon:"AddPhotoAlternateIcon"
}
export const BackupIcon={
    color:"#42CF03",
    variant:"contained",
    icon:"BackupIcon"
}
export const GetAppIcon={
    color:"#03C0CF",
    variant:"contained",
    icon:"GetAppIcon"
}

export const CloudDownloadIcon={
    color:"#03C0CF",
    variant:"contained",
    icon:"CloudDownloadIcon"
}
export const DesktopWindowsIcon={
    color:"#CFCC03",
    variant:"contained",
    icon:"DesktopWindowsIcon"
}
export const AttachMoneyIcon={
    color:"#4EC20B",
    variant:"contained",
    icon:"AttachMoneyIcon"
}