import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../utils/Fetch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonG from "../Material-ui/Input/Button/Button";
import { PDFIcon, ExcelIcon } from "../Material-ui/Input/Button/ConfigButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-export-excel";
import TableG from "../Material-ui/Generador/TableG";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const headers = [
  { label: "No", key: "No" },
  { label: "NOMBRE_BANCO", key: "NOMBRE_BANCO" },
  { label: "FECHA_CREACION", key: "FECHA_CREACION" },
  { label: "NOMBRE_EMPRESA", key: "NOMBRE_EMPRESA" },
  { label: "NOMBRE_SUCURSAL", key: "NOMBRE_SUCURSAL" },
  { label: "USUARIO", key: "USUARIO" },
];
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
  CSV: [],
  FECHA_INICIO: new Date(),
  FECHA_FIN: new Date(),
};
class BANCO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
  
    const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    );
    this.setState({ EMPRESAS: EMPRESA.data });
  }
  updateStateSelecteEmpresa = (EMPRESA) => {
    this.setState({ EMPRESA, ID_EMPRESA: EMPRESA.ID_EMPRESA });
  };
  exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "BANCO";
    const headers = [
      [
        "No",
        "NOMBRE_BANCO",
        "FECHA_CREACION",
        "NOMBRE_EMPRESA",
        "NOMBRE_SUCURSAL",
        "USUARIO",
      ],
    ];
    if (this.state.dataReporte) {
      let data = this.state.dataReporte.map((rows, index) => [
        index + 1,
        rows.NOMBRE_BANCO,
        rows.FECHA_CREACION,
        rows.NOMBRE_EMPRESA,
        rows.NOMBRE_SUCURSAL,
        rows.USUARIO,
      ]);
      let content = { startY: 50, head: headers, body: data };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(
        `REPORTE BANCO     ${this.state.EMPRESA.NOMBRE_EMPRESA}           .pdf`
      );
    } else {
      alert("NO EXISTE INFORMACIÓN PARA EXPORTAR EN PDF");
    }
  };
  Buscar = async () => {
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/reportes/Reporte_BANCO`,
      this.state
    );
    if (data) {
      if (data.data) {
        data.data.map((item) => {
          item.FECHA_CREACION = new Date(item.FECHA_CREACION).toLocaleString();
        });
        await this.setState({ dataReporte: data.data });
        let CSV = this.state.dataReporte.map((rows, index) => ({
          No: index + 1,
          NOMBRE_BANCO: rows.NOMBRE_BANCO,
          FECHA_CREACION: rows.FECHA_CREACION,
          NOMBRE_EMPRESA: rows.NOMBRE_EMPRESA,
          NOMBRE_SUCURSAL: rows.NOMBRE_SUCURSAL,
          USUARIO: rows.USUARIO,
        }));
        this.setState({ CSV: CSV });
      } else {
        this.ClearState();
        alert("NO SE ENCONTRARON DATOS PARA GENERAR EL REPORTE");
      }
    } else {
      this.ClearState();
      alert("NO SE ENCONTRARON DATOS PARA GENERAR EL REPORTE");
    }
  };
  render() {
    const redireccion = this.props.Access("REPORT_BANCO") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        
        {redireccion}
        <Fragment>
          
          <div className="ml-5">
            
            <label>Empresa:</label>
            <Select
              onChange={this.updateStateSelecteEmpresa}
              options={this.state.EMPRESAS}
              isMulti={false}
              components={makeAnimated()}
              placeholder={"Seleccione la empresa"}
              getOptionLabel={(options) => options.NOMBRE_EMPRESA}
              getOptionValue={(options) => options.ID_EMPRESA}
              value={this.state.EMPRESA}
            />
          </div>
          <div className="form-group ml-5">
            
            <button onClick={this.Buscar} className="btn btn-success  mt-2">
              
              BUSCAR
            </button>
          </div>
        </Fragment>
        {this.state.tabla && (
          <TableG
            data={this.state.dataReporte}
            customToolbar={
              <Fragment>
                
                <Fragment>
                  
                  <ExcelFile
                    element={<ButtonG config={ExcelIcon} />}
                    filename={`REPORTE BANCO ${this.state.FECHA_INICIO.toLocaleString()}-${this.state.FECHA_FIN.toLocaleString()}`}
                  >
                    
                    <ExcelSheet data={this.state.CSV} name={`hoja1`}>
                      
                      <ExcelColumn label="No" value="No" />
                      <ExcelColumn label="NOMBRE_BANCO" value="NOMBRE_BANCO" />
                      <ExcelColumn
                        label="FECHA_CREACION"
                        value="FECHA_CREACION"
                      />
                      <ExcelColumn
                        label="NOMBRE_EMPRESA"
                        value="NOMBRE_EMPRESA"
                      />
                      <ExcelColumn
                        label="NOMBRE_SUCURSAL"
                        value="NOMBRE_SUCURSAL"
                      />
                      <ExcelColumn label="USUARIO" value="USUARIO" />
                    </ExcelSheet>
                  </ExcelFile>
                </Fragment>
                <ButtonG config={PDFIcon} onClick={this.exportPDF} />
              </Fragment>
            }
            title="REPORTE BANCO"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_BANCO",
                label: "NOMBRE_BANCO",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACION",
                label: "FECHA_CREACION",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: true,
                type: "text",
              },
              {
                name: "USUARIO",
                label: "USUARIO",
                display: true,
                type: "text",
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(BANCO);
