import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
};
class CAJA_DETALLE extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        c
      ustom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/ 
      // const SERIE_DOCUMENTO =
    //   await fetchGet(`${process.env.REACT_APP_SERVER}/api/SERIE_DOCUMENTO/all`);
    // this.setState({
    //   data: { ...this.state.data, SERIE_DOCUMENTO: SERIE_DOCUMENTO.data },
    // });
    // const CAJA_APERTURA = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/CAJA_APERTURA/all`
    // );
    // this.setState({
    //   data: { ...this.state.data, CAJA_APERTURA: CAJA_APERTURA.data },
    // });
    // const MOVIMIENTO = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/MOVIMIENTO/all`
    // );
    // this.setState({
    //   data: { ...this.state.data, MOVIMIENTO: MOVIMIENTO.data },
    // });
    // const CAJA_PAGOS = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/CAJA_PAGOS/all`
    // );
    // this.setState({
    //   data: { ...this.state.data, CAJA_PAGOS: CAJA_PAGOS.data },
    // });
    // const DETALLE_GENERICO = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/DETALLE_GENERICO/all`
    // );
    // this.setState({
    //   data: { ...this.state.data, DETALLE_GENERICO: DETALLE_GENERICO.data },
    // });
    // const EMPRESA = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/EMPRESA/all`
    // );
    // this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
    // const SUCURSAL = await fetchGet(
    //   `${process.env.REACT_APP_SERVER}/api/SUCURSAL/all`
    // );
    // this.setState({ data: { ...this.state.data, SUCURSAL: SUCURSAL.data } });
  }
  UpdatesIds = async () => {
    // const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    // });
    // const ID_SERIE_DOCUMENTO = await this.state.custom.SERIE_DOCUMENTO
    //   .ID_SERIE_DOCUMENTO;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_SERIE_DOCUMENTO: ID_SERIE_DOCUMENTO },
    // });
    // const ID_CAJA_APERTURA = await this.state.custom.CAJA_APERTURA
    //   .ID_CAJA_APERTURA;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_CAJA_APERTURA: ID_CAJA_APERTURA },
    // });
    // const ID_MOVIMIENTO = await this.state.custom.MOVIMIENTO.ID_MOVIMIENTO;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_MOVIMIENTO: ID_MOVIMIENTO },
    // });
    // const ID_CAJA_PAGOS = await this.state.custom.CAJA_PAGOS.ID_CAJA_PAGOS;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_CAJA_PAGOS: ID_CAJA_PAGOS },
    // });
    // const ID_DETALLE_GENERICO = await this.state.custom.DETALLE_GENERICO
    //   .ID_DETALLE_GENERICO;
    // await this.setState({
    //   custom: {
    //     ...this.state.custom,
    //     ID_DETALLE_GENERICO: ID_DETALLE_GENERICO,
    //   },
    // });
    // const ID_EMPRESA = await this.state.custom.EMPRESA.ID_EMPRESA;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
    // });
    // const ID_SUCURSAL = await this.state.custom.SUCURSAL.ID_SUCURSAL;
    // await this.setState({
    //   custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
    // });
    // const ESTADO = this.state.custom.ESTADO.ID_ACTIVO_GENERICO;
    // await this.setState({ custom: { ...this.state.custom, ESTADO: ESTADO } });
    // const ANULADO_CAJA_DETALLE =
    //   this.state.custom.ANULADO_CAJA_DETALLE.ID_ACTIVO_GENERICO;
    // await this.setState({
    //   custom: {
    //     ...this.state.custom,
    //     ANULADO_CAJA_DETALLE: ANULADO_CAJA_DETALLE,
    //   },
    // });
  };
  UpdateStateCreate = (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CAJA_DETALLE/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/CAJA_DETALLE/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL CAJA_DETALLE ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/CAJA_DETALLE/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        FECHA_CREACION_CAJA_DETALLE: new Date(),
        FECHA_CAJA_DETALLE: new Date(),
        ESTADO: ACTIVO_GENERICO[0],
        FECHA_ANULACION_CAJA_DETALLE: new Date(),
        ANULADO_CAJA_DETALLE: ACTIVO_GENERICO[0],
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });
    // const SERIE_DOCUMENTO = await this.state.data.SERIE_DOCUMENTO.find(
    //   (x) => x.ID_SERIE_DOCUMENTO == e.ID_SERIE_DOCUMENTO
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SERIE_DOCUMENTO: SERIE_DOCUMENTO },
    // });
    // const CAJA_APERTURA = await this.state.data.CAJA_APERTURA.find(
    //   (x) => x.ID_CAJA_APERTURA == e.ID_CAJA_APERTURA
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, CAJA_APERTURA: CAJA_APERTURA },
    // });
    // const MOVIMIENTO = await this.state.data.MOVIMIENTO.find(
    //   (x) => x.ID_MOVIMIENTO == e.ID_MOVIMIENTO
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, MOVIMIENTO: MOVIMIENTO },
    // });
    // const CAJA_PAGOS = await this.state.data.CAJA_PAGOS.find(
    //   (x) => x.ID_CAJA_PAGOS == e.ID_CAJA_PAGOS
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, CAJA_PAGOS: CAJA_PAGOS },
    // });
    // const DETALLE_GENERICO = await this.state.data.DETALLE_GENERICO.find(
    //   (x) => x.ID_DETALLE_GENERICO == e.ID_DETALLE_GENERICO
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, DETALLE_GENERICO: DETALLE_GENERICO },
    // });
    // const EMPRESA = await this.state.data.EMPRESA.find(
    //   (x) => x.ID_EMPRESA == e.ID_EMPRESA
    // );
    // await this.setState({ custom: { ...this.state.custom, EMPRESA: EMPRESA } });
    // const SUCURSAL = await this.state.data.SUCURSAL.find(
    //   (x) => x.ID_SUCURSAL == e.ID_SUCURSAL
    // );
    // await this.setState({
    //   custom: { ...this.state.custom, SUCURSAL: SUCURSAL },
    // });
    // const ESTADO = await ACTIVO_GENERICO.find(
    //   (x) => x.NOMBRE_ACTIVO_GENERICO == e.ESTADO
    // );
    // await this.setState({ custom: { ...this.state.custom, ESTADO: ESTADO } });
    // const ANULADO_CAJA_DETALLE = await ACTIVO_GENERICO.find(
    //   (x) => x.NOMBRE_ACTIVO_GENERICO == e.ANULADO_CAJA_DETALLE
    // );
    // await this.setState({
    //   custom: {
    //     ...this.state.custom,
    //     ANULADO_CAJA_DETALLE: ANULADO_CAJA_DETALLE,
    //   },
    // });
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_CAJA_DETALLE") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {/* {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR CAJA_DETALLE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                type: "A",
                options: this.state.data.SERIE_DOCUMENTO,
                OptionLabel: "NOMBRE_SERIE_DOCUMENTO",
                placeholder: "SELECCIONE SERIE_DOCUMENTO",
                name: "SERIE_DOCUMENTO",
                value: this.state.custom.SERIE_DOCUMENTO,
                label: "SERIE_DOCUMENTO",
              },
              {
                config: Number,
                type: "G",
                name: "SERIE_SERIE_DOCUMENTO",
                value: this.state.custom.SERIE_SERIE_DOCUMENTO,
                label: "SERIE_SERIE_DOCUMENTO",
              },
              {
                config: Number,
                type: "G",
                name: "CORRELATIVO_SERIE_DOCUMENTO",
                value: this.state.custom.CORRELATIVO_SERIE_DOCUMENTO,
                label: "CORRELATIVO_SERIE_DOCUMENTO",
              },
              {
                type: "A",
                options: this.state.data.CAJA_APERTURA,
                OptionLabel: "NOMBRE_CAJA_APERTURA",
                placeholder: "SELECCIONE CAJA_APERTURA",
                name: "CAJA_APERTURA",
                value: this.state.custom.CAJA_APERTURA,
                label: "CAJA_APERTURA",
              },
              {
                type: "A",
                options: this.state.data.MOVIMIENTO,
                OptionLabel: "NOMBRE_MOVIMIENTO",
                placeholder: "SELECCIONE MOVIMIENTO",
                name: "MOVIMIENTO",
                value: this.state.custom.MOVIMIENTO,
                label: "MOVIMIENTO",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_CAJA_DETALLE",
                value: this.state.custom.FECHA_CREACION_CAJA_DETALLE,
                label: "FECHA_CREACION_CAJA_DETALLE",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CAJA_DETALLE",
                value: this.state.custom.FECHA_CAJA_DETALLE,
                label: "FECHA_CAJA_DETALLE",
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_CAJA_DETALLE",
                value: this.state.custom.MONTO_CAJA_DETALLE,
                label: "MONTO_CAJA_DETALLE",
              },
              {
                type: "A",
                options: this.state.data.CAJA_PAGOS,
                OptionLabel: "NOMBRE_CAJA_PAGOS",
                placeholder: "SELECCIONE CAJA_PAGOS",
                name: "CAJA_PAGOS",
                value: this.state.custom.CAJA_PAGOS,
                label: "CAJA_PAGOS",
              },
              {
                type: "A",
                options: this.state.data.DETALLE_GENERICO,
                OptionLabel: "NOMBRE_DETALLE_GENERICO",
                placeholder: "SELECCIONE DETALLE_GENERICO",
                name: "DETALLE_GENERICO",
                value: this.state.custom.DETALLE_GENERICO,
                label: "DETALLE_GENERICO",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              {
                type: "A",
                options: ACTIVO_GENERICO,
                OptionLabel: "NOMBRE_ACTIVO_GENERICO",
                placeholder: "SELECCIONE @ESTADO",
                name: "ESTADO",
                value: this.state.custom.ESTADO,
                label: "ESTADO",
              },
              {
                config: Text,
                type: "G",
                name: "DESCRIPCION_CAJA_DETALLE",
                value: this.state.custom.DESCRIPCION_CAJA_DETALLE,
                label: "DESCRIPCION_CAJA_DETALLE",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_ANULACION_CAJA_DETALLE",
                value: this.state.custom.FECHA_ANULACION_CAJA_DETALLE,
                label: "FECHA_ANULACION_CAJA_DETALLE",
              },
              {
                type: "A",
                options: ACTIVO_GENERICO,
                OptionLabel: "NOMBRE_ACTIVO_GENERICO",
                placeholder: "SELECCIONE @ANULADO_CAJA_DETALLE",
                name: "ANULADO_CAJA_DETALLE",
                value: this.state.custom.ANULADO_CAJA_DETALLE,
                label: "ANULADO_CAJA_DETALLE",
              },
              {
                config: Text,
                type: "G",
                name: "OBSERVACIONES_ANULADO_CAJA_DETALLE",
                value: this.state.custom.OBSERVACIONES_ANULADO_CAJA_DETALLE,
                label: "OBSERVACIONES_ANULADO_CAJA_DETALLE",
              },
              {
                config: Number,
                type: "G",
                name: "AUTORIZACION_ANULADO_CAJA_DETALLE",
                value: this.state.custom.AUTORIZACION_ANULADO_CAJA_DETALLE,
                label: "AUTORIZACION_ANULADO_CAJA_DETALLE",
              },
            ]}
          ></FormsG>
        )} */}
        {/* {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR CAJA_DETALLE</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              {
                type: "A",
                options: this.state.data.SERIE_DOCUMENTO,
                OptionLabel: "NOMBRE_SERIE_DOCUMENTO",
                placeholder: "SELECCIONE SERIE_DOCUMENTO",
                name: "SERIE_DOCUMENTO",
                value: this.state.custom.SERIE_DOCUMENTO,
                label: "SERIE_DOCUMENTO",
              },
              {
                config: Number,
                type: "G",
                name: "SERIE_SERIE_DOCUMENTO",
                value: this.state.custom.SERIE_SERIE_DOCUMENTO,
                label: "SERIE_SERIE_DOCUMENTO",
              },
              {
                config: Number,
                type: "G",
                name: "CORRELATIVO_SERIE_DOCUMENTO",
                value: this.state.custom.CORRELATIVO_SERIE_DOCUMENTO,
                label: "CORRELATIVO_SERIE_DOCUMENTO",
              },
              {
                type: "A",
                options: this.state.data.CAJA_APERTURA,
                OptionLabel: "NOMBRE_CAJA_APERTURA",
                placeholder: "SELECCIONE CAJA_APERTURA",
                name: "CAJA_APERTURA",
                value: this.state.custom.CAJA_APERTURA,
                label: "CAJA_APERTURA",
              },
              {
                type: "A",
                options: this.state.data.MOVIMIENTO,
                OptionLabel: "NOMBRE_MOVIMIENTO",
                placeholder: "SELECCIONE MOVIMIENTO",
                name: "MOVIMIENTO",
                value: this.state.custom.MOVIMIENTO,
                label: "MOVIMIENTO",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CREACION_CAJA_DETALLE",
                value: this.state.custom.FECHA_CREACION_CAJA_DETALLE,
                label: "FECHA_CREACION_CAJA_DETALLE",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_CAJA_DETALLE",
                value: this.state.custom.FECHA_CAJA_DETALLE,
                label: "FECHA_CAJA_DETALLE",
              },
              {
                config: Number,
                type: "G",
                name: "MONTO_CAJA_DETALLE",
                value: this.state.custom.MONTO_CAJA_DETALLE,
                label: "MONTO_CAJA_DETALLE",
              },
              {
                type: "A",
                options: this.state.data.CAJA_PAGOS,
                OptionLabel: "NOMBRE_CAJA_PAGOS",
                placeholder: "SELECCIONE CAJA_PAGOS",
                name: "CAJA_PAGOS",
                value: this.state.custom.CAJA_PAGOS,
                label: "CAJA_PAGOS",
              },
              {
                type: "A",
                options: this.state.data.DETALLE_GENERICO,
                OptionLabel: "NOMBRE_DETALLE_GENERICO",
                placeholder: "SELECCIONE DETALLE_GENERICO",
                name: "DETALLE_GENERICO",
                value: this.state.custom.DETALLE_GENERICO,
                label: "DETALLE_GENERICO",
              },
              {
                type: "A",
                options: this.state.data.EMPRESA,
                OptionLabel: "NOMBRE_EMPRESA",
                placeholder: "SELECCIONE EMPRESA",
                name: "EMPRESA",
                value: this.state.custom.EMPRESA,
                label: "EMPRESA",
              },
              {
                type: "A",
                options: this.state.data.SUCURSAL,
                OptionLabel: "NOMBRE_SUCURSAL",
                placeholder: "SELECCIONE SUCURSAL",
                name: "SUCURSAL",
                value: this.state.custom.SUCURSAL,
                label: "SUCURSAL",
              },
              {
                type: "A",
                options: ACTIVO_GENERICO,
                OptionLabel: "NOMBRE_ACTIVO_GENERICO",
                placeholder: "SELECCIONE ESTADO",
                name: "ESTADO",
                value: this.state.custom.ESTADO,
                label: "ESTADO",
              },
              {
                config: Text,
                type: "G",
                name: "DESCRIPCION_CAJA_DETALLE",
                value: this.state.custom.DESCRIPCION_CAJA_DETALLE,
                label: "DESCRIPCION_CAJA_DETALLE",
              },
              {
                type: "D",
                subType: "DT",
                name: "FECHA_ANULACION_CAJA_DETALLE",
                value: this.state.custom.FECHA_ANULACION_CAJA_DETALLE,
                label: "FECHA_ANULACION_CAJA_DETALLE",
              },
              {
                type: "A",
                options: ACTIVO_GENERICO,
                OptionLabel: "NOMBRE_ACTIVO_GENERICO",
                placeholder: "SELECCIONE ANULADO_CAJA_DETALLE",
                name: "ANULADO_CAJA_DETALLE",
                value: this.state.custom.ANULADO_CAJA_DETALLE,
                label: "ANULADO_CAJA_DETALLE",
              },
              {
                config: Text,
                type: "G",
                name: "OBSERVACIONES_ANULADO_CAJA_DETALLE",
                value: this.state.custom.OBSERVACIONES_ANULADO_CAJA_DETALLE,
                label: "OBSERVACIONES_ANULADO_CAJA_DETALLE",
              },
              {
                config: Number,
                type: "G",
                name: "AUTORIZACION_ANULADO_CAJA_DETALLE",
                value: this.state.custom.AUTORIZACION_ANULADO_CAJA_DETALLE,
                label: "AUTORIZACION_ANULADO_CAJA_DETALLE",
              },
            ]}
          ></FormsG>
        )} */}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {/* {" "}
                {this.props.Access("CREATE_CAJA_DETALLE") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "} */}
              </Fragment>
            }
            title="CAJA_DETALLE"
            url="CAJA_DETALLE"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_SERIE_DOCUMENTO",
                label: "NOMBRE_SERIE_DOCUMENTO",
                display: true,
                type: "text",
              },
              {
                name: "SERIE_SERIE_DOCUMENTO",
                label: "SERIE_SERIE_DOCUMENTO",
                display: true,
                type: "number",
              },
              {
                name: "CORRELATIVO_SERIE_DOCUMENTO",
                label: "CORRELATIVO_SERIE_DOCUMENTO",
                display: true,
                type: "number",
              },
              {
                name: "NOMBRE_CAJA_APERTURA",
                label: "NOMBRE_CAJA_APERTURA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_MOVIMIENTO",
                label: "NOMBRE_MOVIMIENTO",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACION_CAJA_DETALLE",
                label: "FECHA_CREACION_CAJA_DETALLE",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CAJA_DETALLE",
                label: "FECHA_CAJA_DETALLE",
                display: true,
                type: "text",
              },
              {
                name: "MONTO_CAJA_DETALLE",
                label: "MONTO_CAJA_DETALLE",
                display: true,
                type: "number",
              },
              {
                name: "NOMBRE_CAJA_PAGOS",
                label: "NOMBRE_CAJA_PAGOS",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_DETALLE_GENERICO",
                label: "NOMBRE_DETALLE_GENERICO",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NOMBRE_SUCURSAL",
                label: "NOMBRE_SUCURSAL",
                display: true,
                type: "text",
              },
              { name: "ESTADO", label: "ESTADO", display: true, type: "bit" },
              {
                name: "DESCRIPCION_CAJA_DETALLE",
                label: "DESCRIPCION_CAJA_DETALLE",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_ANULACION_CAJA_DETALLE",
                label: "FECHA_ANULACION_CAJA_DETALLE",
                display: true,
                type: "text",
              },
              {
                name: "ANULADO_CAJA_DETALLE",
                label: "ANULADO_CAJA_DETALLE",
                display: true,
                type: "bit",
              },
              {
                name: "OBSERVACIONES_ANULADO_CAJA_DETALLE",
                label: "OBSERVACIONES_ANULADO_CAJA_DETALLE",
                display: true,
                type: "text",
              },
              {
                name: "AUTORIZACION_ANULADO_CAJA_DETALLE",
                label: "AUTORIZACION_ANULADO_CAJA_DETALLE",
                display: true,
                type: "number",
              },
              // {
              //   name: "ELIMINAR",
              //   type: "button",
              //   display: this.props.Access("DELETE_CAJA_DETALLE")
              //     ? true
              //     : "excluded",
              //   buttonConfig: EliminarIcon,
              //   OnClickButton: this.Delete,
              // },
              // {
              //   name: "MODIFICAR",
              //   type: "button",
              //   display: this.props.Access("UPDATE_CAJA_DETALLE")
              //     ? true
              //     : "excluded",
              //   buttonConfig: ModificarIcon,
              //   OnClickButton: this.ShowUpdate,
              // },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(CAJA_DETALLE);
