import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function TextFieldG({
config,onChange,name,value,label,helperText="",readOnly=false,disabled=false,required=true,className="",shrink=false
}) {
    const {id
        ,type
        ,variant,multiline} =config;

       
        if(shrink){
        return (
          <TextField
          className={className}
    id={id}
    label={label}
    name={name}
    type={type}
    value={value}
    variant={variant}  
    onChange={onChange}
    multiline={multiline}
    helperText={helperText}
    InputProps={{
      readOnly,
    }}
    InputLabelProps={{
      shrink: shrink,//mostrar directamente el label arriba, en lugar de adentro y luego subir cuando se escribe
    }}
    disabled={disabled}
    required={required}
  />
);
}else{        
  return (
                <TextField
                className={className}
          id={id}
          label={label}
          name={name}
          type={type}
          value={value}
          variant={variant}  
          onChange={onChange}
          multiline={multiline}
          helperText={helperText}
          InputProps={{
            readOnly,
          }}
          
          disabled={disabled}
          required={required}
        />

        
  );}
}
