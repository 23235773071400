import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import index from "../../index";

const initialState = {
  datosss: `<span lang=ES-GT style='font-size:10.0pt;mso-fareast-font-family:"Arial";mso-ansi-language:ES-GT;mso-fareast-language:EN-US;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'></span>`,
};
class CONTRATO extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  render() {
    return (
      <div id="contratoOriginal">
        {/* EN CODIGO SOY CONTRATO OBLIGACION ESCRITURAR
        Y EN LA PAGINA SOY CONTRATO _ESCRITURACION */}
        En {this.props.EMPRESA.DIRECCION_EMPRESA} municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, departamento{" "}
        de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha{" "}
        {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}. Comparece por una parte el señor:{" "}
        <b>
          {" "} {this.props.EMPRESA.REPRESENTANTE_LEGAL}
        </b>
        , quien manifiesta ser de {this.props.EMPRESA.REPRESENTANTE_EDAD_LETRAS}{" "}
        años, {this.props.EMPRESA.REPRESENTANTE_ESTADO_CIVIL},{" "}
        {this.props.EMPRESA.REPRESENTANTE_NACIONALIDAD},{" "}
        {this.props.EMPRESA.REPRESENTANTE_PROFESION}, con domicilio en el
        departamento de{" "}
        {this.props.EMPRESA.REPRESENTANTE_DOMICILIO_DEPARTAMENTO} y de paso por
        este departamento, se identifica con Documento Personal de
        Identificación con Código Único de Identificación{" "}
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
        {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala, {" "}
          quien{" "}
        actúa en calidad de Gerente General y Representante Legal de la
        entidad denominada <b>AGROPECUARIO ONIS, SOCIEDAD ANÓNIMA,</b> calidad que acredita con el
        acta notarial en la que consta su nombramiento como tal, autorizada en
        Santa Catarina Pinula, departamento de Guatemala, el veintidós  de septiembre de 
        dos mil veintiuno, por la Notaria, María Soledad Monzón Braham, documento inscrito 
        en el Registro Mercantil al número de registro seiscientos treinta y ocho mil 
        cuatrocientos trece (638,413), folio: doscientos cuatro (204) del 
        libro: setecientos ochenta y ocho (788) de Auxiliares de Comercio.{" "}
        En lo sucesivo se denominará <b>"El obligado"</b>; y, por la otra parte comparece:{" "}
        <b>
          {this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}
        </b>
        , de {this.props.custom.EDAD_LETRAS} años,{" "}
        {this.props.custom.ESTADO_CIVIL_CLIENTE_CONTRATO.toLowerCase()},{" "}
        {this.props.custom.NACIONALIDAD_CLIENTE_CONTARTO.toLowerCase()},{" "}
        {this.props.custom.PROFECION_CLIENTE_CONTARTO.toLowerCase()}, con
        domicilio y residencia en {this.props.custom.DIRECCION_CLIENTE_CONTRATO}{" "}
        , municipio de {this.props.custom.MUNICIPIO_CLIENTE_CONTRATO},
        departamento de {this.props.custom.DEPARTAMENTO_CLIENTE_CONTRATO}, se
        identifica con Documento Personal de Identificación, con Código Único de
        Identificación {this.props.custom.DPI_LETRAS} (
        {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala, en lo
        sucesivo se denominará <b>"El obligante"</b>. Los
        comparecientes me aseguran hallarse en el libre ejercicio de sus
        derechos civiles, en español idioma que hablan y entienden 
        manifiestan que por el presente acto otorgan{" "}
        <b><u>CONTRATO PRIVADO DE OBLIGACIÓN DE ESCRITURAR FRACCIÓN DE BIEN INMUEBLE RUSTICO,</u></b> de conformidad con
        las siguientes cláusulas: <b><u>PRIMERA:</u></b> Declara: Manifiesta <b>"El Promitente Vendedor",</b>{" "} 
        en la calidad con que actúa, que su representada entidad mercantil danomidad{" "} 
        <b>AGROPECUARIA ONIS, SOCIEDAD ANÓNIMA</b>, es legítima propietara de la finca denominada <b>"JOCOTILLO DOS"</b>{" "}
        {/* denominada “LAS FLORES”  */}
        inscrita en el Registro General de la
        Propiedad identificada con el número de <b>Finca: </b>
        <b>
          {" "}
          {this.props.custom.FINCA_LETRAS} (
          {this.props.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO}),
          folio: {this.props.custom.FOLIO_LETRAS} (
          {this.props.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO})
          del libro: {this.props.custom.LIBRO_LETRAS} (
          {this.props.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO})
          de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}
        </b>
        , consistentes en terreno rústicos, se ubican en el municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, Departamento
        de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, 
        con las medidas y colindancias que constan en su respectiva inscripción
        registral.{" "}
        <b><u>SEGUNDA:</u> OBJETO DEL CONTRATO:</b> Continúa manifestando{" "}
        <b>"El Obligado"</b>, que por este acto, <b>a)</b>{" "}
        {this.props.custom.LOTES_CONTRATO_VISTA.map((item, index) => {
          return (
            <Fragment>
            vende un lote identificado como número{" "}
              <b>
                {" "}
                {item.LOTE_LETRAS.toUpperCase()} ({item.NO_LOTE}) MANZANA “
                {item.NOMBRE_MANZANA}”,{" "} con un área superficial de{" "}
                {item.LOTE_LETRAS_METRAJE} {" "}
              ({item.TAMANIO_LOTE}),{" "}
             
              b) POR EL PRECIO DE VENTA DE:{" "}
              {this.props.custom.PRECIO_TOTAL_LETRAS.toUpperCase()} (Q.{" "}
              {this.props.custom.PRECIO_VENTA_CLIENTE_TOTOTAL_MONEDA}),
            c)
              </b>
            </Fragment>
          );
        })}
        y se obliga a escriturar a favor de {" "}
        <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, el lote
        identificado anteriormente. <b>d)</b> Manifiesta el obligado, que <b>ACEPTA</b> {" "}
        el pago que tiene recibidos al contado y a su entera satisfacción en este momento.{" "}
        <b><u>TERCERA:</u> OBLIGACIÓN DE ESCRITURAR:</b> Manifiesta el <b>"El Obligado"</b>,
        que se compromete a escriturar el lote de terreno antes mencionado en el{" "}
        <b>PLAZO DE UN AÑO</b> a partir del presente documento privado.
        a) otras condiciones de la negociación: El precio del lote no incluye gastos de escrituración,
        ni pago del Impuesto al Valor Agregado (IVA), ni tasas o contribuciones municipales,
        en caso que <b>"El Obligante"</b> quiera dar por terminada la opción de compra del inmueble,
        perderá a favor de <b>"El Obligado"</b>; el diez por ciento (10%) del valor total de la
         propiedad por concepto de arras, más los gastos administrativos equivalentes al 
         catorce por ciento (14%), los cuales se harán efectivos de lo abonado al lote prometido
          en venta en el presente contrato, la devolución únicamente del <u>capital 
          y no  intereses</u>, dicha devolución se realizará de igual manera de la recepción de 
          las cuotas canceladas, si existiere un saldo a favor del Obligante, 
          esperara el tiempo necesario que le tome a <b>"El Obligado"</b>; vender la propiedad  
          para recibir el saldo restante,  b) la escritura en la que se documente la 
          compraventa se entregará debidamente registrada y/o inscrita en el Segundo Registro 
          De La Propiedad de la República de Guatemala, <b>"el obligado"</b>, designa a 
          Bufete Jurídico Figueroa & Asociados exclusivamente, para que faccione la escritura 
          de compraventa; los honorarios, impuestos, gastos de escrituración y registro, 
          serán por cuenta del obligante, así como cualquier otro gasto que se designe por 
          este negocio,
        los cuales tendrán un costo de {this.props.EMPRESA.ESCRITURA_PRECIO_LETRAS}{" "}
        (Q.
        {this.props.EMPRESA.ESCRITURA_PRECIO_MONEDA}) por lote.{" "}
        <b><u>CUARTA:</u></b> Posesión del terreno: 
        a) El Obligante podrá tomar posesión del inmueble al momento de la negociación, 
        b) El Obligante recibe el inmueble para su cuidado, limpieza, y resguardo, 
        tiene la obligación de limpiar su propiedad en caso contrario se cobrar 
        ochenta quetzales trimestrales, c) las bases de delimitación de la propiedad 
        se entregan al momento de la firma del presente contrato, siendo responsable 
        de el resguardo de las misma El Obligante d) El Obligante se obliga a cancelar 
        una cuota anual de limpieza y mantenimiento de las calles principales de la 
        lotificación, siendo el costo de  {/* ciento cincuenta quetzales anuales por lote 
        estándar y trescientos quetzales si es un lote esquina,*/} ciento cincuenta quetzales anuales{" "}
        por lote.{" "}
        
        <b><u>QUINTA:</u></b>{" "}<u>Obligación de compra de servicios:</u> El Obligante, 
        se obliga a comprar con la desarrolladora REBIERA INMOBILIARIA, los servicios 
        básicos y urbanización, dicha obligación se plasmará en la escritura traslativa 
        de dominio para su cumplimiento.{" "}

        <b><u>SEXTA:</u></b> Los comparecientes manifiestan su responsabilidad en cuanto a 
        las obligaciones adquiridas y renunciamos al fuero de nuestros domicilios 
        pudiendo ser demandados ante cualquier tribunal de la República de Guatemala 
        en caso de incumplimiento. {" "}
        <b><u>SÉPTIMA:</u></b> Los comparecientes manifiestan su plena conformidad y <b>ACEPTAN</b>{" "} 
        el presente contrato privado, quienes bien enterados de su contenido, objeto, 
        validez y efectos legales, lo aceptan, ratifican y firman.
        <pre></pre>
        <br />
        <br />
        <br />
        <pre><b>F:__________________ F:___________________</b></pre>
        <br />
        <br />
        {/*<br id="SaltoPagina"/>*/}
        <pre>En {this.props.EMPRESA.DIRECCION_EMPRESA} municipio de {this.props.EMPRESA.MUNICIPIO_EMPRESA}, departamento{" "}
        de {this.props.EMPRESA.DEPARTAMENTO_EMPRESA}, con fecha{" "}
        {this.props.custom.FECHA_CREACION_CONTRATO_LETRAS}, yo como Notario (a),
        Doy fe, que las firmas que anteceden y calzan{" "} <b> CONTRATO PRIVADO DE OBLIGACIÓN DE ESCRITURAR FRACCIÓN DE BIEN INMUEBE RUSTICO, son AUTENTICAS</b> {" "}
        por haber sido puestas en mi presencia por: <b> {this.props.EMPRESA.REPRESENTANTE_LEGAL} </b> quien se identifica con{" "}
        Documento Personal de Identificación, con Código Único de Identificación{" "}
        {this.props.EMPRESA.REPRESENTANTE_DPI_LETRAS} (
        {this.props.EMPRESA.SEPARAR_REPRESENTANTE_DPI}) extendido por el
        Registro Nacional de las Personas de la República de Guatemala; y,{" "}
        <b>{this.props.custom.NOMBRE_COMPLETO_CLIENTE.toUpperCase()}</b>, quien
        se identifica con Documento Personal de Identificación, con Código Único
        de Identificación: {this.props.custom.DPI_LETRAS} (
        {this.props.custom.DPI_SEPARADO_CLIENTE_CONTRATO}) extendido por el{" "}
        Registro Nacional de las Personas de la República de Guatemala.{" "}
        Previa lectura de la 
        presente Acta de Legalización de firmas, la cual firmo y sello, los comparecientes{" "}
        vuelven a firmar.<pre></pre></pre>
        <br />
        <br />
       <b><pre>F:____________________ F:____________________</pre>
        <br />
        <pre><i>ANTE MÍ:</i></pre></b>
        
      </div>
    );
  }
}
export default withRouter(CONTRATO);
