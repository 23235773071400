import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar, PDFIcon, ExcelIcon
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
import AutocompleteG from "../../Material-ui/Input/Autocomplete/Autocomplete";
import "jspdf-autotable";

import ReactExport from "react-export-excel";
import jsPDF from "jspdf";
import { it } from "date-fns/locale";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];

const initialState = {
  custom: {
    CONTADO:false
    },
  data: {LOTE:[],
    MANZANA: [], MANZANA_AGRUPADO: [],
    MANZANA2: [], MANZANA_AGRUPADO2: [],
    MANZANA3: [], MANZANA_AGRUPADO3: [],
    MANZANA4: [], MANZANA_AGRUPADO4: [],
    MANZANA5: [], MANZANA_AGRUPADO5: [],
     USUARIO_PERSONAL:[],PORCENTAJEANIOSCONTRATO:[]},
  Lotes: {lote2:false,lote3:false,lote4:false,lote5:false},
  crear: false,
  modificar: false,
  tabla: true,
};
class ASIG extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };

  exportTicket = async(id) => {

    const data=await this.state.dataReporte.find(x=>x.ID_ESTADO_CUENTA_CLIENTE==id)


        const unit = "pt";
        const size = "letter";
        const orientation = "p";
        let marginLeft = 40;
        let margintop = 120;

        const doc = new jsPDF(orientation, unit, size);
        var width   = doc.internal.pageSize.getWidth();
        var height  = doc.internal.pageSize.getHeight();

        doc.line(20,140,width-20,140)
        let cliente="CLIENTE:";
        doc.setFontSize(12);
        doc.text(cliente, marginLeft, margintop);

        marginLeft =parseInt( marginLeft+doc.getTextWidth(cliente)+5);
        doc.setFontSize(12);
        doc.text(data.NOMBRE_CLIENTE, marginLeft, margintop);


        marginLeft = 40;//parseInt(marginLeft+doc.getTextWidth(data.NOMBRE_CLIENTE)+5);
        margintop=margintop+40;
        let FECHA_DE_PAGO="FECHA DE PAGO REALIZADO:";
        doc.text(FECHA_DE_PAGO, marginLeft, margintop);
        marginLeft = parseInt(marginLeft+doc.getTextWidth(FECHA_DE_PAGO)+5);
        doc.setFontSize(12);
        doc.text(data.FECHA_PAGO_REAL, marginLeft, margintop);


        marginLeft = 40;//parseInt(marginLeft+doc.getTextWidth(data.NOMBRE_CLIENTE)+5);
        margintop=margintop+20;
        let NO_CUOTA="NO_CUOTA:";
        doc.text(NO_CUOTA, marginLeft, margintop);
        marginLeft = parseInt(marginLeft+doc.getTextWidth(NO_CUOTA)+5);
        doc.setFontSize(12);
        doc.text(data.NO_CUOTA.toString(), marginLeft, margintop);


        margintop=margintop+20;
        marginLeft =40;
        let MORA="MORA:"
        let CAPITAL="CAPITAL:"
        let INTERES="INTERES:"
        let TOTAL_CUOTA="TOTAL CUOTA:"
        doc.setFontSize(12);
        doc.text(MORA, marginLeft, margintop);
        marginLeft = parseInt(marginLeft+doc.getTextWidth(MORA)+5);
        doc.text(data.MORA.toString() , marginLeft, margintop);

        margintop=margintop+20;
        marginLeft =40;
        doc.text(CAPITAL, marginLeft, margintop);
        marginLeft = parseInt(marginLeft+doc.getTextWidth(CAPITAL)+5);
        doc.text(data.CAPITAL.toString() , marginLeft, margintop);


        margintop=margintop+20;
        marginLeft =40;
        doc.text(INTERES, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(INTERES)+5);
        doc.text(data.INTERES.toString() , marginLeft, margintop);

        margintop=margintop+20;
        marginLeft =40;
        doc.text(TOTAL_CUOTA, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(TOTAL_CUOTA)+5);
        doc.text(data.TOTAL_CUOTA.toFixed(2) , marginLeft, margintop);



        let TOTAL_NO_PAGADO="FALTANTE DE PAGO: "
        margintop=margintop+40;
        marginLeft =40;
        doc.text(TOTAL_NO_PAGADO, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(TOTAL_NO_PAGADO)+5);
        doc.text(this.state.totalesCuota.totalNoPagado.toFixed(2) , marginLeft, margintop);

        let TOTAL_PAGADO="TOTAL PAGADO:"
        margintop=margintop+20;
        marginLeft =40;
        doc.text(TOTAL_PAGADO, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(TOTAL_PAGADO)+5);
        doc.text(this.state.totalesCuota.totalPagado.toFixed(2) , marginLeft, margintop);

        doc.line(20,margintop+10,width-20,margintop+10)
        doc.line(20,140,20,margintop+10)
        doc.line(width-20,140,width-20,margintop+10)

        marginLeft =40;
        margintop=margintop+40;
        doc.setFontSize(12);
        doc.text(`FECHA DE IMPRESION: ${new Date().toLocaleString()}`, marginLeft, margintop);

        let NOMBRE_USUARIO_PAGO="COBRADOR:"
        margintop=margintop+20;
        marginLeft =40;
        doc.text(NOMBRE_USUARIO_PAGO, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(NOMBRE_USUARIO_PAGO)+5);
        doc.text(data.NOMBRE_USUARIO_PAGO , marginLeft, margintop);


        let NOMBRE_EMPRESA="PROYECTO: "
        margintop=margintop+20;
        marginLeft =40;
        doc.text(NOMBRE_EMPRESA, marginLeft, margintop);
        marginLeft =parseInt( marginLeft+doc.getTextWidth(NOMBRE_EMPRESA)+5);
        doc.text(data.NOMBRE_EMPRESA , marginLeft, margintop);

        await   doc.save(`TICKED DE PAGO DE ${data.NOMBRE_CLIENTE}.pdf`)
       // let content = { startY: 50, head: headers, body: data };NOMBRE_PERSONAL

      };

      async componentDidMount() {
        const PORCENTAJEANIOSCONTRATO  = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/porcentaje_Anios_Contrato/contrato/${this.props.EMPRESA.ID_EMPRESA}`
        );
       await this.setState({
          data: { ...this.state.data, PORCENTAJEANIOSCONTRATO: PORCENTAJEANIOSCONTRATO.data },
        });


        const MANZANA_AGRUPADO = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO_AGRUPADO/${this.props.EMPRESA.ID_EMPRESA}`
        );
        await this.setState({
          data: { ...this.state.data, MANZANA_AGRUPADO: MANZANA_AGRUPADO.data },
        });

         const USUARIO_PERSONAL = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/USUARIO/USUARIO_PERSONAL_CONTRATO`
        );
        this.setState({
          data: { ...this.state.data, USUARIO_PERSONAL: USUARIO_PERSONAL.data },
        });
      }
      ModificarLotes =(name,value)=> async (e) => {
        e.preventDefault();
        await this.setState({
          Lotes: { ...this.state.Lotes,[name]:value},
        });
        await this.CalcularDatos();

      };
      UpdatesIds = async () => {
        const ID_USUARIO = this.props.auth[0].ID_USUARIO;
        await this.setState({
          custom: {
            ...this.state.custom,
            ID_USUARIO:ID_USUARIO
          },
        });

       if(this.props.auth[0].ID_USUARIO!=40)
        {
        await this.setState({
          custom: {
            ...this.state.custom,
            ID_USUARIO_ADMIN_OFICINA_CONTRATO: ID_USUARIO,
          },
        });
      }
        await this.setState({
          custom: { ...this.state.custom},
        });
        await this.setState({
          custom: {
            ...this.state.custom,
            ID_CLIENTE:this.props.match.params.id,
            ID_EMPRESA: this.props.EMPRESA.ID_EMPRESA,
            ID_SUCURSAL: this.props.auth[0].ID_SUCURSAL,
            // ID_TIPO_CONTRATO:this.props.TIPO_CONTRATO.ID_TIPO_CONTRATO,
            ESTADO: 1,
            CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO:this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION.MESES_PORCENTAJE_ANIOS_CONTRATO
          },
        });
      };

      handleInputChange= async (event) => {
        
        const target = event.target;
        console.log(target)
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(target.checked,"target.checked")//es este
       //heckboxChange.PAGADO=value;

       await  this.setState({
         custom:{
           ...this.state.custom,[name]:value
         }
        })
        await this.CalcularDatos();

      }
      UpdateStateCreate = async(e) => {
        if (e.target) {
          if (e.target.type === "file") {
            const { name } = e.target;
            const image = e.target.files[0];
            const reader = new window.FileReader();
            reader.readAsDataURL(image);
            reader.onload = async (e) => {
              await this.setState({
                custom: { ...this.state.custom, [name]: e.target.result },
              });
            };
          } else {
            const { name, value } = e.target;
          await  this.setState({ custom: { ...this.state.custom, [name]: value } });
          if(name=="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO")
            {
                if(value)
                  {
                      if(this.state.custom && this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO)
                      {
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: value-this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO } });
                      }
                      else{
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });
                      }
                      await    this.setState({ custom: { ...this.state.custom,
                        CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO:( value*0.14).toFixed(2),
                        CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO:( value*0.10).toFixed(2)
                      } });
                  }
                  else{
                    await    this.setState({ custom: { ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });

                      await    this.setState({ custom: { ...this.state.custom,
                        CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO:( 0).toFixed(2),
                        CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO:( 0).toFixed(2)
                      } });
                  }
             }


            if(name=="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO")
            {
                if(value)
                  {
                      if(this.state.custom && this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO)
                      {
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO-value } });
                      }
                      else{
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });
                      }
                  }
                  else{
                    await    this.setState({ custom: { ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });
                  }
            }
            if(name=="CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO")
            {
                if(value)
                  {
                      if(this.state.custom && this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO)
                      {
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO:( this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO/value).toFixed(2) } });
                      }
                      else{
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0 } });
                      }
                  }
                  else{
                    await    this.setState({ custom: { ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0 } });
                  }
            }
            if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2:
                        value -
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
                    },
                  });
                }
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO2: (
                      value * 0.14
                    ).toFixed(2),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO2: (
                      value * 0.1
                    ).toFixed(2),
                  },
                });
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
                  },
                });

                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO2: (0).toFixed(
                      2
                    ),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO2: (0).toFixed(2),
                  },
                });
              }
            }

            if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2:
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2 -
                        value,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2: 0,
                  },
                });
              }
            }
            if (
              name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
            ) {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO2:
                        (
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2 /
                          value
                        ).toFixed(2),
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO2: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO2: 0,
                  },
                });
              }
            }
            if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3:
                        value -
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
                    },
                  });
                }
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO3: (
                      value * 0.14
                    ).toFixed(2),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO3: (
                      value * 0.1
                    ).toFixed(2),
                  },
                });
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
                  },
                });

                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO3: (0).toFixed(
                      2
                    ),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO3: (0).toFixed(2),
                  },
                });
              }
            }

            if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3:
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3 -
                        value,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3: 0,
                  },
                });
              }
            }
            if (
              name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
            ) {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO3:
                        (
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3 /
                          value
                        ).toFixed(3),
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO3: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO3: 0,
                  },
                });
              }
            }


            if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4:
                        value -
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
                    },
                  });
                }
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO4: (
                      value * 0.14
                    ).toFixed(2),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO4: (
                      value * 0.1
                    ).toFixed(2),
                  },
                });
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
                  },
                });

                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO4: (0).toFixed(
                      2
                    ),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO4: (0).toFixed(2),
                  },
                });
              }
            }

            if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4:
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4 -
                        value,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4: 0,
                  },
                });
              }
            }
            if (
              name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
            ) {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO4:
                        (
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4 /
                          value
                        ).toFixed(4),
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO4: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO4: 0,
                  },
                });
              }
            }

            if (name == "CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5:
                        value -
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
                    },
                  });
                }
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO5: (
                      value * 0.15
                    ).toFixed(2),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO5: (
                      value * 0.1
                    ).toFixed(2),
                  },
                });
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
                  },
                });

                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO5: (0).toFixed(
                      2
                    ),
                    CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO5: (0).toFixed(2),
                  },
                });
              }
            }

            if (name == "CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5") {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5:
                        this.state.custom
                          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5 -
                        value,
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5: 0,
                  },
                });
              }
            }
            if (
              name == "CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO"
            ) {
              if (value) {
                if (
                  this.state.custom &&
                  this.state.custom
                    .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5
                ) {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO5:
                        (
                          this.state.custom
                            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5 /
                          value
                        ).toFixed(5),
                    },
                  });
                } else {
                  await this.setState({
                    custom: {
                      ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO5: 0,
                    },
                  });
                }
              } else {
                await this.setState({
                  custom: {
                    ...this.state.custom,
                    CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO5: 0,
                  },
                });
              }
            }

          }

            await this.CalcularDatos();
        } else if (e.reason) {
          const { name, value, reason } = e;
          this.setState({ custom: { ...this.state.custom, [name]: value } });
        } else {
          const datos = e.split("|");
          this.setState({
            custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
          });
        }
      };
      UpdateStateCreatedos = async(e) => {
        if (e.target) {
          if (e.target.type === "file") {
            const { name } = e.target;
            const image = e.target.files[0];
            const reader = new window.FileReader();
            reader.readAsDataURL(image);
            reader.onload = async (e) => {
              await this.setState({
                custom: { ...this.state.custom, [name]: e.target.result },
              });
            };
          } else {
            const { name, value } = e.target;
          await  this.setState({ custom: { ...this.state.custom, [name]: value } });
          if(name=="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO")
            {
                if(value)
                  {
                      if(this.state.custom && this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO)
                      {
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: value-this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO } });
                      }
                      else{
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });
                      }
                      await
                      await    this.setState({ custom: { ...this.state.custom,
                        CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO:( value*0.14).toFixed(2),
                        CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO:( value*0.10).toFixed(2)
                      } });
                  }
                  else{
                    await    this.setState({ custom: { ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });

                      await    this.setState({ custom: { ...this.state.custom,
                        CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO:( 0).toFixed(2),
                        CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO:( 0).toFixed(2)
                      } });
                  }
             }


            if(name=="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO")
            {
                if(value)
                  {
                      if(this.state.custom && this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO)
                      {
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO-value } });
                      }
                      else{
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });
                      }
                  }
                  else{
                    await    this.setState({ custom: { ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO: 0 } });
                  }
            }
            if(name=="CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO")
            {
                if(value)
                  {
                      if(this.state.custom && this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO)
                      {
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO:( this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO/value).toFixed(2) } });
                      }
                      else{
                        await    this.setState({ custom: { ...this.state.custom,
                          CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0 } });
                      }
                  }
                  else{
                    await    this.setState({ custom: { ...this.state.custom,
                      CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO: 0 } });
                  }
            }
          }

        } else if (e.reason) {
          const { name, value, reason } = e;
          this.setState({ custom: { ...this.state.custom, [name]: value } });
        } else {
          const datos = e.split("|");
          this.setState({
            custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
          });
        }
      };

      updateStateManzana = async(MANZANA) => {

        const LOTE = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
        );
       await this.setState({
          data: { ...this.state.data, LOTE: LOTE.data },
        });


       await this.setState({
          custom:{
    ...this.state.custom,
    MANZANA: MANZANA,
    CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO:MANZANA.NOMBRE_MANZANA
          }});
      }
  updateStateManzana_AGRUPADO = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    ); 
    await this.setState({
      data: { ...this.state.data, MANZANA: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO: MANZANA_AGRUPADO.data },
    });
  };
  updateStateManzana_AGRUPADO2 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA2: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO2: MANZANA_AGRUPADO.data },
    });
  };
  updateStateManzana_AGRUPADO3 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA3: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO3: MANZANA_AGRUPADO.data },
    });
  };
  updateStateManzana_AGRUPADO4 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA4: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO4: MANZANA_AGRUPADO.data },
    });
  };
  updateStateManzana_AGRUPADO5 = async (MANZANA_AGRUPADO) => {
    const MANZANA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/MANZANA/CONTRATO/${this.props.EMPRESA.ID_EMPRESA}/${MANZANA_AGRUPADO.UBICACION_MANZANA}`
    );
    await this.setState({
      data: { ...this.state.data, MANZANA5: MANZANA.data },
      custom: { ...this.state.custom, MANZANA_AGRUPADO5: MANZANA_AGRUPADO.data },
    });
  };


      updateStateLote =async (LOTE) => {
        await this.setState({
          custom:{
    ...this.state.custom,
    LOTE: LOTE,
    ID_LOTE:LOTE.ID_LOTE,
    CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO:LOTE.NO_LOTE,
    CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO:LOTE.TAMANIO_LOTE,
    CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO:LOTE.PRECIO_LOTE_MAS_INSTERES,
    CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO:LOTE.ENGANCHE_LOTE,
    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO:LOTE.PRECIO_LOTE_MAS_INSTERES-LOTE.ENGANCHE_LOTE
          }});
    await this.CalcularDatos();
      }
      updateStateManzana2 = async(MANZANA) => {

        const LOTE = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
        );
       await this.setState({
          data: { ...this.state.data, LOTE2: LOTE.data },
        });


       await this.setState({
          custom:{
    ...this.state.custom,
    MANZANA2: MANZANA,
    CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO2:MANZANA.NOMBRE_MANZANA
          }});
      }
      updateStateLote2 =async (LOTE) => {
        await this.setState({
          custom:{
    ...this.state.custom,
    LOTE2: LOTE,
    ID_LOTE2:LOTE.ID_LOTE,
    CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO2:LOTE.NO_LOTE,
    CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2:LOTE.TAMANIO_LOTE,
    CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2:LOTE.PRECIO_LOTE_MAS_INSTERES,
    CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2:LOTE.ENGANCHE_LOTE,
    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2:LOTE.PRECIO_LOTE_MAS_INSTERES-LOTE.ENGANCHE_LOTE
          }});
    await this.CalcularDatos();
      }
      updateStateManzana3 = async(MANZANA) => {

        const LOTE = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
        );
       await this.setState({
          data: { ...this.state.data, LOTE3: LOTE.data },
        });


       await this.setState({
          custom:{
    ...this.state.custom,
    MANZANA3: MANZANA,
    CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO3:MANZANA.NOMBRE_MANZANA
          }});
      }
      updateStateLote3 =async (LOTE) => {
        await this.setState({
          custom:{
    ...this.state.custom,
    LOTE3: LOTE,
    ID_LOTE3:LOTE.ID_LOTE,
    CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO3:LOTE.NO_LOTE,
    CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3:LOTE.TAMANIO_LOTE,
    CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3:LOTE.PRECIO_LOTE_MAS_INSTERES,
    CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3:LOTE.ENGANCHE_LOTE,
    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3:LOTE.PRECIO_LOTE_MAS_INSTERES-LOTE.ENGANCHE_LOTE
          }});
    await this.CalcularDatos();
      }
      updateStateManzana4 = async(MANZANA) => {

        const LOTE = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
        );
       await this.setState({
          data: { ...this.state.data, LOTE4: LOTE.data },
        });


       await this.setState({
          custom:{
    ...this.state.custom,
    MANZANA4: MANZANA,
    CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO4:MANZANA.NOMBRE_MANZANA
          }});
      }
      updateStateLote4 =async (LOTE) => {
        await this.setState({
          custom:{
    ...this.state.custom,
    LOTE4: LOTE,
    ID_LOTE4:LOTE.ID_LOTE,
    CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO4:LOTE.NO_LOTE,
    CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4:LOTE.TAMANIO_LOTE,
    CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4:LOTE.PRECIO_LOTE_MAS_INSTERES,
    CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4:LOTE.ENGANCHE_LOTE,
    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4:LOTE.PRECIO_LOTE_MAS_INSTERES-LOTE.ENGANCHE_LOTE
          }});
    await this.CalcularDatos();
      }

      updateStateManzana5 = async(MANZANA) => {

        const LOTE = await fetchGet(
          `${process.env.REACT_APP_SERVER}/api/LOTE/loteByManzanaNoVendidas/${MANZANA.ID_MANZANA}`
        );
       await this.setState({
          data: { ...this.state.data, LOTE5: LOTE.data },
        });


       await this.setState({
          custom:{
    ...this.state.custom,
    MANZANA5: MANZANA,
    CONDICIONES_DE_COMPRA_MANZANA_CLIENTE_CONTRATO5:MANZANA.NOMBRE_MANZANA
          }});
      }
      updateStateLote5 =async (LOTE) => {
        await this.setState({
          custom:{
    ...this.state.custom,
    LOTE5: LOTE,
    ID_LOTE5:LOTE.ID_LOTE,
    CONDICIONES_DE_COMPRA_NO_LOTE_CLIENTE_CONTRATO5:LOTE.NO_LOTE,
    CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5:LOTE.TAMANIO_LOTE,
    CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5:LOTE.PRECIO_LOTE_MAS_INSTERES,
    CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5:LOTE.ENGANCHE_LOTE,
    CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5:LOTE.PRECIO_LOTE_MAS_INSTERES-LOTE.ENGANCHE_LOTE
          }});
    await this.CalcularDatos();
      }


    updateStateCuotas =async (CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION) => {
     await this.setState({
        custom:{
    ...this.state.custom,
    CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION: CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION,
        }});
    await this.CalcularDatos();

    }

    CalcularDatos = async () => {
      let CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL = 0; //precio
      let CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL = 0; //enganceh
      let CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL = 0; //saldo
      if (
        this.state.custom &&
        this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION &&
        this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
          .MESES_PORCENTAJE_ANIOS_CONTRATO
      ) {

        
        let Meses = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
          .MESES_PORCENTAJE_ANIOS_CONTRATO;
  let INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO=(!this.state.custom.CONTADO)? await this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION.INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO:0
  let BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO=(!this.state.custom.CONTADO)? await this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION.BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO:1
  let IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO=(!this.state.custom.CONTADO)? await this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION.IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO:0
        let InteresAnual =
          (INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO) / 12;
        //lote 1
        let Precio = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO;
        let Enganche = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO;
        let Saldo = await this.state.custom
          .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO;
          let Base =0;
          let Iva =0;
          let anios =0;
     if(!this.state.custom.CONTADO){
      Base = parseFloat(
        (
          Precio /
          (BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO)
        ).toFixed(2)
      );
       Iva = parseFloat(
        (
          Base *
         IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
        ).toFixed(2)
      );
       anios =
        (Saldo * InteresAnual * (1 + InteresAnual) ** Meses) /
          ((1 + InteresAnual) ** Meses - 1) +
        Iva / Meses;
      anios = parseFloat(anios.toFixed(2));
     }else{
       Base =0;
       Iva =0;
       anios =Saldo  / Meses;
      anios = parseFloat(anios.toFixed(2));
     }


        CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Precio);
        CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
          parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
          parseFloat(Enganche);
        CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
          parseFloat(
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
          ) + parseFloat(Saldo);

        if (this.state && this.state.Lotes && this.state.Lotes.lote2) {
          let Precio2 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2;
          let Enganche2 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2;
          let Saldo2 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2;
            let Base2 =0;
            let Iva2 =0;
            let anios2 =0;
       if(!this.state.custom.CONTADO){
           Base2 = parseFloat(
            (
              Precio2 /
              (BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO)
            ).toFixed(2)
          );
           Iva2 = parseFloat(
            (
              Base2 *
              IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
            ).toFixed(2)
          );
           anios2 =
            (Saldo2 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva2 / Meses;
          anios2 = parseFloat(anios2.toFixed(2));
        }else{
          Base2 =0;
          Iva2 =0;
          anios2 =Saldo2  / Meses;
         anios2 = parseFloat(anios2.toFixed(2));
        }
          Precio = Precio + Precio2;
          Enganche = Enganche + Enganche2;
          Saldo = Saldo + Saldo2;
          Base = Base + Base2;
          Iva = Iva + Iva2;
          anios = anios + anios2;

          CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Precio2);
          CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
            parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
            parseFloat(Enganche2);
          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Saldo2);
        }

        if (this.state && this.state.Lotes && this.state.Lotes.lote3) {
          let Precio3 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3;
          let Enganche3 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3;
          let Saldo3 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3;
            let Base3 =0;
            let Iva3 =0;
            let anios3 =0;
       if(!this.state.custom.CONTADO){

           Base3 = parseFloat(
            (
              Precio3 /
              (BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO)
            ).toFixed(2)
          );
           Iva3 = parseFloat(
            (
              Base3 *
            IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
            ).toFixed(2)
          );
           anios3 =
            (Saldo3 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva3 / Meses;
          anios3 = parseFloat(anios3.toFixed(2));
        }else{
          Base3 =0;
          Iva3 =0;
          anios3 =Saldo3  / Meses;
         anios3 = parseFloat(anios3.toFixed(2));
        }
          Precio = Precio + Precio3;
          Enganche = Enganche + Enganche3;
          Saldo = Saldo + Saldo3;
          Base = Base + Base3;
          Iva = Iva + Iva3;
          anios = anios + anios3;

          CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Precio3);
          CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
            parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
            parseFloat(Enganche3);
          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Saldo3);
        }

        if (this.state && this.state.Lotes && this.state.Lotes.lote4) {
          let Precio4 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4;
          let Enganche4 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4;
          let Saldo4 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4;
            let Base4 =0;
            let Iva4 =0;
            let anios4 =0;
       if(!this.state.custom.CONTADO){
           Base4 = parseFloat(
            (
              Precio4 /
              (BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO)
            ).toFixed(2)
          );
           Iva4 = parseFloat(
            (
              Base4 *
            IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
            ).toFixed(2)
          );
           anios4 =
            (Saldo4 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva4 / Meses;
          anios4 = parseFloat(anios4.toFixed(2));
        }else{
          Base4 =0;
          Iva4 =0;
          anios4 =Saldo4  / Meses;
         anios4 = parseFloat(anios4.toFixed(2));
        }
          Precio = Precio + Precio4;
          Enganche = Enganche + Enganche4;
          Saldo = Saldo + Saldo4;
          Base = Base + Base4;
          Iva = Iva + Iva4;
          anios = anios + anios4;
          CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Precio4);
          CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
            parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
            parseFloat(Enganche4);
          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Saldo4);
        }
        if (this.state && this.state.Lotes && this.state.Lotes.lote5) {
          let Precio5 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5;
          let Enganche5 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5;
          let Saldo5 = await this.state.custom
            .CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5;
            let Base5 =0;
            let Iva5 =0;
            let anios5 =0;
       if(!this.state.custom.CONTADO){
           Base5 = parseFloat(
            (
              Precio5 /
              (BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO)
            ).toFixed(2)
          );
           Iva5 = parseFloat(
            (
              Base5 *
             IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO
            ).toFixed(2)
          );
           anios5 =
            (Saldo5 * InteresAnual * (1 + InteresAnual) ** Meses) /
              ((1 + InteresAnual) ** Meses - 1) +
            Iva5 / Meses;
          anios5 = parseFloat(anios5.toFixed(2));
        }else{
          Base5 =0;
          Iva5 =0;
          anios5 =Saldo5  / Meses;
         anios5 = parseFloat(anios5.toFixed(2));
        }
          Precio = Precio + Precio5;
          Enganche = Enganche + Enganche5;
          Saldo = Saldo + Saldo5;
          Base = Base + Base5;
          Iva = Iva + Iva5;
          anios = anios + anios5;
          CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Precio5);
          CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL =
            parseFloat(CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL) +
            parseFloat(Enganche5);
          CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL =
            parseFloat(
              CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL
            ) + parseFloat(Saldo5);
        }
        await this.setState({
          custom: {
            ...this.state.custom,
            CALCULO_INTERES_PORCENTAJE_CONTRATO:
             // this.state.custom
             //   .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
                //.
                INTERES_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO,
            CALCULO_IVA_PORCENTAJE_CONTRATO:
             // this.state.custom
              //  .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
                //.
                IVA_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO,
            CALCULO_BASE_PORCENTAJE_CONTRATO:
           //   this.state.custom
             ////   .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
               // .
               BASE_PROCENTAJE_PORCENTAJE_ANIOS_CONTRATO,
            CALCULO_MESES_CONTRATO:
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
                .MESES_PORCENTAJE_ANIOS_CONTRATO,
            CALCULO_BASE_CONTRATO: Base,
            CALCULO_INTERESANUAL: InteresAnual,
            CALCULO_IVA_CONTRATO: Iva,
            CALCULO_ANIOS_CONTRATO: anios,
            ID_PORCENTAJE_ANIOS_CONTRATO:
              this.state.custom
                .CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION
                .ID_PORCENTAJE_ANIOS_CONTRATO,
            CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL:
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL.toFixed(
                2
              ),
            CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL:
              CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL.toFixed(2),
            CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL:
              CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL.toFixed(
                2
              ),
          },
        });

        await this.setState({
          custom: {
            ...this.state.custom,
            CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO: (
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL * 0.14
            ).toFixed(2),
            CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO: (
              CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL * 0.1
            ).toFixed(2),
          },
        });

        await this.setState({
          calculoManual: {
            Meses,
            Precio,
            Enganche,
            Saldo,
            Base,
            InteresAnual,
            Iva,
            anios,
          },
          custom: {
            ...this.state.custom,
            CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO:
              anios.toFixed(2),
          },
        });
      }
    };

  Create = async (e) => {
    e.preventDefault();
await this.UpdatesIds();

    await this.setState({
      custom: {
        ...this.state.custom,
        FECHA_CREACION_CUENTA: new Date(),ESTADO: ACTIVO_GENERICO[0],CUOTA_PAGADA:1
      },
    });

    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/contrato/CREADOR_CUOTAS_SIMPLE`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);

    await this.setState({
      custom: {},
      tabla: false,
      crear: false,
    });
    await this.setState({
      custom: {},
      tabla: true,
      crear: true,
    });
    this.props.history.push(
      `${process.env.PUBLIC_URL}/cliente`
    );
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();

    await this.setState({
      custom:{
        ...this.state.custom,
        MONTO_PAGO:this.state.custom.TOTAL_CUOTA
      }
    })

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/PAGO_ESTADO_CUENTA_CLIENTE_DETALLE/CREATE`,
      this.state.custom
    );
    alert(data.message);
    await this.Buscar();

    await this.exportTicket(this.state.custom.ID_ESTADO_CUENTA_CLIENTE)
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR LA CUENTA?")) {
      e.ESTADO = 0;
      await this.setState({ customDelete: e });

      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/CUENTA/delete`,
        this.state.customDelete
      );
      alert(data.message);
      await this.setState({
        customDelete: {},
        tabla: false,
      });
      await this.setState({
        customDelete: {},
        tabla: true,
      });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });

    await this.setState({ crear: true, modificar: false, tabla: false,CONTADO:false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {

    if(e.CUOTA_PAGADA!=="PAGADO")
    {
      await this.setState({ custom: e });
      await this.setState({
        custom: { ...this.state.custom, FECHA_PAGO: new Date()},
      });

      await this.setState({ custom: { ...this.state.custom } });

      this.setState({ crear: false, modificar: true, tabla: false });
    }else{

      alert("LA CUOTA YA A SIDO PAGADA")
    }

  };
  RegresarAnterior = (e) => {
    this.props.history.goBack();
  };
  render() {
    const redireccion = this.props.Access("READ_ESTADO_CUENTA_CLIENTE") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
                    <div id="container-contrato" class="container">
          <div class="mb-2 row">
            <h5 className="col-sm col-form-label ">CONDICIONES DE COMPRA</h5>
            <label class="col-sm-1 col-form-label ">SECTOR:</label>
            <div class="col-sm-3">
              <Select
                classNamePrefix="select-solo-con"
                className="select-solo"
                onChange={this.updateStateManzana_AGRUPADO}
                options={
                  this.state.data ? this.state.data.MANZANA_AGRUPADO : null
                }
                isMulti={false}
                components={makeAnimated()}
                placeholder={"SELECCIONE SECTOR"}
                getOptionLabel={(options) => options.UBICACION_MANZANA}
                getOptionValue={(options) => options.UBICACION_MANZANA}
                value={this.state.custom.MANZANA_AGRUPADO}
              />
            </div>

          </div>
                  <h5 className="h5-contrato">CONDICIONES DE COMPRA</h5>
                      <div class="mb-2 row">
                      <label class="col-sm-1 col-form-label ">MANZANA:</label>
                          <div class="col-sm-3">
                              <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateManzana}
                          options={(this.state.data)?this.state.data.MANZANA:null}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE LA MANZANA"}
                          getOptionLabel={(options) => options.NOMBRE_MANZANA}
                          getOptionValue={(options) => options.ID_MANZANA}
                          value={this.state.custom.MANZANA}
                          />
                          </div>
                          <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                          <div class="col-sm-3">
                              <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateLote}
                          options={(this.state.data)?this.state.data.LOTE:null}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE LOTE"}
                          getOptionLabel={(options) => options.NO_LOTE}
                          getOptionValue={(options) => options.ID_LOTE}
                          value={this.state.custom.LOTE}
                          />
                          </div>

                          <label class="col-sm-1 col-form-label ">METROS<sup>2</sup></label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} readOnly={false} type="text" class=" form-control " name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO" value={this.state.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO} placeholder="metros "/>
                          </div>
                      </div>
                      <div class="mb-2 row">
                          <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO} placeholder="finca numero "/>
                          </div>
                          <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO} placeholder="Folio Numero "/>
                          </div>
                          <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} class="form-control " name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO} placeholder="Libro numero "/>
                          </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                      <div class="mb-2 row">
                          <label class="col-sm-2 col-form-label ">PRECIO VENTA:</label>
                          <div class="col-sm-4">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO} placeholder="Precio venta "/>
                          </div>
                          <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                          <div class="col-sm-4">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO} placeholder="Enganche del terreno "/>
                          </div>
                      </div>
                      <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label ">SALDO DEL PRECIO DE VENTA:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO} placeholder="Saldo Precio venta "/>
                          </div>

                      </div>
                  </div>
                  <div className="col text-center" name="botones">
                  {this.state.Lotes.lote2==false &&(
                      <button  onClick={this.ModificarLotes("lote2",true)}  class="btn btn-primary">Agregar</button>
                  )}
                  </div>

                  <br/>
                  {this.state.Lotes.lote2 &&(
                    <Fragment>
                        <div id="container-contrato" class="container">
              <div class="mb-2 row">
                <h5 className="col-sm col-form-label ">CONDICIONES DE COMPRA</h5>
                <label class="col-sm-1 col-form-label ">SECTOR:</label>
                <div class="col-sm-3">
                  <Select
                    classNamePrefix="select-solo-con"
                    className="select-solo"
                    onChange={this.updateStateManzana_AGRUPADO2}
                    options={
                      this.state.data ? this.state.data.MANZANA_AGRUPADO : null
                    }
                    isMulti={false}
                    components={makeAnimated()}
                    placeholder={"SELECCIONE SECTOR"}
                    getOptionLabel={(options) => options.UBICACION_MANZANA}
                    getOptionValue={(options) => options.UBICACION_MANZANA}
                    value={this.state.custom.MANZANA_AGRUPADO2}
                  />
                </div>

              </div>
                  <h5 className="h5-contrato">CONDICIONES DE COMPRA</h5>
                      <div class="mb-2 row">
                      <label class="col-sm-1 col-form-label ">MANZANA:</label>
                          <div class="col-sm-3">
                              <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateManzana2}
                          options={(this.state.data)?this.state.data.MANZANA:null}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE LA MANZANA"}
                          getOptionLabel={(options) => options.NOMBRE_MANZANA}
                          getOptionValue={(options) => options.ID_MANZANA}
                          value={this.state.custom.MANZANA2}
                          />
                          </div>
                          <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                          <div class="col-sm-3">
                              <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateLote2}
                          options={(this.state.data)?this.state.data.LOTE:null}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE LOTE"}
                          getOptionLabel={(options) => options.NO_LOTE}
                          getOptionValue={(options) => options.ID_LOTE}
                          value={this.state.custom.LOTE2}
                          />
                          </div>

                          <label class="col-sm-1 col-form-label ">METROS<sup>2</sup></label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} readOnly={false} type="text" class=" form-control " name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2" value={this.state.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO2} placeholder="metros "/>
                          </div>
                      </div>
                      <div class="mb-2 row">
                          <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO2} placeholder="finca numero "/>
                          </div>
                          <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO2} placeholder="Folio Numero "/>
                          </div>
                          <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} class="form-control " name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO2} placeholder="Libro numero "/>
                          </div>
                      </div>
                      <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                      <div class="mb-2 row">
                          <label class="col-sm-2 col-form-label ">PRECIO VENTA:</label>
                          <div class="col-sm-4">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO2} placeholder="Precio venta "/>
                          </div>
                          <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                          <div class="col-sm-4">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO2} placeholder="Enganche del terreno "/>
                          </div>
                      </div>
                      <div class="mb-2 row">
                          <label class="col-sm-3 col-form-label ">SALDO DEL PRECIO DE VENTA:</label>
                          <div class="col-sm-3">
                              <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO2} placeholder="Saldo Precio venta "/>
                          </div>

                      </div>
                  </div>
                  <div className="col text-center" name="botones">
                  {this.state.Lotes.lote3==false &&(
                    <Fragment>
                      <button  onClick={this.ModificarLotes("lote3",true)}  class="btn btn-primary">Agregar</button>
                      <button  onClick={this.ModificarLotes("lote2",false)}  class="btn btn-danger">Eliminar</button>
                    </Fragment>
                  )}
                  </div>
                  <br/>
                    </Fragment>
                  )}
                      {this.state.Lotes.lote3 &&(
                    <Fragment>
                  <div id="container-contrato" class="container">
              <div class="mb-2 row">
                <h5 className="col-sm col-form-label ">CONDICIONES DE COMPRA</h5>
                <label class="col-sm-1 col-form-label ">SECTOR:</label>
                <div class="col-sm-3">
                  <Select
                    classNamePrefix="select-solo-con"
                    className="select-solo"
                    onChange={this.updateStateManzana_AGRUPADO3}
                    options={
                      this.state.data ? this.state.data.MANZANA_AGRUPADO: null
                    }
                    isMulti={false}
                    components={makeAnimated()}
                    placeholder={"SELECCIONE SECTOR"}
                    getOptionLabel={(options) => options.UBICACION_MANZANA}
                    getOptionValue={(options) => options.UBICACION_MANZANA}
                    value={this.state.custom.MANZANA_AGRUPADO3}
                  />
                </div>

              </div>
                  <h5 className="h5-contrato">CONDICIONES DE COMPRA</h5>
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">MANZANA:</label>
                  <div class="col-sm-3">
                    <Select
                  classNamePrefix="select-solo-con"
                  className="select-solo"
                  onChange={this.updateStateManzana3}
                  options={(this.state.data)?this.state.data.MANZANA:null}
                  isMulti={false}
                  components={makeAnimated()}
                  placeholder={"SELECCIONE LA MANZANA"}
                  getOptionLabel={(options) => options.NOMBRE_MANZANA}
                  getOptionValue={(options) => options.ID_MANZANA}
                  value={this.state.custom.MANZANA3}
                  />
                  </div>
                  <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                  <div class="col-sm-3">
                    <Select
                  classNamePrefix="select-solo-con"
                  className="select-solo"
                  onChange={this.updateStateLote3}
                  options={(this.state.data)?this.state.data.LOTE:null}
                  isMulti={false}
                  components={makeAnimated()}
                  placeholder={"SELECCIONE LOTE"}
                  getOptionLabel={(options) => options.NO_LOTE}
                  getOptionValue={(options) => options.ID_LOTE}
                  value={this.state.custom.LOTE3}
                  />
                  </div>

                  <label class="col-sm-1 col-form-label ">METROS<sup>2</sup></label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} readOnly={false} type="text" class=" form-control " name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3" value={this.state.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO3} placeholder="metros "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO3} placeholder="finca numero "/>
                  </div>
                  <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO3} placeholder="Folio Numero "/>
                  </div>
                  <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} class="form-control " name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO3} placeholder="Libro numero "/>
                  </div>
                  </div>
                  <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                  <div class="mb-2 row">
                  <label class="col-sm-2 col-form-label ">PRECIO VENTA:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO3} placeholder="Precio venta "/>
                  </div>
                  <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO3} placeholder="Enganche del terreno "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-3 col-form-label ">SALDO DEL PRECIO DE VENTA:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO3} placeholder="Saldo Precio venta "/>
                  </div>

                  </div>
                  </div>
                  <div className="col text-center" name="botones">
                  {this.state.Lotes.lote4==false &&(
                    <Fragment>
                      <button  onClick={this.ModificarLotes("lote4",true)}  class="btn btn-primary">Agregar</button>
                      <button  onClick={this.ModificarLotes("lote3",false)}  class="btn btn-danger">Eliminar</button>
                    </Fragment>
                  )}
                  </div>
                  <br/>
                    </Fragment>
                  )}
                      {this.state.Lotes.lote4 &&(
                    <Fragment>
                  <div id="container-contrato" class="container">
                  <h5 className="h5-contrato">CONDICIONES DE COMPRA</h5>
              <div class="mb-2 row">
                <h5 className="col-sm col-form-label ">CONDICIONES DE COMPRA</h5>
                <label class="col-sm-1 col-form-label ">SECTOR:</label>
                <div class="col-sm-3">
                  <Select
                    classNamePrefix="select-solo-con"
                    className="select-solo"
                    onChange={this.updateStateManzana_AGRUPADO4}
                    options={
                      this.state.data ? this.state.data.MANZANA_AGRUPADO : null
                    }
                    isMulti={false}
                    components={makeAnimated()}
                    placeholder={"SELECCIONE SECTOR"}
                    getOptionLabel={(options) => options.UBICACION_MANZANA}
                    getOptionValue={(options) => options.UBICACION_MANZANA}
                    value={this.state.custom.MANZANA_AGRUPADO4}
                  />
                </div>

              </div>
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">MANZANA:</label>
                  <div class="col-sm-3">
                    <Select
                  classNamePrefix="select-solo-con"
                  className="select-solo"
                  onChange={this.updateStateManzana4}
                  options={(this.state.data)?this.state.data.MANZANA:null}
                  isMulti={false}
                  components={makeAnimated()}
                  placeholder={"SELECCIONE LA MANZANA"}
                  getOptionLabel={(options) => options.NOMBRE_MANZANA}
                  getOptionValue={(options) => options.ID_MANZANA}
                  value={this.state.custom.MANZANA4}
                  />
                  </div>
                  <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                  <div class="col-sm-3">
                    <Select
                  classNamePrefix="select-solo-con"
                  className="select-solo"
                  onChange={this.updateStateLote4}
                  options={(this.state.data)?this.state.data.LOTE:null}
                  isMulti={false}
                  components={makeAnimated()}
                  placeholder={"SELECCIONE LOTE"}
                  getOptionLabel={(options) => options.NO_LOTE}
                  getOptionValue={(options) => options.ID_LOTE}
                  value={this.state.custom.LOTE4}
                  />
                  </div>

                  <label class="col-sm-1 col-form-label ">METROS<sup>2</sup></label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} readOnly={false} type="text" class=" form-control " name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4" value={this.state.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO4} placeholder="metros "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO4} placeholder="finca numero "/>
                  </div>
                  <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO4} placeholder="Folio Numero "/>
                  </div>
                  <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} class="form-control " name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO4} placeholder="Libro numero "/>
                  </div>
                  </div>
                  <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                  <div class="mb-2 row">
                  <label class="col-sm-2 col-form-label ">PRECIO VENTA:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO4} placeholder="Precio venta "/>
                  </div>
                  <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO4} placeholder="Enganche del terreno "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-3 col-form-label ">SALDO DEL PRECIO DE VENTA:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO4} placeholder="Saldo Precio venta "/>
                  </div>

                  </div>
                  </div>
                  <div className="col text-center" name="botones">
                  {this.state.Lotes.lote5==false &&(
                    <Fragment>
                      <button  onClick={this.ModificarLotes("lote5",true)}  class="btn btn-primary">Agregar</button>
                      <button  onClick={this.ModificarLotes("lote4",false)}  class="btn btn-danger">Eliminar</button>
                    </Fragment>
                  )}
                  </div>
                  <br/>
                    </Fragment>
                  )}
                      {this.state.Lotes.lote5 &&(
                    <Fragment>
                  <div id="container-contrato" class="container">
                  <h5 className="h5-contrato">CONDICIONES DE COMPRA</h5>
              <div class="mb-2 row">
                <h5 className="col-sm col-form-label ">CONDICIONES DE COMPRA</h5>
                <label class="col-sm-1 col-form-label ">SECTOR:</label>
                <div class="col-sm-3">
                  <Select
                    classNamePrefix="select-solo-con"
                    className="select-solo"
                    onChange={this.updateStateManzana_AGRUPADO5}
                    options={
                      this.state.data ? this.state.data.MANZANA_AGRUPADO : null
                    }
                    isMulti={false}
                    components={makeAnimated()}
                    placeholder={"SELECCIONE SECTOR"}
                    getOptionLabel={(options) => options.UBICACION_MANZANA}
                    getOptionValue={(options) => options.UBICACION_MANZANA}
                    value={this.state.custom.MANZANA_AGRUPADO5}
                  />
                </div>

              </div>
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">MANZANA:</label>
                  <div class="col-sm-3">
                    <Select
                  classNamePrefix="select-solo-con"
                  className="select-solo"
                  onChange={this.updateStateManzana5}
                  options={(this.state.data)?this.state.data.MANZANA:null}
                  isMulti={false}
                  components={makeAnimated()}
                  placeholder={"SELECCIONE LA MANZANA"}
                  getOptionLabel={(options) => options.NOMBRE_MANZANA}
                  getOptionValue={(options) => options.ID_MANZANA}
                  value={this.state.custom.MANZANA5}
                  />
                  </div>
                  <label class="col-sm-1 col-form-label ">NO. DE LOTE:</label>
                  <div class="col-sm-3">
                    <Select
                  classNamePrefix="select-solo-con"
                  className="select-solo"
                  onChange={this.updateStateLote5}
                  options={(this.state.data)?this.state.data.LOTE:null}
                  isMulti={false}
                  components={makeAnimated()}
                  placeholder={"SELECCIONE LOTE"}
                  getOptionLabel={(options) => options.NO_LOTE}
                  getOptionValue={(options) => options.ID_LOTE}
                  value={this.state.custom.LOTE5}
                  />
                  </div>

                  <label class="col-sm-1 col-form-label ">METROS<sup>2</sup></label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} readOnly={false} type="text" class=" form-control " name="CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5" value={this.state.custom.CONDICIONES_DE_COMPRA_METROS_CLIENTE_CONTRATO5} placeholder="metros "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">FINCA NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_FINCA_CLIENTE_CONTRATO5} placeholder="finca numero "/>
                  </div>
                  <label class="col-sm-1 col-form-label ">FOLIO NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_FOLIO_CLIENTE_CONTRATO5} placeholder="Folio Numero "/>
                  </div>
                  <label class="col-sm-1 col-form-label ">LIBRO NO:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} class="form-control " name="CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5" defaultValue={this.state.custom.CONDICIONES_DE_COMPRA_NO_LIBRO_CLIENTE_CONTRATO5} placeholder="Libro numero "/>
                  </div>
                  </div>
                  <h5 className="h5-contrato">CONDICIONES DE LA OPCION</h5>
                  <div class="mb-2 row">
                  <label class="col-sm-2 col-form-label ">PRECIO VENTA:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATO5} placeholder="Precio venta "/>
                  </div>
                  <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATO5} placeholder="Enganche del terreno "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-3 col-form-label ">SALDO DEL PRECIO DE VENTA:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreate} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5" readOnly={false} value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATO5} placeholder="Saldo Precio venta "/>
                  </div>

                  </div>
                  </div>
                  <div className="col text-center" name="botones">
                    <Fragment>
                      <button  onClick={this.ModificarLotes("lote5",false)}  class="btn btn-danger">Eliminar</button>
                    </Fragment>
                  </div>
                  <br/>
                    </Fragment>
                  )}
                  {/* servicios calculados */}
                  <div id="container-contrato" class="container">
                  <div class="mb-2 row">
                  <label class="col-sm-1 col-form-label ">CONTADO:</label>
                    <div class="col-sm-1">
                      <input
                        onChange={this.handleInputChange}
                        type="checkbox"
                        class=""
                        name="CONTADO"
                        value={
                          this.state.custom.CONTADO
                        }
                        placeholder="CONTADO"
                      />
                    </div>
                  <label class="col-sm-2 col-form-label ">ARRAS 10%:</label>
                          <div class="col-sm-2">
                              <input onChange={this.UpdateStateCreatedos} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ARRAS_CLIENTE_CONTRATO} placeholder="Arras "/>
                          </div>
                          <label class="col-sm-2 col-form-label ">SALDO A PAGAR EN:</label>
                          <div class="col-sm-4">
                              <Select
                          classNamePrefix="select-solo-con"
                          className="select-solo"
                          onChange={this.updateStateCuotas}
                          options={this.state.data.PORCENTAJEANIOSCONTRATO}
                          isMulti={false}
                          components={makeAnimated()}
                          placeholder={"SELECCIONE LOS MESES"}
                          getOptionLabel={(options) => options.MESES_PORCENTAJE_ANIOS_CONTRATO}
                          getOptionValue={(options) => options.ID_PORCENTAJE_ANIOS_CONTRATO}
                          value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_A_PAGAR_EN_CLIENTE_CONTRATO_OPCION}
                          />
                          </div>
                  </div><br/>
                      <div class="mb-2 row">
                          <label class="col-sm-4 col-form-label ">PLAZO MEDIANTE CUOTAS NIVELADAS DE:</label>
                          <div class="col-sm-2">
                              <input onChange={this.UpdateStateCreatedos} type="number" class="form-control" readOnly={false} name="CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO" value={this.state.custom.CONDICIONES_DE_LA_OPCION_PLAZO_MEDIANTE_CUOTAS_CLIENTE_CONTRATO} placeholder="Cuota "/>
                          </div>
                          <label class="col-sm-4 col-form-label ">CON UNA COMISION Y SERVICIOS QUE SUMADOS SON:</label>
                          <div class="col-sm-2">
                              <input onChange={this.UpdateStateCreatedos} type="number" class="form-control" name="CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO" defaultValue={this.state.custom.CONDICIONES_DE_LA_OPCION_COMISION_CLIENTE_CONTRATO} placeholder="Cuota "/>
                          </div>
                      </div>
                      <div class="mb-2 row">

                          <label class="col-sm-5 col-form-label ">MENSUALMENTE LA PRIMER CUOTA DEBERA PAGARSE EL:</label>
                          <div class="col-sm-2">
                              <input onChange={this.UpdateStateCreate} type="date"class="form-control" name="CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO" defaultValue={this.state.custom.CONDICIONES_DE_LA_OPCION_FECHA_PRIMER_PAGOCLIENTE_CONTRATO}  placeholder="fecha de pago "/>
                          </div>
                      </div>

                  {/*totales generales  */}
                  <h5 className="h5-contrato">TOTAL</h5>
                  <div class="mb-2 row">
                  <label class="col-sm-2 col-form-label ">PRECIO VENTA:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreatedos} type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL" value={this.state.custom.CONDICIONES_DE_LA_OPCION_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL} placeholder="Precio venta "/>
                  </div>
                  <label class="col-sm-2 col-form-label ">ENGANCHE:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreatedos}    type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL" value={this.state.custom.CONDICIONES_DE_LA_OPCION_ENGANCHE_CLIENTE_CONTRATOTOTAL} placeholder="Enganche del terreno "/>
                  </div>
                  </div>
                  <div class="mb-2 row">
                  <label class="col-sm-3 col-form-label ">SALDO DEL PRECIO DE VENTA:</label>
                  <div class="col-sm-3">
                    <input onChange={this.UpdateStateCreatedos}  type="number" class="form-control " name="CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL"  value={this.state.custom.CONDICIONES_DE_LA_OPCION_SALDO_PRECIO_VENTA_CLIENTE_CONTRATOTOTAL} placeholder="Saldo Precio venta "/>
                  </div>
                  <label class="col-sm-2 col-form-label ">APARTADO:</label>
                  <div class="col-sm-4">
                    <input onChange={this.UpdateStateCreatedos}  type="number" class="form-control " name="APARTADO_CONTRATO" defaultValue={0} value={this.state.custom.APARTADO_CONTRATO} placeholder="Apartado "/>
                  </div>

                  </div>

                      </div><br/>


                      <div className="col text-center" name="botones">
                <button  onClick={this.Create} class="btn btn-primary">Ingresar</button>
            </div>
                     </Fragment>
    );
  }
}
export default withRouter(ASIG);
