import React, { Component, Fragment } from "react";
import { fetchPost, fetchGet } from "../../../../../src/utils/Fetch";
import imagenes from "../.././.././../components/Inicio/imagenes"
import ".././Haciendas el Naranjo/style.css";

import ReactPlayer from 'react-player'
import { withRouter, Redirect, Link } from "react-router-dom";
import { Button, Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {};
class Inicio extends Component {
  state = {
    img: imagenes.img[0],
    empresas: imagenes.empresas,
  };

  CambioInicio = (item) => async (e) => {
    e.preventDefault();
    await this.setState({ img: imagenes.img[item.index] });
  };

  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
    const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/Inicio`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
  }

  render() {
    const StylerCarouselPrimario = {
      width: "100%",
      height: "370px",
    };
    const StylerCarouselSecundario = {
      width: "600px",
      height: "300px",
    };
    const imgTotalCarousel = {
      width: "280px",
    };

    return (
      <>

<footer class="bg-dark py-1">
  <div class="container">
    <div class="row">
      <div class="col-md-2 mb-3 mb-md-0 mx-auto text-center"> </div>
      <div class="col-md-5 mb-3 mb-md-0 mx-auto text-center">
        <h5 class="text-white">IDAS DESING</h5>
        <p class="text-muted">Software a la Medida.</p>
      </div>
      <div class="col-md-3 d-flex justify-content-md-end justify-content-center align-items-center">
        <a href="https://api.whatsapp.com/send?phone=50236659309" target="_blank" class="text-white me-3">
          <i class="fab fa-whatsapp fa-2x"></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=100076337020488" target="_blank" class="text-white me-3">
          <i class="fab fa-facebook fa-2x"></i>
        </a>
        <a href="idasdesing.com/" target="_blank" class="btn btn-outline-light">Visita nuestra página web</a>
      </div>
    </div>
  </div>
</footer>





      </>
    );
  }
}

export default withRouter(Inicio);
