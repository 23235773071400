import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function AutocompleteG({className="", options=[{}],defaultValue,OptionLabel,name,label,value,onChange,multiple=false,disabled=false,placeholder=""}) {
  return (
<Autocomplete
 className={"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl "+className}
        multiple={multiple}
        disabled={disabled}
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option[OptionLabel]}
        value={value}
        defaultValue={defaultValue}
        filterSelectedOptions
        onChange={(event, newValue,reason) => {
                  const e={
                    name:name,
                    value:newValue,
                    reason:reason
                  }
                  onChange(e)
      }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        )}
      />

    )
}
