import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
import AutocompleteG from "../../Material-ui/Input/Autocomplete/Autocomplete";
const initialState = {
  custom: {ACCESOXROL:[]},
  data: {ACCESOS_NO_ROL:{}},
  crear: false,
  modificar: false,
  tabla: true,
};
class ACCESO_ROL extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {
    const ACCESOS_NO_ROL = await fetchGet(`${process.env.REACT_APP_SERVER}/api/ACCESO_ROL/rol/no/${this.props.match.params.id}`);
    this.setState({ data: { ...this.state.data, ACCESOS_NO_ROL: ACCESOS_NO_ROL.data } });

    const ROL = await fetchGet(`${process.env.REACT_APP_SERVER}/api/ROL/find/${this.props.match.params.id}`);
    this.setState({ custom: { ...this.state.custom, ...ROL.data } });
  }
    UpdatesIds = async () => { 
      const ID_USUARIO=this.props.auth[0].ID_USUARIO;
      await this.setState({
        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
      });
   
      const ID_SUCURSAL=this.props.auth[0].ID_SUCURSAL;
      await this.setState({
        custom: { ...this.state.custom, ID_SUCURSAL: ID_SUCURSAL },
      });

      const ID_EMPRESA=this.props.auth[0].ID_EMPRESA;
      await this.setState({
        custom: { ...this.state.custom, ID_EMPRESA: ID_EMPRESA },
      });
     
  };
  UpdateStateCreate = (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
        this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
      this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
      this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();

    await this.setState({
      custom: { ...this.state.custom, FECHA_CREACION_ACCESO_ROL: new Date() },
    })
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/ACCESO_ROL/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);

    this.props.history.push('/roles');
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/ACCESO_ROL/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL ACCESO_ROL ?")) {
    e.ESTADO=0;
      await this.setState({ custom: e });

      
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/ACCESO_ROL/delete`,this.state.custom
      );
      alert(data.message);
      await this.setState({
        custom: {},
        tabla:false
      });
      await  this.setState({
        custom: {},
        tabla:true
      });
    }
  };
  RegresarAnterior=(e) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/roles`);
  }
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: { ...this.state.custom, FECHA_CREACION_ACCESO_ROL: new Date() },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    await this.setState({ custom: e });
    const ACCESO = await this.state.data.ACCESO.find(
      (x) => x.ID_ACCESO == e.ID_ACCESO
    );
    await this.setState({ custom: { ...this.state.custom, ACCESO: ACCESO } });
    const ROL = await this.state.data.ROL.find((x) => x.ID_ROL == e.ID_ROL);
    await this.setState({ custom: { ...this.state.custom, ROL: ROL } });
    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_ACCESO_ROL") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR ASINGACIÓN</h1>{" "}
              </Fragment>
            }
            customFooterbar={
          <Fragment>
              <div className="text-center">

          <AutocompleteG  options= {this.state.data.ACCESOS_NO_ROL}
                 OptionLabel= "NOMBRE_ACCESO"
                 placeholder= "SELECCIONE ACCESO"
                 name= "ACCESOXROL"
                 multiple={true}
                 onChange={this.UpdateStateCreate}
                 defaultValue={this.state.custom.ACCESOXROL}
                 label= "SELECCIONE ACCESO"/>
              </div>

              <div className="text-center">

              <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.RegresarAnterior}
                />

{              this.props.Access("CREATE_ACCESO_ROL")&&(
              
              <ButtonG
                config={Agregar}
                className="m-3"
                onClick={this.Create}
              />
            )}    
              </div>
              </Fragment>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_ROL",
                value: this.state.custom.NOMBRE_ROL,
                readOnly:true
              }                   
            ]}
          ></FormsG>
       {this.state.tabla && (
        
          <TableG
            title="ACCESO ROL"
            url="ACCESO_ROL"
            urlSecundary={`rol/${this.props.match.params.id}`}
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_ACCESO",
                label: "NOMBRE ACCESO",
                display: true,
                type: "text",
              },
              {
                name: "FECHA_CREACION_ACCESO_ROL",
                label: "FECHA ACCESO ROL",
                display: true,
                type: "text",
              },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_ACCESO_ROL")
                  ? true
                  : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
            ]}
          />
          )};
      </Fragment>
    );
  }
}
export default withRouter(ACCESO_ROL);
