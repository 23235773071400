import React, { Component, Fragment } from "react";
import { fetchPost, fetchGet } from "../../utils/Fetch";
import imagenes from "./imagenes";
import { withRouter, Redirect, Link } from "react-router-dom";
import "./estilo.css";
import { Button, Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {};
class Inicio extends Component {
  state = {
    img: imagenes.img[0],
    empresas: imagenes.empresas,
  };

  CambioInicio = (item) => async (e) => {
    e.preventDefault();
    await this.setState({ img: imagenes.img[item.index] });
  };

  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
    const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/Inicio`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
  }

  render() {
    const StylerCarouselPrimario = {
      width: "100%",
      height: "370px",
    };
    const StylerCarouselSecundario = {
      width: "600px",
      height: "300px",
    };
    const imgTotalCarousel = {
      width: "280px",
    };

    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Container>
            <button class="w3-btn" style={{}}>
              <img
                src={this.state.img.logoEmpresa}
                style={{ paddingRight: "10px" }}
                width="200px"
                height="50px"
                class="d-inline-block align-top"
              />
            </button>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item active">
                  <Link to="/QuienesSomos" class="nav-link">
                    ¿Quienes Somos?
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/EmpresaIncio" class="nav-link">
                    Empresa
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/ViviendaInicio" class="nav-link">
                    Vivienda
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/ContactanosInicio" class="nav-link">
                    Contactanos
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/login" class="nav-link">
                    Iniciar Sesión
                  </Link>
                </li>
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <br />


        <br />
        <br />
        <br />
        <br />


        {/*
------------------------------------------------*--------------------------------------------*-------------------------*-------------------- */}


<div className="container">
  <div className="row">
    <div className="col-md-12 text-center">
    <h1 className="titulo-proyectos">Nuestros Proyectos</h1>
    </div>
  </div>



  <div className="row" id="el_contenedor">

  <div className="col-md-2">
    </div>


    {/* <div id="carouselExampleIndicators" class="carousel slide col-md-8 text-center" data-bs-ride="carousel"> */}

  <div id="carouselExampleCaptions" class="carousel slide col-md-8 text-center" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 9"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 10"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 10"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="10" aria-label="Slide 11"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="11" aria-label="Slide 12"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="12" aria-label="Slide 13"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="13" aria-label="Slide 14"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="14" aria-label="Slide 15"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="15" aria-label="Slide 16"></button>


    {/* Agrega más botones de indicadores según sea necesario */}
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="overlay"></div>
      <img src={this.state.img.naranjo} class="d-block w-100" alt="Naranjo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Naranjo</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.jocotillo} class="d-block w-100" alt="Jocotillo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Jocotillo</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.pradosdechiquimulilla} class="d-block w-100" alt="Prados de Chiquimulilla" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Prados de Chiquimulilla</h5>
        <p></p>
      </div>
    </div>





    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.arbolitos} class="d-block w-100" alt="Naranjo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Arbolitos</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.brisas} class="d-block w-100" alt="Jocotillo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Brisas del Mar</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.martillo} class="d-block w-100" alt="Prados de Chiquimulilla" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>El Martillo</h5>
        <p></p>
      </div>
    </div>

    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.villasrosario} class="d-block w-100" alt="Naranjo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Villas del Rosario</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.tikal} class="d-block w-100" alt="Jocotillo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Proyecto Tikal</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.santaana} class="d-block w-100" alt="Prados de Chiquimulilla" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Parcelamientos Santa Ana Y Pacayal</h5>
        <p></p>
      </div>
    </div>


    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.SantaElena} class="d-block w-100" alt="Naranjo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Fincas Santa Elena, Posas Verdes</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.LogoSanJacinto} class="d-block w-100" alt="Jocotillo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Haciendas San Jacinto</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.LomaReal} class="d-block w-100" alt="Prados de Chiquimulilla" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Residencial Loma Real</h5>
        <p></p>
      </div>
    </div>

    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.ElEden} class="d-block w-100" alt="Naranjo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Lotrificación El Edén</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.lasflores} class="d-block w-100" alt="Jocotillo" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Lotificación Las Flores</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.ladicha} class="d-block w-100" alt="Prados de Chiquimulilla" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Parcelamiento La Dicha</h5>
        <p></p>
      </div>
    </div>
    <div class="carousel-item">
      <div class="overlay"></div>
      <img src={this.state.img.Pasaco} class="d-block w-100" alt="Prados de Chiquimulilla" />
      <div class="carousel-caption d-flex flex-column justify-content-end align-items-start">
        <h5>Prados del Amate</h5>
        <p></p>
      </div>
    </div>






    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Anterior</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Siguiente</span>
  </button>
</div>



<div className="col-md-2">
    </div>



    <div className="col-md-3">
      <Link to="/NuestrosProyectos/Naranjo" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.naranjo}
          className="img-fluid"
          alt="A"
        />
      </Link>
    </div>

    <div className="col-md-3">
      <Link to="/NuestrosProyectos/Jocotillo" className="w3-btn">
        <img
          id="logo_jocotillo"
          src={this.state.img.jocotillo}
          className="img-fluid"
          alt="Jocotillo"
        />
      </Link>
    </div>

    <div className="col-md-3">
      <Link to="/NuestrosProyectos/Pradosdechiquimulilla" className="w3-btn">
        <img
          id="logo_elvalle"
          src={this.state.img.pradosdechiquimulilla}
          className="img-fluid"
          alt="Prados de Chiquimulilla"
        />
      </Link>
    </div>

    <div className="col-md-3">
      <Link to="/NuestrosProyectos/ResidencialeslosArbolitos" className="w3-btn">
        <img
          id="logoarbolitos"
          src={this.state.img.arbolitos}
          className="img-fluid"
          alt="Residenciales los Arbolitos"
        />
      </Link>
    </div>

    <div className="col-md-3">
      <Link to="/NuestrosProyectos/BrisasdelMar" className="w3-btn">
        <img
          id="logobrisas"
          src={this.state.img.brisas}
          className="img-fluid"
          alt="Brisas del Mar"
        />
      </Link>
    </div>

    <div className="col-md-3">
      <Link to="/NuestrosProyectos/ResidencialesElMartillo" className="w3-btn">
        <img
          id="logomartillo"
          src={this.state.img.martillo}
          className="img-fluid"
          alt="Residenciales El Martillo"
        />
      </Link>
    </div>


    <div className="col-md-3">
      <Link to="/NuestrosProyectos/VillasDelRosario" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.villasrosario}
          className="img-fluid"
          alt="VillasDelRosario"
        />
      </Link>
    </div>


    <div className="col-md-3">
      <Link to="/NuestrosProyectos/ProyectoTikal" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.tikal}
          className="img-fluid"
          alt="Tikal"
        />
      </Link>
    </div>


    <div className="col-md-3">
      <Link to="/NuestrosProyectos/ParcelamientosAnaYPacayal" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.santaana}
          className="img-fluid"
          alt="Tikal"
        />
      </Link>
    </div>

    <div className="col-md-3">
      <Link to="/NuestrosProyectos/SantaElenaPosasVerdes" className="w3-btn">
        <img
          id="logo_jocotillo"
          src={this.state.img.SantaElena}
          className="img-fluid"
          alt="SantaElenaPosasVerdes"
        />
      </Link>
    </div>





    <div className="col-md-3">
      <Link to="/NuestrosProyectos/SanJacinto" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.LogoSanJacinto}
          className="img-fluid"
          alt="SanJacinto"
        />
      </Link>
    </div>



    <div className="col-md-3">
      <Link to="/NuestrosProyectos/LomaReal" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.LomaReal}
          className="img-fluid"
          alt="LomaReal"
        />
      </Link>
    </div>


    <div className="col-md-3">
      <Link to="/NuestrosProyectos/JardinDelEden" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.ElEden}
          className="img-fluid"
          alt="Eden"
        />
      </Link>
    </div>



    <div className="col-md-3">
      <Link to="/NuestrosProyectos/LasFlores" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.lasflores}
          className="img-fluid"
          alt="LasFlores"
        />
      </Link>
    </div>


    <div className="col-md-3">
      <Link to="/NuestrosProyectos/LaDicha" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.ladicha}
          className="img-fluid"
          alt="LaDicha"
        />
      </Link>
    </div>


    <div className="col-md-3">
      <Link to="/NuestrosProyectos/Pasaco" className="w3-btn">
        <img
          id="logo_naranjo"
          src={this.state.img.Pasaco}
          className="img-fluid"
          alt="Pasaco"
        />
      </Link>
    </div>



    {/* <div className="col-md-3">
    <Link to="/Pasaco" class="w3-btn" style={{}}>
              <img
                id="logosantaana"
                src={this.state.img.Pasaco}
                width="80%"
                height="110%"
                class="d-inline-block align-top"
              />
            </Link>
    <Link to="/Pasaco" class="w3-btn" style={{}}>
              <img
                id="logopasaco"
                src={this.state.img.santaana}

                width="100%"
                height="900%"
                class="d-inline-block align-top"
              />
            </Link>
    </div> */}








  </div>


</div>

<br></br>
<br></br>
<br></br>

<footer class="bg-dark py-1">
  <div class="container">
    <div class="row">
      <div class="col-md-2 mb-3 mb-md-0 mx-auto text-center"> </div>
      <div class="col-md-5 mb-3 mb-md-0 mx-auto text-center">
        <h5 class="text-white">IDAS DESING</h5>
        <p class="text-muted">Software a la Medida.</p>
      </div>
      <div class="col-md-3 d-flex justify-content-md-end justify-content-center align-items-center">
        <a href="https://api.whatsapp.com/send?phone=50236659309" target="_blank" class="text-white me-3">
          <i class="fab fa-whatsapp fa-2x"></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=100076337020488" target="_blank" class="text-white me-3">
          <i class="fab fa-facebook fa-2x"></i>
        </a>
        <a href="idasdesing.com/" target="_blank" class="btn btn-outline-light">Visita nuestra página web</a>
      </div>
    </div>
  </div>
</footer>

      </>
    );
  }
}

export default withRouter(Inicio);
