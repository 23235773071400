import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost,fetchDelete,fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {Agregar,ModificarIcon,CrearIcon, Modificar,  EliminarIcon, Regresar} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {Password, Text} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
import { Fab, TextField } from "@material-ui/core";
import ImgG from "../../Material-ui/Input/File/Img";


const initialState = {
custom:{},
file:{},
data:null,
crear:false,
modificar:false,
tabla:true,
};

class Usuarios extends Component {
  state = {
    ...initialState,
  };

  UpdateStateCreate = (e) => {

    if(e.target)//TextField
    {
      if(e.target.type==="file")//file
      {
        const { name} = e.target;
        const image = e.target.files[0]
        // this.setState({ files: e.target.files[0] })
           const reader = new window.FileReader()
           reader.readAsDataURL(image)
           reader.onload =async e  => {
            await this.setState({
              custom:{
                ...this.state.custom,
                [name]:(e.target.result) 
               }
             });
           }
      }
      else{//TextField
      const { name, value } = e.target;
      console.log(name, value )
      this.setState({
       custom:{
         ...this.state.custom,
         [name]: value
        }
      });
    }
    }
    else if(e.reason){//Autocomplete
      const { name, value,reason } = e;
      this.setState({
       custom:{
         ...this.state.custom,
         [name]: value
        }
      });
          }
          else
          {
            const datos=e.split("|")//Date
            this.setState({
              custom:{
                ...this.state.custom,
              [datos[0]]: new Date(datos[1])
            }
            });

          }
  };

  ClearState = () => {
    this.setState({ ...initialState });
  };

  ShowUpdate =async (e) => {
   await this.setState({custom:e})

    const rol=await this.state.data.rol.find(x=>x.ID_ROL==e.ID_ROL)
await this.setState({
  custom:{
    ...this.state.custom,
    rol:rol
  }
})

this.setState({
  crear:false,
  modificar:true,
  tabla:false
})
 };

 ToBinary = function (str) {
  return atob(str).split('').map(function (c) { return c.charCodeAt(0); });
}

ToBase64 = function (u8) {
  return btoa(String.fromCharCode.apply(null, u8));
}

 Create=async (e) => {
  e.preventDefault();
  const data = await fetchPost(
    `${process.env.REACT_APP_SERVER}/api/usuario/create`,
    this.state.custom
  );
  this.setState({ mensaje: data.data });
  alert(data.message);
this.setState({
 custom:{},
crear:false,
modificar:false,
tabla:true
})
};

Update= async(e) => {
  e.preventDefault();
  const data = await fetchPost(
    `${process.env.REACT_APP_SERVER}/api/usuario/CambioContra`,
    this.state.custom
  );
  this.setState({ mensaje: data.data });
  alert(data.message);

this.setState({
  custom:{},
crear:false,
modificar:false,
tabla:true
})
this.props.history.push("/login");

};

  ShowCreate = (e) => {

this.setState({
  crear:true,
  modificar:false,
  tabla:false

})
 };

 ShowTable = (e) => {
  this.props.history.push("/login");
   };

  async componentDidMount() {
   
  }

  validarForm = () => {
    const { user, password } = this.state;

    const noValido = !user || !password;

    return noValido;
  };
  
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("Seguro que deseas eliminar el acceso")) {
      await this.setState({custom:e})
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/usuario/delete/${e.ID_USUARIO}/${false}`
    );
    alert(data.message);
    this.setState({
      custom:{},
     tabla:false
     })
   this.setState({
    tabla:true
   })
  }
    };
    
  render() {
    
    return (
      <Fragment>


  <FormsG updateState={this.UpdateStateCreate}  customToolbar={
<Fragment>
<h1 className="text-center">Modificar Contraseña</h1>
 </Fragment> 
  }
   customFooterbar={<div className="text-center" > 
   <ButtonG config={Regresar} className="m-3"  onClick={this.ShowTable}/>
   <ButtonG config={Modificar} className="m-3"  onClick={this.Update}/></div>}
allcolumns={[
{config:Text,name:"CONTRA_USUARIO",value:this.state.custom.CONTRA_USUARIO,label:"CONTRA_USUARIO",},
]}
 ></FormsG>

     </Fragment>
    );
  }
}

export default withRouter(Usuarios);
