import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ButtonG from "../../Material-ui/Input/Button/Button";
import {
  Agregar,
  ModificarIcon,
  CrearIcon,
  Modificar,
  EliminarIcon,
  Regresar,
} from "../../Material-ui/Input/Button/ConfigButton";
import FormsG from "../../Material-ui/Generador/FormsG";
import {
  Text,
  Number,
} from "../../Material-ui/Input/TextField/ConfigTextField";
import TableG from "../../Material-ui/Generador/TableG";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {
  custom: {},
  data: null,
  crear: false,
  modificar: false,
  tabla: true,
};
class EMPRESA extends Component {
  state = { ...initialState };
  ClearState = () => {
    this.setState({ ...initialState });
  };
  async componentDidMount() {

    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
  }
  calcularEdad=(fecha)=> {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
}
  UpdatesIds = async () => {
    const ID_USUARIO = this.props.auth[0].ID_USUARIO;
    await this.setState({
      custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },
    });
  };
  UpdateStateCreate = async (e) => {
    if (e.target) {
      if (e.target.type === "file") {
        const { name } = e.target;
        const image = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(image);
        reader.onload = async (e) => {
          await this.setState({
            custom: { ...this.state.custom, [name]: e.target.result },
          });
        };
      } else {
        const { name, value } = e.target;
       await this.setState({ custom: { ...this.state.custom, [name]: value } });
      }
    } else if (e.reason) {
      const { name, value, reason } = e;
     await this.setState({ custom: { ...this.state.custom, [name]: value } });
    } else {
      const datos = e.split("|");
     await this.setState({
        custom: { ...this.state.custom, [datos[0]]: new Date(datos[1]) },
      });
      if(datos && datos[0]=="REPRESENTANTE_FECHA_NACIMIENTO")
      {
        if(datos[1])
          {
            await      this.setState({
                custom:{
                  ...this.state.custom,
                  REPRESENTANTE_EDAD:this.calcularEdad(new Date(datos[1]))
                }
              })
          }
      }
    }
  };
  Create = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/create`,
      this.state.custom
    );
    this.setState({ mensaje: data.data });
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Update = async (e) => {
    e.preventDefault();
    await this.UpdatesIds();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/update`,
      this.state.custom
    );
    alert(data.message);
    this.setState({
      file: {},
      custom: {},
      crear: false,
      modificar: false,
      tabla: true,
    });
  };
  Delete = async (e) => {
    console.log(e);
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ELIMINAR AL EMPRESA ?")) {
      await this.setState({ custom: e });
      const data = await fetchPost(
        `${process.env.REACT_APP_SERVER}/api/EMPRESA/delete`,
        this.state.custom
      );
      alert(data.message);
      this.setState({ custom: {}, tabla: false });
      this.setState({ custom: {}, tabla: true });
    }
  };
  ShowCreate = async (e) => {
    await this.setState({ custom: {} });
    await this.setState({
      custom: {
        ...this.state.custom,
        ESTADO: ACTIVO_GENERICO[0].ID_ACTIVO_GENERICO,
      },
    });
    await this.setState({ crear: true, modificar: false, tabla: false });
  };
  ShowTable = (e) => {
    this.setState({ crear: false, modificar: false, tabla: true });
  };
  ShowUpdate = async (e) => {
    this.setState({ custom: e });

    this.setState({ crear: false, modificar: true, tabla: false });
  };
  render() {
    const redireccion = this.props.Access("READ_EMPRESA") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {" "}
        {redireccion}
        {this.state.crear && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">CREAR EMPRESA</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Agregar}
                  className="m-3"
                  onClick={this.Create}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_EMPRESA",
                value: this.state.custom.NOMBRE_EMPRESA,
                label: "NOMBRE_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_EMPRESA",
                value: this.state.custom.DIRECCION_EMPRESA,
                label: "DIRECCION_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "NIT_EMPRESA",
                value: this.state.custom.NIT_EMPRESA,
                label: "NIT_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "RAZON_SOCIAL_EMPRESA",
                value: this.state.custom.RAZON_SOCIAL_EMPRESA,
                label: "RAZON_SOCIAL_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "TELEFONO_EMPRESA",
                value: this.state.custom.TELEFONO_EMPRESA,
                label: "TELEFONO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "ENGARGADO_EMPRESA",
                value: this.state.custom.ENGARGADO_EMPRESA,
                label: "ENGARGADO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_LEGAL",
                value: this.state.custom.REPRESENTANTE_LEGAL,
                label: "REPRESENTANTE_LEGAL",
              },
              {
                type: "D",
                subType: "D",
                name: "REPRESENTANTE_FECHA_NACIMIENTO",
                value: this.state.custom.REPRESENTANTE_FECHA_NACIMIENTO,
                label: "REPRESENTANTE_FECHA_NACIMIENTO",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_DPI",
                value: this.state.custom.REPRESENTANTE_DPI,
                label: "REPRESENTANTE_DPI",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_NACIONALIDAD",
                value: this.state.custom.REPRESENTANTE_NACIONALIDAD,
                label: "REPRESENTANTE_NACIONALIDAD",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_ENTIDAD_MERCANTIL",
                value: this.state.custom.REPRESENTANTE_ENTIDAD_MERCANTIL,
                label: "REPRESENTANTE_ENTIDAD_MERCANTIL",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA",
                value: this.state.custom.NOTARIA,
                label: "NOTARIA",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_REGISTRO_MERCANTIL",
                value: this.state.custom.NOTARIA_REGISTRO_MERCANTIL,
                label: "NOTARIA_REGISTRO_MERCANTIL",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_FOLIO",
                value: this.state.custom.NOTARIA_FOLIO,
                label: "NOTARIA_FOLIO",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_LIBRO",
                value: this.state.custom.NOTARIA_LIBRO,
                label: "NOTARIA_LIBRO",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_ESTADO_CIVIL",
                value: this.state.custom.REPRESENTANTE_ESTADO_CIVIL,
                label: "REPRESENTANTE_ESTADO_CIVIL",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_EDAD",
                value: this.state.custom.REPRESENTANTE_EDAD,
                label: "REPRESENTANTE_EDAD",
              },
              {
                config: Text,
                type: "G",
                name: "MUNICIPIO_EMPRESA",
                value: this.state.custom.MUNICIPIO_EMPRESA,
                label: "MUNICIPIO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "DEPARTAMENTO_EMPRESA",
                value: this.state.custom.DEPARTAMENTO_EMPRESA,
                label: "DEPARTAMENTO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_PROFESION",
                value: this.state.custom.REPRESENTANTE_PROFESION,
                label: "REPRESENTANTE_PROFESION",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_DOMICILIO_DEPARTAMENTO",
                value: this.state.custom.REPRESENTANTE_DOMICILIO_DEPARTAMENTO,
                label: "REPRESENTANTE_DOMICILIO_DEPARTAMENTO",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_REGISTRO_ESCRITURA",
                value: this.state.custom.NOTARIA_REGISTRO_ESCRITURA,
                label: "NOTARIA_REGISTRO_ESCRITURA",
              },
              {
                config: Number,
                type: "G",
                name: "ESCRITURA_PRECIO",
                value: this.state.custom.ESCRITURA_PRECIO,
                label: "ESCRITURA_PRECIO",
              }
            ]}
          ></FormsG>
        )}
        {this.state.modificar && (
          <FormsG
            updateState={this.UpdateStateCreate}
            customToolbar={
              <Fragment>
                {" "}
                <h1 className="text-center">MODIFICAR EMPRESA</h1>{" "}
              </Fragment>
            }
            customFooterbar={
              <div className="text-center">
                {" "}
                <ButtonG
                  config={Regresar}
                  className="m-3"
                  onClick={this.ShowTable}
                />{" "}
                <ButtonG
                  config={Modificar}
                  className="m-3"
                  onClick={this.Update}
                />
              </div>
            }
            allcolumns={[
              {
                config: Text,
                type: "G",
                name: "NOMBRE_EMPRESA",
                value: this.state.custom.NOMBRE_EMPRESA,
                label: "NOMBRE_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "DIRECCION_EMPRESA",
                value: this.state.custom.DIRECCION_EMPRESA,
                label: "DIRECCION_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "NIT_EMPRESA",
                value: this.state.custom.NIT_EMPRESA,
                label: "NIT_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "RAZON_SOCIAL_EMPRESA",
                value: this.state.custom.RAZON_SOCIAL_EMPRESA,
                label: "RAZON_SOCIAL_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "TELEFONO_EMPRESA",
                value: this.state.custom.TELEFONO_EMPRESA,
                label: "TELEFONO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "ENGARGADO_EMPRESA",
                value: this.state.custom.ENGARGADO_EMPRESA,
                label: "ENGARGADO_EMPRESA",
              },
              // {
              //   type: "I",
              //   name: "LOGOTIPO_EMPRESA",
              //   value: this.state.custom.LOGOTIPO_EMPRESA,
              //   label: "SUBIR IMAGEN",
              // },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_LEGAL",
                value: this.state.custom.REPRESENTANTE_LEGAL,
                label: "REPRESENTANTE_LEGAL",
              },
              {
                type: "D",
                subType: "D",
                name: "REPRESENTANTE_FECHA_NACIMIENTO",
                value: this.state.custom.REPRESENTANTE_FECHA_NACIMIENTO,
                label: "REPRESENTANTE_FECHA_NACIMIENTO",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_DPI",
                value: this.state.custom.REPRESENTANTE_DPI,
                label: "REPRESENTANTE_DPI",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_NACIONALIDAD",
                value: this.state.custom.REPRESENTANTE_NACIONALIDAD,
                label: "REPRESENTANTE_NACIONALIDAD",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_ENTIDAD_MERCANTIL",
                value: this.state.custom.REPRESENTANTE_ENTIDAD_MERCANTIL,
                label: "REPRESENTANTE_ENTIDAD_MERCANTIL",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA",
                value: this.state.custom.NOTARIA,
                label: "NOTARIA",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_REGISTRO_MERCANTIL",
                value: this.state.custom.NOTARIA_REGISTRO_MERCANTIL,
                label: "NOTARIA_REGISTRO_MERCANTIL",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_FOLIO",
                value: this.state.custom.NOTARIA_FOLIO,
                label: "NOTARIA_FOLIO",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_LIBRO",
                value: this.state.custom.NOTARIA_LIBRO,
                label: "NOTARIA_LIBRO",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_ESTADO_CIVIL",
                value: this.state.custom.REPRESENTANTE_ESTADO_CIVIL,
                label: "REPRESENTANTE_ESTADO_CIVIL",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_EDAD",
                value: this.state.custom.REPRESENTANTE_EDAD,
                label: "REPRESENTANTE_EDAD",
              },
              {
                config: Text,
                type: "G",
                name: "MUNICIPIO_EMPRESA",
                value: this.state.custom.MUNICIPIO_EMPRESA,
                label: "MUNICIPIO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "DEPARTAMENTO_EMPRESA",
                value: this.state.custom.DEPARTAMENTO_EMPRESA,
                label: "DEPARTAMENTO_EMPRESA",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_PROFESION",
                value: this.state.custom.REPRESENTANTE_PROFESION,
                label: "REPRESENTANTE_PROFESION",
              },
              {
                config: Text,
                type: "G",
                name: "REPRESENTANTE_DOMICILIO_DEPARTAMENTO",
                value: this.state.custom.REPRESENTANTE_DOMICILIO_DEPARTAMENTO,
                label: "REPRESENTANTE_DOMICILIO_DEPARTAMENTO",
              },
              {
                config: Text,
                type: "G",
                name: "NOTARIA_REGISTRO_ESCRITURA",
                value: this.state.custom.NOTARIA_REGISTRO_ESCRITURA,
                label: "NOTARIA_REGISTRO_ESCRITURA",
              },
              {
                config: Number,
                type: "G",
                name: "ESCRITURA_PRECIO",
                value: this.state.custom.ESCRITURA_PRECIO,
                label: "ESCRITURA_PRECIO",
              }
            ]}
          ></FormsG>
        )}
        {this.state.tabla && (
          <TableG
            customToolbar={
              <Fragment>
                {" "}
                {this.props.Access("CREATE_EMPRESA") && (
                  <ButtonG config={CrearIcon} onClick={this.ShowCreate} />
                )}{" "}
              </Fragment>
            }
            title="EMPRESA"
            url="EMPRESA"
            responsive="vertical"
            allcolumns={[
              {
                name: "NOMBRE_EMPRESA",
                label: "NOMBRE_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "DIRECCION_EMPRESA",
                label: "DIRECCION_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "NIT_EMPRESA",
                label: "NIT_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "RAZON_SOCIAL_EMPRESA",
                label: "RAZON_SOCIAL_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "TELEFONO_EMPRESA",
                label: "TELEFONO_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "ENGARGADO_EMPRESA",
                label: "ENGARGADO_EMPRESA",
                display: true,
                type: "text",
              },
              {
                name: "ELIMINAR",
                type: "button",
                display: this.props.Access("DELETE_EMPRESA")
                  ? true
                  : "excluded",
                buttonConfig: EliminarIcon,
                OnClickButton: this.Delete,
              },
              {
                name: "MODIFICAR",
                type: "button",
                display: this.props.Access("UPDATE_EMPRESA")
                  ? true
                  : "excluded",
                buttonConfig: ModificarIcon,
                OnClickButton: this.ShowUpdate,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(EMPRESA);
