import React, { Component, Fragment } from "react";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import imagenes from "../imagenes";
import { withRouter, Redirect, Link } from "react-router-dom";
import "../estilo.css";
import { Button, Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
const ACTIVO_GENERICO = [
  { ID_ACTIVO_GENERICO: true, NOMBRE_ACTIVO_GENERICO: "ACTIVO" },
  { ID_ACTIVO_GENERICO: false, NOMBRE_ACTIVO_GENERICO: "INACTIVO" },
];
const initialState = {};
class Inicio extends Component {
  state = {
    img: imagenes.img[0],
    empresas: imagenes.empresas,
  };

  CambioInicio = (item) => async (e) => {
    e.preventDefault();
    await this.setState({ img: imagenes.img[item.index] });
  };

  async componentDidMount() {
    /*const ID_USUARIO=this.props.auth[0].ID_USUARIO;      await this.setState({        custom: { ...this.state.custom, ID_USUARIO: ID_USUARIO },      });*/
    const EMPRESA = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/EMPRESA/Inicio`
    );
    this.setState({ data: { ...this.state.data, EMPRESA: EMPRESA.data } });
  }

  render() {
    const StylerCarouselPrimario = {
      width: "100%",
      height: "370px",
    };
    const StylerCarouselSecundario = {
      width: "600px",
      height: "300px",
    };
    const imgTotalCarousel = {
      width: "280px",
    };

    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Container>
            <Nav>
              <Nav.Link eventKey={2} href="/">
                <button class="w3-btn" style={{}}>
                  <img
                    src={this.state.img.logoEmpresa}
                    style={{ paddingRight: "10px" }}
                    width="200px"
                    height="50px"
                    class="d-inline-block align-top"
                  />
                </button>
              </Nav.Link>
            </Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item active">
                  <Link to="/QuienesSomos" class="nav-link">
                    ¿Quienes Somos?
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/EmpresaIncio" class="nav-link">
                    Empresa
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/ViviendaInicio" class="nav-link">
                    Vivienda
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/ContactanosInicio" class="nav-link">
                    Contactanos
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link to="/login" class="nav-link">
                    Iniciar Sesión
                  </Link>
                </li>
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withRouter(Inicio);
